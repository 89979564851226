{
    "ua9":[
        658,
        658
    ],
    "uae":[
        658,
        658
    ],
    "u203c":[
        1
    ],
    "u2049":[
        1
    ],
    "u2122":[
        658
    ],
    "u2139":[
        12,
        11
    ],
    "u2194":[
        12,
        11,
        11
    ],
    "u2195":[
        12,
        11,
        11
    ],
    "u2196":[
        12,
        11,
        11
    ],
    "u2197":[
        12,
        11,
        11
    ],
    "u2198":[
        12,
        11,
        11
    ],
    "u2199":[
        12,
        11,
        11
    ],
    "u21a9":[
        12,
        11
    ],
    "u21aa":[
        12,
        11
    ],
    "u231a":[
        658,
        196,
        222,
        478,
        67
    ],
    "u231b":[
        578,
        4,
        12
    ],
    "u2328":[
        196,
        58
    ],
    "u23cf":[
        12,
        11
    ],
    "u23e9":[
        12,
        11
    ],
    "u23ea":[
        12,
        11
    ],
    "u23eb":[
        12,
        11
    ],
    "u23ec":[
        12,
        11
    ],
    "u23ed":[
        12,
        11
    ],
    "u23ee":[
        12,
        11
    ],
    "u23ef":[
        12,
        11
    ],
    "u23f0":[
        195,
        4,
        708,
        2,
        0,
        195,
        707
    ],
    "u23f1":[
        58,
        594,
        478,
        67,
        58,
        2
    ],
    "u23f2":[
        589,
        11,
        67,
        58,
        2
    ],
    "u23f3":[
        578,
        4,
        12
    ],
    "u23f8":[
        12,
        11
    ],
    "u23f9":[
        12,
        11
    ],
    "u23fa":[
        12,
        11
    ],
    "u24c2":[
        12,
        11
    ],
    "u25aa":[
        658
    ],
    "u25ab":[
        0
    ],
    "u25b6":[
        12,
        11
    ],
    "u25c0":[
        12,
        11
    ],
    "u25fb":[
        0
    ],
    "u25fc":[
        658
    ],
    "u25fd":[
        0
    ],
    "u25fe":[
        658
    ],
    "u2600":[
        4,
        4
    ],
    "u2601":[
        222,
        79
    ],
    "u2602":[
        67,
        576,
        6,
        576
    ],
    "u2603":[
        79,
        79,
        707,
        336,
        2,
        707,
        13,
        4,
        125
    ],
    "u2604":[
        593,
        796,
        671,
        478
    ],
    "u260e":[
        1,
        2,
        11
    ],
    "u2611":[
        10,
        419
    ],
    "u2614":[
        67,
        576,
        6,
        576,
        593
    ],
    "u2615":[
        196,
        222,
        478,
        222,
        623,
        596
    ],
    "u2618":[
        197
    ],
    "u261d":[
        694,
        649,
        649
    ],
    "u2620":[
        222,
        58,
        58,
        589
    ],
    "u2622":[
        13,
        11,
        11
    ],
    "u2623":[
        13,
        11,
        11
    ],
    "u2626":[
        6,
        11,
        11
    ],
    "u262a":[
        6,
        11,
        11,
        11
    ],
    "u262e":[
        6,
        11
    ],
    "u262f":[
        6,
        11,
        11,
        6
    ],
    "u2638":[
        6,
        11
    ],
    "u2639":[
        195,
        823
    ],
    "u263a":[
        195,
        824,
        823,
        823
    ],
    "u2640":[
        214,
        11,
        11,
        11
    ],
    "u2642":[
        10,
        11,
        11
    ],
    "u2648":[
        6,
        11
    ],
    "u2649":[
        6,
        11,
        11
    ],
    "u264a":[
        6,
        11,
        11,
        11
    ],
    "u264b":[
        6,
        11,
        6
    ],
    "u264c":[
        6,
        11,
        11
    ],
    "u264d":[
        6,
        11
    ],
    "u264e":[
        6,
        11
    ],
    "u264f":[
        6,
        11,
        11
    ],
    "u2650":[
        6,
        11
    ],
    "u2651":[
        6,
        11
    ],
    "u2652":[
        6,
        11
    ],
    "u2653":[
        6,
        11
    ],
    "u265f":[
        658,
        67
    ],
    "u2660":[
        658
    ],
    "u2663":[
        658
    ],
    "u2665":[
        1
    ],
    "u2666":[
        1
    ],
    "u2668":[
        2,
        2,
        2,
        2
    ],
    "u267b":[
        579,
        197,
        197,
        197,
        197
    ],
    "u267e":[
        661,
        11
    ],
    "u267f":[
        10,
        11,
        11
    ],
    "u2692":[
        1058,
        1058,
        1058,
        67,
        13,
        13,
        67
    ],
    "u2693":[
        10,
        10
    ],
    "u2694":[
        222,
        669,
        596,
        741,
        819,
        4,
        4,
        195,
        222,
        669,
        596,
        741,
        819,
        4,
        4,
        195
    ],
    "u2695":[
        6,
        11,
        11,
        6,
        11
    ],
    "u2696":[
        67,
        67,
        67,
        4,
        4,
        4,
        4
    ],
    "u2697":[
        660,
        79,
        665,
        660,
        660,
        79,
        669,
        661,
        589
    ],
    "u2699":[
        67
    ],
    "u269b":[
        6,
        11,
        11
    ],
    "u269c":[
        576,
        590,
        590,
        6
    ],
    "u26a0":[
        195,
        336
    ],
    "u26a1":[
        4
    ],
    "u26a7":[
        6,
        11,
        11,
        11,
        11
    ],
    "u26aa":[
        0
    ],
    "u26ab":[
        658
    ],
    "u26b0":[
        194,
        8,
        1031,
        596,
        4,
        194
    ],
    "u26b1":[
        596,
        194,
        596,
        8,
        8,
        596
    ],
    "u26bd":[
        478,
        222,
        658
    ],
    "u26be":[
        79,
        1059,
        2
    ],
    "u26c4":[
        79,
        79,
        707,
        336,
        2,
        707,
        13,
        4
    ],
    "u26c5":[
        4,
        4,
        79
    ],
    "u26c8":[
        13,
        79,
        593
    ],
    "u26ce":[
        6,
        11,
        11
    ],
    "u26cf":[
        13,
        13,
        67
    ],
    "u26d1":[
        596,
        194,
        214,
        2,
        9,
        11
    ],
    "u26d3":[
        1060,
        79,
        1060,
        79,
        1060,
        79,
        79,
        1060,
        79,
        79,
        1060,
        79,
        1060,
        1061,
        1060,
        1061
    ],
    "u26d4":[
        1,
        11
    ],
    "u26e9":[
        2,
        2,
        58,
        2
    ],
    "u26ea":[
        714,
        546,
        8,
        546,
        2,
        8
    ],
    "u26f0":[
        58,
        587,
        58,
        58,
        585
    ],
    "u26f1":[
        4,
        67,
        2,
        79,
        2
    ],
    "u26f2":[
        196,
        79,
        589,
        12,
        665,
        79,
        589,
        662,
        125,
        662,
        661,
        419,
        723,
        665,
        662,
        661,
        125
    ],
    "u26f3":[
        197,
        189,
        58,
        4,
        2
    ],
    "u26f4":[
        214,
        220,
        3,
        13,
        222,
        67,
        222,
        2,
        67,
        222,
        10,
        67,
        222,
        3
    ],
    "u26f5":[
        722,
        641,
        3,
        4,
        13,
        0
    ],
    "u26f7":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        649,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f8":[
        196,
        196,
        0,
        0,
        2
    ],
    "u26f9":[
        649,
        675,
        222,
        629,
        222,
        676,
        675,
        649,
        4,
        629,
        649,
        194
    ],
    "u26fa":[
        13,
        195,
        58,
        4,
        578
    ],
    "u26fd":[
        196,
        4,
        589,
        1,
        196,
        79,
        67,
        589
    ],
    "u2702":[
        2,
        196,
        2,
        222,
        196
    ],
    "u2705":[
        197,
        11
    ],
    "u2708":[
        67,
        3,
        222,
        67
    ],
    "u2709":[
        222,
        196,
        196,
        79
    ],
    "u270a":[
        694,
        649,
        649
    ],
    "u270b":[
        694,
        649,
        694
    ],
    "u270c":[
        694,
        649,
        649
    ],
    "u270d":[
        694,
        706,
        694,
        706,
        694,
        706,
        12,
        12,
        694,
        649,
        694,
        12
    ],
    "u270f":[
        596,
        214,
        195,
        58,
        222,
        196
    ],
    "u2712":[
        196,
        67,
        658,
        67
    ],
    "u2714":[
        658
    ],
    "u2716":[
        658
    ],
    "u271d":[
        6,
        11
    ],
    "u2721":[
        6,
        11
    ],
    "u2728":[
        4,
        195
    ],
    "u2733":[
        197,
        11
    ],
    "u2734":[
        658
    ],
    "u2744":[
        125,
        125,
        125
    ],
    "u2747":[
        197,
        11,
        11
    ],
    "u274c":[
        2
    ],
    "u274e":[
        197,
        11
    ],
    "u2753":[
        1
    ],
    "u2754":[
        222
    ],
    "u2755":[
        222
    ],
    "u2757":[
        1
    ],
    "u2763":[
        2
    ],
    "u2764":[
        2
    ],
    "u2795":[
        658
    ],
    "u2796":[
        658
    ],
    "u2797":[
        658
    ],
    "u27a1":[
        12,
        11
    ],
    "u27b0":[
        658
    ],
    "u27bf":[
        10,
        11
    ],
    "u2934":[
        12,
        11
    ],
    "u2935":[
        12,
        11
    ],
    "u2b05":[
        12,
        11
    ],
    "u2b06":[
        12,
        11
    ],
    "u2b07":[
        12,
        11
    ],
    "u2b1b":[
        658
    ],
    "u2b1c":[
        0
    ],
    "u2b50":[
        4
    ],
    "u2b55":[
        2
    ],
    "u3030":[
        58
    ],
    "u303d":[
        195
    ],
    "u3297":[
        2,
        11
    ],
    "u3299":[
        2,
        11
    ],
    "ue50a":[
        196,
        67,
        79,
        222,
        222,
        58,
        1
    ],
    "u1f004":[
        0,
        1
    ],
    "u1f0cf":[
        0,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        2
    ],
    "u1f170":[
        2,
        11
    ],
    "u1f171":[
        2,
        11
    ],
    "u1f17e":[
        2,
        11
    ],
    "u1f17f":[
        10,
        11
    ],
    "u1f18e":[
        2,
        11
    ],
    "u1f191":[
        2,
        11
    ],
    "u1f192":[
        12,
        11,
        11
    ],
    "u1f193":[
        12,
        11
    ],
    "u1f194":[
        6,
        11
    ],
    "u1f195":[
        12,
        11
    ],
    "u1f196":[
        12,
        11
    ],
    "u1f197":[
        12,
        11
    ],
    "u1f198":[
        2,
        11
    ],
    "u1f199":[
        12,
        11
    ],
    "u1f19a":[
        13,
        11
    ],
    "u1f1e6":[
        12,
        11
    ],
    "u1f1e7":[
        12,
        11
    ],
    "u1f1e8":[
        12,
        11
    ],
    "u1f1e9":[
        12,
        11
    ],
    "u1f1ea":[
        12,
        11
    ],
    "u1f1eb":[
        12,
        11
    ],
    "u1f1ec":[
        12,
        11
    ],
    "u1f1ed":[
        12,
        11
    ],
    "u1f1ee":[
        12,
        11
    ],
    "u1f1ef":[
        12,
        11
    ],
    "u1f1f0":[
        12,
        11
    ],
    "u1f1f1":[
        12,
        11
    ],
    "u1f1f2":[
        12,
        11
    ],
    "u1f1f3":[
        12,
        11
    ],
    "u1f1f4":[
        12,
        11
    ],
    "u1f1f5":[
        12,
        11
    ],
    "u1f1f6":[
        12,
        11
    ],
    "u1f1f7":[
        12,
        11
    ],
    "u1f1f8":[
        12,
        11
    ],
    "u1f1f9":[
        12,
        11
    ],
    "u1f1fa":[
        12,
        11
    ],
    "u1f1fb":[
        12,
        11
    ],
    "u1f1fc":[
        12,
        11
    ],
    "u1f1fd":[
        12,
        11
    ],
    "u1f1fe":[
        12,
        11
    ],
    "u1f1ff":[
        12,
        11
    ],
    "u1f201":[
        12,
        11
    ],
    "u1f202":[
        10,
        11
    ],
    "u1f21a":[
        13,
        11
    ],
    "u1f22f":[
        197,
        11
    ],
    "u1f232":[
        2,
        11
    ],
    "u1f233":[
        10,
        11
    ],
    "u1f234":[
        2,
        11
    ],
    "u1f235":[
        2,
        11
    ],
    "u1f236":[
        13,
        11
    ],
    "u1f237":[
        13,
        11
    ],
    "u1f238":[
        13,
        11
    ],
    "u1f239":[
        2,
        11
    ],
    "u1f23a":[
        13,
        11
    ],
    "u1f250":[
        2,
        2
    ],
    "u1f251":[
        13,
        11
    ],
    "u1f300":[
        3
    ],
    "u1f301":[
        125,
        79,
        222,
        79,
        575,
        478,
        222
    ],
    "u1f302":[
        67,
        6,
        576
    ],
    "u1f303":[
        10,
        577,
        58,
        195,
        11
    ],
    "u1f304":[
        195,
        13,
        578,
        189,
        579
    ],
    "u1f305":[
        195,
        13,
        578,
        10,
        12
    ],
    "u1f306":[
        13,
        580,
        58,
        195
    ],
    "u1f307":[
        195,
        13,
        578,
        580,
        58,
        195
    ],
    "u1f308":[
        581,
        582,
        583,
        584,
        585,
        586
    ],
    "u1f309":[
        426,
        575,
        11
    ],
    "u1f30a":[
        10,
        3
    ],
    "u1f30b":[
        58,
        587,
        58,
        4,
        4,
        588,
        588,
        4,
        4,
        588,
        222,
        589,
        589,
        589,
        589,
        589,
        589,
        589
    ],
    "u1f30c":[
        5,
        576,
        58,
        590,
        591,
        478
    ],
    "u1f30d":[
        125,
        189
    ],
    "u1f30e":[
        125,
        189
    ],
    "u1f30f":[
        125,
        189,
        189
    ],
    "u1f310":[
        12
    ],
    "u1f311":[
        67,
        592
    ],
    "u1f312":[
        546,
        67,
        592,
        195
    ],
    "u1f313":[
        546,
        67,
        195,
        592
    ],
    "u1f314":[
        546,
        67,
        195,
        592
    ],
    "u1f315":[
        546,
        195
    ],
    "u1f316":[
        546,
        67,
        195,
        592
    ],
    "u1f317":[
        546,
        67,
        195,
        592
    ],
    "u1f318":[
        546,
        67,
        592,
        195
    ],
    "u1f319":[
        546,
        195
    ],
    "u1f31a":[
        67,
        592,
        58
    ],
    "u1f31b":[
        546,
        195,
        58
    ],
    "u1f31c":[
        546,
        195,
        58
    ],
    "u1f31d":[
        546,
        195,
        58
    ],
    "u1f31e":[
        13,
        195,
        13
    ],
    "u1f31f":[
        4,
        546
    ],
    "u1f320":[
        10,
        13,
        4,
        546
    ],
    "u1f321":[
        222,
        2,
        58
    ],
    "u1f324":[
        4,
        4,
        79
    ],
    "u1f325":[
        4,
        4,
        79
    ],
    "u1f326":[
        4,
        4,
        79,
        593
    ],
    "u1f327":[
        79,
        593
    ],
    "u1f328":[
        79,
        593
    ],
    "u1f329":[
        13,
        79
    ],
    "u1f32a":[
        594,
        67,
        67,
        595,
        222
    ],
    "u1f32b":[
        478,
        79,
        222,
        79,
        478,
        222
    ],
    "u1f32c":[
        419,
        419,
        419,
        419,
        419,
        419,
        222,
        196,
        196,
        196
    ],
    "u1f32d":[
        596,
        597,
        2,
        195
    ],
    "u1f32e":[
        578,
        578,
        4,
        4,
        578,
        4,
        596,
        194,
        596,
        195,
        195,
        194,
        596,
        546,
        578,
        546,
        546,
        578,
        197,
        220,
        2,
        546,
        220,
        2,
        2,
        13
    ],
    "u1f32f":[
        195,
        4,
        596,
        598,
        599,
        600,
        601,
        194,
        578,
        194,
        13,
        596,
        596,
        546,
        197,
        220,
        546,
        578,
        578,
        197,
        220,
        220,
        2,
        197,
        2
    ],
    "u1f330":[
        194,
        602,
        602,
        602,
        602,
        8
    ],
    "u1f331":[
        197
    ],
    "u1f332":[
        8,
        189,
        579,
        189,
        579,
        189,
        579,
        189,
        579,
        189
    ],
    "u1f333":[
        8,
        189,
        579
    ],
    "u1f334":[
        194,
        596,
        579,
        189
    ],
    "u1f335":[
        197,
        579
    ],
    "u1f336":[
        603,
        197
    ],
    "u1f337":[
        197,
        214,
        604
    ],
    "u1f338":[
        604,
        11,
        11,
        11,
        11,
        11,
        214,
        195
    ],
    "u1f339":[
        579,
        9,
        1
    ],
    "u1f33a":[
        197,
        197,
        604,
        214,
        1,
        195
    ],
    "u1f33b":[
        579,
        4,
        605
    ],
    "u1f33c":[
        197,
        222,
        79,
        13
    ],
    "u1f33d":[
        189,
        13,
        606,
        197
    ],
    "u1f33e":[
        195,
        197
    ],
    "u1f33f":[
        197,
        220,
        189,
        607
    ],
    "u1f340":[
        197
    ],
    "u1f341":[
        2
    ],
    "u1f342":[
        596,
        194
    ],
    "u1f343":[
        220,
        197,
        593
    ],
    "u1f344":[
        196,
        2,
        604,
        604,
        604
    ],
    "u1f345":[
        2,
        197
    ],
    "u1f346":[
        576,
        197
    ],
    "u1f347":[
        197,
        5,
        6,
        590,
        576,
        6,
        6
    ],
    "u1f348":[
        220,
        197,
        189,
        197
    ],
    "u1f349":[
        189,
        578,
        2,
        52
    ],
    "u1f34a":[
        13,
        8,
        189
    ],
    "u1f34b":[
        189,
        197,
        195,
        197
    ],
    "u1f34c":[
        578,
        546,
        195,
        578,
        220,
        579,
        195,
        578,
        546,
        194
    ],
    "u1f34d":[
        189,
        4,
        195
    ],
    "u1f34e":[
        2,
        197,
        8
    ],
    "u1f34f":[
        197,
        579,
        8
    ],
    "u1f350":[
        220,
        8
    ],
    "u1f351":[
        189,
        608,
        197,
        2
    ],
    "u1f352":[
        197,
        189,
        1,
        1
    ],
    "u1f353":[
        1,
        197,
        604
    ],
    "u1f354":[
        596,
        8,
        195,
        197,
        2,
        596,
        578
    ],
    "u1f355":[
        13,
        195,
        1,
        1
    ],
    "u1f356":[
        546,
        194,
        8
    ],
    "u1f357":[
        546,
        194
    ],
    "u1f358":[
        194,
        609,
        58
    ],
    "u1f359":[
        32,
        222,
        58
    ],
    "u1f35a":[
        2,
        9,
        610,
        222
    ],
    "u1f35b":[
        611,
        612,
        13,
        32,
        222,
        546
    ],
    "u1f35c":[
        222,
        196,
        214,
        4,
        546,
        613,
        546
    ],
    "u1f35d":[
        611,
        612,
        195,
        578,
        578,
        2,
        214,
        4,
        196,
        4
    ],
    "u1f35e":[
        596,
        614,
        578
    ],
    "u1f35f":[
        1,
        13,
        195,
        2,
        1
    ],
    "u1f360":[
        615,
        195,
        616,
        615,
        617
    ],
    "u1f361":[
        596,
        618,
        546,
        220,
        596
    ],
    "u1f362":[
        596,
        67,
        596,
        222,
        596
    ],
    "u1f363":[
        32,
        222,
        9,
        2,
        214,
        58,
        58,
        79,
        79,
        197,
        197,
        13,
        13
    ],
    "u1f364":[
        4,
        13,
        619,
        214
    ],
    "u1f365":[
        79,
        620
    ],
    "u1f366":[
        195,
        4,
        195,
        13,
        578,
        546
    ],
    "u1f367":[
        3,
        12,
        3,
        621,
        622,
        214
    ],
    "u1f368":[
        3,
        12,
        604,
        578,
        623,
        12,
        3
    ],
    "u1f369":[
        4,
        623,
        623,
        13,
        604,
        3,
        125,
        3
    ],
    "u1f36a":[
        624,
        623
    ],
    "u1f36b":[
        194,
        623,
        2,
        1,
        222
    ],
    "u1f36c":[
        2,
        1,
        2,
        478,
        478
    ],
    "u1f36d":[
        4,
        2,
        13,
        195
    ],
    "u1f36e":[
        611,
        612,
        194,
        623,
        195,
        8
    ],
    "u1f36f":[
        13,
        1,
        4,
        578,
        8,
        13,
        195,
        13
    ],
    "u1f370":[
        611,
        612,
        11,
        79,
        4,
        546,
        4,
        79,
        11,
        2,
        604
    ],
    "u1f371":[
        58,
        58,
        1,
        2,
        79,
        4,
        546,
        546,
        189,
        189,
        197,
        625,
        626,
        13,
        222
    ],
    "u1f372":[
        58,
        67,
        195,
        197,
        189,
        596,
        627,
        578
    ],
    "u1f373":[
        58,
        67,
        478,
        4
    ],
    "u1f374":[
        196
    ],
    "u1f375":[
        58,
        67,
        79,
        197,
        220,
        189
    ],
    "u1f376":[
        3,
        12,
        125,
        222,
        594,
        11,
        125,
        125,
        125,
        125,
        419
    ],
    "u1f377":[
        594,
        222,
        222,
        9,
        2,
        11
    ],
    "u1f378":[
        594,
        222,
        8,
        189,
        11
    ],
    "u1f379":[
        594,
        222,
        222,
        13,
        195,
        194,
        11,
        4,
        628,
        197,
        189,
        579
    ],
    "u1f37a":[
        4,
        195,
        13,
        4,
        32
    ],
    "u1f37b":[
        4,
        4,
        13,
        79,
        629,
        195,
        13,
        4,
        32
    ],
    "u1f37c":[
        4,
        222,
        3,
        196
    ],
    "u1f37d":[
        79,
        222,
        79,
        196,
        196,
        196,
        196,
        196,
        196,
        196
    ],
    "u1f37e":[
        222,
        630,
        194,
        602,
        631,
        578,
        546,
        631
    ],
    "u1f37f":[
        79,
        603,
        632,
        546,
        4,
        4,
        11
    ],
    "u1f380":[
        2,
        9,
        214
    ],
    "u1f381":[
        633,
        633,
        634,
        603,
        603
    ],
    "u1f382":[
        594,
        222,
        2,
        604,
        2,
        214,
        635,
        636
    ],
    "u1f383":[
        13,
        637,
        638
    ],
    "u1f384":[
        8,
        189,
        579,
        189,
        579,
        189,
        579,
        189,
        579,
        189,
        195,
        2,
        220,
        4
    ],
    "u1f385":[
        649,
        649,
        2,
        649,
        8,
        640,
        0,
        649,
        641,
        612
    ],
    "u1f386":[
        10,
        3,
        546,
        478,
        195
    ],
    "u1f387":[
        10,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        3,
        478,
        478,
        478
    ],
    "u1f388":[
        634,
        650,
        650,
        634
    ],
    "u1f389":[
        2,
        214,
        9,
        590,
        197,
        189,
        6,
        195
    ],
    "u1f38a":[
        58,
        4,
        1,
        4,
        1,
        590,
        197,
        189,
        6,
        195
    ],
    "u1f38b":[
        52,
        1,
        9,
        4,
        220,
        189,
        579,
        189,
        579,
        220
    ],
    "u1f38c":[
        651,
        67,
        1,
        651,
        67,
        1
    ],
    "u1f38d":[
        197,
        578,
        197,
        578,
        189,
        596,
        189
    ],
    "u1f38e":[
        652,
        653,
        654,
        195,
        58,
        478,
        58,
        9,
        652,
        653,
        655,
        656,
        419,
        195,
        478,
        58,
        9,
        657
    ],
    "u1f38f":[
        222,
        13,
        67,
        2,
        11,
        52,
        604,
        3,
        11,
        52,
        419
    ],
    "u1f390":[
        196,
        579,
        197,
        579,
        11
    ],
    "u1f391":[
        10,
        195,
        197,
        546,
        79,
        194
    ],
    "u1f392":[
        194,
        8,
        194,
        1,
        9,
        11
    ],
    "u1f393":[
        658,
        58,
        659,
        634,
        633,
        634,
        658
    ],
    "u1f396":[
        3,
        79,
        2,
        4,
        546,
        4
    ],
    "u1f397":[
        4,
        195,
        4,
        195
    ],
    "u1f399":[
        58,
        67,
        196,
        58,
        67,
        67
    ],
    "u1f39a":[
        222,
        58,
        196,
        10
    ],
    "u1f39b":[
        222,
        660,
        603,
        52,
        58,
        79
    ],
    "u1f39e":[
        419,
        336,
        478,
        125
    ],
    "u1f39f":[
        214,
        1
    ],
    "u1f3a0":[
        2,
        195,
        13,
        594,
        222,
        661,
        13,
        662,
        195,
        13,
        58,
        2
    ],
    "u1f3a1":[
        222,
        589,
        589,
        594,
        5,
        576,
        591,
        189,
        663,
        661,
        419,
        1,
        604
    ],
    "u1f3a2":[
        79,
        222,
        594,
        222,
        2,
        662,
        214,
        220,
        661,
        13,
        590,
        661,
        79
    ],
    "u1f3a3":[
        222,
        12,
        12,
        52,
        3,
        52,
        10,
        8,
        196,
        67
    ],
    "u1f3a4":[
        594,
        658,
        3
    ],
    "u1f3a5":[
        658,
        67,
        222,
        594,
        658
    ],
    "u1f3a6":[
        12,
        11,
        11
    ],
    "u1f3a7":[
        67,
        658,
        3
    ],
    "u1f3a8":[
        596,
        189,
        10,
        2,
        195
    ],
    "u1f3a9":[
        658,
        67,
        576
    ],
    "u1f3aa":[
        2,
        214,
        1,
        1,
        52,
        3,
        222,
        478,
        478
    ],
    "u1f3ab":[
        222,
        196
    ],
    "u1f3ac":[
        637,
        637,
        222,
        58
    ],
    "u1f3ad":[
        220,
        189,
        591,
        6
    ],
    "u1f3ae":[
        658,
        664,
        664,
        664,
        664,
        664,
        660,
        195,
        197,
        665,
        2,
        594
    ],
    "u1f3af":[
        2,
        11,
        2,
        11,
        2,
        666,
        4,
        3,
        667
    ],
    "u1f3b0":[
        658,
        222,
        79,
        1,
        6,
        13
    ],
    "u1f3b1":[
        658,
        79,
        658
    ],
    "u1f3b2":[
        9,
        2,
        214,
        11,
        79
    ],
    "u1f3b3":[
        222,
        1,
        222,
        1,
        658,
        52
    ],
    "u1f3b4":[
        2,
        52,
        11
    ],
    "u1f3b5":[
        593
    ],
    "u1f3b6":[
        593,
        593
    ],
    "u1f3b7":[
        634,
        668,
        222,
        669,
        634
    ],
    "u1f3b8":[
        650,
        58,
        478,
        58,
        222,
        58,
        650,
        67,
        67,
        67
    ],
    "u1f3b9":[
        658,
        79,
        658
    ],
    "u1f3ba":[
        634,
        222,
        634,
        634,
        222
    ],
    "u1f3bb":[
        670,
        58,
        638,
        222,
        670,
        58,
        638
    ],
    "u1f3bc":[
        594,
        658
    ],
    "u1f3bd":[
        10,
        3,
        546
    ],
    "u1f3be":[
        197,
        220,
        11
    ],
    "u1f3bf":[
        79,
        3,
        10,
        1,
        12,
        10,
        419
    ],
    "u1f3c0":[
        13,
        336
    ],
    "u1f3c1":[
        594,
        589,
        594,
        589,
        658,
        79
    ],
    "u1f3c2":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        649,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c3":[
        649,
        594,
        675,
        629,
        222,
        676,
        675,
        649,
        4,
        629,
        649
    ],
    "u1f3c4":[
        674,
        662,
        220,
        478,
        649,
        649,
        4,
        649,
        58,
        660,
        660
    ],
    "u1f3c5":[
        3,
        12,
        4,
        546,
        4
    ],
    "u1f3c6":[
        4,
        195,
        194,
        194
    ],
    "u1f3c7":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        649,
        4,
        6,
        52,
        222,
        189,
        220,
        649,
        589
    ],
    "u1f3c8":[
        8,
        79
    ],
    "u1f3c9":[
        12,
        222,
        79
    ],
    "u1f3ca":[
        649,
        629,
        675,
        634,
        3,
        3
    ],
    "u1f3cb":[
        649,
        629,
        196,
        58,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        4,
        649,
        649,
        194,
        8
    ],
    "u1f3cc":[
        649,
        699,
        700,
        701,
        67,
        649,
        222,
        706,
        701,
        222,
        700,
        649,
        629,
        649,
        4,
        699,
        701,
        649,
        629,
        67
    ],
    "u1f3cd":[
        196,
        58,
        197,
        196,
        196,
        222,
        197,
        67,
        222,
        11,
        67,
        58,
        197,
        222,
        67,
        58
    ],
    "u1f3ce":[
        707,
        13,
        12,
        58,
        708,
        11,
        336,
        13,
        125,
        195,
        2
    ],
    "u1f3cf":[
        596,
        194,
        58,
        1,
        8
    ],
    "u1f3d0":[
        0,
        196
    ],
    "u1f3d1":[
        194,
        2,
        4
    ],
    "u1f3d2":[
        13,
        658,
        67
    ],
    "u1f3d3":[
        2,
        596,
        195,
        222
    ],
    "u1f3d4":[
        58,
        587,
        11,
        58,
        709,
        11,
        58,
        585,
        709,
        710
    ],
    "u1f3d5":[
        711,
        8,
        189,
        579,
        189,
        579,
        189,
        579,
        189,
        579,
        189,
        13,
        195,
        58,
        4,
        578,
        79
    ],
    "u1f3d6":[
        12,
        125,
        13,
        67,
        2,
        578,
        2,
        4
    ],
    "u1f3d7":[
        67,
        583,
        712,
        587,
        713,
        67
    ],
    "u1f3d8":[
        58,
        195,
        58,
        8,
        12,
        578,
        67,
        195,
        194,
        3,
        67,
        67
    ],
    "u1f3d9":[
        125,
        67,
        58,
        195
    ],
    "u1f3da":[
        8,
        596,
        194,
        714,
        58,
        8,
        8
    ],
    "u1f3db":[
        58,
        222,
        669,
        222,
        79,
        669,
        222
    ],
    "u1f3dc":[
        4,
        195,
        4,
        578,
        13,
        4,
        197,
        579,
        13
    ],
    "u1f3dd":[
        12,
        125,
        13,
        195,
        4,
        194,
        596,
        579,
        189
    ],
    "u1f3de":[
        125,
        58,
        67,
        189,
        3,
        10,
        8,
        579,
        478
    ],
    "u1f3df":[
        715,
        67,
        424,
        67,
        586,
        424,
        716,
        717,
        716,
        585
    ],
    "u1f3e0":[
        9,
        578,
        195,
        67,
        67,
        194,
        3,
        189
    ],
    "u1f3e1":[
        189,
        9,
        195,
        197,
        578,
        195,
        67,
        67,
        194,
        3,
        197
    ],
    "u1f3e2":[
        718,
        719,
        718,
        3,
        12,
        3,
        12,
        3,
        67
    ],
    "u1f3e3":[
        589,
        67,
        3,
        589,
        222,
        3,
        222,
        12,
        2
    ],
    "u1f3e4":[
        7,
        1,
        578,
        194,
        222,
        589,
        596,
        7,
        4
    ],
    "u1f3e5":[
        222,
        196,
        196,
        3,
        79,
        3,
        12,
        2
    ],
    "u1f3e6":[
        67,
        222,
        58,
        222,
        589,
        594,
        222,
        589,
        79,
        478
    ],
    "u1f3e7":[
        12,
        419,
        10
    ],
    "u1f3e8":[
        194,
        194,
        195,
        3,
        578,
        3,
        578,
        12,
        195
    ],
    "u1f3e9":[
        1,
        1,
        604,
        12,
        720,
        3,
        720,
        12,
        2,
        604,
        214
    ],
    "u1f3ea":[
        589,
        58,
        12,
        222,
        67,
        3,
        12,
        2,
        604,
        11,
        9
    ],
    "u1f3eb":[
        195,
        721,
        12,
        195,
        578,
        599,
        3,
        578,
        10,
        722,
        0,
        9
    ],
    "u1f3ec":[
        195,
        714,
        3,
        578,
        714,
        3,
        12,
        195,
        2,
        708,
        604,
        11
    ],
    "u1f3ed":[
        1,
        0,
        9,
        194,
        589,
        641
    ],
    "u1f3ee":[
        58,
        1,
        2
    ],
    "u1f3ef":[
        641,
        596,
        67,
        641,
        67,
        196,
        721
    ],
    "u1f3f0":[
        222,
        10,
        589,
        723,
        10,
        67,
        723,
        594,
        222
    ],
    "u1f3f3":[
        594,
        589,
        594,
        589,
        79
    ],
    "u1f3f4":[
        594,
        589,
        594,
        589,
        658
    ],
    "u1f3f5":[
        588,
        4,
        13,
        195,
        32
    ],
    "u1f3f7":[
        546,
        596,
        194
    ],
    "u1f3f8":[
        3,
        58,
        196,
        196,
        196,
        3,
        10,
        10,
        1,
        196,
        79,
        222
    ],
    "u1f3f9":[
        194,
        596,
        196,
        2,
        13,
        9,
        2,
        13,
        9,
        2,
        222
    ],
    "u1f3fa":[
        194,
        733,
        194
    ],
    "u1f3fb":[
        639
    ],
    "u1f3fc":[
        642
    ],
    "u1f3fd":[
        643
    ],
    "u1f3fe":[
        644
    ],
    "u1f3ff":[
        647
    ],
    "u1f400":[
        214,
        67,
        67,
        67,
        734,
        58,
        67
    ],
    "u1f401":[
        214,
        222,
        222,
        222,
        604,
        2
    ],
    "u1f402":[
        194,
        222,
        58
    ],
    "u1f403":[
        658,
        11,
        196
    ],
    "u1f404":[
        222,
        658,
        658,
        222
    ],
    "u1f405":[
        4,
        58,
        58
    ],
    "u1f406":[
        195,
        58,
        13,
        4
    ],
    "u1f407":[
        196,
        604,
        196,
        604,
        222,
        196,
        58,
        604
    ],
    "u1f408":[
        670,
        737,
        58,
        738,
        739,
        670,
        740
    ],
    "u1f409":[
        579,
        197,
        58,
        579,
        11,
        579,
        197,
        579,
        189
    ],
    "u1f40a":[
        579,
        189,
        58,
        197,
        11
    ],
    "u1f40b":[
        12,
        3,
        58
    ],
    "u1f40c":[
        6,
        590,
        195,
        4
    ],
    "u1f40d":[
        2,
        197,
        58
    ],
    "u1f40e":[
        58,
        623,
        58,
        194,
        58
    ],
    "u1f40f":[
        58,
        67,
        79,
        196,
        58,
        79,
        195
    ],
    "u1f410":[
        4,
        195,
        79,
        222,
        58
    ],
    "u1f411":[
        4,
        79,
        58,
        195
    ],
    "u1f412":[
        741,
        742,
        58,
        638
    ],
    "u1f413":[
        4,
        2,
        79,
        58
    ],
    "u1f414":[
        79,
        79,
        2,
        13,
        4,
        58
    ],
    "u1f415":[
        596,
        194,
        596,
        743,
        743,
        733,
        8,
        58,
        194,
        596,
        596
    ],
    "u1f416":[
        604,
        58
    ],
    "u1f417":[
        194,
        8,
        79,
        52,
        8,
        194,
        58
    ],
    "u1f418":[
        196,
        67,
        58
    ],
    "u1f419":[
        5,
        5,
        576,
        6,
        58
    ],
    "u1f41a":[
        222,
        67,
        196
    ],
    "u1f41b":[
        590,
        576,
        6,
        11,
        576
    ],
    "u1f41c":[
        658,
        658,
        658
    ],
    "u1f41d":[
        658,
        195,
        222,
        196
    ],
    "u1f41e":[
        658,
        658,
        2,
        658
    ],
    "u1f41f":[
        12,
        12,
        52,
        3,
        52,
        10
    ],
    "u1f420":[
        4,
        195,
        13,
        13,
        58
    ],
    "u1f421":[
        596,
        596,
        194,
        596,
        58,
        194,
        8
    ],
    "u1f422":[
        197,
        197,
        579,
        58,
        189
    ],
    "u1f423":[
        196,
        195,
        13,
        8,
        79
    ],
    "u1f424":[
        13,
        4,
        195,
        8,
        4
    ],
    "u1f425":[
        4,
        195,
        13,
        8
    ],
    "u1f426":[
        4,
        2,
        2,
        2,
        2,
        58,
        9,
        2,
        2
    ],
    "u1f427":[
        4,
        58,
        478,
        4
    ],
    "u1f428":[
        196,
        67,
        58
    ],
    "u1f429":[
        196,
        67,
        67,
        222,
        67,
        67,
        222,
        196,
        196,
        222,
        67,
        58,
        196
    ],
    "u1f42a":[
        194,
        58
    ],
    "u1f42b":[
        194,
        58
    ],
    "u1f42c":[
        744,
        745,
        746,
        744
    ],
    "u1f42d":[
        196,
        604,
        196,
        604,
        67,
        67,
        67,
        733
    ],
    "u1f42e":[
        196,
        578,
        222,
        734,
        194,
        733
    ],
    "u1f42f":[
        195,
        2,
        734,
        195,
        733,
        11,
        733
    ],
    "u1f430":[
        196,
        604,
        222,
        11,
        58,
        604
    ],
    "u1f431":[
        195,
        670,
        195,
        58,
        739,
        660,
        738
    ],
    "u1f432":[
        579,
        197,
        58,
        579,
        11,
        579
    ],
    "u1f433":[
        12,
        3,
        12,
        3,
        58
    ],
    "u1f434":[
        194,
        58,
        58,
        194,
        8,
        596
    ],
    "u1f435":[
        742,
        742,
        741,
        742,
        58,
        638,
        741
    ],
    "u1f436":[
        2,
        1,
        596,
        743,
        58,
        8,
        596,
        747
    ],
    "u1f437":[
        604,
        214,
        8,
        58
    ],
    "u1f438":[
        663,
        197,
        11,
        58,
        189
    ],
    "u1f439":[
        195,
        214,
        578,
        195,
        214,
        194,
        733
    ],
    "u1f43a":[
        67,
        222,
        67,
        222,
        58
    ],
    "u1f43b":[
        194,
        734,
        194,
        2,
        596,
        733
    ],
    "u1f43c":[
        733,
        67,
        32,
        222,
        2,
        733,
        67,
        11,
        67,
        733
    ],
    "u1f43d":[
        734,
        8
    ],
    "u1f43e":[
        8
    ],
    "u1f43f":[
        748,
        596,
        58,
        8,
        748,
        8,
        748
    ],
    "u1f440":[
        478,
        79,
        594,
        58,
        478,
        79,
        594,
        58,
        478
    ],
    "u1f441":[
        79,
        58,
        478,
        749,
        58,
        478
    ],
    "u1f442":[
        649,
        694,
        694
    ],
    "u1f443":[
        649,
        694,
        8
    ],
    "u1f444":[
        738,
        8,
        11
    ],
    "u1f445":[
        8,
        214,
        2
    ],
    "u1f446":[
        649
    ],
    "u1f447":[
        649
    ],
    "u1f448":[
        649
    ],
    "u1f449":[
        649
    ],
    "u1f44a":[
        649,
        694,
        694
    ],
    "u1f44b":[
        694,
        649,
        593,
        593,
        593
    ],
    "u1f44c":[
        694,
        649
    ],
    "u1f44d":[
        754,
        755
    ],
    "u1f44e":[
        754,
        755
    ],
    "u1f44f":[
        694,
        629,
        754
    ],
    "u1f450":[
        694,
        649,
        649
    ],
    "u1f451":[
        13,
        13,
        13,
        195,
        189,
        756,
        2,
        4,
        195,
        13
    ],
    "u1f452":[
        546,
        546,
        6,
        11,
        11,
        11,
        11,
        4
    ],
    "u1f453":[
        658,
        3
    ],
    "u1f454":[
        125,
        2,
        9,
        2,
        757,
        757,
        10
    ],
    "u1f455":[
        12,
        3,
        12,
        3
    ],
    "u1f456":[
        12,
        12,
        10,
        10
    ],
    "u1f457":[
        3,
        419,
        12
    ],
    "u1f458":[
        12,
        12,
        10,
        125,
        11,
        196,
        10
    ],
    "u1f459":[
        5,
        576
    ],
    "u1f45a":[
        5,
        590,
        6
    ],
    "u1f45b":[
        611,
        641,
        641,
        214,
        2
    ],
    "u1f45c":[
        4,
        6,
        546
    ],
    "u1f45d":[
        194,
        220,
        197
    ],
    "u1f45e":[
        758,
        759,
        759,
        58
    ],
    "u1f45f":[
        594,
        1,
        9,
        2,
        222,
        2
    ],
    "u1f460":[
        58,
        2
    ],
    "u1f461":[
        596,
        194,
        1
    ],
    "u1f462":[
        8,
        194,
        596
    ],
    "u1f463":[
        8
    ],
    "u1f464":[
        10
    ],
    "u1f465":[
        3,
        10
    ],
    "u1f466":[
        649,
        649,
        8,
        194,
        4
    ],
    "u1f467":[
        4,
        4,
        4,
        649,
        649,
        760,
        194,
        8,
        4
    ],
    "u1f468":[
        649,
        649,
        8,
        194,
        4
    ],
    "u1f469":[
        4,
        649,
        760,
        194,
        4,
        8
    ],
    "u1f46a":[
        4,
        649,
        649,
        194,
        8,
        629,
        675,
        649,
        194,
        8,
        196,
        67,
        4,
        649,
        649,
        194,
        8,
        189
    ],
    "u1f46b":[
        4,
        649,
        706,
        649,
        58,
        738,
        603,
        649,
        706,
        649,
        4,
        760,
        8,
        194,
        589,
        58,
        674,
        649,
        706,
        649,
        4,
        194,
        8
    ],
    "u1f46c":[
        4,
        649,
        589,
        58,
        674,
        649,
        706,
        649,
        4,
        194,
        8,
        649,
        660,
        58,
        711,
        649,
        706,
        649,
        4,
        194,
        8
    ],
    "u1f46d":[
        4,
        649,
        706,
        649,
        58,
        738,
        603,
        649,
        706,
        649,
        4,
        760,
        8,
        194,
        706,
        649,
        58,
        784,
        790,
        649,
        706,
        649,
        4,
        760,
        8,
        194
    ],
    "u1f46e":[
        698,
        649,
        706,
        4,
        649,
        194,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46f":[
        649,
        649,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        58,
        649,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        58
    ],
    "u1f470":[
        0,
        11,
        649,
        706,
        4,
        649,
        194,
        8,
        11
    ],
    "u1f471":[
        677,
        649,
        4,
        8,
        194
    ],
    "u1f472":[
        649,
        2,
        194,
        197,
        707,
        546,
        8
    ],
    "u1f473":[
        629,
        4,
        649,
        706,
        649,
        194,
        8,
        649,
        0,
        641,
        675
    ],
    "u1f474":[
        641,
        649,
        8,
        641,
        194
    ],
    "u1f475":[
        721,
        649,
        599,
        760,
        194,
        8,
        599,
        590
    ],
    "u1f476":[
        649,
        793,
        11,
        694
    ],
    "u1f477":[
        794,
        795,
        58,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        3,
        795,
        4
    ],
    "u1f478":[
        4,
        649,
        760,
        194,
        4,
        722,
        612,
        8
    ],
    "u1f479":[
        58,
        2,
        58,
        11,
        11,
        3,
        195,
        58,
        9,
        1,
        13
    ],
    "u1f47a":[
        603,
        478,
        58,
        633,
        58,
        738
    ],
    "u1f47b":[
        79,
        58,
        669
    ],
    "u1f47c":[
        79,
        649,
        4,
        8,
        796
    ],
    "u1f47d":[
        222,
        58
    ],
    "u1f47e":[
        797
    ],
    "u1f47f":[
        590,
        590,
        797
    ],
    "u1f480":[
        222,
        58
    ],
    "u1f481":[
        4,
        629,
        675,
        649,
        649,
        194,
        629,
        706,
        649,
        8
    ],
    "u1f482":[
        2,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f483":[
        2,
        649,
        2,
        4,
        649,
        4,
        649,
        2,
        9,
        649
    ],
    "u1f484":[
        214,
        2,
        604,
        195,
        4
    ],
    "u1f485":[
        649,
        2,
        2,
        594,
        594
    ],
    "u1f486":[
        629,
        675,
        4,
        649,
        649,
        194,
        798,
        649,
        8
    ],
    "u1f487":[
        669,
        660,
        4,
        629,
        675,
        649,
        194,
        8,
        669,
        660
    ],
    "u1f488":[
        222,
        11,
        2,
        3,
        2,
        196
    ],
    "u1f489":[
        222,
        799,
        800,
        801,
        222,
        196,
        58,
        67
    ],
    "u1f48a":[
        650,
        668
    ],
    "u1f48b":[
        2
    ],
    "u1f48c":[
        222,
        196,
        196,
        79,
        2
    ],
    "u1f48d":[
        669,
        593,
        796,
        593,
        669
    ],
    "u1f48e":[
        671,
        593,
        674,
        796,
        593
    ],
    "u1f48f":[
        649,
        8,
        194,
        4,
        4,
        2
    ],
    "u1f490":[
        579,
        197,
        2,
        214,
        2,
        214,
        13,
        195
    ],
    "u1f491":[
        4,
        196,
        67,
        649,
        194,
        8,
        629,
        675,
        649,
        194,
        8,
        2
    ],
    "u1f492":[
        714,
        604,
        8,
        604,
        2,
        8,
        2
    ],
    "u1f493":[
        2,
        668
    ],
    "u1f494":[
        2,
        2
    ],
    "u1f495":[
        2
    ],
    "u1f496":[
        2,
        668
    ],
    "u1f497":[
        604,
        214,
        2
    ],
    "u1f498":[
        4,
        2,
        195,
        4,
        9
    ],
    "u1f499":[
        593
    ],
    "u1f49a":[
        711
    ],
    "u1f49b":[
        668
    ],
    "u1f49c":[
        790
    ],
    "u1f49d":[
        2,
        633,
        633,
        668,
        633
    ],
    "u1f49e":[
        604,
        2
    ],
    "u1f49f":[
        2,
        478
    ],
    "u1f4a0":[
        3,
        12,
        419
    ],
    "u1f4a1":[
        546,
        222,
        195,
        196,
        222
    ],
    "u1f4a2":[
        1
    ],
    "u1f4a3":[
        222,
        658,
        658,
        670,
        668
    ],
    "u1f4a4":[
        674
    ],
    "u1f4a5":[
        650,
        634,
        478
    ],
    "u1f4a6":[
        593
    ],
    "u1f4a7":[
        593
    ],
    "u1f4a8":[
        671
    ],
    "u1f4a9":[
        741,
        478,
        58,
        802
    ],
    "u1f4aa":[
        694,
        649,
        694
    ],
    "u1f4ab":[
        633,
        4
    ],
    "u1f4ac":[
        671,
        698
    ],
    "u1f4ad":[
        671
    ],
    "u1f4ae":[
        11,
        214,
        214
    ],
    "u1f4af":[
        650
    ],
    "u1f4b0":[
        633,
        741,
        660
    ],
    "u1f4b1":[
        58,
        660
    ],
    "u1f4b2":[
        58
    ],
    "u1f4b3":[
        4,
        58,
        769,
        594
    ],
    "u1f4b4":[
        803,
        546,
        804,
        803,
        578,
        4,
        803
    ],
    "u1f4b5":[
        189,
        805,
        197,
        189,
        578,
        4,
        189
    ],
    "u1f4b6":[
        698,
        593,
        674,
        698,
        578,
        4,
        698
    ],
    "u1f4b7":[
        6,
        806,
        590,
        6,
        578,
        4,
        6
    ],
    "u1f4b8":[
        189,
        805,
        197,
        189,
        4,
        578,
        189,
        79,
        222
    ],
    "u1f4b9":[
        197,
        11,
        11
    ],
    "u1f4ba":[
        660,
        669,
        669,
        222,
        593,
        674,
        79,
        669,
        669,
        669,
        660,
        593,
        593,
        698,
        660,
        669,
        669,
        222
    ],
    "u1f4bb":[
        222,
        669,
        593,
        807,
        669
    ],
    "u1f4bc":[
        808,
        8,
        808,
        222
    ],
    "u1f4bd":[
        58,
        546,
        4,
        578,
        478
    ],
    "u1f4be":[
        658,
        3,
        79
    ],
    "u1f4bf":[
        594,
        222,
        478
    ],
    "u1f4c0":[
        546,
        4,
        578
    ],
    "u1f4c1":[
        10,
        3
    ],
    "u1f4c2":[
        10,
        3
    ],
    "u1f4c3":[
        222,
        79,
        196
    ],
    "u1f4c4":[
        79,
        222,
        196
    ],
    "u1f4c5":[
        809,
        67,
        424,
        478,
        604
    ],
    "u1f4c6":[
        58,
        79,
        67,
        2,
        478,
        604,
        196
    ],
    "u1f4c7":[
        58,
        222,
        3,
        67,
        196
    ],
    "u1f4c8":[
        222,
        79,
        2
    ],
    "u1f4c9":[
        222,
        79,
        661
    ],
    "u1f4ca":[
        222,
        79,
        189,
        661,
        2
    ],
    "u1f4cb":[
        194,
        11,
        222,
        58,
        196
    ],
    "u1f4cc":[
        1,
        2,
        196,
        2
    ],
    "u1f4cd":[
        58,
        196,
        2
    ],
    "u1f4ce":[
        196
    ],
    "u1f4cf":[
        195,
        58
    ],
    "u1f4d0":[
        195
    ],
    "u1f4d1":[
        79,
        222,
        196,
        67,
        576
    ],
    "u1f4d2":[
        13,
        222,
        79,
        4,
        195,
        13,
        8,
        67
    ],
    "u1f4d3":[
        58,
        222,
        79,
        58,
        67,
        58,
        222
    ],
    "u1f4d4":[
        194,
        222,
        79,
        194,
        596,
        194,
        79
    ],
    "u1f4d5":[
        9,
        222,
        79,
        1,
        2,
        9
    ],
    "u1f4d6":[
        10,
        58,
        196,
        79,
        222
    ],
    "u1f4d7":[
        579,
        222,
        79,
        189,
        197,
        579
    ],
    "u1f4d8":[
        10,
        222,
        79,
        12,
        3,
        10
    ],
    "u1f4d9":[
        13,
        222,
        79,
        13,
        4,
        13
    ],
    "u1f4da":[
        5,
        6,
        222,
        196,
        10,
        3,
        222,
        196,
        13,
        4,
        222,
        196
    ],
    "u1f4db":[
        2,
        478
    ],
    "u1f4dc":[
        546,
        810,
        578,
        194
    ],
    "u1f4dd":[
        222,
        196,
        67,
        596,
        214,
        195,
        58,
        222,
        196
    ],
    "u1f4de":[
        658
    ],
    "u1f4df":[
        658,
        663,
        67,
        2,
        197
    ],
    "u1f4e0":[
        58,
        67,
        663,
        222,
        196
    ],
    "u1f4e1":[
        196,
        222,
        67,
        222,
        222,
        4,
        4
    ],
    "u1f4e2":[
        1,
        222,
        67,
        2,
        196
    ],
    "u1f4e3":[
        12,
        3,
        10
    ],
    "u1f4e4":[
        596,
        8,
        194,
        2
    ],
    "u1f4e5":[
        596,
        8,
        194,
        197
    ],
    "u1f4e6":[
        8,
        194,
        596,
        596,
        196,
        222,
        222,
        79
    ],
    "u1f4e7":[
        222,
        196,
        196,
        79,
        67
    ],
    "u1f4e8":[
        3,
        222,
        196,
        196,
        79
    ],
    "u1f4e9":[
        222,
        196,
        196,
        79,
        3
    ],
    "u1f4ea":[
        194,
        196,
        58,
        196,
        2
    ],
    "u1f4eb":[
        194,
        196,
        58,
        196,
        2
    ],
    "u1f4ec":[
        194,
        58,
        222,
        196,
        79,
        196,
        2
    ],
    "u1f4ed":[
        194,
        196,
        58,
        2
    ],
    "u1f4ee":[
        67,
        2,
        9,
        58,
        79,
        222,
        67
    ],
    "u1f4ef":[
        4,
        1,
        1
    ],
    "u1f4f0":[
        196,
        222,
        196,
        3
    ],
    "u1f4f1":[
        658,
        3
    ],
    "u1f4f2":[
        658,
        3
    ],
    "u1f4f3":[
        13,
        11
    ],
    "u1f4f4":[
        13,
        11,
        11
    ],
    "u1f4f5":[
        52,
        11,
        52,
        2
    ],
    "u1f4f6":[
        12,
        11
    ],
    "u1f4f7":[
        67,
        658,
        222,
        658,
        12,
        11,
        478
    ],
    "u1f4f8":[
        658,
        222,
        658,
        12,
        11,
        4,
        11
    ],
    "u1f4f9":[
        658,
        67
    ],
    "u1f4fa":[
        658,
        658,
        658,
        3,
        67
    ],
    "u1f4fb":[
        58,
        196,
        58,
        67
    ],
    "u1f4fc":[
        658,
        222,
        52,
        67,
        125
    ],
    "u1f4fd":[
        658,
        594
    ],
    "u1f4ff":[
        58,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        13
    ],
    "u1f500":[
        12,
        11,
        11,
        11
    ],
    "u1f501":[
        12,
        11
    ],
    "u1f502":[
        12,
        11,
        11,
        12
    ],
    "u1f503":[
        12,
        11
    ],
    "u1f504":[
        12,
        11
    ],
    "u1f505":[
        4,
        4,
        4,
        4
    ],
    "u1f506":[
        4
    ],
    "u1f507":[
        594,
        222,
        2
    ],
    "u1f508":[
        594,
        222
    ],
    "u1f509":[
        594,
        222
    ],
    "u1f50a":[
        594,
        222,
        594,
        594
    ],
    "u1f50b":[
        197,
        197,
        189,
        11,
        222,
        196,
        222
    ],
    "u1f50c":[
        669,
        658
    ],
    "u1f50d":[
        669,
        67,
        594,
        419
    ],
    "u1f50e":[
        669,
        67,
        594,
        419
    ],
    "u1f50f":[
        589,
        4,
        67,
        58
    ],
    "u1f510":[
        589,
        4,
        194
    ],
    "u1f511":[
        194
    ],
    "u1f512":[
        589,
        4
    ],
    "u1f513":[
        589,
        4
    ],
    "u1f514":[
        4,
        4
    ],
    "u1f515":[
        4,
        4,
        2
    ],
    "u1f516":[
        589,
        478,
        11,
        589,
        79,
        589,
        2,
        2,
        478
    ],
    "u1f517":[
        594,
        594
    ],
    "u1f518":[
        10,
        419
    ],
    "u1f519":[
        658
    ],
    "u1f51a":[
        658
    ],
    "u1f51b":[
        658
    ],
    "u1f51c":[
        658
    ],
    "u1f51d":[
        658
    ],
    "u1f51e":[
        52,
        478,
        2
    ],
    "u1f51f":[
        12,
        478
    ],
    "u1f520":[
        12,
        11
    ],
    "u1f521":[
        12,
        11
    ],
    "u1f522":[
        12,
        11
    ],
    "u1f523":[
        12,
        11
    ],
    "u1f524":[
        12,
        11
    ],
    "u1f525":[
        13,
        195
    ],
    "u1f526":[
        67,
        594,
        67,
        594,
        195
    ],
    "u1f527":[
        594
    ],
    "u1f528":[
        13,
        67
    ],
    "u1f529":[
        594,
        67,
        222,
        594,
        79,
        222,
        594,
        79
    ],
    "u1f52a":[
        222,
        589,
        658,
        478
    ],
    "u1f52b":[
        4,
        4,
        197,
        579,
        588,
        189,
        197,
        220,
        478,
        195
    ],
    "u1f52c":[
        67,
        67,
        594,
        594,
        660,
        67,
        589,
        79
    ],
    "u1f52d":[
        669,
        603,
        603,
        650,
        811,
        58,
        669
    ],
    "u1f52e":[
        741,
        812,
        590,
        576
    ],
    "u1f52f":[
        6,
        478,
        478,
        478
    ],
    "u1f530":[
        67,
        813,
        814
    ],
    "u1f531":[
        195,
        195
    ],
    "u1f532":[
        658,
        0
    ],
    "u1f533":[
        0,
        658
    ],
    "u1f534":[
        2
    ],
    "u1f535":[
        3
    ],
    "u1f536":[
        13
    ],
    "u1f537":[
        3
    ],
    "u1f538":[
        13
    ],
    "u1f539":[
        3
    ],
    "u1f53a":[
        738
    ],
    "u1f53b":[
        738
    ],
    "u1f53c":[
        12,
        11
    ],
    "u1f53d":[
        12,
        11
    ],
    "u1f549":[
        6,
        11,
        11
    ],
    "u1f54a":[
        189,
        196,
        222,
        189,
        189
    ],
    "u1f54b":[
        52,
        58,
        546,
        4,
        546,
        4,
        546
    ],
    "u1f54c":[
        13,
        546,
        546,
        8,
        546,
        13,
        4
    ],
    "u1f54d":[
        722,
        58,
        641,
        660,
        641,
        660,
        641,
        58,
        660,
        195,
        195,
        660,
        660
    ],
    "u1f54e":[
        6,
        11
    ],
    "u1f550":[
        196,
        79,
        67,
        67
    ],
    "u1f551":[
        196,
        79,
        67,
        67
    ],
    "u1f552":[
        196,
        79,
        67,
        67
    ],
    "u1f553":[
        196,
        79,
        67,
        67
    ],
    "u1f554":[
        196,
        79,
        67,
        67
    ],
    "u1f555":[
        196,
        79,
        67,
        67
    ],
    "u1f556":[
        196,
        79,
        67,
        67
    ],
    "u1f557":[
        196,
        79,
        67,
        67
    ],
    "u1f558":[
        196,
        79,
        67,
        67
    ],
    "u1f559":[
        196,
        79,
        67,
        67
    ],
    "u1f55a":[
        196,
        79,
        660,
        660
    ],
    "u1f55b":[
        196,
        79,
        660,
        660
    ],
    "u1f55c":[
        196,
        79,
        660,
        660
    ],
    "u1f55d":[
        196,
        79,
        660,
        660
    ],
    "u1f55e":[
        196,
        79,
        660,
        660
    ],
    "u1f55f":[
        196,
        79,
        660,
        660
    ],
    "u1f560":[
        196,
        79,
        67,
        67
    ],
    "u1f561":[
        196,
        79,
        67
    ],
    "u1f562":[
        196,
        79,
        67,
        67
    ],
    "u1f563":[
        196,
        79,
        67,
        67
    ],
    "u1f564":[
        196,
        79,
        67,
        67
    ],
    "u1f565":[
        196,
        79,
        67,
        67
    ],
    "u1f566":[
        196,
        79,
        67,
        67
    ],
    "u1f567":[
        196,
        79,
        67,
        67
    ],
    "u1f56f":[
        660,
        79,
        634,
        668,
        222
    ],
    "u1f570":[
        8,
        8,
        9,
        4,
        478,
        194,
        194,
        8
    ],
    "u1f573":[
        658,
        815,
        222
    ],
    "u1f574":[
        649,
        67,
        818,
        58,
        58,
        4,
        649,
        649,
        194,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f575":[
        67,
        58,
        67,
        58,
        4,
        649,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f576":[
        658
    ],
    "u1f577":[
        658,
        658,
        658,
        658,
        658,
        658,
        658,
        658,
        658,
        658
    ],
    "u1f578":[
        67
    ],
    "u1f579":[
        196,
        603,
        658,
        658,
        67
    ],
    "u1f57a":[
        58,
        587,
        649,
        694,
        58,
        649,
        649,
        4,
        2,
        58
    ],
    "u1f587":[
        196,
        67
    ],
    "u1f58a":[
        194,
        195,
        658,
        195,
        4,
        4
    ],
    "u1f58b":[
        196,
        67,
        658,
        67
    ],
    "u1f58c":[
        12,
        194,
        222,
        9
    ],
    "u1f58d":[
        2,
        214,
        58,
        1
    ],
    "u1f590":[
        649,
        694
    ],
    "u1f595":[
        649,
        649,
        694
    ],
    "u1f596":[
        694,
        649,
        649,
        694
    ],
    "u1f5a4":[
        658
    ],
    "u1f5a5":[
        222,
        593,
        669,
        79,
        478
    ],
    "u1f5a8":[
        660,
        79,
        593,
        58,
        674,
        79,
        669,
        478,
        669
    ],
    "u1f5b1":[
        196,
        58
    ],
    "u1f5b2":[
        67,
        196,
        222,
        576,
        58
    ],
    "u1f5bc":[
        742,
        741,
        796,
        593,
        58,
        660,
        738,
        58,
        650,
        11
    ],
    "u1f5c2":[
        668,
        633,
        739,
        660,
        711,
        670,
        784
    ],
    "u1f5c3":[
        660,
        222,
        79,
        478,
        669,
        660,
        634,
        593,
        738,
        79
    ],
    "u1f5c4":[
        58,
        67,
        58,
        79
    ],
    "u1f5d1":[
        669,
        660
    ],
    "u1f5d2":[
        67,
        3,
        222,
        196,
        58,
        67,
        67,
        67,
        67
    ],
    "u1f5d3":[
        67,
        222,
        2,
        58,
        67,
        11
    ],
    "u1f5dc":[
        660,
        660,
        669,
        660,
        603,
        603,
        738
    ],
    "u1f5dd":[
        658,
        660,
        658,
        658,
        658,
        660,
        658
    ],
    "u1f5de":[
        196,
        67,
        222,
        79,
        196,
        593
    ],
    "u1f5e1":[
        669,
        222,
        596,
        741,
        819,
        4,
        4,
        195
    ],
    "u1f5e3":[
        629,
        10
    ],
    "u1f5e8":[
        796
    ],
    "u1f5ef":[
        79,
        660
    ],
    "u1f5f3":[
        796,
        58,
        546,
        593,
        674,
        593,
        194
    ],
    "u1f5fa":[
        222,
        79,
        3,
        125,
        189,
        197,
        189
    ],
    "u1f5fb":[
        587,
        58,
        79,
        589,
        222,
        589
    ],
    "u1f5fc":[
        1,
        2,
        2,
        820,
        2,
        589,
        79
    ],
    "u1f5fd":[
        125,
        821,
        822,
        822,
        821,
        822
    ],
    "u1f5fe":[
        125,
        579,
        579
    ],
    "u1f5ff":[
        67,
        222,
        67,
        67
    ],
    "u1f600":[
        195,
        823,
        11
    ],
    "u1f601":[
        195,
        823,
        11,
        823
    ],
    "u1f602":[
        195,
        823,
        11,
        593
    ],
    "u1f603":[
        195,
        823,
        11
    ],
    "u1f604":[
        195,
        823,
        11
    ],
    "u1f605":[
        195,
        823,
        11,
        593
    ],
    "u1f606":[
        195,
        823,
        11
    ],
    "u1f607":[
        195,
        823,
        4,
        593,
        661,
        195
    ],
    "u1f608":[
        590,
        590,
        797
    ],
    "u1f609":[
        195,
        823,
        823
    ],
    "u1f60a":[
        195,
        824,
        823
    ],
    "u1f60b":[
        195,
        823,
        825,
        826
    ],
    "u1f60c":[
        195,
        823
    ],
    "u1f60d":[
        195,
        823,
        2
    ],
    "u1f60e":[
        195,
        58,
        823
    ],
    "u1f60f":[
        195,
        823
    ],
    "u1f610":[
        195,
        823
    ],
    "u1f611":[
        195,
        823
    ],
    "u1f612":[
        195,
        823
    ],
    "u1f613":[
        195,
        823,
        593
    ],
    "u1f614":[
        195,
        823
    ],
    "u1f615":[
        195,
        823
    ],
    "u1f616":[
        195,
        823
    ],
    "u1f617":[
        195,
        823
    ],
    "u1f618":[
        195,
        823,
        823,
        4,
        2
    ],
    "u1f619":[
        195,
        823
    ],
    "u1f61a":[
        195,
        824,
        823
    ],
    "u1f61b":[
        195,
        827,
        828,
        424
    ],
    "u1f61c":[
        195,
        827,
        478,
        58,
        828,
        424
    ],
    "u1f61d":[
        195,
        827,
        828,
        424
    ],
    "u1f61e":[
        195,
        823
    ],
    "u1f61f":[
        195,
        823,
        823
    ],
    "u1f620":[
        195,
        823
    ],
    "u1f621":[
        603,
        58
    ],
    "u1f622":[
        195,
        823,
        823,
        593
    ],
    "u1f623":[
        195,
        823
    ],
    "u1f624":[
        195,
        823,
        478,
        79
    ],
    "u1f625":[
        195,
        823,
        593
    ],
    "u1f626":[
        195,
        823
    ],
    "u1f627":[
        195,
        823,
        823
    ],
    "u1f628":[
        195,
        671,
        823,
        823
    ],
    "u1f629":[
        195,
        823,
        11
    ],
    "u1f62a":[
        195,
        823,
        593
    ],
    "u1f62b":[
        195,
        823,
        222,
        823
    ],
    "u1f62c":[
        195,
        823,
        11,
        823
    ],
    "u1f62d":[
        195,
        823,
        593,
        823,
        593,
        738
    ],
    "u1f62e":[
        195,
        823
    ],
    "u1f62f":[
        195,
        823
    ],
    "u1f630":[
        195,
        823,
        593,
        671,
        823
    ],
    "u1f631":[
        195,
        671,
        478,
        823,
        634
    ],
    "u1f632":[
        195,
        823,
        478
    ],
    "u1f633":[
        195,
        824,
        478,
        58,
        823
    ],
    "u1f634":[
        195,
        823,
        4,
        698
    ],
    "u1f635":[
        195,
        823
    ],
    "u1f636":[
        195,
        823
    ],
    "u1f637":[
        195,
        823,
        478
    ],
    "u1f638":[
        195,
        738,
        670,
        195,
        739,
        58,
        478
    ],
    "u1f639":[
        195,
        738,
        670,
        195,
        739,
        58,
        478,
        593
    ],
    "u1f63a":[
        195,
        738,
        670,
        195,
        739,
        58,
        478
    ],
    "u1f63b":[
        195,
        738,
        670,
        195,
        739,
        58,
        478,
        2
    ],
    "u1f63c":[
        195,
        738,
        670,
        195,
        739,
        58,
        58
    ],
    "u1f63d":[
        195,
        738,
        670,
        195,
        739,
        58
    ],
    "u1f63e":[
        195,
        738,
        670,
        195,
        739,
        58,
        58
    ],
    "u1f63f":[
        195,
        738,
        670,
        195,
        739,
        58,
        58,
        593
    ],
    "u1f640":[
        195,
        738,
        670,
        195,
        739,
        58,
        478,
        634
    ],
    "u1f641":[
        195,
        823
    ],
    "u1f642":[
        195,
        823
    ],
    "u1f643":[
        195,
        823
    ],
    "u1f644":[
        195,
        769,
        832
    ],
    "u1f645":[
        675,
        4,
        649,
        194,
        8,
        706,
        649,
        629,
        675,
        706,
        649,
        629
    ],
    "u1f646":[
        4,
        649,
        629,
        649,
        194,
        8
    ],
    "u1f647":[
        629,
        4,
        706,
        649,
        8,
        194,
        593
    ],
    "u1f648":[
        741,
        742,
        58,
        638,
        741,
        835,
        741,
        742,
        741,
        741,
        835
    ],
    "u1f649":[
        742,
        742,
        741,
        741,
        742,
        58,
        638,
        741,
        835,
        742
    ],
    "u1f64a":[
        742,
        742,
        741,
        742,
        58,
        638,
        741,
        742,
        741,
        742,
        741,
        835
    ],
    "u1f64b":[
        629,
        649,
        675,
        4,
        649,
        194,
        8,
        649,
        694,
        629
    ],
    "u1f64c":[
        649,
        706,
        694,
        649,
        593
    ],
    "u1f64d":[
        629,
        675,
        4,
        649,
        649,
        194,
        8
    ],
    "u1f64e":[
        629,
        675,
        4,
        649,
        649,
        194,
        8
    ],
    "u1f64f":[
        665,
        662,
        706,
        649,
        665,
        662,
        706,
        649,
        706
    ],
    "u1f680":[
        9,
        4,
        195,
        3,
        52,
        9
    ],
    "u1f681":[
        67,
        196,
        196,
        67,
        195,
        195,
        12,
        196,
        196,
        67
    ],
    "u1f682":[
        611,
        336,
        708,
        9,
        2,
        336,
        721,
        707,
        194,
        599,
        1,
        214,
        13,
        3
    ],
    "u1f683":[
        611,
        708,
        58,
        189,
        578,
        4,
        197,
        3
    ],
    "u1f684":[
        611,
        714,
        12,
        336,
        0,
        722,
        708,
        10,
        578
    ],
    "u1f685":[
        611,
        641,
        336,
        12,
        10,
        3,
        12
    ],
    "u1f686":[
        722,
        708,
        599,
        722,
        708,
        722,
        0,
        4,
        3,
        10
    ],
    "u1f687":[
        58,
        599,
        708,
        722,
        641,
        2,
        708,
        3,
        546,
        599
    ],
    "u1f688":[
        611,
        641,
        641,
        13,
        707,
        12,
        707,
        3,
        2
    ],
    "u1f689":[
        722,
        708,
        599,
        722,
        708,
        707,
        721,
        641,
        599,
        3,
        189,
        546,
        58,
        721,
        722,
        721,
        611,
        708,
        611
    ],
    "u1f68a":[
        722,
        708,
        599,
        722,
        708,
        707,
        721,
        641,
        599,
        3,
        189,
        546,
        58,
        721
    ],
    "u1f68b":[
        611,
        708,
        58,
        0,
        67,
        714,
        0,
        1,
        2,
        3,
        9
    ],
    "u1f68c":[
        599,
        222,
        611,
        714,
        52,
        196,
        13,
        708,
        58,
        3,
        4
    ],
    "u1f68d":[
        58,
        196,
        196,
        222,
        222,
        195,
        67,
        125,
        4,
        11,
        2,
        67,
        58
    ],
    "u1f68e":[
        197,
        721,
        722,
        0,
        189,
        52,
        196,
        3,
        578,
        214,
        604
    ],
    "u1f68f":[
        721,
        721,
        714,
        1,
        599,
        13,
        2
    ],
    "u1f690":[
        222,
        67,
        79,
        196,
        58,
        196,
        3
    ],
    "u1f691":[
        222,
        79,
        58,
        196,
        2,
        3
    ],
    "u1f692":[
        222,
        2,
        478,
        58,
        594,
        3,
        4,
        12
    ],
    "u1f693":[
        79,
        58,
        67,
        58,
        79,
        2,
        3
    ],
    "u1f694":[
        67,
        222,
        58,
        3,
        222,
        67,
        196,
        2,
        478
    ],
    "u1f695":[
        222,
        195,
        58,
        222,
        13,
        3
    ],
    "u1f696":[
        58,
        195,
        3,
        195,
        4,
        13,
        11,
        67,
        578,
        222,
        196
    ],
    "u1f697":[
        2,
        419,
        58,
        222
    ],
    "u1f698":[
        58,
        12,
        419,
        12,
        195,
        10,
        3,
        10
    ],
    "u1f699":[
        58,
        12,
        58,
        222,
        419,
        3
    ],
    "u1f69a":[
        2,
        195,
        3,
        58,
        222
    ],
    "u1f69b":[
        196,
        195,
        3,
        58,
        222,
        197
    ],
    "u1f69c":[
        222,
        197,
        58,
        419,
        197,
        195,
        197,
        58,
        195,
        578,
        13,
        67,
        189,
        579
    ],
    "u1f69d":[
        721,
        611,
        721,
        714,
        641,
        714,
        0,
        189,
        3
    ],
    "u1f69e":[
        641,
        611,
        708,
        58,
        578,
        197,
        189,
        4,
        3,
        67
    ],
    "u1f69f":[
        708,
        13,
        546,
        599,
        707,
        722,
        12,
        708,
        707
    ],
    "u1f6a0":[
        708,
        546,
        10,
        125,
        721,
        722,
        599,
        3
    ],
    "u1f6a1":[
        708,
        721,
        722,
        599,
        2,
        125,
        3,
        336
    ],
    "u1f6a2":[
        58,
        641,
        722,
        67,
        196,
        3,
        0,
        641,
        721,
        714,
        1,
        9,
        708
    ],
    "u1f6a3":[
        52,
        194,
        13,
        3,
        662,
        649,
        649,
        4,
        649,
        629
    ],
    "u1f6a4":[
        189,
        2,
        3,
        195,
        9,
        3,
        189,
        194,
        9,
        3,
        58
    ],
    "u1f6a5":[
        658,
        197,
        195,
        2
    ],
    "u1f6a6":[
        658,
        197,
        195,
        2
    ],
    "u1f6a7":[
        195,
        58,
        196
    ],
    "u1f6a8":[
        2,
        1,
        196,
        222,
        836
    ],
    "u1f6a9":[
        67,
        2
    ],
    "u1f6aa":[
        741,
        4,
        837,
        812,
        837,
        812
    ],
    "u1f6ab":[
        2
    ],
    "u1f6ac":[
        596,
        79,
        67,
        222
    ],
    "u1f6ad":[
        52,
        11,
        2
    ],
    "u1f6ae":[
        12,
        11,
        11,
        11
    ],
    "u1f6af":[
        52,
        11,
        11,
        2
    ],
    "u1f6b0":[
        10,
        11,
        11
    ],
    "u1f6b1":[
        52,
        11,
        11,
        2
    ],
    "u1f6b2":[
        214,
        58,
        2,
        2,
        2,
        58,
        67
    ],
    "u1f6b3":[
        52,
        11,
        11,
        2
    ],
    "u1f6b4":[
        649,
        706,
        4,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        649,
        675,
        629,
        675
    ],
    "u1f6b5":[
        197,
        649,
        4,
        706,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        649,
        675,
        629,
        675
    ],
    "u1f6b6":[
        58,
        706,
        698,
        674,
        649,
        649,
        4,
        629,
        649
    ],
    "u1f6b7":[
        52,
        11,
        2
    ],
    "u1f6b8":[
        195,
        52,
        195,
        52
    ],
    "u1f6b9":[
        10,
        11
    ],
    "u1f6ba":[
        214,
        11
    ],
    "u1f6bb":[
        12,
        11
    ],
    "u1f6bc":[
        13,
        11
    ],
    "u1f6bd":[
        589,
        79,
        196,
        79,
        222,
        589
    ],
    "u1f6be":[
        10,
        11
    ],
    "u1f6bf":[
        67,
        196,
        3
    ],
    "u1f6c0":[
        649,
        4,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c1":[
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c2":[
        10,
        478,
        478,
        478
    ],
    "u1f6c3":[
        10,
        478,
        478
    ],
    "u1f6c4":[
        10,
        478
    ],
    "u1f6c5":[
        10,
        478,
        478
    ],
    "u1f6cb":[
        222,
        222,
        634,
        838,
        637,
        838,
        711,
        711,
        711,
        711
    ],
    "u1f6cc":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        649,
        4
    ],
    "u1f6cd":[
        13,
        2,
        195,
        576
    ],
    "u1f6ce":[
        13,
        4,
        4,
        194,
        194
    ],
    "u1f6cf":[
        194,
        194,
        10,
        3,
        189
    ],
    "u1f6d0":[
        6,
        11,
        11
    ],
    "u1f6d1":[
        2,
        222
    ],
    "u1f6d2":[
        222,
        67,
        79,
        58
    ],
    "u1f6d5":[
        839,
        840,
        8,
        840,
        8,
        840,
        839,
        8,
        58,
        841,
        839
    ],
    "u1f6d6":[
        596,
        8,
        194,
        842,
        843,
        546,
        546,
        4,
        844,
        845
    ],
    "u1f6d7":[
        10,
        11,
        11
    ],
    "u1f6dd":[
        846,
        675,
        847,
        848,
        849,
        848,
        675,
        847,
        846
    ],
    "u1f6de":[
        850,
        851,
        846,
        851,
        852,
        851,
        846,
        853,
        846,
        853
    ],
    "u1f6df":[
        854,
        855,
        853,
        853,
        846
    ],
    "u1f6e0":[
        594,
        13,
        67
    ],
    "u1f6e1":[
        222,
        3,
        10
    ],
    "u1f6e2":[
        12,
        10,
        196
    ],
    "u1f6e3":[
        125,
        197,
        478,
        596,
        708,
        599,
        222,
        599,
        67,
        67,
        189,
        856,
        197
    ],
    "u1f6e4":[
        125,
        197,
        478,
        194,
        222,
        708,
        599,
        722,
        189
    ],
    "u1f6e5":[
        67,
        67,
        79,
        12,
        222,
        13,
        3
    ],
    "u1f6e9":[
        67,
        67,
        1,
        222,
        1,
        222,
        10,
        1,
        1,
        67,
        58,
        214,
        1,
        214
    ],
    "u1f6eb":[
        838,
        222,
        478,
        660,
        593,
        222,
        660,
        593,
        669
    ],
    "u1f6ec":[
        838,
        222,
        478,
        660,
        593,
        222,
        660,
        593,
        669
    ],
    "u1f6f0":[
        196,
        3,
        196,
        222,
        222,
        196,
        196,
        125,
        196,
        4,
        4
    ],
    "u1f6f3":[
        2,
        222,
        222,
        222,
        10,
        222,
        3,
        4
    ],
    "u1f6f4":[
        58,
        2,
        3,
        3,
        196,
        3,
        2,
        3,
        2,
        214
    ],
    "u1f6f5":[
        13,
        58,
        196,
        2,
        1,
        1,
        58,
        13,
        58
    ],
    "u1f6f6":[
        2,
        3,
        4,
        195
    ],
    "u1f6f7":[
        79,
        811,
        738,
        811,
        811,
        194,
        194,
        596,
        741
    ],
    "u1f6f8":[
        546,
        546,
        660,
        660,
        669,
        222,
        79,
        593,
        796,
        796,
        796,
        796,
        796
    ],
    "u1f6f9":[
        9,
        2,
        12,
        12,
        125,
        599,
        722,
        12,
        12,
        125,
        12,
        12,
        125,
        599,
        722,
        12,
        12,
        125,
        12
    ],
    "u1f6fa":[
        196,
        3,
        58,
        196,
        857,
        189,
        579,
        189,
        4,
        58,
        196,
        13
    ],
    "u1f6fb":[
        2,
        58,
        222,
        419,
        858,
        196,
        222
    ],
    "u1f6fc":[
        58,
        13,
        8,
        125,
        214,
        196,
        13,
        8,
        10,
        1
    ],
    "u1f7e0":[
        13
    ],
    "u1f7e1":[
        668
    ],
    "u1f7e2":[
        711
    ],
    "u1f7e3":[
        790
    ],
    "u1f7e4":[
        194
    ],
    "u1f7e5":[
        2
    ],
    "u1f7e6":[
        3
    ],
    "u1f7e7":[
        13
    ],
    "u1f7e8":[
        668
    ],
    "u1f7e9":[
        711
    ],
    "u1f7ea":[
        790
    ],
    "u1f7eb":[
        194
    ],
    "u1f7f0":[
        658
    ],
    "u1f90c":[
        694,
        694,
        706,
        694,
        706,
        694,
        706,
        694,
        694,
        649
    ],
    "u1f90d":[
        0
    ],
    "u1f90e":[
        194
    ],
    "u1f90f":[
        649,
        694
    ],
    "u1f910":[
        195,
        823,
        196
    ],
    "u1f911":[
        195,
        823,
        838,
        11
    ],
    "u1f912":[
        195,
        823,
        4,
        823,
        222,
        603,
        660,
        859
    ],
    "u1f913":[
        195,
        823,
        832,
        11,
        832,
        58
    ],
    "u1f914":[
        584,
        832,
        583,
        832
    ],
    "u1f915":[
        195,
        823,
        0,
        714,
        641
    ],
    "u1f916":[
        13,
        4,
        195,
        13,
        10,
        3,
        12,
        11,
        2,
        478,
        222
    ],
    "u1f917":[
        195,
        823,
        13,
        860
    ],
    "u1f918":[
        694,
        649,
        649,
        649
    ],
    "u1f919":[
        649,
        694,
        649,
        694,
        649,
        694,
        649
    ],
    "u1f91a":[
        694,
        649
    ],
    "u1f91b":[
        649,
        694,
        694,
        694,
        649
    ],
    "u1f91c":[
        649,
        694,
        694,
        694,
        649
    ],
    "u1f91d":[
        694,
        649,
        694,
        195
    ],
    "u1f91e":[
        694,
        649,
        649
    ],
    "u1f91f":[
        649,
        694,
        649,
        694,
        649
    ],
    "u1f920":[
        195,
        823,
        861,
        823
    ],
    "u1f921":[
        674,
        674,
        674,
        739,
        674,
        674,
        674,
        824,
        603,
        823,
        650
    ],
    "u1f922":[
        197,
        58,
        579
    ],
    "u1f923":[
        584,
        832,
        11,
        832,
        862
    ],
    "u1f924":[
        195,
        823,
        125
    ],
    "u1f925":[
        195,
        823,
        13
    ],
    "u1f926":[
        629,
        4,
        649,
        649,
        4,
        194,
        675,
        675,
        8,
        694,
        863,
        649,
        694,
        194,
        694,
        8,
        629
    ],
    "u1f927":[
        195,
        823,
        222,
        478
    ],
    "u1f928":[
        195,
        823,
        823
    ],
    "u1f929":[
        195,
        823,
        11,
        588
    ],
    "u1f92a":[
        584,
        769,
        58,
        58,
        832,
        828,
        424,
        11
    ],
    "u1f92b":[
        584,
        832,
        583,
        832
    ],
    "u1f92c":[
        603,
        58,
        58,
        11
    ],
    "u1f92d":[
        195,
        823,
        13
    ],
    "u1f92e":[
        584,
        832,
        867,
        868,
        868,
        868
    ],
    "u1f92f":[
        584,
        583,
        869,
        870,
        832,
        222,
        79
    ],
    "u1f930":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        706,
        649,
        694,
        4,
        649,
        4,
        741,
        8,
        760
    ],
    "u1f931":[
        784,
        649,
        4,
        649,
        195,
        649,
        741,
        8,
        760,
        4,
        4,
        197,
        649
    ],
    "u1f932":[
        694,
        649,
        694,
        649,
        694
    ],
    "u1f933":[
        649,
        649,
        721,
        336,
        125,
        694,
        694,
        649,
        649,
        649,
        649,
        649,
        649,
        629
    ],
    "u1f934":[
        649,
        8,
        194,
        4,
        196,
        79
    ],
    "u1f935":[
        58,
        818,
        67,
        871,
        58,
        649,
        706,
        769,
        58,
        4,
        649,
        194,
        8
    ],
    "u1f936":[
        641,
        649,
        641,
        649,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f937":[
        4,
        629,
        649,
        675,
        634,
        649,
        629,
        194,
        8
    ],
    "u1f938":[
        649,
        649,
        649,
        649,
        4,
        675,
        222,
        629,
        222,
        675,
        222,
        649,
        629
    ],
    "u1f939":[
        629,
        649,
        706,
        4,
        649,
        194,
        8,
        641,
        189,
        1,
        12,
        706,
        649,
        675
    ],
    "u1f93a":[
        222,
        196,
        222,
        478,
        58,
        67,
        67
    ],
    "u1f93c":[
        675,
        706,
        706,
        706,
        675,
        649,
        629,
        649,
        629,
        4,
        67,
        196,
        196,
        649,
        706,
        649,
        67,
        649,
        649,
        649,
        4
    ],
    "u1f93d":[
        649,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93e":[
        675,
        676,
        222,
        649,
        675,
        675,
        649,
        649,
        4,
        629,
        10,
        649
    ],
    "u1f93f":[
        67,
        58,
        874,
        125,
        58,
        419,
        875,
        13,
        58,
        195
    ],
    "u1f940":[
        711,
        876,
        220,
        711,
        711,
        711,
        603,
        738
    ],
    "u1f941":[
        670,
        670,
        670,
        811,
        670,
        670,
        670,
        634,
        478,
        633,
        613,
        603
    ],
    "u1f942":[
        629,
        79,
        195,
        546
    ],
    "u1f943":[
        222,
        877,
        878,
        879,
        612,
        880,
        881,
        641,
        594,
        222,
        11
    ],
    "u1f944":[
        196
    ],
    "u1f945":[
        222,
        882,
        882,
        883
    ],
    "u1f947":[
        3,
        12,
        4,
        884
    ],
    "u1f948":[
        3,
        12,
        222,
        885
    ],
    "u1f949":[
        3,
        12,
        886,
        887
    ],
    "u1f94a":[
        1,
        196,
        222,
        9,
        79
    ],
    "u1f94b":[
        79,
        196,
        67,
        222,
        196,
        196,
        58,
        58,
        58
    ],
    "u1f94c":[
        1,
        67,
        196
    ],
    "u1f94d":[
        58,
        10,
        58,
        58,
        13,
        3,
        58
    ],
    "u1f94e":[
        888,
        889,
        2
    ],
    "u1f94f":[
        13,
        4,
        546,
        4,
        13,
        79
    ],
    "u1f950":[
        13,
        890,
        4
    ],
    "u1f951":[
        579,
        579,
        220,
        663,
        8,
        891
    ],
    "u1f952":[
        189,
        189,
        189,
        197,
        189,
        197,
        197,
        197,
        197
    ],
    "u1f953":[
        2,
        604,
        578,
        214,
        578,
        214
    ],
    "u1f954":[
        596,
        194
    ],
    "u1f955":[
        197,
        13,
        892
    ],
    "u1f956":[
        804,
        803,
        546
    ],
    "u1f957":[
        222,
        67,
        197,
        189,
        663,
        58,
        2,
        6,
        79,
        2,
        214,
        220
    ],
    "u1f958":[
        58,
        58,
        4,
        578,
        194,
        197,
        13
    ],
    "u1f959":[
        893,
        546,
        197,
        197,
        8,
        194,
        2,
        214,
        197,
        803
    ],
    "u1f95a":[
        639,
        684
    ],
    "u1f95b":[
        222,
        11,
        594,
        594,
        594,
        222,
        11,
        79,
        594
    ],
    "u1f95c":[
        596,
        194,
        194
    ],
    "u1f95d":[
        194,
        194,
        197,
        220,
        220,
        220,
        663,
        663,
        58
    ],
    "u1f95e":[
        894,
        715,
        583,
        636,
        583,
        636,
        583,
        895,
        896,
        895,
        896,
        897,
        636,
        898
    ],
    "u1f95f":[
        546,
        546,
        546,
        546,
        546,
        546,
        546,
        546,
        546,
        4,
        195,
        195,
        195
    ],
    "u1f960":[
        634,
        13,
        741,
        13,
        741,
        222,
        669
    ],
    "u1f961":[
        79,
        196,
        67,
        2,
        1
    ],
    "u1f962":[
        194,
        8,
        194,
        8
    ],
    "u1f963":[
        9,
        214,
        8,
        196,
        222
    ],
    "u1f964":[
        594,
        222,
        222,
        3,
        12,
        125,
        79,
        125,
        576,
        2,
        576,
        2,
        3,
        11
    ],
    "u1f965":[
        749,
        899,
        478,
        899,
        478,
        196,
        196,
        478,
        899,
        749,
        900
    ],
    "u1f966":[
        197,
        189,
        579,
        189,
        189,
        189,
        189,
        189,
        189,
        189,
        189,
        189,
        189
    ],
    "u1f967":[
        13,
        9,
        9,
        1,
        13,
        13,
        195,
        13,
        13,
        195,
        13
    ],
    "u1f968":[
        13,
        901,
        578
    ],
    "u1f969":[
        546,
        546,
        9,
        578,
        578,
        0,
        722
    ],
    "u1f96a":[
        13,
        4,
        546,
        197,
        195,
        1,
        13,
        4,
        546
    ],
    "u1f96b":[
        196,
        196,
        0,
        189,
        189,
        220,
        220,
        189,
        189,
        196,
        196,
        0,
        222,
        0,
        222,
        0,
        195,
        4
    ],
    "u1f96c":[
        189,
        902,
        579,
        197,
        220
    ],
    "u1f96d":[
        197,
        903,
        904,
        197
    ],
    "u1f96e":[
        4,
        13,
        905,
        8,
        8,
        4,
        13,
        905,
        905
    ],
    "u1f96f":[
        194,
        596,
        222,
        11,
        194,
        11,
        623,
        596,
        596
    ],
    "u1f970":[
        195,
        823,
        4,
        2
    ],
    "u1f971":[
        195,
        823,
        13
    ],
    "u1f972":[
        195,
        823,
        593
    ],
    "u1f973":[
        195,
        823,
        906,
        2,
        214,
        12,
        125,
        12,
        125,
        10,
        3,
        214,
        197
    ],
    "u1f974":[
        195,
        832,
        823,
        832
    ],
    "u1f975":[
        214,
        58,
        58,
        604,
        214,
        593
    ],
    "u1f976":[
        665,
        662,
        11,
        662,
        419,
        419
    ],
    "u1f977":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        649,
        8
    ],
    "u1f978":[
        195,
        832,
        58,
        4,
        194,
        8,
        58,
        58
    ],
    "u1f979":[
        195,
        832,
        11,
        13,
        832,
        11,
        593,
        11,
        13,
        832,
        11,
        593
    ],
    "u1f97a":[
        195,
        832,
        11,
        832,
        13,
        11,
        832,
        13,
        11
    ],
    "u1f97b":[
        4,
        189,
        197,
        6,
        908,
        189,
        5,
        4,
        909,
        5,
        4,
        197,
        189,
        5,
        4
    ],
    "u1f97c":[
        669,
        79,
        79,
        478,
        650,
        674,
        478,
        222,
        669
    ],
    "u1f97d":[
        669,
        669,
        669,
        669,
        222,
        222,
        79,
        58
    ],
    "u1f97e":[
        194,
        8,
        58,
        58,
        1,
        58
    ],
    "u1f97f":[
        336,
        579,
        596
    ],
    "u1f980":[
        9,
        9,
        9,
        9,
        9,
        1,
        9,
        58,
        2,
        2,
        2,
        2,
        2,
        2,
        9
    ],
    "u1f981":[
        8,
        195,
        2,
        734,
        195,
        734,
        195,
        733,
        578,
        733,
        596
    ],
    "u1f982":[
        692,
        692,
        647,
        647,
        644,
        644,
        58,
        692
    ],
    "u1f983":[
        4,
        1,
        4,
        194,
        658,
        58,
        67
    ],
    "u1f984":[
        910,
        911,
        910,
        912,
        58,
        913,
        914,
        915
    ],
    "u1f985":[
        58,
        79,
        195,
        58
    ],
    "u1f986":[
        596,
        194,
        79,
        58,
        579,
        195,
        478,
        58
    ],
    "u1f987":[
        67,
        67,
        67,
        916,
        67,
        58,
        916,
        196,
        196,
        196
    ],
    "u1f988":[
        67,
        58,
        67,
        11,
        67,
        58
    ],
    "u1f989":[
        8,
        4,
        8,
        8,
        194,
        8,
        194,
        546,
        58,
        195,
        13
    ],
    "u1f98a":[
        13,
        9,
        670,
        546,
        733
    ],
    "u1f98b":[
        662,
        662,
        3,
        58,
        58,
        662,
        3,
        58,
        58,
        58
    ],
    "u1f98c":[
        8,
        194,
        733,
        734,
        194,
        733
    ],
    "u1f98d":[
        658,
        658,
        67,
        658,
        196,
        67
    ],
    "u1f98e":[
        579,
        579,
        579,
        197,
        58
    ],
    "u1f98f":[
        67,
        196,
        67,
        196,
        222,
        222,
        58,
        734
    ],
    "u1f990":[
        1,
        2,
        2,
        1,
        58
    ],
    "u1f991":[
        214,
        214,
        604,
        1,
        1,
        214,
        604,
        58
    ],
    "u1f992":[
        13,
        917,
        195,
        13,
        58,
        917
    ],
    "u1f993":[
        79,
        58,
        58,
        79,
        67,
        58,
        67,
        58,
        58
    ],
    "u1f994":[
        721,
        8,
        623,
        623,
        596,
        0,
        336,
        194,
        596
    ],
    "u1f995":[
        579,
        197,
        52,
        189
    ],
    "u1f996":[
        918,
        919,
        920,
        921,
        920,
        920,
        13,
        920,
        918,
        58,
        918
    ],
    "u1f997":[
        922,
        923,
        922,
        922,
        922,
        58,
        924
    ],
    "u1f998":[
        741,
        596,
        742,
        58,
        596,
        741
    ],
    "u1f999":[
        194,
        596,
        194,
        58,
        194
    ],
    "u1f99a":[
        58,
        58,
        58,
        220,
        197,
        3,
        197,
        197,
        10,
        58,
        10,
        58,
        3
    ],
    "u1f99b":[
        196,
        669,
        478,
        660,
        67,
        58,
        669
    ],
    "u1f99c":[
        67,
        67,
        67,
        13,
        4,
        197,
        214,
        12,
        189,
        58,
        189
    ],
    "u1f99d":[
        196,
        58,
        196,
        79,
        58,
        196,
        67,
        79,
        58,
        67,
        67,
        67,
        58
    ],
    "u1f99e":[
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        925,
        1,
        1,
        2,
        1,
        9,
        1,
        2,
        925,
        9,
        1,
        2,
        1,
        1,
        58,
        58,
        214,
        2,
        1
    ],
    "u1f99f":[
        658,
        658,
        658,
        52,
        67,
        67,
        67,
        222,
        79,
        222,
        79
    ],
    "u1f9a0":[
        189,
        189,
        189,
        189,
        189,
        189,
        189,
        579,
        197,
        579,
        663
    ],
    "u1f9a1":[
        658,
        196,
        79,
        79
    ],
    "u1f9a2":[
        79,
        222,
        58,
        222,
        79,
        13
    ],
    "u1f9a3":[
        8,
        194,
        926,
        927,
        58,
        8,
        928
    ],
    "u1f9a4":[
        929,
        67,
        222,
        79,
        4,
        4,
        194,
        196,
        58,
        196,
        196,
        67,
        196,
        222
    ],
    "u1f9a5":[
        930,
        8,
        922,
        922,
        931,
        58,
        478,
        58,
        58
    ],
    "u1f9a6":[
        922,
        922,
        923,
        924,
        922,
        924,
        58,
        58,
        922,
        922,
        923,
        660,
        660,
        660
    ],
    "u1f9a7":[
        932,
        932,
        933,
        934,
        933,
        58,
        578,
        935,
        932
    ],
    "u1f9a8":[
        58,
        79,
        79,
        79
    ],
    "u1f9a9":[
        214,
        604,
        79,
        58,
        214,
        604,
        604,
        214,
        214,
        58,
        214
    ],
    "u1f9aa":[
        8,
        194,
        222,
        79,
        4,
        546,
        4,
        79,
        11
    ],
    "u1f9ab":[
        194,
        927,
        194,
        936,
        58,
        8,
        194,
        194,
        8,
        194
    ],
    "u1f9ac":[
        194,
        8,
        58,
        596,
        196
    ],
    "u1f9ad":[
        196,
        196,
        58,
        67,
        196,
        67,
        196,
        58,
        67,
        196,
        58,
        222
    ],
    "u1f9ae":[
        596,
        194,
        596,
        743,
        733,
        8,
        58,
        194,
        194,
        937,
        938,
        937
    ],
    "u1f9af":[
        222,
        2,
        58,
        58
    ],
    "u1f9b0":[
        649,
        588
    ],
    "u1f9b1":[
        649,
        4
    ],
    "u1f9b2":[
        649
    ],
    "u1f9b3":[
        649,
        79
    ],
    "u1f9b4":[
        578,
        939,
        596
    ],
    "u1f9b5":[
        649,
        694
    ],
    "u1f9b6":[
        649,
        694
    ],
    "u1f9b7":[
        79,
        669
    ],
    "u1f9b8":[
        9,
        9,
        3,
        649,
        706,
        4,
        649,
        194,
        10,
        125,
        194,
        2,
        2
    ],
    "u1f9b9":[
        58,
        196,
        649,
        706,
        649,
        4,
        649,
        1,
        196,
        52,
        194
    ],
    "u1f9ba":[
        940,
        941,
        942,
        942,
        940
    ],
    "u1f9bb":[
        67,
        196,
        649,
        694,
        67,
        196,
        222
    ],
    "u1f9bc":[
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        67,
        222,
        58,
        67
    ],
    "u1f9bd":[
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58
    ],
    "u1f9be":[
        67,
        222,
        196,
        196,
        222,
        196,
        196,
        222
    ],
    "u1f9bf":[
        67,
        222,
        196,
        222,
        594,
        1,
        9,
        2,
        222,
        2
    ],
    "u1f9c0":[
        4,
        546,
        4,
        13
    ],
    "u1f9c1":[
        419,
        8,
        194,
        125,
        578,
        195,
        195,
        2,
        3,
        197,
        13,
        590
    ],
    "u1f9c2":[
        67,
        222,
        478,
        67,
        222
    ],
    "u1f9c3":[
        943,
        546,
        195,
        13,
        214,
        578,
        195,
        222,
        196,
        578
    ],
    "u1f9c4":[
        196,
        79,
        196
    ],
    "u1f9c5":[
        944,
        945,
        946,
        946,
        946
    ],
    "u1f9c6":[
        947,
        948,
        948,
        947,
        948,
        947,
        949,
        950,
        4,
        197,
        220
    ],
    "u1f9c7":[
        611,
        222,
        13,
        4,
        13,
        951,
        952,
        4,
        546
    ],
    "u1f9c8":[
        196,
        222,
        578,
        546,
        195,
        578,
        546,
        195
    ],
    "u1f9c9":[
        623,
        953,
        222,
        67,
        197,
        11,
        222,
        196,
        196,
        222,
        79
    ],
    "u1f9ca":[
        478,
        799,
        800,
        801,
        954,
        955,
        800,
        801
    ],
    "u1f9cb":[
        594,
        956,
        222,
        79,
        957,
        590,
        3,
        125,
        6,
        5,
        590,
        58,
        58,
        58
    ],
    "u1f9cc":[
        958,
        959,
        960,
        220,
        197,
        220,
        197,
        961,
        579,
        220,
        960,
        197,
        962
    ],
    "u1f9cd":[
        649,
        674,
        629,
        4,
        649,
        706,
        649,
        8,
        194,
        58
    ],
    "u1f9ce":[
        649,
        649,
        4,
        629,
        58,
        698,
        674,
        649
    ],
    "u1f9cf":[
        629,
        4,
        649,
        706,
        649,
        194,
        8,
        675,
        629,
        649,
        593
    ],
    "u1f9d0":[
        584,
        832,
        769,
        58,
        660,
        671
    ],
    "u1f9d1":[
        4,
        649,
        194,
        8
    ],
    "u1f9d2":[
        4,
        649,
        194,
        8
    ],
    "u1f9d3":[
        641,
        649,
        194,
        8,
        641
    ],
    "u1f9d4":[
        4,
        649,
        4,
        649,
        194,
        8
    ],
    "u1f9d5":[
        6,
        576,
        6,
        649,
        760,
        8,
        194,
        5
    ],
    "u1f9d6":[
        79,
        79,
        79,
        79,
        649,
        4,
        649,
        706,
        649,
        194,
        8,
        706,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d7":[
        67,
        196,
        649,
        649,
        4,
        58,
        706,
        706,
        706,
        676,
        649,
        675,
        649,
        629,
        676,
        58,
        67
    ],
    "u1f9d8":[
        674,
        649,
        649,
        706,
        706,
        649,
        629,
        4,
        649,
        706,
        649,
        194
    ],
    "u1f9d9":[
        676,
        629,
        649,
        706,
        641,
        649,
        194,
        8,
        629,
        596,
        3,
        973,
        649
    ],
    "u1f9da":[
        974,
        974,
        3,
        649,
        649,
        706,
        629,
        4,
        649,
        194,
        8
    ],
    "u1f9db":[
        58,
        1,
        194,
        649,
        706,
        58,
        649,
        194,
        2,
        649,
        194,
        194,
        478
    ],
    "u1f9dc":[
        649,
        649,
        965,
        977,
        978,
        977,
        649,
        706,
        576,
        649,
        194,
        8
    ],
    "u1f9dd":[
        8,
        629,
        649,
        706,
        8,
        649,
        194,
        8,
        649,
        8
    ],
    "u1f9de":[
        125,
        576,
        195,
        125,
        980,
        58,
        125,
        3,
        8,
        10,
        576,
        591,
        591,
        546,
        4
    ],
    "u1f9df":[
        629,
        675,
        222,
        222,
        196,
        58,
        222,
        67,
        196,
        8,
        196,
        58
    ],
    "u1f9e0":[
        214,
        2,
        604,
        214,
        214
    ],
    "u1f9e1":[
        13
    ],
    "u1f9e2":[
        981,
        58,
        662,
        662,
        665
    ],
    "u1f9e3":[
        9,
        2,
        9,
        2,
        9
    ],
    "u1f9e4":[
        58,
        67,
        67,
        196,
        67
    ],
    "u1f9e5":[
        3,
        10,
        12,
        12
    ],
    "u1f9e6":[
        3,
        662,
        662,
        3,
        662,
        3,
        662,
        3,
        195
    ],
    "u1f9e7":[
        982,
        983,
        984,
        985,
        985,
        985
    ],
    "u1f9e8":[
        578,
        546,
        195,
        195,
        195,
        194,
        2,
        195,
        214,
        546
    ],
    "u1f9e9":[
        711
    ],
    "u1f9ea":[
        222,
        986,
        594,
        987,
        222
    ],
    "u1f9eb":[
        196,
        196,
        79,
        222,
        214,
        196,
        478
    ],
    "u1f9ec":[
        197,
        546,
        4,
        214,
        4,
        197,
        546,
        4,
        214,
        4,
        197,
        546,
        6,
        12,
        3,
        6,
        576,
        3
    ],
    "u1f9ed":[
        13,
        546,
        478,
        222,
        58,
        2,
        594,
        478
    ],
    "u1f9ee":[
        222,
        643,
        661,
        1,
        189,
        4,
        741
    ],
    "u1f9ef":[
        67,
        2,
        2,
        58,
        67,
        67,
        58,
        222,
        2,
        214,
        478,
        197
    ],
    "u1f9f0":[
        58,
        2,
        222,
        1,
        222,
        589,
        58,
        58
    ],
    "u1f9f1":[
        194,
        9,
        596,
        194,
        9,
        194,
        9,
        194,
        9
    ],
    "u1f9f2":[
        222,
        1,
        222,
        4
    ],
    "u1f9f3":[
        58,
        58,
        222,
        674,
        593,
        58,
        698,
        478
    ],
    "u1f9f4":[
        67,
        196,
        4,
        222,
        196,
        195,
        578
    ],
    "u1f9f5":[
        5,
        5,
        194,
        576,
        6
    ],
    "u1f9f6":[
        8,
        10,
        10,
        3,
        3,
        3,
        3
    ],
    "u1f9f7":[
        196,
        67,
        196
    ],
    "u1f9f8":[
        194,
        194,
        194,
        9,
        8,
        194,
        58,
        596,
        58,
        988,
        194,
        596,
        596,
        194,
        596,
        596,
        58,
        988
    ],
    "u1f9f9":[
        194,
        67,
        195,
        4,
        3,
        10
    ],
    "u1f9fa":[
        194,
        194,
        596,
        8,
        596,
        819
    ],
    "u1f9fb":[
        79,
        196,
        222,
        79,
        79,
        478,
        196,
        67,
        222
    ],
    "u1f9fc":[
        222,
        478,
        222,
        478,
        214,
        604,
        989,
        222,
        478,
        222,
        222,
        478,
        478
    ],
    "u1f9fd":[
        13,
        195,
        4,
        4
    ],
    "u1f9fe":[
        222,
        196
    ],
    "u1f9ff":[
        990,
        11,
        125,
        58
    ],
    "u1fa70":[
        604,
        214,
        604,
        604,
        214,
        604,
        604
    ],
    "u1fa71":[
        784,
        711,
        785
    ],
    "u1fa72":[
        991,
        593,
        593,
        992
    ],
    "u1fa73":[
        711,
        838,
        838,
        838
    ],
    "u1fa74":[
        125,
        10,
        12,
        10,
        12
    ],
    "u1fa78":[
        603
    ],
    "u1fa79":[
        596,
        194,
        993,
        994
    ],
    "u1fa7a":[
        658,
        196,
        658
    ],
    "u1fa7b":[
        125,
        662,
        665,
        11
    ],
    "u1fa7c":[
        222,
        594,
        4
    ],
    "u1fa80":[
        1,
        9,
        196,
        2,
        214,
        196
    ],
    "u1fa81":[
        3,
        10,
        10,
        12,
        6
    ],
    "u1fa82":[
        67,
        67,
        67,
        67,
        67,
        67,
        9,
        13,
        4,
        58,
        196,
        125,
        196,
        125,
        604,
        2
    ],
    "u1fa83":[
        222,
        8,
        194,
        195
    ],
    "u1fa84":[
        58,
        67,
        79,
        478,
        4
    ],
    "u1fa85":[
        197,
        995,
        12,
        195,
        6,
        214,
        3,
        195,
        214,
        13,
        12,
        214,
        590,
        13,
        579,
        6,
        12,
        3,
        995,
        195,
        197,
        13,
        995,
        195,
        579,
        214,
        6,
        12,
        13,
        12,
        590,
        214,
        197
    ],
    "u1fa86":[
        1,
        478,
        58,
        197,
        214,
        8,
        2,
        478,
        58,
        546,
        13,
        8,
        2,
        197,
        222,
        79,
        13
    ],
    "u1fa90":[
        195,
        13,
        996,
        13
    ],
    "u1fa91":[
        997,
        194,
        194,
        194,
        997,
        596,
        997,
        194
    ],
    "u1fa92":[
        197,
        197,
        189,
        79,
        189,
        579,
        220
    ],
    "u1fa93":[
        13,
        67,
        222
    ],
    "u1fa94":[
        1,
        998,
        4,
        52,
        13,
        195
    ],
    "u1fa95":[
        660,
        58,
        670,
        478,
        222,
        58,
        660,
        670,
        638
    ],
    "u1fa96":[
        999,
        1000,
        194,
        8,
        1001,
        1002,
        1003
    ],
    "u1fa97":[
        578,
        596,
        1,
        478,
        478,
        58,
        9
    ],
    "u1fa98":[
        1004,
        8,
        8,
        194,
        194,
        8,
        1,
        8,
        596,
        578,
        8
    ],
    "u1fa99":[
        13,
        195,
        578,
        4,
        578,
        13,
        13,
        546,
        195,
        546,
        546,
        4,
        546
    ],
    "u1fa9a":[
        222,
        196,
        194,
        9,
        195
    ],
    "u1fa9b":[
        196,
        79,
        2,
        214,
        9,
        67,
        79
    ],
    "u1fa9c":[
        596,
        8,
        596,
        8,
        596,
        8,
        741,
        8
    ],
    "u1fa9d":[
        195,
        195,
        4,
        13,
        194,
        546,
        546,
        578
    ],
    "u1fa9e":[
        4,
        596,
        546,
        419,
        478,
        8,
        194,
        4,
        194
    ],
    "u1fa9f":[
        742,
        741,
        796,
        1005,
        741,
        741
    ],
    "u1faa0":[
        596,
        1006,
        1006,
        1007,
        1008
    ],
    "u1faa1":[
        196,
        222,
        5,
        79
    ],
    "u1faa2":[
        3,
        10,
        419
    ],
    "u1faa3":[
        125,
        12,
        12,
        3,
        426,
        196,
        58
    ],
    "u1faa4":[
        8,
        1009,
        1010,
        195,
        13,
        13,
        13,
        194,
        1011,
        1011
    ],
    "u1faa5":[
        222,
        196,
        1012,
        1013
    ],
    "u1faa6":[
        669,
        669,
        67,
        222,
        222,
        67,
        669,
        596,
        637,
        838,
        711
    ],
    "u1faa7":[
        596,
        79,
        67,
        194
    ],
    "u1faa8":[
        67,
        196,
        222,
        196,
        67,
        79,
        196,
        79,
        67
    ],
    "u1faa9":[
        1014,
        1015,
        1016,
        1017,
        1018,
        1019,
        1020
    ],
    "u1faaa":[
        1021,
        1022,
        1023
    ],
    "u1faab":[
        79,
        1024,
        1025,
        196,
        222,
        196,
        222,
        11
    ],
    "u1faac":[
        661,
        11,
        593,
        52,
        11,
        1026
    ],
    "u1fab0":[
        658,
        658,
        658,
        1027,
        1027,
        1,
        658,
        658
    ],
    "u1fab1":[
        1006,
        1028,
        1029,
        1007,
        1030
    ],
    "u1fab2":[
        579,
        579,
        579,
        197,
        189,
        579,
        197,
        189,
        189,
        197
    ],
    "u1fab3":[
        8,
        8,
        8,
        194,
        8,
        596,
        8,
        8,
        8
    ],
    "u1fab4":[
        194,
        1031,
        8,
        579,
        579,
        579,
        579,
        579,
        579,
        579,
        579,
        579,
        579,
        189,
        579,
        579,
        579,
        189,
        189,
        189,
        189,
        189,
        189,
        189,
        197,
        197,
        189,
        197,
        197,
        197,
        189,
        189,
        197,
        220,
        663,
        220,
        220,
        220,
        220,
        663,
        663,
        663,
        663,
        194
    ],
    "u1fab5":[
        8,
        194,
        578,
        8,
        194,
        596,
        596,
        58,
        194
    ],
    "u1fab6":[
        194,
        596,
        194,
        8,
        194
    ],
    "u1fab7":[
        1032,
        604,
        214,
        13,
        649,
        214,
        1032
    ],
    "u1fab8":[
        629,
        1033,
        1026,
        11,
        11
    ],
    "u1fab9":[
        832,
        1034,
        197,
        189,
        1035,
        1034,
        1036,
        1034,
        1037,
        1037
    ],
    "u1faba":[
        832,
        1034,
        197,
        189,
        1035,
        1034,
        1036,
        1034,
        1037,
        1037,
        593,
        661,
        1026,
        593,
        661,
        1026
    ],
    "u1fac0":[
        604,
        1,
        9,
        214,
        604,
        214,
        214,
        604,
        1038,
        1039,
        1039,
        1039
    ],
    "u1fac1":[
        604,
        214,
        2,
        214
    ],
    "u1fac2":[
        3,
        10,
        3,
        10,
        3,
        10
    ],
    "u1fac3":[
        197,
        1040,
        706,
        649,
        694,
        4,
        194,
        8
    ],
    "u1fac4":[
        629,
        675,
        706,
        649,
        694,
        4,
        194,
        8
    ],
    "u1fac5":[
        4,
        649,
        194,
        8,
        1041,
        1042
    ],
    "u1fad0":[
        189,
        197,
        1043,
        1044,
        1044,
        1043,
        1044,
        590,
        1043,
        1044,
        1045,
        1045
    ],
    "u1fad1":[
        579,
        189,
        189,
        197
    ],
    "u1fad2":[
        1046,
        194,
        194,
        194,
        579,
        1046,
        1047
    ],
    "u1fad3":[
        803,
        893,
        546,
        803,
        194,
        893,
        1048
    ],
    "u1fad4":[
        195,
        4,
        578,
        13,
        578,
        578,
        4,
        194,
        596,
        596,
        546,
        546,
        578,
        578,
        4,
        2
    ],
    "u1fad5":[
        58,
        58,
        67,
        196,
        196,
        222,
        4,
        578,
        58,
        58,
        67,
        58,
        1,
        214,
        578,
        4,
        10,
        196,
        10,
        196
    ],
    "u1fad6":[
        10,
        12,
        12,
        10,
        10
    ],
    "u1fad7":[
        1049,
        11,
        846,
        1050,
        1051,
        1049,
        741
    ],
    "u1fad8":[
        1052,
        1053,
        1054,
        1052,
        1053,
        1054,
        1052,
        1053,
        1054
    ],
    "u1fad9":[
        1049,
        583,
        583,
        706,
        846,
        583,
        11
    ],
    "u1fae0":[
        195,
        832
    ],
    "u1fae1":[
        195,
        13,
        860,
        832
    ],
    "u1fae2":[
        195,
        832,
        13
    ],
    "u1fae3":[
        195,
        832,
        769,
        58,
        583,
        583,
        860,
        860
    ],
    "u1fae4":[
        195,
        832
    ],
    "u1fae5":[
        1055,
        195,
        832
    ],
    "u1fae6":[
        214,
        1056,
        769
    ],
    "u1fae7":[
        665,
        665,
        1057,
        723,
        11,
        1005
    ],
    "u1faf0":[
        649,
        694
    ],
    "u1faf1":[
        694,
        649
    ],
    "u1faf2":[
        694,
        649,
        694
    ],
    "u1faf3":[
        694,
        649,
        694,
        649,
        694,
        649,
        649,
        694
    ],
    "u1faf4":[
        649,
        694,
        649,
        694,
        649,
        694,
        649,
        694
    ],
    "u1faf5":[
        649,
        694
    ],
    "u1faf6":[
        649,
        706
    ],
    "u1f1e6_1f1e8":[
        14,
        15,
        11,
        16,
        17,
        18,
        19,
        11,
        20,
        15,
        21,
        22,
        22,
        23,
        15,
        24,
        25,
        26,
        24,
        27,
        28,
        29,
        22,
        23,
        15,
        25,
        26,
        24,
        27,
        28,
        11,
        30,
        30,
        11,
        11,
        31,
        15,
        14,
        32,
        33,
        33
    ],
    "u1f1e6_1f1e9":[
        34,
        35,
        36,
        37,
        34,
        36,
        11,
        11,
        36,
        34,
        38
    ],
    "u1f1e6_1f1ea":[
        39,
        32,
        40,
        41
    ],
    "u1f1e6_1f1eb":[
        40,
        42,
        43,
        11,
        11,
        44,
        11,
        11,
        11,
        11,
        11,
        11,
        11
    ],
    "u1f1e6_1f1ec":[
        11,
        40,
        45,
        46,
        47
    ],
    "u1f1e6_1f1ee":[
        14,
        48,
        11,
        49,
        11,
        49,
        11,
        49,
        11,
        50,
        14,
        32,
        33,
        33
    ],
    "u1f1e6_1f1f1":[
        51,
        52,
        51
    ],
    "u1f1e6_1f1f2":[
        53,
        54,
        55
    ],
    "u1f1e6_1f1f4":[
        40,
        56,
        57,
        58,
        57
    ],
    "u1f1e6_1f1f6":[
        59,
        11
    ],
    "u1f1e6_1f1f7":[
        60,
        32,
        61,
        62,
        61,
        62,
        63,
        64
    ],
    "u1f1e6_1f1f8":[
        14,
        65,
        32,
        66,
        66,
        11,
        67,
        68,
        69,
        68,
        11,
        67,
        52
    ],
    "u1f1e6_1f1f9":[
        32,
        70
    ],
    "u1f1e6_1f1fa":[
        14,
        11,
        14,
        32,
        33,
        33
    ],
    "u1f1e6_1f1fc":[
        71,
        11,
        72,
        57
    ],
    "u1f1e6_1f1fd":[
        73,
        72,
        74
    ],
    "u1f1e6_1f1ff":[
        75,
        76,
        11,
        11,
        77
    ],
    "u1f1e7_1f1e6":[
        78,
        79,
        80
    ],
    "u1f1e7_1f1e7":[
        81,
        81,
        82,
        52
    ],
    "u1f1e7_1f1e9":[
        83,
        84
    ],
    "u1f1e7_1f1ea":[
        40,
        85,
        86
    ],
    "u1f1e7_1f1eb":[
        87,
        88,
        45
    ],
    "u1f1e7_1f1ec":[
        32,
        89,
        90
    ],
    "u1f1e7_1f1ed":[
        32,
        47
    ],
    "u1f1e7_1f1ee":[
        32,
        47,
        91,
        47,
        91,
        47
    ],
    "u1f1e7_1f1ef":[
        45,
        92,
        93
    ],
    "u1f1e7_1f1f1":[
        32,
        94,
        95,
        96,
        97,
        94,
        98,
        99,
        11,
        99,
        100
    ],
    "u1f1e7_1f1f2":[
        33,
        52,
        101,
        11,
        33,
        102,
        103,
        104,
        105,
        52,
        52,
        106,
        104,
        11,
        52,
        14,
        32,
        33,
        33
    ],
    "u1f1e7_1f1f3":[
        107,
        40,
        32,
        108,
        108,
        108
    ],
    "u1f1e7_1f1f4":[
        109,
        102,
        110,
        11,
        111,
        111,
        111,
        110,
        102,
        109,
        112,
        111,
        111,
        110,
        102,
        109,
        112,
        113,
        109,
        11,
        52,
        114,
        112,
        110,
        109,
        11,
        115,
        116
    ],
    "u1f1e7_1f1f6":[
        117,
        118,
        119,
        52,
        120
    ],
    "u1f1e7_1f1f7":[
        121,
        122,
        123,
        124,
        125,
        3,
        12
    ],
    "u1f1e7_1f1f8":[
        126,
        127,
        40
    ],
    "u1f1e7_1f1f9":[
        128,
        129,
        11
    ],
    "u1f1e7_1f1fb":[
        88,
        130,
        131
    ],
    "u1f1e7_1f1fc":[
        32,
        60,
        40
    ],
    "u1f1e7_1f1fe":[
        132,
        133,
        11,
        133,
        133,
        133
    ],
    "u1f1e7_1f1ff":[
        56,
        134,
        11,
        135,
        11,
        136,
        136,
        136,
        136,
        137,
        11,
        138,
        138,
        139,
        140,
        32,
        139,
        140,
        139,
        140,
        141,
        138,
        52,
        142,
        143,
        144,
        145,
        11,
        146,
        137,
        147,
        141,
        11,
        148,
        149,
        141,
        150,
        52,
        148,
        141,
        150,
        52,
        137
    ],
    "u1f1e8_1f1e6":[
        110,
        32,
        110
    ],
    "u1f1e8_1f1e8":[
        151,
        102,
        152,
        151
    ],
    "u1f1e8_1f1e9":[
        153,
        154,
        155
    ],
    "u1f1e8_1f1eb":[
        156,
        32,
        157,
        73,
        72
    ],
    "u1f1e8_1f1ec":[
        158,
        159,
        160
    ],
    "u1f1e8_1f1ed":[
        161,
        11
    ],
    "u1f1e8_1f1ee":[
        162,
        32,
        163
    ],
    "u1f1e8_1f1f0":[
        14,
        11,
        14,
        32,
        33,
        33
    ],
    "u1f1e8_1f1f1":[
        164,
        32,
        165,
        11
    ],
    "u1f1e8_1f1f2":[
        166,
        167,
        168
    ],
    "u1f1e8_1f1f3":[
        169,
        170
    ],
    "u1f1e8_1f1f4":[
        80,
        171,
        172
    ],
    "u1f1e8_1f1f5":[
        70,
        173,
        32
    ],
    "u1f1e8_1f1f7":[
        174,
        32,
        175,
        32,
        176,
        177,
        178,
        179,
        177,
        178,
        177
    ],
    "u1f1e8_1f1fa":[
        180,
        32,
        181,
        11
    ],
    "u1f1e8_1f1fb":[
        182,
        32,
        183,
        184
    ],
    "u1f1e8_1f1fc":[
        174,
        185,
        11
    ],
    "u1f1e8_1f1fd":[
        186,
        187,
        11,
        188,
        187
    ],
    "u1f1e8_1f1fe":[
        32,
        189,
        189,
        13
    ],
    "u1f1e8_1f1ff":[
        190,
        32,
        191
    ],
    "u1f1e9_1f1ea":[
        192,
        193,
        40
    ],
    "u1f1e9_1f1ec":[
        32,
        14,
        194,
        2,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        32,
        196,
        197,
        14,
        32,
        33,
        33
    ],
    "u1f1e9_1f1ef":[
        198,
        199,
        32,
        190
    ],
    "u1f1e9_1f1f0":[
        200,
        32
    ],
    "u1f1e9_1f1f2":[
        201,
        32,
        45,
        45,
        40,
        40,
        2,
        202,
        203,
        204,
        203,
        205,
        11,
        204,
        205,
        52
    ],
    "u1f1e9_1f1f4":[
        206,
        2,
        175,
        32,
        206,
        175,
        11,
        11,
        206,
        11,
        175,
        175,
        206,
        207,
        207,
        208,
        11,
        52,
        209,
        209,
        175,
        175,
        175
    ],
    "u1f1e9_1f1ff":[
        210,
        32,
        211,
        211
    ],
    "u1f1ea_1f1e6":[
        212,
        213,
        214,
        215,
        2,
        4,
        196,
        67
    ],
    "u1f1ea_1f1e8":[
        216,
        217,
        218,
        218,
        217,
        216,
        219,
        220,
        8,
        221,
        219,
        195,
        217,
        216,
        222,
        11,
        194
    ],
    "u1f1ea_1f1ea":[
        40,
        223,
        32
    ],
    "u1f1ea_1f1ec":[
        40,
        32,
        175,
        224,
        32,
        224,
        32,
        224
    ],
    "u1f1ea_1f1ed":[
        40,
        225,
        32,
        226,
        226
    ],
    "u1f1ea_1f1f7":[
        227,
        228,
        229,
        230
    ],
    "u1f1ea_1f1f8":[
        212,
        213,
        214,
        215,
        2,
        4,
        196,
        67
    ],
    "u1f1ea_1f1f9":[
        231,
        179,
        232,
        233,
        231,
        231,
        231,
        231,
        231
    ],
    "u1f1ea_1f1fa":[
        234,
        235
    ],
    "u1f1eb_1f1ee":[
        131,
        32,
        236
    ],
    "u1f1eb_1f1ef":[
        237,
        52,
        11,
        72,
        11,
        238,
        239,
        240,
        240,
        240,
        241,
        240,
        52,
        11,
        240,
        14,
        32,
        33,
        33
    ],
    "u1f1eb_1f1f0":[
        14,
        242,
        243,
        244,
        243,
        11,
        245,
        11,
        246,
        11,
        52,
        247,
        247,
        248,
        249,
        14,
        32,
        33,
        33
    ],
    "u1f1eb_1f1f2":[
        250,
        11
    ],
    "u1f1eb_1f1f4":[
        32,
        251,
        70,
        251
    ],
    "u1f1eb_1f1f7":[
        70,
        173,
        32
    ],
    "u1f1ec_1f1e6":[
        163,
        252,
        45
    ],
    "u1f1ec_1f1e7":[
        14,
        33,
        32,
        33
    ],
    "u1f1ec_1f1e9":[
        47,
        253,
        45,
        47,
        45,
        45
    ],
    "u1f1ec_1f1ea":[
        32,
        92,
        92,
        32,
        92
    ],
    "u1f1ec_1f1eb":[
        254,
        255,
        256
    ],
    "u1f1ec_1f1ec":[
        32,
        92,
        257
    ],
    "u1f1ec_1f1ed":[
        166,
        80,
        167,
        52
    ],
    "u1f1ec_1f1ee":[
        258,
        32,
        258,
        258,
        52,
        52,
        258,
        52,
        52,
        258,
        258,
        52,
        52,
        259,
        258,
        259,
        259
    ],
    "u1f1ec_1f1f1":[
        260,
        32,
        32,
        260
    ],
    "u1f1ec_1f1f2":[
        261,
        262,
        32,
        263
    ],
    "u1f1ec_1f1f3":[
        47,
        45,
        264
    ],
    "u1f1ec_1f1f5":[
        265,
        40,
        151,
        255
    ],
    "u1f1ec_1f1f6":[
        266,
        32,
        227,
        267,
        52,
        11,
        52,
        11,
        52,
        11,
        52,
        11,
        52,
        11,
        268,
        269,
        270
    ],
    "u1f1ec_1f1f7":[
        271,
        32,
        271,
        32
    ],
    "u1f1ec_1f1f8":[
        14,
        272,
        273,
        11,
        274,
        275,
        11,
        274,
        276,
        277,
        278,
        11,
        279,
        280,
        281,
        52,
        11,
        282,
        283,
        284,
        285,
        284,
        14,
        32,
        33,
        33
    ],
    "u1f1ec_1f1f9":[
        286,
        32,
        287,
        288,
        289,
        290,
        288,
        288,
        289,
        288,
        290,
        288,
        291,
        287,
        292
    ],
    "u1f1ec_1f1fa":[
        2,
        293,
        2,
        294,
        295,
        296,
        259,
        259,
        297,
        298,
        298,
        298,
        298,
        298,
        298,
        11,
        2
    ],
    "u1f1ec_1f1fc":[
        45,
        87,
        47,
        52
    ],
    "u1f1ec_1f1fe":[
        87,
        11,
        45,
        40,
        47
    ],
    "u1f1ed_1f1f0":[
        169,
        11,
        11,
        11,
        169
    ],
    "u1f1ed_1f1f2":[
        14,
        11,
        14,
        32,
        33,
        33
    ],
    "u1f1ed_1f1f3":[
        32,
        299,
        300
    ],
    "u1f1ed_1f1f7":[
        32,
        301,
        110,
        110,
        11,
        301,
        302
    ],
    "u1f1ed_1f1f9":[
        303,
        304,
        11,
        305,
        304,
        303,
        306,
        306,
        306,
        11,
        303,
        11,
        303,
        305
    ],
    "u1f1ed_1f1fa":[
        32,
        307,
        308
    ],
    "u1f1ee_1f1e8":[
        235,
        32,
        309,
        310,
        311,
        52,
        312,
        313,
        309,
        309,
        309,
        29,
        314,
        315,
        11
    ],
    "u1f1ee_1f1e9":[
        316,
        32
    ],
    "u1f1ee_1f1ea":[
        317,
        32,
        318
    ],
    "u1f1ee_1f1f1":[
        131,
        32,
        131,
        319
    ],
    "u1f1ee_1f1f2":[
        33,
        320,
        320,
        11,
        320
    ],
    "u1f1ee_1f1f3":[
        321,
        322,
        323,
        324,
        323,
        325,
        324
    ],
    "u1f1ee_1f1f4":[
        32,
        14,
        194,
        2,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        32,
        196,
        197,
        14,
        32,
        33,
        33
    ],
    "u1f1ee_1f1f6":[
        40,
        32,
        2,
        326,
        326
    ],
    "u1f1ee_1f1f7":[
        327,
        32,
        328,
        329,
        1,
        1,
        330
    ],
    "u1f1ee_1f1f8":[
        331,
        332,
        32
    ],
    "u1f1ee_1f1f9":[
        333,
        334,
        32
    ],
    "u1f1ef_1f1ea":[
        32,
        335,
        195,
        2,
        336,
        195,
        2,
        337
    ],
    "u1f1ef_1f1f2":[
        121,
        40,
        338
    ],
    "u1f1ef_1f1f4":[
        40,
        225,
        32,
        47,
        11
    ],
    "u1f1ef_1f1f5":[
        32,
        339
    ],
    "u1f1f0_1f1ea":[
        340,
        341,
        40,
        32,
        40,
        11,
        40,
        11,
        40,
        341,
        11
    ],
    "u1f1f0_1f1ec":[
        92,
        195,
        92,
        195,
        195
    ],
    "u1f1f0_1f1ed":[
        342,
        343,
        11,
        344
    ],
    "u1f1f0_1f1ee":[
        32,
        47,
        4,
        259,
        259,
        345,
        45,
        32,
        14,
        32
    ],
    "u1f1f0_1f1f2":[
        252,
        346,
        47,
        32,
        347,
        11,
        11
    ],
    "u1f1f0_1f1f3":[
        87,
        47,
        45,
        40,
        11
    ],
    "u1f1f0_1f1f5":[
        348,
        32,
        349,
        11
    ],
    "u1f1f0_1f1f7":[
        32,
        200,
        350,
        58
    ],
    "u1f1f0_1f1fc":[
        225,
        47,
        32,
        40
    ],
    "u1f1f0_1f1fe":[
        14,
        351,
        352,
        33,
        11,
        353,
        354,
        354,
        351,
        11,
        355,
        354,
        354,
        356,
        14,
        32,
        33,
        33
    ],
    "u1f1f0_1f1ff":[
        357,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195,
        195
    ],
    "u1f1f1_1f1e6":[
        47,
        130,
        11
    ],
    "u1f1f1_1f1e7":[
        216,
        32,
        151,
        32
    ],
    "u1f1f1_1f1e8":[
        358,
        11,
        52,
        45
    ],
    "u1f1f1_1f1ee":[
        56,
        174,
        359,
        360,
        360,
        52,
        360
    ],
    "u1f1f1_1f1f0":[
        361,
        362,
        363,
        364,
        361
    ],
    "u1f1f1_1f1f7":[
        365,
        32,
        130,
        11
    ],
    "u1f1f1_1f1f8":[
        158,
        32,
        304,
        58,
        366
    ],
    "u1f1f1_1f1f9":[
        367,
        368,
        369
    ],
    "u1f1f1_1f1fa":[
        32,
        70,
        370
    ],
    "u1f1f1_1f1fb":[
        371,
        32
    ],
    "u1f1f1_1f1fe":[
        40,
        372,
        373,
        11,
        11
    ],
    "u1f1f2_1f1e6":[
        369,
        210
    ],
    "u1f1f2_1f1e8":[
        32,
        47
    ],
    "u1f1f2_1f1e9":[
        2,
        59,
        195,
        2,
        10,
        374,
        195,
        197,
        2,
        195
    ],
    "u1f1f2_1f1ea":[
        195,
        2,
        195,
        189,
        10,
        195,
        195,
        195
    ],
    "u1f1f2_1f1eb":[
        70,
        173,
        32
    ],
    "u1f1f2_1f1ec":[
        375,
        376,
        32
    ],
    "u1f1f2_1f1ed":[
        182,
        13,
        32,
        11
    ],
    "u1f1f2_1f1f0":[
        377,
        378,
        377
    ],
    "u1f1f2_1f1f1":[
        379,
        45,
        47
    ],
    "u1f1f2_1f1f2":[
        380,
        381,
        382,
        11
    ],
    "u1f1f2_1f1f3":[
        383,
        384,
        385,
        385,
        385
    ],
    "u1f1f2_1f1f4":[
        386,
        80,
        11,
        11
    ],
    "u1f1f2_1f1f5":[
        59,
        11,
        387,
        388,
        389,
        390,
        11,
        387,
        387,
        387,
        387,
        391,
        392,
        392,
        393,
        394,
        387,
        391,
        392,
        391,
        392,
        391,
        392,
        391,
        392,
        391,
        392
    ],
    "u1f1f2_1f1f6":[
        395,
        32,
        32,
        11
    ],
    "u1f1f2_1f1f7":[
        396,
        397,
        398,
        398
    ],
    "u1f1f2_1f1f8":[
        14,
        3,
        399,
        52,
        400,
        401,
        402,
        403,
        52,
        14,
        32,
        33,
        33
    ],
    "u1f1f2_1f1f9":[
        181,
        32,
        181,
        181,
        181,
        196,
        222,
        222,
        222,
        404,
        196
    ],
    "u1f1f2_1f1fa":[
        380,
        405,
        406,
        407
    ],
    "u1f1f2_1f1fb":[
        72,
        376,
        11
    ],
    "u1f1f2_1f1fc":[
        197,
        2,
        40,
        2
    ],
    "u1f1f2_1f1fd":[
        408,
        47,
        32,
        220,
        189,
        3,
        195,
        189,
        195,
        194
    ],
    "u1f1f2_1f1fe":[
        2,
        32,
        409,
        195,
        195
    ],
    "u1f1f2_1f1ff":[
        410,
        40,
        411,
        32,
        2,
        410,
        40,
        11,
        40
    ],
    "u1f1f3_1f1e6":[
        158,
        236,
        32,
        211,
        73,
        236,
        73
    ],
    "u1f1f3_1f1e8":[
        158,
        412,
        413,
        40,
        414,
        40,
        40,
        40
    ],
    "u1f1f3_1f1ea":[
        32,
        415,
        416
    ],
    "u1f1f3_1f1eb":[
        109,
        32,
        109,
        32
    ],
    "u1f1f3_1f1ec":[
        417,
        32
    ],
    "u1f1f3_1f1ee":[
        59,
        32,
        418,
        32,
        59,
        3,
        419,
        189,
        420,
        419,
        2,
        10,
        195,
        2,
        32
    ],
    "u1f1f3_1f1f1":[
        32,
        421,
        422
    ],
    "u1f1f3_1f1f4":[
        88,
        130,
        32
    ],
    "u1f1f3_1f1f5":[
        423,
        424,
        11
    ],
    "u1f1f3_1f1f7":[
        174,
        346,
        11
    ],
    "u1f1f3_1f1fa":[
        425,
        14,
        32,
        33,
        33,
        426,
        425
    ],
    "u1f1f3_1f1ff":[
        14,
        117,
        33,
        14,
        32,
        33,
        33
    ],
    "u1f1f4_1f1f2":[
        151,
        32,
        427,
        32,
        32
    ],
    "u1f1f5_1f1e6":[
        32,
        428,
        72
    ],
    "u1f1f5_1f1ea":[
        429,
        32
    ],
    "u1f1f5_1f1eb":[
        47,
        32,
        430,
        431,
        47,
        432
    ],
    "u1f1f5_1f1ec":[
        40,
        47,
        11,
        45
    ],
    "u1f1f5_1f1ed":[
        47,
        433,
        32,
        45,
        45
    ],
    "u1f1f5_1f1f0":[
        434,
        32,
        11,
        11
    ],
    "u1f1f5_1f1f1":[
        32,
        435
    ],
    "u1f1f5_1f1f2":[
        436,
        195,
        52,
        419,
        437,
        195,
        52,
        32,
        58,
        160,
        158,
        32
    ],
    "u1f1f5_1f1f3":[
        14,
        2,
        438,
        259,
        259,
        259,
        438,
        438,
        438,
        439,
        438,
        438,
        259,
        259,
        440,
        438,
        259,
        259,
        259,
        259,
        14,
        32,
        33,
        33
    ],
    "u1f1f5_1f1f7":[
        441,
        32,
        442,
        11
    ],
    "u1f1f5_1f1f8":[
        40,
        443,
        32,
        72
    ],
    "u1f1f5_1f1f9":[
        340,
        110,
        195,
        110,
        11,
        444,
        444,
        234
    ],
    "u1f1f5_1f1fc":[
        445,
        446
    ],
    "u1f1f5_1f1fe":[
        433,
        32,
        110,
        52,
        32,
        52,
        32,
        196,
        52,
        32,
        4,
        447,
        447,
        447
    ],
    "u1f1f6_1f1e6":[
        448,
        32
    ],
    "u1f1f7_1f1ea":[
        449,
        450,
        451
    ],
    "u1f1f7_1f1f4":[
        174,
        45,
        47
    ],
    "u1f1f7_1f1f8":[
        452,
        32,
        453,
        454,
        4,
        454,
        452,
        453,
        452,
        11,
        454,
        453,
        32
    ],
    "u1f1f7_1f1fa":[
        172,
        171,
        32
    ],
    "u1f1f7_1f1fc":[
        455,
        456,
        370,
        456,
        370,
        456
    ],
    "u1f1f8_1f1e6":[
        457,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11
    ],
    "u1f1f8_1f1e7":[
        458,
        459,
        45,
        11,
        11,
        11,
        11,
        11,
        11
    ],
    "u1f1f8_1f1e8":[
        460,
        461,
        462,
        32,
        225
    ],
    "u1f1f8_1f1e9":[
        72,
        40,
        32,
        443
    ],
    "u1f1f8_1f1ea":[
        463,
        464
    ],
    "u1f1f8_1f1ec":[
        32,
        70,
        11,
        32
    ],
    "u1f1f8_1f1ed":[
        14,
        32,
        451,
        465,
        466,
        467,
        468,
        469,
        451,
        52,
        32,
        470,
        32,
        52,
        14,
        32,
        33,
        33
    ],
    "u1f1f8_1f1ee":[
        216,
        32,
        471,
        216,
        426,
        11,
        218
    ],
    "u1f1f8_1f1ef":[
        88,
        130,
        32
    ],
    "u1f1f8_1f1f0":[
        472,
        32,
        473,
        11,
        472,
        11,
        473
    ],
    "u1f1f8_1f1f1":[
        32,
        91,
        46
    ],
    "u1f1f8_1f1f2":[
        474,
        32,
        13,
        4,
        475,
        222,
        196,
        476,
        477,
        477,
        79,
        478,
        196
    ],
    "u1f1f8_1f1f3":[
        479,
        480,
        481,
        479
    ],
    "u1f1f8_1f1f4":[
        71,
        11
    ],
    "u1f1f8_1f1f7":[
        482,
        32,
        483,
        484
    ],
    "u1f1f8_1f1f8":[
        40,
        254,
        256,
        32,
        233,
        255
    ],
    "u1f1f8_1f1f9":[
        199,
        73,
        72,
        52
    ],
    "u1f1f8_1f1fb":[
        233,
        32,
        485,
        486,
        11,
        486,
        11,
        486,
        11,
        486,
        11,
        487,
        11,
        488,
        487,
        489,
        487
    ],
    "u1f1f8_1f1fd":[
        490,
        491,
        32,
        492,
        493,
        491,
        494,
        11,
        11,
        495,
        496,
        492
    ],
    "u1f1f8_1f1fe":[
        32,
        47,
        40,
        225
    ],
    "u1f1f8_1f1ff":[
        497,
        498,
        499,
        11,
        498,
        11,
        498,
        11,
        52,
        11,
        52,
        500,
        501
    ],
    "u1f1f9_1f1e6":[
        14,
        502,
        503,
        504,
        505,
        506,
        505,
        506,
        505,
        507,
        508,
        505,
        506,
        505,
        506,
        509,
        11,
        510,
        510,
        11,
        510,
        52,
        511,
        508,
        512,
        512,
        14,
        32,
        33,
        33
    ],
    "u1f1f9_1f1e8":[
        14,
        11,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        519,
        519,
        519,
        519,
        519,
        519,
        519,
        519,
        519,
        519,
        14,
        32,
        33,
        33
    ],
    "u1f1f9_1f1e9":[
        520,
        521,
        200
    ],
    "u1f1f9_1f1eb":[
        522,
        32,
        522,
        32,
        70,
        11,
        11
    ],
    "u1f1f9_1f1ec":[
        523,
        73,
        72,
        11
    ],
    "u1f1f9_1f1ed":[
        524,
        32,
        525,
        32,
        524
    ],
    "u1f1f9_1f1ef":[
        526,
        340,
        32,
        4,
        4
    ],
    "u1f1f9_1f1f0":[
        14,
        11,
        527
    ],
    "u1f1f9_1f1f1":[
        160,
        82,
        40,
        11
    ],
    "u1f1f9_1f1f2":[
        528,
        529,
        530,
        528,
        530,
        11,
        530,
        530,
        530,
        528,
        528,
        529,
        530,
        530,
        11
    ],
    "u1f1f9_1f1f3":[
        372,
        11,
        372,
        372
    ],
    "u1f1f9_1f1f4":[
        531,
        117,
        531
    ],
    "u1f1f9_1f1f7":[
        532,
        32
    ],
    "u1f1f9_1f1f9":[
        47,
        32,
        40
    ],
    "u1f1f9_1f1fb":[
        533,
        73,
        14,
        32,
        33,
        33,
        14,
        32,
        33,
        33
    ],
    "u1f1f9_1f1fc":[
        534,
        535,
        11,
        11
    ],
    "u1f1f9_1f1ff":[
        91,
        536,
        40,
        537
    ],
    "u1f1fa_1f1e6":[
        538,
        406
    ],
    "u1f1fa_1f1ec":[
        539,
        540,
        40,
        11,
        541,
        540,
        40,
        541,
        542,
        541
    ],
    "u1f1fa_1f1f2":[
        543,
        32,
        544,
        11
    ],
    "u1f1fa_1f1f3":[
        545,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        545,
        545,
        545,
        545,
        545,
        545,
        545,
        11,
        11
    ],
    "u1f1fa_1f1f8":[
        543,
        32,
        544,
        11
    ],
    "u1f1fa_1f1fe":[
        433,
        32,
        546,
        195,
        4,
        194,
        4
    ],
    "u1f1fa_1f1ff":[
        547,
        91,
        47,
        32,
        11
    ],
    "u1f1fb_1f1e6":[
        548,
        32,
        549,
        549,
        550,
        550,
        549,
        551,
        551,
        550,
        549,
        549,
        550,
        52,
        11,
        550,
        550,
        550,
        550,
        550,
        551,
        551,
        552,
        552,
        552,
        552,
        552,
        550,
        550
    ],
    "u1f1fb_1f1e8":[
        163,
        46,
        45,
        163
    ],
    "u1f1fb_1f1ea":[
        181,
        14,
        235,
        11
    ],
    "u1f1fb_1f1ec":[
        14,
        11,
        553,
        554,
        11,
        555,
        450,
        556,
        556,
        14,
        32,
        33,
        33
    ],
    "u1f1fb_1f1ee":[
        32,
        557,
        558,
        559,
        11,
        560,
        561
    ],
    "u1f1fb_1f1f3":[
        562,
        451
    ],
    "u1f1fb_1f1fa":[
        72,
        158,
        40,
        563,
        563,
        563
    ],
    "u1f1fc_1f1eb":[
        70,
        32,
        522,
        32,
        70,
        11
    ],
    "u1f1fc_1f1f8":[
        47,
        174,
        11
    ],
    "u1f1fd_1f1f0":[
        564,
        565,
        566
    ],
    "u1f1fe_1f1ea":[
        40,
        32,
        47
    ],
    "u1f1fe_1f1f9":[
        32,
        567,
        336,
        32,
        10,
        10,
        10,
        10,
        10,
        10,
        301,
        567,
        32,
        4,
        222,
        67,
        222,
        196
    ],
    "u1f1ff_1f1e6":[
        568,
        522,
        40,
        569,
        570,
        32
    ],
    "u1f1ff_1f1f2":[
        571,
        572,
        40,
        393
    ],
    "u1f1ff_1f1fc":[
        32,
        393,
        573,
        4,
        345,
        574,
        573,
        393,
        40
    ],
    "u1f385_1f3fb":[
        639,
        639,
        2,
        639,
        8,
        640,
        0,
        639,
        641,
        612
    ],
    "u1f385_1f3fc":[
        642,
        642,
        2,
        642,
        8,
        640,
        0,
        642,
        641,
        612
    ],
    "u1f385_1f3fd":[
        643,
        643,
        2,
        643,
        8,
        640,
        0,
        643,
        641,
        612
    ],
    "u1f385_1f3fe":[
        644,
        644,
        2,
        644,
        645,
        646,
        0,
        644,
        641,
        612
    ],
    "u1f385_1f3ff":[
        647,
        647,
        2,
        647,
        52,
        648,
        0,
        647,
        641,
        612
    ],
    "u1f3c2_1f3fb":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        639,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c2_1f3fc":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        642,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c2_1f3fd":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        672,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c2_1f3fe":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        644,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c2_1f3ff":[
        662,
        79,
        214,
        662,
        660,
        419,
        662,
        647,
        669,
        660,
        671,
        660,
        671,
        3
    ],
    "u1f3c3_1f3fb":[
        639,
        594,
        675,
        629,
        222,
        676,
        675,
        639,
        58,
        629,
        639
    ],
    "u1f3c3_1f3fb_200d_2640_fe0f":[
        639,
        594,
        1,
        2,
        222,
        9,
        1,
        639,
        639,
        58,
        2,
        639
    ],
    "u1f3c3_1f3fb_200d_2642_fe0f":[
        639,
        594,
        662,
        661,
        222,
        673,
        639,
        58,
        674,
        639
    ],
    "u1f3c3_1f3fc":[
        642,
        594,
        675,
        629,
        222,
        676,
        675,
        642,
        677,
        629,
        642
    ],
    "u1f3c3_1f3fc_200d_2640_fe0f":[
        642,
        594,
        1,
        2,
        222,
        9,
        1,
        642,
        642,
        677,
        2,
        642
    ],
    "u1f3c3_1f3fc_200d_2642_fe0f":[
        642,
        594,
        662,
        661,
        222,
        673,
        642,
        677,
        674,
        642
    ],
    "u1f3c3_1f3fd":[
        672,
        594,
        675,
        629,
        222,
        676,
        675,
        672,
        678,
        629,
        672
    ],
    "u1f3c3_1f3fd_200d_2640_fe0f":[
        672,
        594,
        1,
        2,
        222,
        9,
        1,
        672,
        672,
        678,
        2,
        672
    ],
    "u1f3c3_1f3fd_200d_2642_fe0f":[
        672,
        594,
        662,
        661,
        222,
        673,
        672,
        678,
        674,
        672
    ],
    "u1f3c3_1f3fe":[
        644,
        594,
        675,
        629,
        222,
        676,
        675,
        644,
        645,
        629,
        644
    ],
    "u1f3c3_1f3fe_200d_2640_fe0f":[
        644,
        594,
        1,
        2,
        222,
        9,
        1,
        644,
        644,
        645,
        2,
        644
    ],
    "u1f3c3_1f3fe_200d_2642_fe0f":[
        644,
        594,
        662,
        661,
        222,
        673,
        644,
        645,
        674,
        644
    ],
    "u1f3c3_1f3ff":[
        647,
        594,
        675,
        629,
        222,
        676,
        675,
        647,
        679,
        629,
        647
    ],
    "u1f3c3_1f3ff_200d_2640_fe0f":[
        647,
        594,
        1,
        2,
        222,
        9,
        1,
        647,
        647,
        679,
        2,
        647
    ],
    "u1f3c3_1f3ff_200d_2642_fe0f":[
        647,
        594,
        662,
        661,
        222,
        673,
        647,
        679,
        674,
        647
    ],
    "u1f3c3_200d_2640_fe0f":[
        649,
        594,
        1,
        2,
        222,
        9,
        1,
        649,
        649,
        4,
        2,
        649
    ],
    "u1f3c3_200d_2642_fe0f":[
        649,
        594,
        662,
        661,
        222,
        673,
        649,
        4,
        674,
        649
    ],
    "u1f3c4_1f3fb":[
        674,
        662,
        220,
        478,
        639,
        639,
        58,
        639,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fb_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        639,
        639,
        58,
        58,
        660
    ],
    "u1f3c4_1f3fb_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        639,
        639,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fc":[
        674,
        662,
        220,
        478,
        642,
        642,
        677,
        642,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fc_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        642,
        642,
        677,
        677,
        58,
        660
    ],
    "u1f3c4_1f3fc_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        642,
        642,
        677,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fd":[
        674,
        662,
        220,
        478,
        672,
        672,
        678,
        672,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fd_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        672,
        672,
        678,
        678,
        58,
        660
    ],
    "u1f3c4_1f3fd_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        672,
        672,
        678,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fe":[
        674,
        662,
        220,
        478,
        644,
        644,
        645,
        644,
        58,
        660,
        660
    ],
    "u1f3c4_1f3fe_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        644,
        644,
        645,
        645,
        58,
        660
    ],
    "u1f3c4_1f3fe_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        644,
        644,
        645,
        58,
        660,
        660
    ],
    "u1f3c4_1f3ff":[
        674,
        662,
        220,
        478,
        647,
        647,
        679,
        647,
        58,
        660,
        660
    ],
    "u1f3c4_1f3ff_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        647,
        647,
        679,
        679,
        58,
        660
    ],
    "u1f3c4_1f3ff_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        647,
        647,
        679,
        58,
        660,
        660
    ],
    "u1f3c4_200d_2640_fe0f":[
        674,
        662,
        220,
        478,
        649,
        649,
        4,
        4,
        58,
        660
    ],
    "u1f3c4_200d_2642_fe0f":[
        674,
        662,
        220,
        478,
        649,
        649,
        4,
        58,
        660,
        660
    ],
    "u1f3c7_1f3fb":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        402,
        58,
        6,
        52,
        222,
        189,
        220,
        402,
        589
    ],
    "u1f3c7_1f3fc":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        681,
        677,
        6,
        52,
        222,
        189,
        220,
        681,
        589
    ],
    "u1f3c7_1f3fd":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        643,
        678,
        6,
        52,
        222,
        189,
        220,
        643,
        589
    ],
    "u1f3c7_1f3fe":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        682,
        645,
        6,
        52,
        222,
        189,
        220,
        682,
        589
    ],
    "u1f3c7_1f3ff":[
        58,
        623,
        58,
        194,
        58,
        4,
        680,
        189,
        58,
        478,
        58,
        680,
        683,
        679,
        6,
        52,
        222,
        189,
        220,
        683,
        589
    ],
    "u1f3ca_1f3fb":[
        639,
        629,
        675,
        684,
        3,
        3
    ],
    "u1f3ca_1f3fb_200d_2640_fe0f":[
        639,
        2,
        684,
        3,
        3
    ],
    "u1f3ca_1f3fb_200d_2642_fe0f":[
        639,
        674,
        685,
        3,
        3
    ],
    "u1f3ca_1f3fc":[
        642,
        629,
        675,
        686,
        3,
        3
    ],
    "u1f3ca_1f3fc_200d_2640_fe0f":[
        642,
        2,
        686,
        3,
        3
    ],
    "u1f3ca_1f3fc_200d_2642_fe0f":[
        642,
        674,
        687,
        3,
        3
    ],
    "u1f3ca_1f3fd":[
        672,
        629,
        675,
        688,
        3,
        3
    ],
    "u1f3ca_1f3fd_200d_2640_fe0f":[
        643,
        2,
        688,
        3,
        3
    ],
    "u1f3ca_1f3fd_200d_2642_fe0f":[
        643,
        674,
        689,
        3,
        3
    ],
    "u1f3ca_1f3fe":[
        644,
        629,
        675,
        690,
        3,
        3
    ],
    "u1f3ca_1f3fe_200d_2640_fe0f":[
        644,
        2,
        690,
        3,
        3
    ],
    "u1f3ca_1f3fe_200d_2642_fe0f":[
        644,
        674,
        691,
        3,
        3
    ],
    "u1f3ca_1f3ff":[
        647,
        629,
        675,
        692,
        3,
        3
    ],
    "u1f3ca_1f3ff_200d_2640_fe0f":[
        647,
        2,
        692,
        3,
        3
    ],
    "u1f3ca_1f3ff_200d_2642_fe0f":[
        647,
        674,
        693,
        3,
        3
    ],
    "u1f3ca_200d_2640_fe0f":[
        649,
        2,
        694,
        3,
        3
    ],
    "u1f3ca_200d_2642_fe0f":[
        649,
        674,
        694,
        3,
        3
    ],
    "u1f3cb_1f3fb":[
        639,
        629,
        196,
        58,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        58,
        639,
        639,
        194,
        8
    ],
    "u1f3cb_1f3fb_200d_2640_fe0f":[
        58,
        639,
        2,
        196,
        58,
        639,
        58,
        639,
        58,
        194,
        8,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fb_200d_2642_fe0f":[
        639,
        674,
        196,
        58,
        639,
        58,
        639,
        58,
        194,
        8,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        639,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fc":[
        642,
        629,
        196,
        58,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        677,
        642,
        642,
        194,
        8
    ],
    "u1f3cb_1f3fc_200d_2640_fe0f":[
        677,
        642,
        2,
        196,
        58,
        642,
        677,
        642,
        677,
        194,
        8,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fc_200d_2642_fe0f":[
        642,
        674,
        196,
        58,
        642,
        677,
        642,
        677,
        194,
        8,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        642,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fd":[
        672,
        629,
        196,
        58,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        678,
        672,
        672,
        194,
        8
    ],
    "u1f3cb_1f3fd_200d_2640_fe0f":[
        678,
        672,
        2,
        196,
        58,
        672,
        678,
        672,
        678,
        194,
        8,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fd_200d_2642_fe0f":[
        672,
        674,
        196,
        58,
        672,
        678,
        672,
        678,
        194,
        8,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        672,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fe":[
        644,
        629,
        196,
        58,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        645,
        644,
        644,
        646,
        8
    ],
    "u1f3cb_1f3fe_200d_2640_fe0f":[
        645,
        644,
        2,
        196,
        58,
        644,
        645,
        644,
        645,
        646,
        8,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_1f3fe_200d_2642_fe0f":[
        644,
        674,
        196,
        58,
        644,
        645,
        644,
        645,
        646,
        8,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        644,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cb_1f3ff":[
        647,
        629,
        196,
        58,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        675,
        194,
        664,
        664,
        695,
        695,
        695,
        679,
        647,
        647,
        648,
        52
    ],
    "u1f3cb_1f3ff_200d_2640_fe0f":[
        679,
        647,
        2,
        196,
        58,
        647,
        679,
        647,
        679,
        648,
        52,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_1f3ff_200d_2642_fe0f":[
        647,
        674,
        196,
        58,
        647,
        679,
        647,
        679,
        648,
        52,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        647,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cb_fe0f_200d_2640_fe0f":[
        4,
        649,
        2,
        196,
        58,
        649,
        4,
        649,
        4,
        194,
        8,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        2,
        194,
        664,
        664,
        695,
        695,
        695,
        695
    ],
    "u1f3cb_fe0f_200d_2642_fe0f":[
        649,
        674,
        196,
        58,
        649,
        4,
        649,
        4,
        194,
        8,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        649,
        696,
        194,
        664,
        664,
        695,
        695,
        695
    ],
    "u1f3cc_1f3fb":[
        639,
        699,
        700,
        701,
        67,
        639,
        222,
        697,
        701,
        222,
        700,
        639,
        629,
        639,
        58,
        699,
        701,
        639,
        629,
        67
    ],
    "u1f3cc_1f3fb_200d_2640_fe0f":[
        697,
        1,
        639,
        2,
        67,
        222,
        697,
        639,
        2,
        6,
        639,
        214,
        58,
        67,
        6
    ],
    "u1f3cc_1f3fb_200d_2642_fe0f":[
        698,
        67,
        222,
        697,
        674,
        125,
        3,
        3,
        639,
        674,
        197,
        639,
        665,
        222,
        197,
        67
    ],
    "u1f3cc_1f3fc":[
        642,
        699,
        700,
        701,
        67,
        642,
        222,
        702,
        701,
        222,
        700,
        642,
        629,
        642,
        677,
        699,
        701,
        642,
        629,
        67
    ],
    "u1f3cc_1f3fc_200d_2640_fe0f":[
        702,
        1,
        642,
        2,
        67,
        222,
        702,
        642,
        2,
        6,
        642,
        214,
        677,
        67,
        6
    ],
    "u1f3cc_1f3fc_200d_2642_fe0f":[
        698,
        67,
        222,
        702,
        674,
        125,
        3,
        3,
        642,
        674,
        197,
        642,
        665,
        222,
        197,
        67
    ],
    "u1f3cc_1f3fd":[
        672,
        699,
        700,
        701,
        67,
        672,
        222,
        703,
        701,
        222,
        700,
        672,
        629,
        672,
        678,
        699,
        701,
        672,
        629,
        67
    ],
    "u1f3cc_1f3fd_200d_2640_fe0f":[
        703,
        1,
        643,
        2,
        67,
        222,
        703,
        643,
        2,
        6,
        643,
        214,
        678,
        67,
        6
    ],
    "u1f3cc_1f3fd_200d_2642_fe0f":[
        698,
        67,
        222,
        703,
        674,
        125,
        3,
        3,
        643,
        674,
        197,
        643,
        665,
        222,
        197,
        67
    ],
    "u1f3cc_1f3fe":[
        644,
        699,
        700,
        701,
        67,
        644,
        222,
        704,
        701,
        222,
        700,
        644,
        629,
        644,
        645,
        699,
        701,
        644,
        629,
        67
    ],
    "u1f3cc_1f3fe_200d_2640_fe0f":[
        704,
        1,
        644,
        2,
        67,
        222,
        704,
        644,
        2,
        6,
        644,
        214,
        645,
        67,
        6
    ],
    "u1f3cc_1f3fe_200d_2642_fe0f":[
        698,
        67,
        222,
        704,
        674,
        125,
        3,
        3,
        644,
        674,
        197,
        644,
        665,
        222,
        197,
        67
    ],
    "u1f3cc_1f3ff":[
        647,
        699,
        700,
        701,
        67,
        647,
        222,
        705,
        701,
        222,
        700,
        647,
        629,
        647,
        679,
        699,
        701,
        647,
        629,
        67
    ],
    "u1f3cc_1f3ff_200d_2640_fe0f":[
        705,
        1,
        647,
        2,
        67,
        222,
        705,
        647,
        2,
        6,
        647,
        214,
        679,
        67,
        6
    ],
    "u1f3cc_1f3ff_200d_2642_fe0f":[
        698,
        67,
        222,
        705,
        674,
        125,
        3,
        3,
        647,
        674,
        197,
        647,
        665,
        222,
        197,
        67
    ],
    "u1f3cc_fe0f_200d_2640_fe0f":[
        649,
        1,
        649,
        2,
        67,
        222,
        195,
        649,
        2,
        6,
        649,
        214,
        4,
        67,
        6
    ],
    "u1f3cc_fe0f_200d_2642_fe0f":[
        698,
        67,
        222,
        195,
        674,
        125,
        3,
        3,
        649,
        674,
        197,
        649,
        665,
        222,
        197,
        67
    ],
    "u1f3f3_fe0f_200d_1f308":[
        724,
        725,
        726,
        727,
        728,
        729
    ],
    "u1f3f3_fe0f_200d_26a7_fe0f":[
        730,
        731,
        32,
        731,
        730
    ],
    "u1f3f4_200d_2620_fe0f":[
        658,
        658,
        58,
        0,
        0
    ],
    "u1f3f4_e0067_e0062_e0065_e006e_e0067_e007f":[
        32,
        732
    ],
    "u1f3f4_e0067_e0062_e0073_e0063_e0074_e007f":[
        32,
        251
    ],
    "u1f3f4_e0067_e0062_e0077_e006c_e0073_e007f":[
        32,
        227,
        2,
        11,
        52
    ],
    "u1f408_200d_2b1b":[
        58,
        58,
        735,
        67,
        736,
        58
    ],
    "u1f415_200d_1f9ba":[
        596,
        194,
        194,
        596,
        743,
        194,
        194,
        733,
        8,
        58,
        194,
        9,
        58,
        9,
        604,
        478
    ],
    "u1f43b_200d_2744_fe0f":[
        222,
        67,
        79,
        2,
        222,
        733,
        2,
        58,
        58
    ],
    "u1f441_fe0f_200d_1f5e8_fe0f":[
        658,
        658,
        658,
        11
    ],
    "u1f442_1f3fb":[
        639,
        684,
        684
    ],
    "u1f442_1f3fc":[
        642,
        686,
        686
    ],
    "u1f442_1f3fd":[
        643,
        688,
        688
    ],
    "u1f442_1f3fe":[
        644,
        690,
        690
    ],
    "u1f442_1f3ff":[
        647,
        692,
        692
    ],
    "u1f443_1f3fb":[
        639,
        685,
        8
    ],
    "u1f443_1f3fc":[
        642,
        687,
        8
    ],
    "u1f443_1f3fd":[
        643,
        689,
        8
    ],
    "u1f443_1f3fe":[
        644,
        691,
        645
    ],
    "u1f443_1f3ff":[
        647,
        693,
        52
    ],
    "u1f446_1f3fb":[
        639
    ],
    "u1f446_1f3fc":[
        642
    ],
    "u1f446_1f3fd":[
        643
    ],
    "u1f446_1f3fe":[
        644
    ],
    "u1f446_1f3ff":[
        647
    ],
    "u1f447_1f3fb":[
        639
    ],
    "u1f447_1f3fc":[
        642
    ],
    "u1f447_1f3fd":[
        643
    ],
    "u1f447_1f3fe":[
        644
    ],
    "u1f447_1f3ff":[
        647
    ],
    "u1f448_1f3fb":[
        639
    ],
    "u1f448_1f3fc":[
        642
    ],
    "u1f448_1f3fd":[
        643
    ],
    "u1f448_1f3fe":[
        644
    ],
    "u1f448_1f3ff":[
        647
    ],
    "u1f449_1f3fb":[
        639
    ],
    "u1f449_1f3fc":[
        642
    ],
    "u1f449_1f3fd":[
        643
    ],
    "u1f449_1f3fe":[
        644
    ],
    "u1f449_1f3ff":[
        647
    ],
    "u1f44a_1f3fb":[
        639,
        684,
        684
    ],
    "u1f44a_1f3fc":[
        642,
        686,
        686
    ],
    "u1f44a_1f3fd":[
        643,
        688,
        688
    ],
    "u1f44a_1f3fe":[
        644,
        690,
        690
    ],
    "u1f44a_1f3ff":[
        647,
        692,
        692
    ],
    "u1f44b_1f3fb":[
        684,
        639,
        593,
        593,
        593
    ],
    "u1f44b_1f3fc":[
        686,
        642,
        593,
        593,
        593
    ],
    "u1f44b_1f3fd":[
        688,
        672,
        593,
        593,
        593
    ],
    "u1f44b_1f3fe":[
        690,
        644,
        593,
        593,
        593
    ],
    "u1f44b_1f3ff":[
        692,
        647,
        593,
        593,
        593
    ],
    "u1f44c_1f3fb":[
        684,
        639
    ],
    "u1f44c_1f3fc":[
        686,
        642
    ],
    "u1f44c_1f3fd":[
        688,
        672
    ],
    "u1f44c_1f3fe":[
        690,
        644
    ],
    "u1f44c_1f3ff":[
        692,
        647
    ],
    "u1f44d_1f3fb":[
        402,
        750
    ],
    "u1f44d_1f3fc":[
        681,
        751
    ],
    "u1f44d_1f3fd":[
        672,
        752
    ],
    "u1f44d_1f3fe":[
        682,
        753
    ],
    "u1f44d_1f3ff":[
        683,
        692
    ],
    "u1f44e_1f3fb":[
        402,
        750
    ],
    "u1f44e_1f3fc":[
        681,
        751
    ],
    "u1f44e_1f3fd":[
        672,
        752
    ],
    "u1f44e_1f3fe":[
        682,
        753
    ],
    "u1f44e_1f3ff":[
        683,
        692
    ],
    "u1f44f_1f3fb":[
        684,
        629,
        639
    ],
    "u1f44f_1f3fc":[
        686,
        629,
        642
    ],
    "u1f44f_1f3fd":[
        688,
        629,
        643
    ],
    "u1f44f_1f3fe":[
        690,
        629,
        644
    ],
    "u1f44f_1f3ff":[
        692,
        629,
        647
    ],
    "u1f450_1f3fb":[
        684,
        639,
        639
    ],
    "u1f450_1f3fc":[
        686,
        642,
        642
    ],
    "u1f450_1f3fd":[
        688,
        643,
        643
    ],
    "u1f450_1f3fe":[
        690,
        644,
        644
    ],
    "u1f450_1f3ff":[
        692,
        647,
        647
    ],
    "u1f466_1f3fb":[
        639,
        639,
        8,
        194,
        58
    ],
    "u1f466_1f3fc":[
        642,
        642,
        8,
        194,
        677
    ],
    "u1f466_1f3fd":[
        643,
        643,
        8,
        194,
        678
    ],
    "u1f466_1f3fe":[
        644,
        644,
        645,
        646,
        645
    ],
    "u1f466_1f3ff":[
        647,
        647,
        52,
        648,
        679
    ],
    "u1f467_1f3fb":[
        58,
        58,
        58,
        639,
        639,
        760,
        194,
        8,
        58
    ],
    "u1f467_1f3fc":[
        677,
        677,
        677,
        642,
        642,
        760,
        194,
        8,
        677
    ],
    "u1f467_1f3fd":[
        678,
        678,
        678,
        643,
        643,
        760,
        194,
        8,
        678
    ],
    "u1f467_1f3fe":[
        645,
        645,
        645,
        644,
        644,
        760,
        646,
        645,
        645
    ],
    "u1f467_1f3ff":[
        679,
        679,
        679,
        647,
        647,
        760,
        648,
        52,
        679
    ],
    "u1f468_1f3fb":[
        639,
        639,
        8,
        194,
        58
    ],
    "u1f468_1f3fb_200d_1f33e":[
        197,
        579,
        639,
        697,
        639,
        194,
        58,
        8,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_1f3fb_200d_1f373":[
        79,
        10,
        639,
        697,
        639,
        194,
        58,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_1f3fb_200d_1f37c":[
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        761,
        79,
        125,
        3,
        220,
        639,
        674,
        639,
        674
    ],
    "u1f468_1f3fb_200d_1f384":[
        639,
        639,
        2,
        639,
        8,
        640,
        0,
        639,
        641,
        612
    ],
    "u1f468_1f3fb_200d_1f393":[
        58,
        67,
        197,
        189,
        639,
        697,
        639,
        194,
        8,
        58,
        58,
        67,
        195
    ],
    "u1f468_1f3fb_200d_1f3a4":[
        402,
        58,
        762,
        762,
        763,
        764,
        764,
        402,
        765,
        402,
        424,
        194,
        766,
        125,
        767,
        768
    ],
    "u1f468_1f3fb_200d_1f3a8":[
        197,
        697,
        639,
        639,
        58,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        639
    ],
    "u1f468_1f3fb_200d_1f3eb":[
        596,
        189,
        11,
        79,
        639,
        697,
        639,
        58,
        8,
        194,
        196,
        1,
        478,
        2
    ],
    "u1f468_1f3fb_200d_1f3ed":[
        12,
        58,
        639,
        3,
        697,
        639,
        194,
        58,
        697,
        697,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_1f3fb_200d_1f4bb":[
        197,
        639,
        697,
        639,
        194,
        58,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f468_1f3fb_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        402,
        765,
        402,
        194,
        766,
        58,
        715,
        399,
        424,
        769
    ],
    "u1f468_1f3fb_200d_1f527":[
        222,
        639,
        697,
        639,
        194,
        58,
        8,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_1f3fb_200d_1f52c":[
        222,
        639,
        697,
        639,
        194,
        58,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_1f3fb_200d_1f680":[
        79,
        214,
        1,
        58,
        639,
        697,
        639,
        194,
        58,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_1f3fb_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        639,
        697,
        639,
        194,
        58,
        8,
        760,
        214,
        772
    ],
    "u1f468_1f3fb_200d_1f91d_200d_1f468_1f3fc":[
        58,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        677,
        642,
        642,
        660,
        58,
        711,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f468_1f3fb_200d_1f91d_200d_1f468_1f3fd":[
        58,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        678,
        672,
        672,
        660,
        58,
        711,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f468_1f3fb_200d_1f91d_200d_1f468_1f3fe":[
        58,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        645,
        644,
        644,
        660,
        58,
        711,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f468_1f3fb_200d_1f91d_200d_1f468_1f3ff":[
        58,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        679,
        647,
        647,
        660,
        58,
        711,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f468_1f3fb_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        639,
        639,
        58,
        698,
        674,
        197,
        639,
        58
    ],
    "u1f468_1f3fb_200d_1f9b0":[
        402,
        8,
        194,
        588
    ],
    "u1f468_1f3fb_200d_1f9b1":[
        402,
        8,
        194,
        58
    ],
    "u1f468_1f3fb_200d_1f9b2":[
        402,
        194,
        8
    ],
    "u1f468_1f3fb_200d_1f9b3":[
        402,
        8,
        194,
        79
    ],
    "u1f468_1f3fb_200d_1f9bc":[
        639,
        639,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        639,
        67,
        222,
        58,
        67
    ],
    "u1f468_1f3fb_200d_1f9bd":[
        639,
        639,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        639
    ],
    "u1f468_1f3fb_200d_2695_fe0f":[
        12,
        639,
        697,
        639,
        194,
        58,
        8,
        222,
        478,
        58,
        222
    ],
    "u1f468_1f3fb_200d_2696_fe0f":[
        58,
        79,
        639,
        697,
        639,
        721,
        8,
        194,
        196,
        10,
        478,
        58,
        67,
        8,
        194,
        639
    ],
    "u1f468_1f3fb_200d_2708_fe0f":[
        79,
        58,
        639,
        697,
        639,
        194,
        58,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f468_1f3fb":[
        58,
        639,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f468_1f3fc":[
        58,
        639,
        661,
        662,
        194,
        8,
        677,
        642,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f468_1f3fd":[
        58,
        639,
        661,
        662,
        194,
        8,
        678,
        672,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f468_1f3fe":[
        58,
        639,
        661,
        662,
        194,
        8,
        645,
        644,
        661,
        662,
        646,
        8,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f468_1f3ff":[
        58,
        639,
        661,
        662,
        194,
        8,
        679,
        647,
        661,
        662,
        648,
        52,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        639,
        58,
        8,
        194,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        639,
        58,
        8,
        194,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        639,
        58,
        8,
        194,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        639,
        58,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f468_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        639,
        58,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f468_1f3fc":[
        642,
        642,
        8,
        194,
        677
    ],
    "u1f468_1f3fc_200d_1f33e":[
        197,
        579,
        642,
        702,
        642,
        194,
        677,
        8,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_1f3fc_200d_1f373":[
        79,
        10,
        642,
        702,
        642,
        194,
        677,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_1f3fc_200d_1f37c":[
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        761,
        79,
        125,
        3,
        220,
        642,
        674,
        642,
        674
    ],
    "u1f468_1f3fc_200d_1f384":[
        642,
        642,
        2,
        642,
        8,
        640,
        0,
        642,
        641,
        612
    ],
    "u1f468_1f3fc_200d_1f393":[
        58,
        67,
        197,
        189,
        642,
        702,
        642,
        194,
        8,
        677,
        58,
        67,
        195
    ],
    "u1f468_1f3fc_200d_1f3a4":[
        681,
        58,
        762,
        762,
        763,
        764,
        764,
        681,
        775,
        681,
        424,
        194,
        766,
        125,
        767,
        768
    ],
    "u1f468_1f3fc_200d_1f3a8":[
        197,
        702,
        642,
        642,
        677,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        642
    ],
    "u1f468_1f3fc_200d_1f3eb":[
        596,
        189,
        11,
        79,
        642,
        702,
        642,
        677,
        8,
        194,
        196,
        1,
        478,
        2
    ],
    "u1f468_1f3fc_200d_1f3ed":[
        12,
        58,
        642,
        3,
        702,
        642,
        194,
        677,
        702,
        702,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_1f3fc_200d_1f4bb":[
        197,
        642,
        702,
        642,
        194,
        677,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f468_1f3fc_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        681,
        775,
        681,
        194,
        766,
        677,
        715,
        399,
        424,
        769
    ],
    "u1f468_1f3fc_200d_1f527":[
        222,
        642,
        702,
        642,
        194,
        677,
        8,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_1f3fc_200d_1f52c":[
        222,
        642,
        702,
        642,
        194,
        677,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_1f3fc_200d_1f680":[
        79,
        214,
        1,
        58,
        642,
        702,
        642,
        194,
        677,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_1f3fc_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        642,
        702,
        642,
        194,
        677,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f468_1f3fc_200d_1f91d_200d_1f468_1f3fb":[
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        639,
        639,
        660,
        58,
        711,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f468_1f3fc_200d_1f91d_200d_1f468_1f3fd":[
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        678,
        672,
        672,
        660,
        58,
        711,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f468_1f3fc_200d_1f91d_200d_1f468_1f3fe":[
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        645,
        644,
        644,
        660,
        58,
        711,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f468_1f3fc_200d_1f91d_200d_1f468_1f3ff":[
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        679,
        647,
        647,
        660,
        58,
        711,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f468_1f3fc_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        642,
        642,
        677,
        698,
        674,
        197,
        642,
        58
    ],
    "u1f468_1f3fc_200d_1f9b0":[
        681,
        8,
        194,
        588
    ],
    "u1f468_1f3fc_200d_1f9b1":[
        642,
        8,
        194,
        677
    ],
    "u1f468_1f3fc_200d_1f9b2":[
        681,
        194,
        8
    ],
    "u1f468_1f3fc_200d_1f9b3":[
        681,
        8,
        194,
        79
    ],
    "u1f468_1f3fc_200d_1f9bc":[
        642,
        642,
        677,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        642,
        67,
        222,
        58,
        67
    ],
    "u1f468_1f3fc_200d_1f9bd":[
        642,
        642,
        677,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        642
    ],
    "u1f468_1f3fc_200d_2695_fe0f":[
        12,
        642,
        702,
        642,
        194,
        677,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f468_1f3fc_200d_2696_fe0f":[
        58,
        79,
        642,
        702,
        642,
        721,
        8,
        194,
        196,
        10,
        478,
        58,
        67,
        8,
        194,
        642
    ],
    "u1f468_1f3fc_200d_2708_fe0f":[
        79,
        58,
        642,
        702,
        642,
        194,
        677,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f468_1f3fb":[
        677,
        642,
        661,
        662,
        194,
        8,
        58,
        639,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f468_1f3fc":[
        677,
        642,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f468_1f3fd":[
        677,
        642,
        661,
        662,
        194,
        8,
        678,
        672,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f468_1f3fe":[
        677,
        642,
        661,
        662,
        194,
        8,
        645,
        644,
        661,
        662,
        646,
        8,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f468_1f3ff":[
        677,
        642,
        661,
        662,
        194,
        8,
        679,
        647,
        661,
        662,
        648,
        52,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        642,
        677,
        8,
        194,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        642,
        677,
        8,
        194,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        642,
        677,
        8,
        194,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        642,
        677,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f468_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        642,
        677,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f468_1f3fd":[
        643,
        643,
        8,
        194,
        678
    ],
    "u1f468_1f3fd_200d_1f33e":[
        197,
        579,
        643,
        703,
        643,
        194,
        678,
        8,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_1f3fd_200d_1f373":[
        79,
        10,
        643,
        703,
        643,
        194,
        678,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_1f3fd_200d_1f37c":[
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        761,
        79,
        125,
        3,
        220,
        672,
        674,
        672,
        674
    ],
    "u1f468_1f3fd_200d_1f384":[
        643,
        643,
        2,
        643,
        8,
        640,
        0,
        643,
        641,
        612
    ],
    "u1f468_1f3fd_200d_1f393":[
        58,
        67,
        197,
        189,
        643,
        703,
        643,
        194,
        8,
        678,
        58,
        67,
        195
    ],
    "u1f468_1f3fd_200d_1f3a4":[
        672,
        58,
        762,
        762,
        763,
        764,
        764,
        672,
        776,
        672,
        424,
        194,
        766,
        125,
        767,
        768
    ],
    "u1f468_1f3fd_200d_1f3a8":[
        197,
        703,
        643,
        643,
        678,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        643
    ],
    "u1f468_1f3fd_200d_1f3eb":[
        596,
        189,
        11,
        79,
        643,
        703,
        643,
        678,
        8,
        194,
        196,
        1,
        478,
        2
    ],
    "u1f468_1f3fd_200d_1f3ed":[
        12,
        58,
        643,
        3,
        703,
        643,
        194,
        678,
        703,
        703,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_1f3fd_200d_1f4bb":[
        197,
        643,
        703,
        643,
        194,
        678,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f468_1f3fd_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        672,
        776,
        672,
        194,
        678,
        766,
        715,
        399,
        424,
        769
    ],
    "u1f468_1f3fd_200d_1f527":[
        222,
        643,
        703,
        643,
        194,
        678,
        8,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_1f3fd_200d_1f52c":[
        222,
        643,
        703,
        643,
        194,
        678,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_1f3fd_200d_1f680":[
        79,
        214,
        1,
        58,
        643,
        703,
        643,
        194,
        678,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_1f3fd_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        643,
        703,
        643,
        194,
        678,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f468_1f3fd_200d_1f91d_200d_1f468_1f3fb":[
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        639,
        639,
        660,
        58,
        711,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f468_1f3fd_200d_1f91d_200d_1f468_1f3fc":[
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        677,
        642,
        642,
        660,
        58,
        711,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f468_1f3fd_200d_1f91d_200d_1f468_1f3fe":[
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        645,
        644,
        644,
        660,
        58,
        711,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f468_1f3fd_200d_1f91d_200d_1f468_1f3ff":[
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        679,
        647,
        647,
        660,
        58,
        711,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f468_1f3fd_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        672,
        672,
        678,
        698,
        674,
        197,
        672,
        58
    ],
    "u1f468_1f3fd_200d_1f9b0":[
        672,
        8,
        194,
        588
    ],
    "u1f468_1f3fd_200d_1f9b1":[
        643,
        8,
        194,
        678
    ],
    "u1f468_1f3fd_200d_1f9b2":[
        672,
        194,
        8
    ],
    "u1f468_1f3fd_200d_1f9b3":[
        672,
        8,
        194,
        79
    ],
    "u1f468_1f3fd_200d_1f9bc":[
        672,
        672,
        678,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        672,
        67,
        222,
        58,
        67
    ],
    "u1f468_1f3fd_200d_1f9bd":[
        672,
        672,
        678,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        672
    ],
    "u1f468_1f3fd_200d_2695_fe0f":[
        12,
        643,
        703,
        643,
        194,
        678,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f468_1f3fd_200d_2696_fe0f":[
        58,
        79,
        643,
        703,
        643,
        721,
        8,
        194,
        196,
        10,
        478,
        58,
        67,
        8,
        194,
        643
    ],
    "u1f468_1f3fd_200d_2708_fe0f":[
        79,
        58,
        643,
        703,
        643,
        194,
        678,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f468_1f3fb":[
        678,
        672,
        661,
        662,
        194,
        8,
        58,
        639,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f468_1f3fc":[
        678,
        672,
        661,
        662,
        194,
        8,
        677,
        642,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f468_1f3fd":[
        678,
        672,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f468_1f3fe":[
        678,
        672,
        661,
        662,
        194,
        8,
        645,
        644,
        661,
        662,
        646,
        8,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f468_1f3ff":[
        678,
        672,
        661,
        662,
        194,
        8,
        679,
        647,
        661,
        662,
        648,
        52,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        672,
        678,
        8,
        194,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        672,
        678,
        8,
        194,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        672,
        678,
        8,
        194,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        672,
        678,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f468_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        672,
        678,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f468_1f3fe":[
        644,
        644,
        645,
        646,
        645
    ],
    "u1f468_1f3fe_200d_1f33e":[
        197,
        579,
        644,
        704,
        644,
        646,
        645,
        645,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_1f3fe_200d_1f373":[
        79,
        10,
        644,
        704,
        644,
        646,
        645,
        645,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_1f3fe_200d_1f37c":[
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        761,
        79,
        125,
        3,
        220,
        644,
        674,
        644,
        674
    ],
    "u1f468_1f3fe_200d_1f384":[
        644,
        644,
        2,
        644,
        645,
        646,
        0,
        644,
        641,
        612
    ],
    "u1f468_1f3fe_200d_1f393":[
        58,
        67,
        197,
        189,
        644,
        704,
        644,
        646,
        645,
        645,
        58,
        67,
        195
    ],
    "u1f468_1f3fe_200d_1f3a4":[
        682,
        58,
        762,
        762,
        763,
        764,
        764,
        682,
        777,
        682,
        424,
        778,
        766,
        125,
        767,
        768
    ],
    "u1f468_1f3fe_200d_1f3a8":[
        197,
        704,
        644,
        644,
        645,
        645,
        646,
        67,
        596,
        189,
        10,
        2,
        195,
        644
    ],
    "u1f468_1f3fe_200d_1f3eb":[
        596,
        189,
        11,
        79,
        644,
        704,
        644,
        645,
        645,
        646,
        196,
        1,
        478,
        2
    ],
    "u1f468_1f3fe_200d_1f3ed":[
        12,
        58,
        644,
        3,
        704,
        644,
        646,
        645,
        704,
        704,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_1f3fe_200d_1f4bb":[
        197,
        644,
        704,
        644,
        646,
        645,
        645,
        79,
        79,
        196,
        79
    ],
    "u1f468_1f3fe_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        682,
        777,
        682,
        778,
        645,
        766,
        715,
        399,
        424,
        769
    ],
    "u1f468_1f3fe_200d_1f527":[
        222,
        644,
        704,
        644,
        646,
        645,
        645,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_1f3fe_200d_1f52c":[
        222,
        644,
        704,
        644,
        646,
        645,
        645,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_1f3fe_200d_1f680":[
        79,
        214,
        1,
        58,
        644,
        704,
        644,
        646,
        645,
        645,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_1f3fe_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        644,
        704,
        644,
        646,
        645,
        645,
        58,
        760,
        214,
        772
    ],
    "u1f468_1f3fe_200d_1f91d_200d_1f468_1f3fb":[
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        639,
        639,
        660,
        58,
        711,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f468_1f3fe_200d_1f91d_200d_1f468_1f3fc":[
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        677,
        642,
        642,
        660,
        58,
        711,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f468_1f3fe_200d_1f91d_200d_1f468_1f3fd":[
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        678,
        672,
        672,
        660,
        58,
        711,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f468_1f3fe_200d_1f91d_200d_1f468_1f3ff":[
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        679,
        647,
        647,
        660,
        58,
        711,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f468_1f3fe_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        644,
        644,
        645,
        698,
        674,
        197,
        644,
        58
    ],
    "u1f468_1f3fe_200d_1f9b0":[
        682,
        8,
        646,
        588
    ],
    "u1f468_1f3fe_200d_1f9b1":[
        644,
        645,
        646,
        645
    ],
    "u1f468_1f3fe_200d_1f9b2":[
        682,
        646,
        8
    ],
    "u1f468_1f3fe_200d_1f9b3":[
        682,
        8,
        646,
        79
    ],
    "u1f468_1f3fe_200d_1f9bc":[
        644,
        644,
        645,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        644,
        67,
        222,
        58,
        67
    ],
    "u1f468_1f3fe_200d_1f9bd":[
        644,
        644,
        645,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        644
    ],
    "u1f468_1f3fe_200d_2695_fe0f":[
        12,
        644,
        704,
        644,
        646,
        645,
        645,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f468_1f3fe_200d_2696_fe0f":[
        58,
        79,
        644,
        704,
        644,
        721,
        645,
        646,
        196,
        10,
        478,
        58,
        67,
        194,
        8,
        194,
        644
    ],
    "u1f468_1f3fe_200d_2708_fe0f":[
        79,
        58,
        644,
        704,
        644,
        646,
        645,
        645,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f468_1f3fb":[
        645,
        644,
        661,
        662,
        646,
        8,
        58,
        639,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f468_1f3fc":[
        645,
        644,
        661,
        662,
        646,
        8,
        677,
        642,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f468_1f3fd":[
        645,
        644,
        661,
        662,
        646,
        8,
        678,
        672,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f468_1f3fe":[
        645,
        644,
        661,
        662,
        646,
        8,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f468_1f3ff":[
        645,
        644,
        661,
        662,
        646,
        8,
        679,
        647,
        661,
        662,
        648,
        52,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        644,
        645,
        8,
        646,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        644,
        645,
        8,
        646,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        644,
        645,
        8,
        646,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        644,
        645,
        8,
        646,
        2
    ],
    "u1f468_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        644,
        645,
        8,
        646,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f468_1f3ff":[
        647,
        647,
        52,
        648,
        679
    ],
    "u1f468_1f3ff_200d_1f33e":[
        197,
        579,
        647,
        705,
        647,
        648,
        679,
        52,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_1f3ff_200d_1f373":[
        79,
        10,
        647,
        705,
        647,
        648,
        679,
        52,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_1f3ff_200d_1f37c":[
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        761,
        79,
        125,
        3,
        220,
        647,
        674,
        647,
        674
    ],
    "u1f468_1f3ff_200d_1f384":[
        647,
        647,
        2,
        647,
        52,
        648,
        0,
        647,
        641,
        612
    ],
    "u1f468_1f3ff_200d_1f393":[
        58,
        67,
        197,
        189,
        647,
        705,
        647,
        648,
        52,
        679,
        58,
        67,
        195
    ],
    "u1f468_1f3ff_200d_1f3a4":[
        683,
        58,
        762,
        762,
        763,
        764,
        764,
        683,
        779,
        683,
        424,
        780,
        52,
        125,
        767,
        768
    ],
    "u1f468_1f3ff_200d_1f3a8":[
        197,
        705,
        647,
        647,
        679,
        52,
        648,
        67,
        596,
        189,
        10,
        2,
        195,
        647
    ],
    "u1f468_1f3ff_200d_1f3eb":[
        596,
        189,
        11,
        79,
        647,
        705,
        647,
        679,
        52,
        648,
        196,
        1,
        478,
        2
    ],
    "u1f468_1f3ff_200d_1f3ed":[
        12,
        58,
        647,
        3,
        705,
        647,
        648,
        679,
        705,
        705,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_1f3ff_200d_1f4bb":[
        197,
        647,
        705,
        647,
        648,
        679,
        52,
        79,
        79,
        196,
        79
    ],
    "u1f468_1f3ff_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        683,
        779,
        683,
        780,
        52,
        679,
        715,
        399,
        424,
        769
    ],
    "u1f468_1f3ff_200d_1f527":[
        222,
        647,
        705,
        647,
        648,
        679,
        52,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_1f3ff_200d_1f52c":[
        222,
        647,
        705,
        647,
        648,
        679,
        52,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_1f3ff_200d_1f680":[
        79,
        58,
        214,
        1,
        58,
        647,
        705,
        647,
        648,
        679,
        52,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_1f3ff_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        647,
        705,
        647,
        648,
        679,
        52,
        58,
        760,
        214,
        772
    ],
    "u1f468_1f3ff_200d_1f91d_200d_1f468_1f3fb":[
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        639,
        639,
        660,
        58,
        711,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f468_1f3ff_200d_1f91d_200d_1f468_1f3fc":[
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        677,
        642,
        642,
        660,
        58,
        711,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f468_1f3ff_200d_1f91d_200d_1f468_1f3fd":[
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        678,
        672,
        672,
        660,
        58,
        711,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f468_1f3ff_200d_1f91d_200d_1f468_1f3fe":[
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        645,
        644,
        644,
        660,
        58,
        711,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f468_1f3ff_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        647,
        647,
        679,
        698,
        674,
        197,
        647,
        58
    ],
    "u1f468_1f3ff_200d_1f9b0":[
        683,
        52,
        648,
        588
    ],
    "u1f468_1f3ff_200d_1f9b1":[
        647,
        52,
        648,
        679
    ],
    "u1f468_1f3ff_200d_1f9b2":[
        683,
        648,
        52
    ],
    "u1f468_1f3ff_200d_1f9b3":[
        683,
        52,
        648,
        79
    ],
    "u1f468_1f3ff_200d_1f9bc":[
        647,
        647,
        679,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        647,
        67,
        222,
        58,
        67
    ],
    "u1f468_1f3ff_200d_1f9bd":[
        647,
        647,
        679,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        647
    ],
    "u1f468_1f3ff_200d_2695_fe0f":[
        12,
        647,
        705,
        647,
        648,
        679,
        52,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f468_1f3ff_200d_2696_fe0f":[
        58,
        79,
        647,
        705,
        647,
        721,
        52,
        648,
        196,
        10,
        478,
        58,
        67,
        194,
        8,
        194,
        647
    ],
    "u1f468_1f3ff_200d_2708_fe0f":[
        79,
        58,
        647,
        705,
        647,
        648,
        679,
        52,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f468_1f3fb":[
        679,
        647,
        661,
        662,
        648,
        52,
        58,
        639,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f468_1f3fc":[
        679,
        647,
        661,
        662,
        648,
        52,
        677,
        642,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f468_1f3fd":[
        679,
        647,
        661,
        662,
        648,
        52,
        678,
        672,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f468_1f3fe":[
        679,
        647,
        661,
        662,
        648,
        52,
        645,
        644,
        661,
        662,
        646,
        8,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f468_1f3ff":[
        679,
        647,
        661,
        662,
        648,
        52,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        647,
        679,
        52,
        648,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        647,
        679,
        52,
        648,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        647,
        679,
        52,
        648,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        647,
        679,
        52,
        648,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f468_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        647,
        679,
        52,
        648,
        2
    ],
    "u1f468_200d_1f33e":[
        197,
        579,
        649,
        706,
        649,
        194,
        4,
        8,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f468_200d_1f373":[
        79,
        10,
        649,
        706,
        649,
        194,
        4,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f468_200d_1f37c":[
        674,
        649,
        706,
        649,
        4,
        194,
        8,
        761,
        79,
        125,
        3,
        220,
        649,
        674,
        649,
        674
    ],
    "u1f468_200d_1f384":[
        649,
        649,
        2,
        649,
        8,
        640,
        0,
        649,
        641,
        612
    ],
    "u1f468_200d_1f393":[
        58,
        67,
        197,
        189,
        649,
        706,
        649,
        194,
        8,
        4,
        58,
        67,
        195
    ],
    "u1f468_200d_1f3a4":[
        754,
        58,
        762,
        762,
        763,
        764,
        764,
        754,
        781,
        754,
        424,
        194,
        766,
        125,
        767,
        768
    ],
    "u1f468_200d_1f3a8":[
        197,
        706,
        649,
        649,
        4,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        649
    ],
    "u1f468_200d_1f3eb":[
        596,
        189,
        11,
        79,
        649,
        706,
        649,
        4,
        8,
        194,
        196,
        1,
        478,
        2
    ],
    "u1f468_200d_1f3ed":[
        12,
        58,
        649,
        3,
        706,
        649,
        194,
        4,
        706,
        706,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f468_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f466_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f467":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        214,
        741,
        8
    ],
    "u1f468_200d_1f467_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        649,
        214,
        741
    ],
    "u1f468_200d_1f467_200d_1f467":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        741,
        8,
        13,
        649,
        214,
        741,
        8
    ],
    "u1f468_200d_1f468_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f468_200d_1f466_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f468_200d_1f467":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f468_200d_1f467_200d_1f466":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        649,
        214,
        741
    ],
    "u1f468_200d_1f468_200d_1f467_200d_1f467":[
        649,
        782,
        783,
        649,
        4,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        741,
        8,
        13,
        649,
        214,
        741,
        8
    ],
    "u1f468_200d_1f469_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f469_200d_1f466_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f469_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        649,
        13,
        741,
        8
    ],
    "u1f468_200d_1f469_200d_1f467_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        649,
        214,
        741
    ],
    "u1f468_200d_1f469_200d_1f467_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        661,
        783,
        649,
        4,
        741,
        8,
        13,
        649,
        214,
        741,
        8,
        13,
        649,
        214,
        741,
        8
    ],
    "u1f468_200d_1f4bb":[
        197,
        649,
        706,
        649,
        194,
        4,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f468_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        754,
        781,
        754,
        636,
        194,
        766,
        715,
        399,
        424,
        769
    ],
    "u1f468_200d_1f527":[
        222,
        649,
        706,
        649,
        194,
        4,
        8,
        10,
        10,
        12,
        10,
        3,
        478,
        67,
        67
    ],
    "u1f468_200d_1f52c":[
        222,
        649,
        706,
        649,
        194,
        4,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f468_200d_1f680":[
        79,
        214,
        1,
        58,
        649,
        706,
        649,
        194,
        4,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f468_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        649,
        706,
        649,
        194,
        4,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f468_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        649,
        649,
        4,
        698,
        674,
        197,
        649,
        58
    ],
    "u1f468_200d_1f9b0":[
        649,
        8,
        194,
        588
    ],
    "u1f468_200d_1f9b1":[
        649,
        8,
        194,
        4
    ],
    "u1f468_200d_1f9b2":[
        649,
        194,
        8
    ],
    "u1f468_200d_1f9b3":[
        649,
        8,
        194,
        79
    ],
    "u1f468_200d_1f9bc":[
        649,
        649,
        4,
        58,
        58,
        674,
        674,
        197,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        649,
        67,
        222,
        58,
        67
    ],
    "u1f468_200d_1f9bd":[
        649,
        649,
        4,
        58,
        58,
        674,
        674,
        197,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        649
    ],
    "u1f468_200d_2695_fe0f":[
        12,
        649,
        706,
        649,
        194,
        4,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f468_200d_2696_fe0f":[
        58,
        79,
        649,
        706,
        649,
        721,
        8,
        194,
        196,
        10,
        478,
        58,
        67,
        8,
        194,
        649
    ],
    "u1f468_200d_2708_fe0f":[
        79,
        58,
        649,
        706,
        649,
        194,
        4,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f468_200d_2764_fe0f_200d_1f468":[
        4,
        649,
        661,
        662,
        194,
        8,
        2
    ],
    "u1f468_200d_2764_fe0f_200d_1f48b_200d_1f468":[
        649,
        4,
        8,
        194,
        2
    ],
    "u1f469_1f3fb":[
        58,
        639,
        760,
        194,
        58,
        8
    ],
    "u1f469_1f3fb_200d_1f33e":[
        579,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        639,
        197,
        10,
        67,
        194,
        58
    ],
    "u1f469_1f3fb_200d_1f373":[
        79,
        10,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        222,
        196,
        79,
        194,
        639,
        222
    ],
    "u1f469_1f3fb_200d_1f37c":[
        6,
        58,
        639,
        697,
        639,
        58,
        639,
        760,
        194,
        8,
        576,
        79,
        125,
        3,
        220,
        639,
        6,
        639,
        6
    ],
    "u1f469_1f3fb_200d_1f384":[
        641,
        639,
        641,
        639,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_1f3fb_200d_1f393":[
        58,
        67,
        6,
        576,
        639,
        697,
        639,
        760,
        8,
        194,
        58,
        639,
        58,
        67,
        195
    ],
    "u1f469_1f3fb_200d_1f3a4":[
        58,
        402,
        786,
        786,
        787,
        788,
        788,
        402,
        765,
        604,
        402,
        2,
        789,
        194,
        766,
        604,
        402,
        767,
        768
    ],
    "u1f469_1f3fb_200d_1f3a8":[
        58,
        6,
        697,
        639,
        760,
        58,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        639
    ],
    "u1f469_1f3fb_200d_1f3eb":[
        596,
        189,
        11,
        6,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        639,
        576,
        591,
        576,
        591
    ],
    "u1f469_1f3fb_200d_1f3ed":[
        12,
        58,
        3,
        58,
        639,
        697,
        639,
        760,
        194,
        58,
        639,
        697,
        697,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_1f3fb_200d_1f4bb":[
        6,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        639,
        79,
        79,
        196,
        79
    ],
    "u1f469_1f3fb_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        58,
        402,
        765,
        402,
        789,
        194,
        766,
        58,
        402
    ],
    "u1f469_1f3fb_200d_1f527":[
        222,
        10,
        478,
        58,
        639,
        697,
        639,
        760,
        58,
        639,
        8,
        194,
        12,
        10,
        3,
        67
    ],
    "u1f469_1f3fb_200d_1f52c":[
        222,
        58,
        639,
        697,
        639,
        760,
        58,
        639,
        8,
        194,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_1f3fb_200d_1f680":[
        79,
        214,
        1,
        58,
        58,
        639,
        697,
        639,
        760,
        58,
        645,
        194,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_1f3fb_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        760,
        214,
        772
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f468_1f3fc":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f468_1f3fd":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f468_1f3fe":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f468_1f3ff":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f469_1f3fc":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        677,
        642,
        702,
        642,
        58,
        784,
        790,
        642,
        702,
        642,
        677,
        760,
        8,
        194
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f469_1f3fd":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        678,
        672,
        703,
        672,
        58,
        784,
        790,
        672,
        703,
        672,
        678,
        760,
        8,
        194
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f469_1f3fe":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        645,
        644,
        704,
        644,
        58,
        784,
        790,
        644,
        704,
        644,
        645,
        760,
        8,
        646
    ],
    "u1f469_1f3fb_200d_1f91d_200d_1f469_1f3ff":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        679,
        647,
        705,
        647,
        58,
        784,
        790,
        647,
        705,
        647,
        679,
        760,
        52,
        648
    ],
    "u1f469_1f3fb_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        639,
        639,
        58,
        58,
        698,
        674,
        784,
        639,
        58
    ],
    "u1f469_1f3fb_200d_1f9b0":[
        588,
        402,
        760,
        194,
        588,
        8
    ],
    "u1f469_1f3fb_200d_1f9b1":[
        58,
        639,
        760,
        194,
        8,
        58
    ],
    "u1f469_1f3fb_200d_1f9b2":[
        402,
        760,
        194,
        8
    ],
    "u1f469_1f3fb_200d_1f9b3":[
        79,
        402,
        760,
        194,
        79,
        8
    ],
    "u1f469_1f3fb_200d_1f9bc":[
        58,
        58,
        674,
        674,
        639,
        639,
        58,
        58,
        784,
        67,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        639,
        67,
        222,
        58,
        67
    ],
    "u1f469_1f3fb_200d_1f9bd":[
        58,
        58,
        674,
        674,
        639,
        639,
        58,
        58,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        639
    ],
    "u1f469_1f3fb_200d_2695_fe0f":[
        12,
        58,
        58,
        222,
        478,
        58,
        222,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        639
    ],
    "u1f469_1f3fb_200d_2696_fe0f":[
        58,
        639,
        697,
        721,
        639,
        760,
        194,
        599,
        590,
        599,
        8,
        478,
        67,
        194,
        639
    ],
    "u1f469_1f3fb_200d_2708_fe0f":[
        79,
        58,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f468_1f3fb":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        674,
        698,
        639,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f468_1f3fc":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        677,
        674,
        698,
        642,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f468_1f3fd":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        678,
        674,
        698,
        672,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f468_1f3fe":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        645,
        674,
        698,
        644,
        646,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f468_1f3ff":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        679,
        674,
        698,
        647,
        648,
        52,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f469_1f3fb":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f469_1f3fc":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f469_1f3fd":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f469_1f3fe":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f469_1f3ff":[
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        58,
        639,
        8,
        194,
        58,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        58,
        639,
        8,
        194,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        58,
        639,
        8,
        194,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        58,
        639,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        58,
        639,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fb":[
        58,
        639,
        8,
        194,
        58,
        639,
        8,
        194,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fc":[
        58,
        639,
        8,
        194,
        677,
        642,
        8,
        194,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fd":[
        58,
        639,
        8,
        194,
        678,
        672,
        8,
        194,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fe":[
        58,
        639,
        8,
        194,
        645,
        644,
        8,
        646,
        2
    ],
    "u1f469_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3ff":[
        58,
        639,
        8,
        194,
        679,
        647,
        52,
        648,
        2
    ],
    "u1f469_1f3fc":[
        677,
        642,
        760,
        194,
        677,
        8
    ],
    "u1f469_1f3fc_200d_1f33e":[
        197,
        10,
        579,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        67,
        194,
        58,
        642
    ],
    "u1f469_1f3fc_200d_1f373":[
        79,
        10,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        222,
        196,
        79,
        194,
        642,
        222
    ],
    "u1f469_1f3fc_200d_1f37c":[
        6,
        677,
        642,
        702,
        642,
        677,
        642,
        760,
        194,
        8,
        576,
        79,
        125,
        3,
        220,
        642,
        6,
        642,
        6
    ],
    "u1f469_1f3fc_200d_1f384":[
        641,
        642,
        641,
        642,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_1f3fc_200d_1f393":[
        58,
        67,
        6,
        576,
        677,
        642,
        702,
        642,
        760,
        8,
        194,
        677,
        642,
        58,
        67,
        195
    ],
    "u1f469_1f3fc_200d_1f3a4":[
        58,
        681,
        786,
        787,
        788,
        786,
        787,
        788,
        681,
        775,
        604,
        681,
        2,
        789,
        194,
        766,
        604,
        681,
        767,
        768
    ],
    "u1f469_1f3fc_200d_1f3a8":[
        677,
        6,
        702,
        642,
        760,
        677,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        642
    ],
    "u1f469_1f3fc_200d_1f3eb":[
        596,
        189,
        11,
        6,
        677,
        58,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        642,
        576,
        591,
        576,
        591
    ],
    "u1f469_1f3fc_200d_1f3ed":[
        12,
        58,
        3,
        677,
        642,
        702,
        642,
        760,
        194,
        677,
        642,
        702,
        702,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_1f3fc_200d_1f4bb":[
        6,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        642,
        79,
        79,
        196,
        79
    ],
    "u1f469_1f3fc_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        677,
        681,
        775,
        681,
        789,
        194,
        766,
        677,
        681
    ],
    "u1f469_1f3fc_200d_1f527":[
        222,
        10,
        478,
        677,
        642,
        702,
        642,
        760,
        677,
        642,
        8,
        194,
        12,
        10,
        3,
        67
    ],
    "u1f469_1f3fc_200d_1f52c":[
        222,
        677,
        642,
        702,
        642,
        760,
        677,
        642,
        8,
        194,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_1f3fc_200d_1f680":[
        79,
        214,
        1,
        58,
        677,
        642,
        702,
        642,
        760,
        677,
        645,
        194,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_1f3fc_200d_1f692":[
        772,
        773,
        222,
        9,
        677,
        58,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        58,
        760,
        214,
        772
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f468_1f3fb":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f468_1f3fd":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f468_1f3fe":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f468_1f3ff":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f469_1f3fb":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        639,
        697,
        639,
        58,
        784,
        790,
        639,
        697,
        639,
        58,
        760,
        8,
        194
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f469_1f3fd":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        678,
        672,
        703,
        672,
        58,
        784,
        790,
        672,
        703,
        672,
        678,
        760,
        8,
        194
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f469_1f3fe":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        645,
        644,
        704,
        644,
        58,
        784,
        790,
        644,
        704,
        644,
        645,
        760,
        8,
        646
    ],
    "u1f469_1f3fc_200d_1f91d_200d_1f469_1f3ff":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        679,
        647,
        705,
        647,
        58,
        784,
        790,
        647,
        705,
        647,
        679,
        760,
        52,
        648
    ],
    "u1f469_1f3fc_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        642,
        642,
        677,
        677,
        698,
        674,
        784,
        642,
        58
    ],
    "u1f469_1f3fc_200d_1f9b0":[
        588,
        681,
        760,
        194,
        588,
        8
    ],
    "u1f469_1f3fc_200d_1f9b1":[
        677,
        642,
        760,
        194,
        8,
        677
    ],
    "u1f469_1f3fc_200d_1f9b2":[
        681,
        760,
        194,
        8
    ],
    "u1f469_1f3fc_200d_1f9b3":[
        79,
        681,
        760,
        194,
        79,
        8
    ],
    "u1f469_1f3fc_200d_1f9bc":[
        58,
        58,
        674,
        674,
        642,
        642,
        677,
        677,
        784,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        642,
        67,
        222,
        58,
        67
    ],
    "u1f469_1f3fc_200d_1f9bd":[
        58,
        58,
        674,
        674,
        642,
        642,
        677,
        677,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        642
    ],
    "u1f469_1f3fc_200d_2695_fe0f":[
        12,
        677,
        58,
        222,
        478,
        58,
        222,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        642
    ],
    "u1f469_1f3fc_200d_2696_fe0f":[
        58,
        642,
        702,
        721,
        642,
        760,
        194,
        599,
        590,
        599,
        8,
        478,
        67,
        194,
        642
    ],
    "u1f469_1f3fc_200d_2708_fe0f":[
        79,
        58,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f468_1f3fb":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        58,
        674,
        698,
        639,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f468_1f3fc":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        674,
        698,
        642,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f468_1f3fd":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        678,
        674,
        698,
        672,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f468_1f3fe":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        645,
        674,
        698,
        644,
        646,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f468_1f3ff":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        679,
        674,
        698,
        647,
        648,
        52,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f469_1f3fb":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f469_1f3fc":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f469_1f3fd":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f469_1f3fe":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f469_1f3ff":[
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        677,
        642,
        8,
        194,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        677,
        642,
        8,
        194,
        677,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        677,
        642,
        8,
        194,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        677,
        642,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        677,
        642,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fb":[
        677,
        642,
        8,
        194,
        58,
        639,
        8,
        194,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fc":[
        677,
        642,
        8,
        194,
        677,
        642,
        8,
        194,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fd":[
        677,
        642,
        8,
        194,
        678,
        672,
        8,
        194,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fe":[
        677,
        642,
        8,
        194,
        645,
        644,
        8,
        646,
        2
    ],
    "u1f469_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3ff":[
        677,
        642,
        8,
        194,
        679,
        647,
        52,
        648,
        2
    ],
    "u1f469_1f3fd":[
        678,
        643,
        760,
        194,
        678,
        8
    ],
    "u1f469_1f3fd_200d_1f33e":[
        197,
        10,
        579,
        678,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        643,
        67,
        194,
        58
    ],
    "u1f469_1f3fd_200d_1f373":[
        79,
        10,
        678,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        222,
        196,
        79,
        194,
        643,
        222
    ],
    "u1f469_1f3fd_200d_1f37c":[
        6,
        678,
        672,
        703,
        672,
        678,
        672,
        760,
        194,
        8,
        576,
        79,
        125,
        3,
        220,
        672,
        6,
        672,
        6
    ],
    "u1f469_1f3fd_200d_1f384":[
        641,
        643,
        641,
        643,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_1f3fd_200d_1f393":[
        58,
        67,
        6,
        576,
        678,
        643,
        703,
        643,
        760,
        8,
        194,
        678,
        643,
        58,
        67,
        195
    ],
    "u1f469_1f3fd_200d_1f3a4":[
        58,
        672,
        786,
        787,
        788,
        786,
        787,
        788,
        672,
        776,
        604,
        672,
        2,
        789,
        194,
        766,
        604,
        672,
        767,
        768
    ],
    "u1f469_1f3fd_200d_1f3a8":[
        678,
        6,
        703,
        643,
        760,
        678,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        643
    ],
    "u1f469_1f3fd_200d_1f3eb":[
        596,
        189,
        11,
        6,
        678,
        58,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        643,
        576,
        591,
        576,
        591
    ],
    "u1f469_1f3fd_200d_1f3ed":[
        12,
        58,
        3,
        678,
        643,
        703,
        643,
        760,
        194,
        678,
        643,
        703,
        703,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_1f3fd_200d_1f4bb":[
        6,
        678,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        643,
        79,
        79,
        196,
        79
    ],
    "u1f469_1f3fd_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        678,
        672,
        776,
        672,
        789,
        194,
        766,
        678,
        672
    ],
    "u1f469_1f3fd_200d_1f527":[
        222,
        10,
        478,
        678,
        643,
        703,
        643,
        760,
        678,
        643,
        8,
        194,
        12,
        10,
        3,
        67
    ],
    "u1f469_1f3fd_200d_1f52c":[
        222,
        678,
        643,
        703,
        643,
        760,
        678,
        643,
        8,
        194,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_1f3fd_200d_1f680":[
        79,
        214,
        1,
        58,
        678,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_1f3fd_200d_1f692":[
        772,
        773,
        222,
        9,
        678,
        58,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        58,
        760,
        214,
        772
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f468_1f3fb":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f468_1f3fc":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f468_1f3fe":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f468_1f3ff":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f469_1f3fb":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        639,
        697,
        639,
        58,
        784,
        790,
        639,
        697,
        639,
        58,
        760,
        8,
        194
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f469_1f3fc":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        677,
        642,
        702,
        642,
        58,
        784,
        790,
        642,
        702,
        642,
        677,
        760,
        8,
        194
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f469_1f3fe":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        645,
        644,
        704,
        644,
        58,
        784,
        790,
        644,
        704,
        644,
        645,
        760,
        8,
        646
    ],
    "u1f469_1f3fd_200d_1f91d_200d_1f469_1f3ff":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        679,
        647,
        705,
        647,
        58,
        784,
        790,
        647,
        705,
        647,
        679,
        760,
        52,
        648
    ],
    "u1f469_1f3fd_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        672,
        672,
        678,
        678,
        698,
        674,
        784,
        672,
        58
    ],
    "u1f469_1f3fd_200d_1f9b0":[
        588,
        672,
        760,
        194,
        588,
        8
    ],
    "u1f469_1f3fd_200d_1f9b1":[
        678,
        643,
        760,
        194,
        8,
        678
    ],
    "u1f469_1f3fd_200d_1f9b2":[
        672,
        760,
        194,
        8
    ],
    "u1f469_1f3fd_200d_1f9b3":[
        79,
        672,
        760,
        194,
        79,
        8
    ],
    "u1f469_1f3fd_200d_1f9bc":[
        58,
        58,
        674,
        674,
        672,
        672,
        678,
        678,
        784,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        672,
        67,
        222,
        58,
        67
    ],
    "u1f469_1f3fd_200d_1f9bd":[
        58,
        58,
        674,
        674,
        672,
        672,
        678,
        678,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        672
    ],
    "u1f469_1f3fd_200d_2695_fe0f":[
        12,
        58,
        58,
        222,
        478,
        58,
        222,
        643,
        703,
        643,
        760,
        58,
        8,
        194,
        643
    ],
    "u1f469_1f3fd_200d_2696_fe0f":[
        58,
        643,
        703,
        721,
        643,
        760,
        194,
        599,
        590,
        599,
        8,
        478,
        67,
        194,
        643
    ],
    "u1f469_1f3fd_200d_2708_fe0f":[
        79,
        58,
        678,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f468_1f3fb":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        58,
        674,
        698,
        639,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f468_1f3fc":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        677,
        674,
        698,
        642,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f468_1f3fd":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        674,
        698,
        672,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f468_1f3fe":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        645,
        674,
        698,
        644,
        646,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f468_1f3ff":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        679,
        674,
        698,
        647,
        648,
        52,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f469_1f3fb":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f469_1f3fc":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f469_1f3fd":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f469_1f3fe":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f469_1f3ff":[
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        678,
        672,
        8,
        194,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        678,
        672,
        8,
        194,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        678,
        672,
        8,
        194,
        678,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        678,
        672,
        8,
        194,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        678,
        672,
        8,
        194,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fb":[
        678,
        672,
        8,
        194,
        58,
        639,
        8,
        194,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fc":[
        678,
        672,
        8,
        194,
        677,
        642,
        8,
        194,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fd":[
        678,
        672,
        8,
        194,
        678,
        672,
        8,
        194,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fe":[
        678,
        672,
        8,
        194,
        645,
        644,
        8,
        646,
        2
    ],
    "u1f469_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3ff":[
        678,
        672,
        8,
        194,
        679,
        647,
        52,
        648,
        2
    ],
    "u1f469_1f3fe":[
        645,
        644,
        760,
        646,
        645,
        645
    ],
    "u1f469_1f3fe_200d_1f33e":[
        197,
        10,
        579,
        645,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        644,
        194,
        67,
        194,
        58
    ],
    "u1f469_1f3fe_200d_1f373":[
        79,
        10,
        645,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        222,
        196,
        79,
        194,
        194,
        644,
        222
    ],
    "u1f469_1f3fe_200d_1f37c":[
        6,
        645,
        644,
        704,
        644,
        645,
        644,
        760,
        646,
        8,
        576,
        79,
        125,
        3,
        220,
        644,
        6,
        644,
        6
    ],
    "u1f469_1f3fe_200d_1f384":[
        641,
        644,
        641,
        644,
        2,
        646,
        645,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_1f3fe_200d_1f393":[
        58,
        67,
        6,
        576,
        645,
        644,
        704,
        644,
        760,
        645,
        646,
        645,
        644,
        58,
        67,
        195
    ],
    "u1f469_1f3fe_200d_1f3a4":[
        58,
        682,
        786,
        787,
        788,
        786,
        787,
        788,
        682,
        777,
        604,
        682,
        2,
        789,
        778,
        645,
        604,
        682,
        767,
        768
    ],
    "u1f469_1f3fe_200d_1f3a8":[
        645,
        6,
        704,
        644,
        760,
        645,
        645,
        646,
        67,
        596,
        189,
        10,
        2,
        195,
        644
    ],
    "u1f469_1f3fe_200d_1f3eb":[
        596,
        189,
        11,
        6,
        645,
        58,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        644,
        576,
        591,
        576,
        591
    ],
    "u1f469_1f3fe_200d_1f3ed":[
        12,
        58,
        3,
        645,
        644,
        704,
        644,
        760,
        646,
        645,
        644,
        704,
        704,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_1f3fe_200d_1f4bb":[
        6,
        645,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        644,
        79,
        79,
        196,
        79
    ],
    "u1f469_1f3fe_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        645,
        682,
        777,
        682,
        789,
        646,
        766,
        645,
        682
    ],
    "u1f469_1f3fe_200d_1f527":[
        222,
        10,
        478,
        645,
        644,
        704,
        644,
        760,
        645,
        644,
        645,
        646,
        12,
        10,
        3,
        67
    ],
    "u1f469_1f3fe_200d_1f52c":[
        222,
        645,
        644,
        704,
        644,
        760,
        645,
        644,
        645,
        646,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_1f3fe_200d_1f680":[
        79,
        214,
        1,
        58,
        645,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_1f3fe_200d_1f692":[
        772,
        773,
        222,
        9,
        645,
        58,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        58,
        760,
        214,
        772
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f468_1f3fb":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f468_1f3fc":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f468_1f3fd":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f468_1f3ff":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f469_1f3fb":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        639,
        697,
        639,
        58,
        784,
        790,
        639,
        697,
        639,
        58,
        760,
        8,
        194
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f469_1f3fc":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        677,
        642,
        702,
        642,
        58,
        784,
        790,
        642,
        702,
        642,
        677,
        760,
        8,
        194
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f469_1f3fd":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        678,
        672,
        703,
        672,
        58,
        784,
        790,
        672,
        703,
        672,
        678,
        760,
        8,
        194
    ],
    "u1f469_1f3fe_200d_1f91d_200d_1f469_1f3ff":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        679,
        647,
        705,
        647,
        58,
        784,
        790,
        647,
        705,
        647,
        679,
        760,
        52,
        648
    ],
    "u1f469_1f3fe_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        644,
        644,
        645,
        645,
        698,
        674,
        784,
        644,
        58
    ],
    "u1f469_1f3fe_200d_1f9b0":[
        588,
        682,
        760,
        646,
        588,
        8
    ],
    "u1f469_1f3fe_200d_1f9b1":[
        645,
        644,
        760,
        646,
        645,
        645
    ],
    "u1f469_1f3fe_200d_1f9b2":[
        682,
        760,
        646,
        8
    ],
    "u1f469_1f3fe_200d_1f9b3":[
        79,
        682,
        760,
        646,
        79,
        8
    ],
    "u1f469_1f3fe_200d_1f9bc":[
        58,
        58,
        674,
        674,
        644,
        644,
        645,
        645,
        784,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        644,
        67,
        222,
        58,
        67
    ],
    "u1f469_1f3fe_200d_1f9bd":[
        58,
        58,
        674,
        674,
        644,
        644,
        645,
        645,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        644
    ],
    "u1f469_1f3fe_200d_2695_fe0f":[
        12,
        645,
        58,
        222,
        478,
        58,
        222,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        644
    ],
    "u1f469_1f3fe_200d_2696_fe0f":[
        58,
        644,
        704,
        721,
        644,
        760,
        646,
        599,
        590,
        599,
        645,
        478,
        67,
        194,
        8,
        194,
        644
    ],
    "u1f469_1f3fe_200d_2708_fe0f":[
        79,
        58,
        645,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f468_1f3fb":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        58,
        674,
        698,
        639,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f468_1f3fc":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        677,
        674,
        698,
        642,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f468_1f3fd":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        678,
        674,
        698,
        672,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f468_1f3fe":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        674,
        698,
        644,
        646,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f468_1f3ff":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        679,
        674,
        698,
        647,
        648,
        52,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f469_1f3fb":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f469_1f3fc":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f469_1f3fd":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f469_1f3fe":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f469_1f3ff":[
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        645,
        644,
        8,
        646,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        645,
        644,
        8,
        646,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        645,
        644,
        8,
        646,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        645,
        644,
        8,
        646,
        645,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        645,
        644,
        8,
        646,
        647,
        679,
        52,
        648,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fb":[
        645,
        644,
        8,
        646,
        58,
        639,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fc":[
        645,
        644,
        8,
        646,
        677,
        642,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fd":[
        645,
        644,
        8,
        646,
        678,
        672,
        8,
        194,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fe":[
        645,
        644,
        8,
        646,
        645,
        644,
        8,
        646,
        2
    ],
    "u1f469_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3ff":[
        645,
        644,
        8,
        646,
        679,
        647,
        52,
        648,
        2
    ],
    "u1f469_1f3ff":[
        679,
        647,
        760,
        648,
        679,
        52
    ],
    "u1f469_1f3ff_200d_1f33e":[
        197,
        10,
        579,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        647,
        194,
        67,
        194,
        58
    ],
    "u1f469_1f3ff_200d_1f373":[
        79,
        10,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        222,
        196,
        79,
        194,
        194,
        647,
        222
    ],
    "u1f469_1f3ff_200d_1f37c":[
        6,
        679,
        647,
        705,
        647,
        679,
        647,
        760,
        648,
        52,
        576,
        79,
        125,
        3,
        220,
        647,
        6,
        647,
        6
    ],
    "u1f469_1f3ff_200d_1f384":[
        641,
        647,
        641,
        647,
        2,
        648,
        52,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_1f3ff_200d_1f393":[
        58,
        67,
        6,
        576,
        679,
        647,
        705,
        647,
        760,
        52,
        648,
        679,
        647,
        58,
        67,
        195
    ],
    "u1f469_1f3ff_200d_1f3a4":[
        58,
        683,
        786,
        786,
        787,
        788,
        788,
        683,
        779,
        604,
        683,
        2,
        789,
        780,
        52,
        604,
        683,
        767,
        768
    ],
    "u1f469_1f3ff_200d_1f3a8":[
        679,
        6,
        705,
        647,
        760,
        679,
        52,
        648,
        67,
        596,
        189,
        10,
        2,
        195,
        647
    ],
    "u1f469_1f3ff_200d_1f3eb":[
        596,
        189,
        11,
        6,
        679,
        58,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        647,
        576,
        591,
        576,
        591
    ],
    "u1f469_1f3ff_200d_1f3ed":[
        12,
        58,
        3,
        679,
        647,
        705,
        647,
        760,
        648,
        679,
        647,
        705,
        705,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_1f3ff_200d_1f4bb":[
        6,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        647,
        79,
        79,
        196,
        79
    ],
    "u1f469_1f3ff_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        679,
        683,
        779,
        683,
        789,
        648,
        52,
        679,
        683
    ],
    "u1f469_1f3ff_200d_1f527":[
        222,
        10,
        478,
        679,
        647,
        705,
        647,
        760,
        679,
        647,
        52,
        648,
        12,
        10,
        3,
        67
    ],
    "u1f469_1f3ff_200d_1f52c":[
        222,
        679,
        647,
        705,
        647,
        760,
        679,
        647,
        52,
        648,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_1f3ff_200d_1f680":[
        58,
        79,
        214,
        1,
        58,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_1f3ff_200d_1f692":[
        772,
        773,
        222,
        9,
        679,
        58,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        58,
        760,
        214,
        772
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f468_1f3fb":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f468_1f3fc":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f468_1f3fd":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f468_1f3fe":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f469_1f3fb":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        639,
        697,
        639,
        58,
        784,
        790,
        639,
        697,
        639,
        58,
        760,
        8,
        194
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f469_1f3fc":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        677,
        642,
        702,
        642,
        58,
        784,
        790,
        642,
        702,
        642,
        677,
        760,
        8,
        194
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f469_1f3fd":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        678,
        672,
        703,
        672,
        58,
        784,
        790,
        672,
        703,
        672,
        678,
        760,
        8,
        194
    ],
    "u1f469_1f3ff_200d_1f91d_200d_1f469_1f3fe":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        645,
        644,
        704,
        644,
        58,
        784,
        790,
        644,
        704,
        644,
        645,
        760,
        8,
        646
    ],
    "u1f469_1f3ff_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        647,
        647,
        679,
        679,
        698,
        674,
        784,
        647,
        58
    ],
    "u1f469_1f3ff_200d_1f9b0":[
        588,
        683,
        760,
        648,
        588,
        52
    ],
    "u1f469_1f3ff_200d_1f9b1":[
        679,
        647,
        760,
        648,
        52,
        679
    ],
    "u1f469_1f3ff_200d_1f9b2":[
        683,
        760,
        648,
        52
    ],
    "u1f469_1f3ff_200d_1f9b3":[
        79,
        683,
        760,
        648,
        79,
        52
    ],
    "u1f469_1f3ff_200d_1f9bc":[
        58,
        58,
        674,
        674,
        647,
        647,
        679,
        679,
        784,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        647,
        67,
        222,
        58,
        67
    ],
    "u1f469_1f3ff_200d_1f9bd":[
        58,
        58,
        674,
        674,
        647,
        647,
        679,
        679,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        647
    ],
    "u1f469_1f3ff_200d_2695_fe0f":[
        12,
        679,
        58,
        222,
        478,
        58,
        222,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        647
    ],
    "u1f469_1f3ff_200d_2696_fe0f":[
        58,
        647,
        705,
        721,
        647,
        760,
        648,
        599,
        590,
        599,
        52,
        478,
        67,
        194,
        8,
        194,
        647
    ],
    "u1f469_1f3ff_200d_2708_fe0f":[
        79,
        58,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f468_1f3fb":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        58,
        674,
        698,
        639,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f468_1f3fc":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        677,
        674,
        698,
        642,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f468_1f3fd":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        678,
        674,
        698,
        672,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f468_1f3fe":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        645,
        674,
        698,
        644,
        646,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f468_1f3ff":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        674,
        698,
        647,
        648,
        52,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f469_1f3fb":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f469_1f3fc":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f469_1f3fd":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        678,
        784,
        785,
        672,
        760,
        194,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f469_1f3fe":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        645,
        784,
        785,
        644,
        760,
        646,
        8,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f469_1f3ff":[
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fb":[
        679,
        647,
        52,
        648,
        639,
        58,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fc":[
        679,
        647,
        52,
        648,
        642,
        677,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fd":[
        679,
        647,
        52,
        648,
        672,
        678,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3fe":[
        679,
        647,
        52,
        648,
        644,
        645,
        8,
        646,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f468_1f3ff":[
        679,
        647,
        52,
        648,
        679,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fb":[
        679,
        647,
        52,
        648,
        58,
        639,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fc":[
        679,
        647,
        52,
        648,
        677,
        642,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fd":[
        679,
        647,
        52,
        648,
        678,
        672,
        8,
        194,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3fe":[
        679,
        647,
        52,
        648,
        645,
        644,
        8,
        646,
        2
    ],
    "u1f469_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f469_1f3ff":[
        679,
        647,
        52,
        648,
        679,
        647,
        52,
        648,
        2
    ],
    "u1f469_200d_1f33e":[
        197,
        10,
        579,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        649,
        67,
        194,
        58
    ],
    "u1f469_200d_1f373":[
        79,
        10,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        222,
        196,
        79,
        194,
        649,
        222
    ],
    "u1f469_200d_1f37c":[
        6,
        4,
        649,
        706,
        649,
        4,
        649,
        760,
        194,
        8,
        576,
        79,
        125,
        3,
        220,
        649,
        6,
        649,
        6
    ],
    "u1f469_200d_1f384":[
        641,
        649,
        641,
        649,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f469_200d_1f393":[
        58,
        67,
        6,
        576,
        4,
        649,
        706,
        649,
        760,
        8,
        194,
        4,
        649,
        58,
        67,
        195
    ],
    "u1f469_200d_1f3a4":[
        58,
        754,
        786,
        787,
        788,
        786,
        787,
        788,
        754,
        781,
        604,
        754,
        2,
        789,
        194,
        766,
        604,
        754,
        767,
        768
    ],
    "u1f469_200d_1f3a8":[
        4,
        6,
        706,
        649,
        760,
        4,
        8,
        194,
        67,
        596,
        189,
        10,
        2,
        195,
        649
    ],
    "u1f469_200d_1f3eb":[
        596,
        189,
        11,
        6,
        4,
        58,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        649,
        576,
        591,
        576,
        591
    ],
    "u1f469_200d_1f3ed":[
        12,
        58,
        3,
        4,
        649,
        706,
        649,
        760,
        194,
        4,
        649,
        706,
        706,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f469_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f469_200d_1f466_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f469_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        13,
        649,
        214,
        214,
        741,
        8
    ],
    "u1f469_200d_1f467_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        649,
        214,
        741
    ],
    "u1f469_200d_1f467_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        13,
        649,
        214,
        741,
        8,
        13,
        649,
        214,
        741,
        8
    ],
    "u1f469_200d_1f469_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        4,
        6,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f469_200d_1f469_200d_1f466_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        4,
        6,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        125,
        649,
        13,
        741,
        8
    ],
    "u1f469_200d_1f469_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        4,
        6,
        785,
        649,
        603,
        741,
        8,
        13,
        649,
        214,
        649,
        13,
        741,
        8
    ],
    "u1f469_200d_1f469_200d_1f467_200d_1f466":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        4,
        6,
        785,
        649,
        603,
        741,
        8,
        125,
        649,
        13,
        741,
        8,
        649,
        214,
        741
    ],
    "u1f469_200d_1f469_200d_1f467_200d_1f467":[
        649,
        4,
        784,
        785,
        649,
        603,
        741,
        8,
        4,
        6,
        785,
        649,
        603,
        741,
        8,
        13,
        649,
        214,
        741,
        8,
        13,
        649,
        214,
        741,
        8
    ],
    "u1f469_200d_1f4bb":[
        6,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        649,
        79,
        79,
        196,
        79
    ],
    "u1f469_200d_1f4bc":[
        715,
        478,
        665,
        197,
        586,
        769,
        667,
        636,
        754,
        781,
        754,
        789,
        194,
        766,
        636,
        754
    ],
    "u1f469_200d_1f527":[
        222,
        10,
        478,
        4,
        649,
        706,
        649,
        760,
        4,
        649,
        8,
        194,
        12,
        10,
        3,
        67
    ],
    "u1f469_200d_1f52c":[
        222,
        4,
        649,
        706,
        649,
        760,
        4,
        649,
        8,
        194,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f469_200d_1f680":[
        79,
        214,
        1,
        58,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f469_200d_1f692":[
        772,
        773,
        222,
        9,
        4,
        58,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        58,
        760,
        214,
        772
    ],
    "u1f469_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        649,
        649,
        4,
        4,
        698,
        674,
        784,
        649,
        58
    ],
    "u1f469_200d_1f9b0":[
        588,
        649,
        760,
        194,
        588,
        8
    ],
    "u1f469_200d_1f9b1":[
        4,
        649,
        760,
        194,
        8,
        4
    ],
    "u1f469_200d_1f9b2":[
        649,
        760,
        194,
        8
    ],
    "u1f469_200d_1f9b3":[
        79,
        649,
        760,
        194,
        79,
        8
    ],
    "u1f469_200d_1f9bc":[
        58,
        58,
        674,
        674,
        649,
        649,
        4,
        4,
        784,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        649,
        67,
        222,
        58,
        67
    ],
    "u1f469_200d_1f9bd":[
        58,
        58,
        674,
        674,
        649,
        649,
        4,
        4,
        784,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        649
    ],
    "u1f469_200d_2695_fe0f":[
        12,
        4,
        58,
        222,
        478,
        58,
        222,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        649
    ],
    "u1f469_200d_2696_fe0f":[
        58,
        649,
        706,
        721,
        649,
        760,
        194,
        599,
        590,
        599,
        8,
        478,
        67,
        194,
        649
    ],
    "u1f469_200d_2708_fe0f":[
        79,
        58,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f469_200d_2764_fe0f_200d_1f468":[
        4,
        784,
        785,
        649,
        760,
        194,
        8,
        674,
        698,
        649,
        194,
        8,
        2
    ],
    "u1f469_200d_2764_fe0f_200d_1f469":[
        4,
        784,
        785,
        649,
        760,
        194,
        8,
        2
    ],
    "u1f469_200d_2764_fe0f_200d_1f48b_200d_1f468":[
        4,
        649,
        8,
        194,
        4,
        2
    ],
    "u1f469_200d_2764_fe0f_200d_1f48b_200d_1f469":[
        4,
        649,
        8,
        194,
        4,
        649,
        8,
        194,
        2
    ],
    "u1f46b_1f3fb":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f46b_1f3fc":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f46b_1f3fd":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f46b_1f3fe":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f46b_1f3ff":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f46c_1f3fb":[
        58,
        639,
        589,
        58,
        674,
        639,
        697,
        639,
        58,
        194,
        8,
        639,
        660,
        58,
        711,
        639,
        697,
        639,
        58,
        194,
        8
    ],
    "u1f46c_1f3fc":[
        677,
        642,
        589,
        58,
        674,
        642,
        702,
        642,
        677,
        194,
        8,
        642,
        660,
        58,
        711,
        642,
        702,
        642,
        677,
        194,
        8
    ],
    "u1f46c_1f3fd":[
        678,
        672,
        589,
        58,
        674,
        672,
        703,
        672,
        678,
        194,
        8,
        672,
        660,
        58,
        711,
        672,
        703,
        672,
        678,
        194,
        8
    ],
    "u1f46c_1f3fe":[
        645,
        644,
        589,
        58,
        674,
        644,
        704,
        644,
        645,
        646,
        8,
        644,
        660,
        58,
        711,
        644,
        704,
        644,
        645,
        646,
        8
    ],
    "u1f46c_1f3ff":[
        679,
        647,
        589,
        58,
        674,
        647,
        705,
        647,
        679,
        648,
        52,
        647,
        660,
        58,
        711,
        647,
        705,
        647,
        679,
        648,
        52
    ],
    "u1f46d_1f3fb":[
        58,
        639,
        697,
        639,
        58,
        738,
        603,
        639,
        697,
        639,
        58,
        760,
        8,
        194,
        697,
        639,
        784,
        790,
        639,
        697,
        639,
        58,
        760,
        8,
        194
    ],
    "u1f46d_1f3fc":[
        677,
        642,
        702,
        642,
        58,
        738,
        603,
        642,
        702,
        642,
        677,
        760,
        8,
        194,
        702,
        642,
        58,
        784,
        790,
        642,
        702,
        642,
        677,
        760,
        8,
        194
    ],
    "u1f46d_1f3fd":[
        678,
        672,
        703,
        672,
        58,
        738,
        603,
        672,
        703,
        672,
        678,
        760,
        8,
        194,
        703,
        672,
        58,
        784,
        790,
        672,
        703,
        672,
        678,
        760,
        8,
        194
    ],
    "u1f46d_1f3fe":[
        645,
        644,
        704,
        644,
        58,
        738,
        603,
        644,
        704,
        644,
        645,
        760,
        8,
        646,
        704,
        644,
        58,
        784,
        790,
        644,
        704,
        644,
        645,
        760,
        8,
        646
    ],
    "u1f46d_1f3ff":[
        679,
        647,
        705,
        647,
        58,
        738,
        603,
        647,
        705,
        647,
        679,
        760,
        52,
        648,
        705,
        647,
        58,
        784,
        790,
        647,
        705,
        647,
        679,
        760,
        52,
        648
    ],
    "u1f46e_1f3fb":[
        698,
        639,
        697,
        58,
        639,
        194,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fb_200d_2640_fe0f":[
        698,
        58,
        639,
        697,
        639,
        58,
        639,
        760,
        8,
        194,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fb_200d_2642_fe0f":[
        698,
        639,
        697,
        639,
        194,
        58,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fc":[
        698,
        642,
        702,
        677,
        642,
        194,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fc_200d_2640_fe0f":[
        698,
        677,
        642,
        702,
        642,
        677,
        642,
        760,
        8,
        194,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fc_200d_2642_fe0f":[
        698,
        642,
        702,
        642,
        194,
        677,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fd":[
        698,
        672,
        703,
        678,
        672,
        194,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fd_200d_2640_fe0f":[
        698,
        678,
        643,
        703,
        643,
        678,
        643,
        760,
        8,
        194,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fd_200d_2642_fe0f":[
        698,
        643,
        703,
        643,
        194,
        678,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fe":[
        698,
        644,
        704,
        645,
        644,
        646,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fe_200d_2640_fe0f":[
        698,
        645,
        644,
        704,
        644,
        645,
        644,
        760,
        645,
        646,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3fe_200d_2642_fe0f":[
        698,
        644,
        704,
        644,
        646,
        645,
        645,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3ff":[
        698,
        647,
        705,
        679,
        647,
        648,
        52,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3ff_200d_2640_fe0f":[
        698,
        679,
        647,
        705,
        647,
        679,
        647,
        760,
        52,
        648,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_1f3ff_200d_2642_fe0f":[
        698,
        647,
        705,
        647,
        648,
        679,
        52,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_200d_2640_fe0f":[
        698,
        4,
        649,
        706,
        649,
        4,
        649,
        760,
        8,
        194,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46e_200d_2642_fe0f":[
        698,
        649,
        706,
        649,
        194,
        4,
        8,
        698,
        674,
        668,
        791,
        674,
        792,
        11
    ],
    "u1f46f_200d_2640_fe0f":[
        649,
        649,
        58,
        649,
        706,
        4,
        649,
        4,
        760,
        8,
        194,
        58,
        58,
        649,
        58,
        649,
        706,
        649,
        4,
        760,
        8,
        194,
        58,
        58
    ],
    "u1f46f_200d_2642_fe0f":[
        649,
        649,
        58,
        649,
        706,
        4,
        649,
        4,
        194,
        8,
        58,
        58,
        649,
        58,
        649,
        706,
        649,
        4,
        194,
        8,
        58
    ],
    "u1f470_1f3fb":[
        0,
        11,
        639,
        697,
        58,
        639,
        194,
        8,
        11
    ],
    "u1f470_1f3fb_200d_2640_fe0f":[
        0,
        58,
        11,
        639,
        58,
        760,
        194,
        8,
        11
    ],
    "u1f470_1f3fb_200d_2642_fe0f":[
        0,
        11,
        639,
        697,
        639,
        58,
        194,
        8,
        11
    ],
    "u1f470_1f3fc":[
        0,
        11,
        642,
        702,
        677,
        642,
        194,
        8,
        11
    ],
    "u1f470_1f3fc_200d_2640_fe0f":[
        0,
        677,
        11,
        642,
        677,
        760,
        194,
        8,
        11
    ],
    "u1f470_1f3fc_200d_2642_fe0f":[
        0,
        11,
        642,
        702,
        642,
        677,
        194,
        8,
        11
    ],
    "u1f470_1f3fd":[
        0,
        11,
        672,
        703,
        678,
        672,
        194,
        8,
        11
    ],
    "u1f470_1f3fd_200d_2640_fe0f":[
        0,
        678,
        11,
        672,
        678,
        760,
        194,
        8,
        11
    ],
    "u1f470_1f3fd_200d_2642_fe0f":[
        0,
        11,
        672,
        703,
        672,
        678,
        194,
        8,
        11
    ],
    "u1f470_1f3fe":[
        0,
        11,
        644,
        704,
        645,
        644,
        646,
        8,
        11
    ],
    "u1f470_1f3fe_200d_2640_fe0f":[
        0,
        645,
        11,
        644,
        645,
        760,
        646,
        8,
        11
    ],
    "u1f470_1f3fe_200d_2642_fe0f":[
        0,
        11,
        644,
        704,
        644,
        645,
        646,
        8,
        11
    ],
    "u1f470_1f3ff":[
        0,
        11,
        647,
        705,
        679,
        647,
        648,
        52,
        11
    ],
    "u1f470_1f3ff_200d_2640_fe0f":[
        0,
        679,
        11,
        647,
        679,
        760,
        648,
        52,
        11
    ],
    "u1f470_1f3ff_200d_2642_fe0f":[
        0,
        11,
        647,
        705,
        647,
        679,
        648,
        52,
        11
    ],
    "u1f470_200d_2640_fe0f":[
        0,
        4,
        11,
        649,
        4,
        760,
        194,
        8,
        11
    ],
    "u1f470_200d_2642_fe0f":[
        0,
        11,
        649,
        706,
        649,
        4,
        194,
        8,
        11
    ],
    "u1f471_1f3fb":[
        677,
        639,
        8,
        194
    ],
    "u1f471_1f3fb_200d_2640_fe0f":[
        677,
        639,
        760,
        194,
        677,
        8
    ],
    "u1f471_1f3fb_200d_2642_fe0f":[
        639,
        8,
        194,
        677
    ],
    "u1f471_1f3fc":[
        677,
        642,
        8,
        194
    ],
    "u1f471_1f3fc_200d_2640_fe0f":[
        677,
        642,
        760,
        194,
        677,
        8
    ],
    "u1f471_1f3fc_200d_2642_fe0f":[
        642,
        8,
        194,
        677
    ],
    "u1f471_1f3fd":[
        677,
        672,
        8,
        194
    ],
    "u1f471_1f3fd_200d_2640_fe0f":[
        677,
        643,
        760,
        194,
        677,
        8
    ],
    "u1f471_1f3fd_200d_2642_fe0f":[
        643,
        8,
        194,
        677
    ],
    "u1f471_1f3fe":[
        677,
        644,
        8,
        646
    ],
    "u1f471_1f3fe_200d_2640_fe0f":[
        677,
        644,
        760,
        646,
        677,
        645
    ],
    "u1f471_1f3fe_200d_2642_fe0f":[
        644,
        645,
        646,
        677
    ],
    "u1f471_1f3ff":[
        677,
        647,
        52,
        648
    ],
    "u1f471_1f3ff_200d_2640_fe0f":[
        677,
        647,
        760,
        648,
        677,
        52
    ],
    "u1f471_1f3ff_200d_2642_fe0f":[
        647,
        52,
        648,
        677
    ],
    "u1f471_200d_2640_fe0f":[
        677,
        649,
        4,
        649,
        4,
        677,
        760,
        194,
        8
    ],
    "u1f471_200d_2642_fe0f":[
        649,
        8,
        194,
        4,
        677
    ],
    "u1f472_1f3fb":[
        639,
        2,
        194,
        197,
        707,
        546,
        8
    ],
    "u1f472_1f3fc":[
        642,
        2,
        194,
        197,
        707,
        546,
        8
    ],
    "u1f472_1f3fd":[
        643,
        2,
        194,
        197,
        707,
        546,
        8
    ],
    "u1f472_1f3fe":[
        644,
        2,
        646,
        197,
        707,
        546,
        645
    ],
    "u1f472_1f3ff":[
        647,
        2,
        648,
        197,
        707,
        546,
        52
    ],
    "u1f473_1f3fb":[
        629,
        58,
        639,
        697,
        639,
        194,
        8,
        639,
        0,
        641,
        675
    ],
    "u1f473_1f3fb_200d_2640_fe0f":[
        6,
        576,
        639,
        697,
        639,
        760,
        8,
        194,
        0,
        641
    ],
    "u1f473_1f3fb_200d_2642_fe0f":[
        197,
        189,
        639,
        639,
        8,
        194,
        58,
        0,
        641
    ],
    "u1f473_1f3fc":[
        629,
        677,
        642,
        702,
        642,
        194,
        8,
        642,
        0,
        641,
        675
    ],
    "u1f473_1f3fc_200d_2640_fe0f":[
        6,
        576,
        642,
        702,
        642,
        760,
        8,
        194,
        0,
        641
    ],
    "u1f473_1f3fc_200d_2642_fe0f":[
        197,
        189,
        642,
        642,
        8,
        194,
        677,
        0,
        641
    ],
    "u1f473_1f3fd":[
        629,
        678,
        672,
        703,
        672,
        194,
        8,
        672,
        0,
        641,
        675
    ],
    "u1f473_1f3fd_200d_2640_fe0f":[
        6,
        576,
        643,
        703,
        643,
        760,
        8,
        194,
        0,
        641
    ],
    "u1f473_1f3fd_200d_2642_fe0f":[
        197,
        189,
        643,
        643,
        8,
        194,
        678,
        0,
        641
    ],
    "u1f473_1f3fe":[
        629,
        645,
        644,
        704,
        644,
        646,
        8,
        644,
        0,
        641,
        675
    ],
    "u1f473_1f3fe_200d_2640_fe0f":[
        6,
        576,
        644,
        704,
        644,
        760,
        645,
        646,
        0,
        641
    ],
    "u1f473_1f3fe_200d_2642_fe0f":[
        197,
        189,
        644,
        644,
        645,
        646,
        645,
        0,
        641
    ],
    "u1f473_1f3ff":[
        629,
        679,
        647,
        705,
        647,
        648,
        52,
        647,
        0,
        641,
        675
    ],
    "u1f473_1f3ff_200d_2640_fe0f":[
        6,
        576,
        647,
        705,
        647,
        760,
        52,
        648,
        0,
        641
    ],
    "u1f473_1f3ff_200d_2642_fe0f":[
        197,
        189,
        647,
        647,
        52,
        648,
        679,
        0,
        641
    ],
    "u1f473_200d_2640_fe0f":[
        6,
        576,
        649,
        706,
        649,
        760,
        8,
        194,
        0,
        641
    ],
    "u1f473_200d_2642_fe0f":[
        197,
        189,
        649,
        649,
        8,
        194,
        4,
        0,
        641
    ],
    "u1f474_1f3fb":[
        641,
        639,
        8,
        641,
        194
    ],
    "u1f474_1f3fc":[
        641,
        642,
        8,
        641,
        194
    ],
    "u1f474_1f3fd":[
        641,
        643,
        8,
        641,
        194
    ],
    "u1f474_1f3fe":[
        641,
        644,
        645,
        641,
        646
    ],
    "u1f474_1f3ff":[
        641,
        647,
        52,
        641,
        648
    ],
    "u1f475_1f3fb":[
        721,
        639,
        599,
        760,
        194,
        8,
        599,
        590
    ],
    "u1f475_1f3fc":[
        721,
        642,
        599,
        760,
        194,
        8,
        599,
        590
    ],
    "u1f475_1f3fd":[
        721,
        643,
        599,
        2,
        194,
        8,
        599,
        590
    ],
    "u1f475_1f3fe":[
        721,
        644,
        599,
        760,
        646,
        645,
        599,
        590
    ],
    "u1f475_1f3ff":[
        721,
        647,
        599,
        760,
        648,
        52,
        599,
        590
    ],
    "u1f476_1f3fb":[
        639,
        8,
        11,
        685
    ],
    "u1f476_1f3fc":[
        642,
        793,
        11,
        687
    ],
    "u1f476_1f3fd":[
        643,
        793,
        11,
        689
    ],
    "u1f476_1f3fe":[
        644,
        793,
        11,
        691
    ],
    "u1f476_1f3ff":[
        647,
        52,
        11,
        693
    ],
    "u1f477_1f3fb":[
        794,
        795,
        58,
        58,
        639,
        697,
        58,
        639,
        194,
        8,
        3,
        795,
        4
    ],
    "u1f477_1f3fb_200d_2640_fe0f":[
        794,
        58,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        639,
        795,
        3,
        4
    ],
    "u1f477_1f3fb_200d_2642_fe0f":[
        794,
        58,
        58,
        639,
        697,
        639,
        194,
        58,
        8,
        795,
        3,
        4
    ],
    "u1f477_1f3fc":[
        794,
        795,
        58,
        58,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        3,
        795,
        4
    ],
    "u1f477_1f3fc_200d_2640_fe0f":[
        794,
        677,
        58,
        58,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        642,
        795,
        3,
        4
    ],
    "u1f477_1f3fc_200d_2642_fe0f":[
        794,
        58,
        58,
        642,
        702,
        642,
        194,
        677,
        8,
        58,
        795,
        3,
        4
    ],
    "u1f477_1f3fd":[
        794,
        795,
        58,
        58,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        3,
        795,
        4
    ],
    "u1f477_1f3fd_200d_2640_fe0f":[
        794,
        678,
        58,
        58,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        643,
        795,
        3,
        4
    ],
    "u1f477_1f3fd_200d_2642_fe0f":[
        794,
        58,
        58,
        643,
        703,
        643,
        194,
        678,
        8,
        58,
        795,
        3,
        4
    ],
    "u1f477_1f3fe":[
        794,
        795,
        58,
        58,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        3,
        795,
        4
    ],
    "u1f477_1f3fe_200d_2640_fe0f":[
        794,
        645,
        58,
        58,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        644,
        795,
        3,
        4
    ],
    "u1f477_1f3fe_200d_2642_fe0f":[
        794,
        58,
        58,
        644,
        704,
        644,
        646,
        645,
        645,
        58,
        795,
        3,
        4
    ],
    "u1f477_1f3ff":[
        794,
        795,
        58,
        58,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        3,
        795,
        4
    ],
    "u1f477_1f3ff_200d_2640_fe0f":[
        794,
        679,
        58,
        58,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        647,
        795,
        3,
        4
    ],
    "u1f477_1f3ff_200d_2642_fe0f":[
        794,
        58,
        58,
        647,
        705,
        647,
        648,
        679,
        52,
        58,
        795,
        3,
        4
    ],
    "u1f477_200d_2640_fe0f":[
        794,
        4,
        58,
        58,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        649,
        795,
        3,
        4
    ],
    "u1f477_200d_2642_fe0f":[
        794,
        58,
        58,
        649,
        706,
        649,
        194,
        4,
        8,
        58,
        795,
        3,
        4
    ],
    "u1f478_1f3fb":[
        58,
        639,
        760,
        194,
        58,
        722,
        612,
        8
    ],
    "u1f478_1f3fc":[
        677,
        642,
        760,
        194,
        677,
        722,
        612,
        8
    ],
    "u1f478_1f3fd":[
        678,
        643,
        760,
        194,
        678,
        722,
        612,
        8
    ],
    "u1f478_1f3fe":[
        645,
        644,
        760,
        646,
        645,
        722,
        612,
        645
    ],
    "u1f478_1f3ff":[
        679,
        647,
        760,
        648,
        679,
        722,
        612,
        52
    ],
    "u1f47c_1f3fb":[
        79,
        639,
        58,
        8,
        796
    ],
    "u1f47c_1f3fc":[
        79,
        642,
        677,
        8,
        796
    ],
    "u1f47c_1f3fd":[
        79,
        643,
        678,
        8,
        796
    ],
    "u1f47c_1f3fe":[
        79,
        644,
        645,
        645,
        796
    ],
    "u1f47c_1f3ff":[
        79,
        647,
        679,
        52,
        796
    ],
    "u1f481_1f3fb":[
        58,
        629,
        675,
        639,
        639,
        194,
        629,
        697,
        639,
        8
    ],
    "u1f481_1f3fb_200d_2640_fe0f":[
        58,
        784,
        785,
        639,
        639,
        760,
        784,
        697,
        639,
        194,
        8
    ],
    "u1f481_1f3fb_200d_2642_fe0f":[
        674,
        698,
        639,
        674,
        697,
        639,
        194,
        8,
        639,
        58
    ],
    "u1f481_1f3fc":[
        677,
        629,
        675,
        642,
        642,
        194,
        629,
        702,
        642,
        8
    ],
    "u1f481_1f3fc_200d_2640_fe0f":[
        677,
        784,
        785,
        642,
        642,
        760,
        784,
        702,
        642,
        194,
        8
    ],
    "u1f481_1f3fc_200d_2642_fe0f":[
        674,
        698,
        642,
        674,
        702,
        642,
        194,
        8,
        642,
        677
    ],
    "u1f481_1f3fd":[
        678,
        629,
        675,
        672,
        672,
        194,
        629,
        703,
        672,
        8
    ],
    "u1f481_1f3fd_200d_2640_fe0f":[
        678,
        784,
        785,
        643,
        643,
        760,
        784,
        703,
        643,
        194,
        8
    ],
    "u1f481_1f3fd_200d_2642_fe0f":[
        674,
        698,
        643,
        674,
        703,
        643,
        194,
        8,
        643,
        678
    ],
    "u1f481_1f3fe":[
        645,
        629,
        675,
        644,
        644,
        646,
        629,
        704,
        644,
        8
    ],
    "u1f481_1f3fe_200d_2640_fe0f":[
        645,
        784,
        785,
        644,
        644,
        760,
        784,
        704,
        644,
        646,
        8
    ],
    "u1f481_1f3fe_200d_2642_fe0f":[
        674,
        698,
        644,
        674,
        704,
        644,
        646,
        645,
        644,
        645
    ],
    "u1f481_1f3ff":[
        679,
        629,
        675,
        647,
        647,
        648,
        629,
        705,
        647,
        52
    ],
    "u1f481_1f3ff_200d_2640_fe0f":[
        679,
        784,
        785,
        647,
        647,
        760,
        784,
        705,
        647,
        648,
        52
    ],
    "u1f481_1f3ff_200d_2642_fe0f":[
        674,
        698,
        647,
        674,
        705,
        647,
        648,
        52,
        647,
        679
    ],
    "u1f481_200d_2640_fe0f":[
        4,
        784,
        785,
        649,
        649,
        760,
        784,
        706,
        649,
        194,
        8
    ],
    "u1f481_200d_2642_fe0f":[
        674,
        698,
        649,
        674,
        706,
        649,
        194,
        8,
        649,
        4
    ],
    "u1f482_1f3fb":[
        2,
        58,
        639,
        697,
        58,
        639,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fb_200d_2640_fe0f":[
        2,
        58,
        58,
        639,
        760,
        8,
        194,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fb_200d_2642_fe0f":[
        2,
        58,
        639,
        697,
        639,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fc":[
        2,
        58,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fc_200d_2640_fe0f":[
        2,
        58,
        677,
        642,
        760,
        8,
        194,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fc_200d_2642_fe0f":[
        2,
        58,
        642,
        702,
        642,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fd":[
        2,
        58,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fd_200d_2640_fe0f":[
        2,
        58,
        678,
        643,
        760,
        8,
        194,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fd_200d_2642_fe0f":[
        2,
        58,
        643,
        703,
        643,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fe":[
        2,
        58,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fe_200d_2640_fe0f":[
        2,
        58,
        645,
        644,
        760,
        645,
        646,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3fe_200d_2642_fe0f":[
        2,
        58,
        644,
        704,
        644,
        646,
        645,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3ff":[
        2,
        58,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3ff_200d_2640_fe0f":[
        2,
        58,
        679,
        647,
        760,
        52,
        648,
        58,
        196,
        478,
        58
    ],
    "u1f482_1f3ff_200d_2642_fe0f":[
        2,
        58,
        647,
        705,
        647,
        648,
        52,
        58,
        196,
        478,
        58
    ],
    "u1f482_200d_2640_fe0f":[
        2,
        58,
        4,
        649,
        760,
        8,
        194,
        58,
        196,
        478,
        58
    ],
    "u1f482_200d_2642_fe0f":[
        2,
        58,
        649,
        706,
        649,
        194,
        8,
        58,
        196,
        478,
        58
    ],
    "u1f483_1f3fb":[
        2,
        639,
        2,
        58,
        639,
        58,
        639,
        2,
        9,
        639
    ],
    "u1f483_1f3fc":[
        2,
        642,
        2,
        677,
        642,
        677,
        642,
        2,
        9,
        642
    ],
    "u1f483_1f3fd":[
        2,
        672,
        2,
        678,
        672,
        678,
        672,
        2,
        9,
        672
    ],
    "u1f483_1f3fe":[
        2,
        644,
        2,
        645,
        644,
        645,
        644,
        2,
        9,
        644
    ],
    "u1f483_1f3ff":[
        2,
        647,
        2,
        679,
        647,
        679,
        647,
        2,
        9,
        647
    ],
    "u1f485_1f3fb":[
        639,
        2,
        2,
        594,
        594
    ],
    "u1f485_1f3fc":[
        642,
        2,
        2,
        594,
        594
    ],
    "u1f485_1f3fd":[
        643,
        2,
        2,
        594,
        594
    ],
    "u1f485_1f3fe":[
        644,
        2,
        2,
        594,
        594
    ],
    "u1f485_1f3ff":[
        647,
        2,
        2,
        594,
        594
    ],
    "u1f486_1f3fb":[
        629,
        675,
        58,
        639,
        639,
        194,
        684,
        639,
        8
    ],
    "u1f486_1f3fb_200d_2640_fe0f":[
        679,
        679,
        679,
        679,
        784,
        785,
        639,
        639,
        760,
        194,
        684,
        639,
        8
    ],
    "u1f486_1f3fb_200d_2642_fe0f":[
        639,
        674,
        698,
        639,
        639,
        639,
        679,
        194,
        684,
        639,
        8
    ],
    "u1f486_1f3fc":[
        629,
        675,
        677,
        642,
        642,
        194,
        686,
        642,
        8
    ],
    "u1f486_1f3fc_200d_2640_fe0f":[
        677,
        677,
        677,
        677,
        784,
        785,
        642,
        642,
        760,
        194,
        702,
        642,
        8
    ],
    "u1f486_1f3fc_200d_2642_fe0f":[
        642,
        674,
        698,
        642,
        642,
        642,
        677,
        194,
        702,
        642,
        8
    ],
    "u1f486_1f3fd":[
        629,
        675,
        678,
        672,
        672,
        194,
        688,
        672,
        8
    ],
    "u1f486_1f3fd_200d_2640_fe0f":[
        678,
        678,
        678,
        678,
        784,
        785,
        643,
        643,
        760,
        194,
        688,
        643,
        8
    ],
    "u1f486_1f3fd_200d_2642_fe0f":[
        643,
        674,
        698,
        643,
        643,
        643,
        678,
        194,
        688,
        643,
        8
    ],
    "u1f486_1f3fe":[
        629,
        675,
        645,
        644,
        644,
        646,
        690,
        644,
        8
    ],
    "u1f486_1f3fe_200d_2640_fe0f":[
        645,
        645,
        645,
        645,
        784,
        785,
        644,
        644,
        760,
        646,
        690,
        644,
        645
    ],
    "u1f486_1f3fe_200d_2642_fe0f":[
        644,
        674,
        698,
        644,
        644,
        644,
        645,
        646,
        690,
        644
    ],
    "u1f486_1f3ff":[
        629,
        675,
        679,
        647,
        647,
        648,
        692,
        647,
        52
    ],
    "u1f486_1f3ff_200d_2640_fe0f":[
        679,
        679,
        679,
        679,
        784,
        785,
        647,
        647,
        760,
        648,
        692,
        647,
        679
    ],
    "u1f486_1f3ff_200d_2642_fe0f":[
        647,
        674,
        698,
        647,
        647,
        647,
        679,
        648,
        692,
        647,
        52
    ],
    "u1f486_200d_2640_fe0f":[
        4,
        4,
        4,
        4,
        784,
        785,
        649,
        649,
        760,
        194,
        798,
        649,
        8
    ],
    "u1f486_200d_2642_fe0f":[
        649,
        674,
        698,
        649,
        649,
        649,
        4,
        194,
        694,
        649,
        8
    ],
    "u1f487_1f3fb":[
        669,
        660,
        58,
        629,
        675,
        639,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fb_200d_2640_fe0f":[
        669,
        660,
        58,
        784,
        785,
        639,
        760,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fb_200d_2642_fe0f":[
        669,
        660,
        58,
        674,
        698,
        639,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fc":[
        669,
        660,
        677,
        629,
        675,
        642,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fc_200d_2640_fe0f":[
        669,
        660,
        677,
        784,
        785,
        642,
        760,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fc_200d_2642_fe0f":[
        669,
        660,
        677,
        674,
        698,
        642,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fd":[
        669,
        660,
        678,
        629,
        675,
        672,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fd_200d_2640_fe0f":[
        669,
        660,
        678,
        784,
        785,
        643,
        760,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fd_200d_2642_fe0f":[
        669,
        660,
        678,
        674,
        698,
        643,
        194,
        8,
        669,
        660
    ],
    "u1f487_1f3fe":[
        669,
        660,
        645,
        629,
        675,
        644,
        646,
        8,
        669,
        660
    ],
    "u1f487_1f3fe_200d_2640_fe0f":[
        669,
        660,
        645,
        784,
        785,
        644,
        760,
        646,
        645,
        669,
        660
    ],
    "u1f487_1f3fe_200d_2642_fe0f":[
        669,
        660,
        645,
        674,
        698,
        644,
        691,
        645,
        669,
        660
    ],
    "u1f487_1f3ff":[
        669,
        660,
        679,
        629,
        675,
        647,
        648,
        52,
        669,
        660
    ],
    "u1f487_1f3ff_200d_2640_fe0f":[
        669,
        660,
        679,
        784,
        785,
        647,
        760,
        648,
        52,
        669,
        660
    ],
    "u1f487_1f3ff_200d_2642_fe0f":[
        669,
        660,
        679,
        674,
        698,
        647,
        648,
        52,
        669,
        660
    ],
    "u1f487_200d_2640_fe0f":[
        669,
        660,
        4,
        784,
        785,
        649,
        760,
        194,
        8,
        669,
        660
    ],
    "u1f487_200d_2642_fe0f":[
        669,
        660,
        4,
        674,
        698,
        649,
        194,
        8,
        669,
        660
    ],
    "u1f48f_1f3fb":[
        639,
        8,
        194,
        58,
        58,
        2
    ],
    "u1f48f_1f3fc":[
        642,
        8,
        194,
        677,
        677,
        2
    ],
    "u1f48f_1f3fd":[
        672,
        8,
        194,
        678,
        678,
        2
    ],
    "u1f48f_1f3fe":[
        644,
        8,
        646,
        645,
        645,
        2
    ],
    "u1f48f_1f3ff":[
        647,
        52,
        648,
        679,
        679,
        2
    ],
    "u1f491_1f3fb":[
        58,
        196,
        67,
        639,
        194,
        8,
        629,
        675,
        639,
        194,
        8,
        2
    ],
    "u1f491_1f3fc":[
        677,
        196,
        67,
        642,
        194,
        8,
        629,
        675,
        642,
        194,
        8,
        2
    ],
    "u1f491_1f3fd":[
        678,
        196,
        67,
        672,
        194,
        8,
        629,
        675,
        672,
        194,
        8,
        2
    ],
    "u1f491_1f3fe":[
        645,
        196,
        67,
        644,
        646,
        8,
        629,
        675,
        644,
        646,
        8,
        2
    ],
    "u1f491_1f3ff":[
        679,
        196,
        67,
        647,
        648,
        52,
        629,
        675,
        647,
        648,
        52,
        2
    ],
    "u1f4aa_1f3fb":[
        684,
        639,
        684
    ],
    "u1f4aa_1f3fc":[
        686,
        642,
        686
    ],
    "u1f4aa_1f3fd":[
        688,
        672,
        688
    ],
    "u1f4aa_1f3fe":[
        690,
        644,
        690
    ],
    "u1f4aa_1f3ff":[
        692,
        647,
        692
    ],
    "u1f574_1f3fb":[
        639,
        67,
        818,
        58,
        58,
        639,
        639,
        194,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f574_1f3fb_200d_2640_fe0f":[
        58,
        402,
        58,
        424,
        424,
        402,
        789,
        816,
        817,
        594,
        58,
        402,
        424,
        58
    ],
    "u1f574_1f3fb_200d_2642_fe0f":[
        639,
        67,
        818,
        58,
        58,
        639,
        639,
        194,
        58,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f574_1f3fc":[
        642,
        67,
        818,
        58,
        58,
        677,
        642,
        642,
        194,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f574_1f3fc_200d_2640_fe0f":[
        677,
        681,
        677,
        424,
        424,
        681,
        789,
        816,
        817,
        594,
        58,
        681,
        424,
        58
    ],
    "u1f574_1f3fc_200d_2642_fe0f":[
        642,
        67,
        818,
        58,
        58,
        642,
        642,
        194,
        677,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f574_1f3fd":[
        672,
        67,
        818,
        58,
        58,
        678,
        672,
        672,
        194,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f574_1f3fd_200d_2640_fe0f":[
        678,
        672,
        678,
        424,
        424,
        672,
        789,
        816,
        817,
        594,
        58,
        672,
        424,
        58
    ],
    "u1f574_1f3fd_200d_2642_fe0f":[
        672,
        67,
        818,
        58,
        58,
        672,
        672,
        194,
        678,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f574_1f3fe":[
        644,
        67,
        818,
        58,
        58,
        645,
        644,
        644,
        646,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f574_1f3fe_200d_2640_fe0f":[
        645,
        682,
        645,
        424,
        424,
        682,
        789,
        646,
        817,
        594,
        58,
        682,
        424,
        58
    ],
    "u1f574_1f3fe_200d_2642_fe0f":[
        644,
        67,
        818,
        58,
        58,
        644,
        644,
        646,
        645,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f574_1f3ff":[
        647,
        67,
        818,
        58,
        58,
        679,
        647,
        647,
        648,
        818,
        67,
        67,
        79,
        58,
        478,
        196,
        67,
        58
    ],
    "u1f574_1f3ff_200d_2640_fe0f":[
        679,
        683,
        679,
        424,
        424,
        683,
        789,
        648,
        817,
        594,
        58,
        683,
        424,
        58
    ],
    "u1f574_1f3ff_200d_2642_fe0f":[
        647,
        67,
        818,
        58,
        58,
        647,
        647,
        648,
        679,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f574_fe0f_200d_2640_fe0f":[
        636,
        754,
        636,
        424,
        424,
        754,
        789,
        816,
        817,
        594,
        58,
        754,
        424,
        58
    ],
    "u1f574_fe0f_200d_2642_fe0f":[
        649,
        67,
        818,
        58,
        58,
        649,
        649,
        194,
        4,
        818,
        67,
        67,
        79,
        3,
        478,
        196,
        58,
        67,
        58
    ],
    "u1f575_1f3fb":[
        67,
        58,
        67,
        58,
        58,
        639,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fb_200d_2640_fe0f":[
        58,
        67,
        58,
        67,
        58,
        639,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fb_200d_2642_fe0f":[
        67,
        694,
        58,
        58,
        639,
        8,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fc":[
        67,
        58,
        67,
        58,
        677,
        642,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fc_200d_2640_fe0f":[
        677,
        67,
        58,
        67,
        58,
        642,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fc_200d_2642_fe0f":[
        67,
        694,
        58,
        677,
        642,
        8,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fd":[
        67,
        58,
        67,
        58,
        678,
        672,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fd_200d_2640_fe0f":[
        678,
        67,
        58,
        67,
        58,
        643,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fd_200d_2642_fe0f":[
        67,
        694,
        58,
        678,
        643,
        8,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fe":[
        67,
        58,
        67,
        58,
        645,
        644,
        646,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fe_200d_2640_fe0f":[
        645,
        67,
        58,
        67,
        58,
        644,
        646,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3fe_200d_2642_fe0f":[
        67,
        694,
        58,
        645,
        644,
        8,
        646,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3ff":[
        67,
        58,
        67,
        58,
        679,
        647,
        648,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3ff_200d_2640_fe0f":[
        679,
        67,
        58,
        67,
        58,
        647,
        648,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_1f3ff_200d_2642_fe0f":[
        67,
        694,
        58,
        679,
        647,
        8,
        648,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_fe0f_200d_2640_fe0f":[
        4,
        67,
        58,
        67,
        58,
        649,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f575_fe0f_200d_2642_fe0f":[
        67,
        694,
        58,
        4,
        649,
        8,
        194,
        58,
        67,
        58,
        67,
        58,
        67
    ],
    "u1f57a_1f3fb":[
        58,
        587,
        639,
        684,
        58,
        639,
        639,
        58,
        2,
        58
    ],
    "u1f57a_1f3fc":[
        58,
        587,
        642,
        686,
        58,
        642,
        642,
        677,
        2,
        58
    ],
    "u1f57a_1f3fd":[
        58,
        587,
        672,
        688,
        58,
        672,
        672,
        678,
        2,
        58
    ],
    "u1f57a_1f3fe":[
        58,
        587,
        644,
        690,
        58,
        644,
        644,
        645,
        2,
        58
    ],
    "u1f57a_1f3ff":[
        58,
        587,
        647,
        692,
        58,
        647,
        647,
        679,
        2,
        58
    ],
    "u1f590_1f3fb":[
        639,
        684
    ],
    "u1f590_1f3fc":[
        642,
        686
    ],
    "u1f590_1f3fd":[
        643,
        688
    ],
    "u1f590_1f3fe":[
        644,
        690
    ],
    "u1f590_1f3ff":[
        647,
        692
    ],
    "u1f595_1f3fb":[
        639,
        639,
        685
    ],
    "u1f595_1f3fc":[
        642,
        642,
        687
    ],
    "u1f595_1f3fd":[
        643,
        643,
        689
    ],
    "u1f595_1f3fe":[
        644,
        644,
        691
    ],
    "u1f595_1f3ff":[
        647,
        647,
        693
    ],
    "u1f596_1f3fb":[
        684,
        639,
        639,
        684
    ],
    "u1f596_1f3fc":[
        686,
        642,
        642,
        686
    ],
    "u1f596_1f3fd":[
        688,
        643,
        643,
        688
    ],
    "u1f596_1f3fe":[
        690,
        644,
        644,
        690
    ],
    "u1f596_1f3ff":[
        692,
        647,
        647,
        692
    ],
    "u1f62e_200d_1f4a8":[
        195,
        823,
        829,
        830
    ],
    "u1f635_200d_1f4ab":[
        195,
        823
    ],
    "u1f636_200d_1f32b_fe0f":[
        830,
        195,
        823,
        831,
        830,
        831,
        830,
        829,
        831
    ],
    "u1f645_1f3fb":[
        675,
        58,
        639,
        194,
        8,
        697,
        639,
        629,
        675,
        697,
        639,
        629
    ],
    "u1f645_1f3fb_200d_2640_fe0f":[
        58,
        639,
        760,
        785,
        194,
        8,
        697,
        639,
        784,
        785,
        697,
        639,
        784
    ],
    "u1f645_1f3fb_200d_2642_fe0f":[
        639,
        58,
        194,
        698,
        8,
        697,
        639,
        674,
        698,
        697,
        639,
        674
    ],
    "u1f645_1f3fc":[
        675,
        677,
        642,
        194,
        8,
        702,
        642,
        629,
        675,
        702,
        642,
        629
    ],
    "u1f645_1f3fc_200d_2640_fe0f":[
        677,
        642,
        760,
        785,
        194,
        8,
        702,
        642,
        784,
        785,
        702,
        642,
        784
    ],
    "u1f645_1f3fc_200d_2642_fe0f":[
        642,
        677,
        194,
        698,
        8,
        702,
        642,
        674,
        698,
        702,
        642,
        674
    ],
    "u1f645_1f3fd":[
        675,
        678,
        672,
        194,
        8,
        703,
        672,
        629,
        675,
        703,
        672,
        629
    ],
    "u1f645_1f3fd_200d_2640_fe0f":[
        678,
        643,
        760,
        785,
        194,
        8,
        703,
        643,
        784,
        785,
        703,
        643,
        784
    ],
    "u1f645_1f3fd_200d_2642_fe0f":[
        643,
        678,
        194,
        698,
        8,
        703,
        643,
        674,
        698,
        703,
        643,
        674
    ],
    "u1f645_1f3fe":[
        675,
        645,
        644,
        646,
        8,
        704,
        644,
        629,
        675,
        704,
        644,
        629
    ],
    "u1f645_1f3fe_200d_2640_fe0f":[
        645,
        644,
        760,
        785,
        646,
        645,
        704,
        644,
        784,
        785,
        704,
        644,
        784
    ],
    "u1f645_1f3fe_200d_2642_fe0f":[
        644,
        645,
        646,
        698,
        645,
        704,
        644,
        674,
        698,
        704,
        644,
        674
    ],
    "u1f645_1f3ff":[
        675,
        679,
        647,
        648,
        52,
        705,
        647,
        629,
        675,
        705,
        647,
        629
    ],
    "u1f645_1f3ff_200d_2640_fe0f":[
        679,
        647,
        760,
        785,
        648,
        52,
        705,
        647,
        784,
        785,
        705,
        647,
        784
    ],
    "u1f645_1f3ff_200d_2642_fe0f":[
        647,
        679,
        648,
        698,
        52,
        705,
        647,
        674,
        698,
        705,
        647,
        674
    ],
    "u1f645_200d_2640_fe0f":[
        4,
        649,
        760,
        785,
        194,
        8,
        706,
        649,
        784,
        785,
        706,
        649,
        784
    ],
    "u1f645_200d_2642_fe0f":[
        833,
        4,
        194,
        698,
        8,
        834,
        833,
        674,
        698,
        834,
        833,
        674
    ],
    "u1f646_1f3fb":[
        58,
        639,
        629,
        639,
        194,
        8
    ],
    "u1f646_1f3fb_200d_2640_fe0f":[
        58,
        784,
        402,
        760,
        194,
        8,
        402
    ],
    "u1f646_1f3fb_200d_2642_fe0f":[
        402,
        58,
        674,
        402,
        194,
        8
    ],
    "u1f646_1f3fc":[
        677,
        642,
        629,
        642,
        194,
        8
    ],
    "u1f646_1f3fc_200d_2640_fe0f":[
        677,
        784,
        681,
        760,
        194,
        8,
        681
    ],
    "u1f646_1f3fc_200d_2642_fe0f":[
        681,
        677,
        674,
        681,
        194,
        8
    ],
    "u1f646_1f3fd":[
        678,
        672,
        629,
        672,
        194,
        8
    ],
    "u1f646_1f3fd_200d_2640_fe0f":[
        678,
        784,
        672,
        760,
        194,
        8,
        672
    ],
    "u1f646_1f3fd_200d_2642_fe0f":[
        672,
        678,
        674,
        672,
        194,
        8
    ],
    "u1f646_1f3fe":[
        645,
        644,
        629,
        644,
        646,
        8
    ],
    "u1f646_1f3fe_200d_2640_fe0f":[
        645,
        784,
        682,
        760,
        646,
        8,
        682
    ],
    "u1f646_1f3fe_200d_2642_fe0f":[
        682,
        645,
        674,
        682,
        646,
        8
    ],
    "u1f646_1f3ff":[
        679,
        647,
        629,
        647,
        648,
        52
    ],
    "u1f646_1f3ff_200d_2640_fe0f":[
        679,
        784,
        683,
        760,
        648,
        52,
        683
    ],
    "u1f646_1f3ff_200d_2642_fe0f":[
        683,
        679,
        674,
        683,
        648,
        52
    ],
    "u1f646_200d_2640_fe0f":[
        4,
        784,
        649,
        760,
        194,
        8,
        649
    ],
    "u1f646_200d_2642_fe0f":[
        649,
        4,
        674,
        649,
        194,
        8
    ],
    "u1f647_1f3fb":[
        629,
        58,
        697,
        639,
        8,
        194,
        593
    ],
    "u1f647_1f3fb_200d_2640_fe0f":[
        58,
        784,
        697,
        639,
        8,
        194,
        593
    ],
    "u1f647_1f3fb_200d_2642_fe0f":[
        674,
        697,
        639,
        639,
        58,
        8,
        194,
        593
    ],
    "u1f647_1f3fc":[
        629,
        677,
        702,
        642,
        8,
        194,
        593
    ],
    "u1f647_1f3fc_200d_2640_fe0f":[
        677,
        784,
        702,
        642,
        8,
        194,
        593
    ],
    "u1f647_1f3fc_200d_2642_fe0f":[
        674,
        702,
        642,
        642,
        677,
        8,
        194,
        593
    ],
    "u1f647_1f3fd":[
        629,
        678,
        703,
        672,
        8,
        194,
        593
    ],
    "u1f647_1f3fd_200d_2640_fe0f":[
        678,
        784,
        703,
        643,
        8,
        194,
        593
    ],
    "u1f647_1f3fd_200d_2642_fe0f":[
        674,
        703,
        643,
        643,
        678,
        8,
        194,
        593
    ],
    "u1f647_1f3fe":[
        629,
        645,
        704,
        644,
        8,
        646,
        593
    ],
    "u1f647_1f3fe_200d_2640_fe0f":[
        645,
        784,
        704,
        644,
        645,
        646,
        593
    ],
    "u1f647_1f3fe_200d_2642_fe0f":[
        674,
        704,
        644,
        644,
        645,
        646,
        593
    ],
    "u1f647_1f3ff":[
        629,
        679,
        705,
        647,
        52,
        648,
        593
    ],
    "u1f647_1f3ff_200d_2640_fe0f":[
        679,
        784,
        705,
        647,
        52,
        648,
        593
    ],
    "u1f647_1f3ff_200d_2642_fe0f":[
        674,
        705,
        647,
        647,
        679,
        52,
        648,
        593
    ],
    "u1f647_200d_2640_fe0f":[
        4,
        784,
        706,
        649,
        8,
        194,
        593
    ],
    "u1f647_200d_2642_fe0f":[
        674,
        706,
        649,
        649,
        4,
        8,
        194,
        593
    ],
    "u1f64b_1f3fb":[
        629,
        639,
        675,
        58,
        639,
        194,
        8,
        639,
        684,
        629
    ],
    "u1f64b_1f3fb_200d_2640_fe0f":[
        58,
        639,
        58,
        639,
        685,
        784,
        194,
        8,
        639,
        760,
        785
    ],
    "u1f64b_1f3fb_200d_2642_fe0f":[
        639,
        194,
        8,
        639,
        58,
        639,
        684,
        674,
        698,
        639
    ],
    "u1f64b_1f3fc":[
        629,
        642,
        675,
        677,
        642,
        194,
        8,
        642,
        686,
        629
    ],
    "u1f64b_1f3fc_200d_2640_fe0f":[
        677,
        642,
        677,
        642,
        687,
        784,
        194,
        8,
        642,
        785,
        760
    ],
    "u1f64b_1f3fc_200d_2642_fe0f":[
        642,
        194,
        8,
        642,
        677,
        642,
        686,
        674,
        698,
        642
    ],
    "u1f64b_1f3fd":[
        629,
        672,
        675,
        678,
        672,
        194,
        8,
        672,
        688,
        629
    ],
    "u1f64b_1f3fd_200d_2640_fe0f":[
        678,
        643,
        678,
        643,
        689,
        784,
        194,
        643,
        8,
        760,
        785
    ],
    "u1f64b_1f3fd_200d_2642_fe0f":[
        643,
        194,
        8,
        643,
        678,
        643,
        688,
        674,
        698,
        643
    ],
    "u1f64b_1f3fe":[
        629,
        644,
        675,
        645,
        644,
        646,
        8,
        644,
        690,
        629
    ],
    "u1f64b_1f3fe_200d_2640_fe0f":[
        645,
        644,
        645,
        644,
        691,
        784,
        646,
        645,
        644,
        760,
        785
    ],
    "u1f64b_1f3fe_200d_2642_fe0f":[
        644,
        646,
        645,
        644,
        645,
        644,
        690,
        674,
        698,
        644
    ],
    "u1f64b_1f3ff":[
        629,
        647,
        675,
        679,
        647,
        648,
        52,
        647,
        692,
        629
    ],
    "u1f64b_1f3ff_200d_2640_fe0f":[
        679,
        647,
        679,
        647,
        693,
        784,
        648,
        52,
        647,
        760,
        785
    ],
    "u1f64b_1f3ff_200d_2642_fe0f":[
        647,
        648,
        52,
        647,
        679,
        647,
        692,
        674,
        698,
        647
    ],
    "u1f64b_200d_2640_fe0f":[
        4,
        649,
        4,
        649,
        694,
        784,
        194,
        8,
        649,
        760,
        785
    ],
    "u1f64b_200d_2642_fe0f":[
        649,
        194,
        8,
        649,
        4,
        649,
        694,
        674,
        698,
        649
    ],
    "u1f64c_1f3fb":[
        639,
        697,
        684,
        639,
        593
    ],
    "u1f64c_1f3fc":[
        642,
        702,
        686,
        642,
        593
    ],
    "u1f64c_1f3fd":[
        643,
        703,
        688,
        643,
        593
    ],
    "u1f64c_1f3fe":[
        644,
        704,
        690,
        644,
        593
    ],
    "u1f64c_1f3ff":[
        647,
        705,
        692,
        647,
        593
    ],
    "u1f64d_1f3fb":[
        629,
        675,
        58,
        639,
        639,
        194,
        8
    ],
    "u1f64d_1f3fb_200d_2640_fe0f":[
        58,
        784,
        639,
        639,
        58,
        785,
        8,
        194,
        760
    ],
    "u1f64d_1f3fb_200d_2642_fe0f":[
        639,
        8,
        194,
        639,
        58,
        674,
        698,
        639,
        639
    ],
    "u1f64d_1f3fc":[
        629,
        675,
        677,
        642,
        642,
        194,
        8
    ],
    "u1f64d_1f3fc_200d_2640_fe0f":[
        677,
        784,
        642,
        642,
        677,
        785,
        8,
        194,
        760
    ],
    "u1f64d_1f3fc_200d_2642_fe0f":[
        642,
        8,
        194,
        642,
        677,
        674,
        698,
        642,
        642
    ],
    "u1f64d_1f3fd":[
        629,
        675,
        678,
        672,
        672,
        194,
        8
    ],
    "u1f64d_1f3fd_200d_2640_fe0f":[
        678,
        784,
        643,
        643,
        678,
        785,
        8,
        194,
        760
    ],
    "u1f64d_1f3fd_200d_2642_fe0f":[
        643,
        8,
        194,
        643,
        678,
        674,
        698,
        643,
        643
    ],
    "u1f64d_1f3fe":[
        629,
        675,
        645,
        644,
        644,
        646,
        8
    ],
    "u1f64d_1f3fe_200d_2640_fe0f":[
        645,
        784,
        644,
        644,
        645,
        785,
        646,
        760
    ],
    "u1f64d_1f3fe_200d_2642_fe0f":[
        644,
        645,
        646,
        644,
        645,
        674,
        698,
        644,
        644
    ],
    "u1f64d_1f3ff":[
        629,
        675,
        679,
        647,
        647,
        648,
        52
    ],
    "u1f64d_1f3ff_200d_2640_fe0f":[
        679,
        784,
        647,
        647,
        679,
        785,
        52,
        648,
        760
    ],
    "u1f64d_1f3ff_200d_2642_fe0f":[
        647,
        52,
        648,
        647,
        679,
        674,
        698,
        647,
        647
    ],
    "u1f64d_200d_2640_fe0f":[
        4,
        784,
        649,
        649,
        4,
        785,
        8,
        194,
        760
    ],
    "u1f64d_200d_2642_fe0f":[
        649,
        8,
        194,
        649,
        4,
        674,
        698,
        649,
        649
    ],
    "u1f64e_1f3fb":[
        629,
        675,
        58,
        639,
        639,
        194,
        8
    ],
    "u1f64e_1f3fb_200d_2640_fe0f":[
        58,
        784,
        639,
        639,
        58,
        785,
        760,
        8,
        194
    ],
    "u1f64e_1f3fb_200d_2642_fe0f":[
        674,
        698,
        639,
        194,
        8,
        639,
        58
    ],
    "u1f64e_1f3fc":[
        629,
        675,
        677,
        642,
        642,
        194,
        8
    ],
    "u1f64e_1f3fc_200d_2640_fe0f":[
        677,
        784,
        642,
        642,
        677,
        785,
        760,
        8,
        194
    ],
    "u1f64e_1f3fc_200d_2642_fe0f":[
        674,
        698,
        642,
        194,
        8,
        642,
        677
    ],
    "u1f64e_1f3fd":[
        629,
        675,
        678,
        672,
        672,
        194,
        8
    ],
    "u1f64e_1f3fd_200d_2640_fe0f":[
        678,
        784,
        643,
        643,
        678,
        785,
        760,
        8,
        194
    ],
    "u1f64e_1f3fd_200d_2642_fe0f":[
        674,
        698,
        643,
        194,
        8,
        643,
        678
    ],
    "u1f64e_1f3fe":[
        629,
        675,
        645,
        644,
        644,
        646,
        8
    ],
    "u1f64e_1f3fe_200d_2640_fe0f":[
        645,
        784,
        644,
        644,
        645,
        785,
        760,
        646
    ],
    "u1f64e_1f3fe_200d_2642_fe0f":[
        674,
        698,
        644,
        646,
        645,
        644,
        645
    ],
    "u1f64e_1f3ff":[
        629,
        675,
        679,
        647,
        647,
        648,
        52
    ],
    "u1f64e_1f3ff_200d_2640_fe0f":[
        679,
        784,
        647,
        647,
        679,
        785,
        760,
        52,
        648
    ],
    "u1f64e_1f3ff_200d_2642_fe0f":[
        674,
        698,
        647,
        648,
        52,
        647,
        679
    ],
    "u1f64e_200d_2640_fe0f":[
        4,
        784,
        649,
        649,
        4,
        785,
        760,
        8,
        194
    ],
    "u1f64e_200d_2642_fe0f":[
        674,
        698,
        649,
        194,
        8,
        649,
        4
    ],
    "u1f64f_1f3fb":[
        665,
        662,
        697,
        639,
        665,
        662,
        697,
        639,
        697
    ],
    "u1f64f_1f3fc":[
        665,
        662,
        702,
        642,
        702,
        665,
        662,
        642,
        702
    ],
    "u1f64f_1f3fd":[
        665,
        662,
        703,
        643,
        665,
        662,
        703,
        643,
        703
    ],
    "u1f64f_1f3fe":[
        665,
        662,
        704,
        644,
        665,
        662,
        704,
        644,
        704
    ],
    "u1f64f_1f3ff":[
        665,
        662,
        705,
        647,
        665,
        662,
        705,
        647,
        705
    ],
    "u1f6a3_1f3fb":[
        52,
        194,
        13,
        3,
        662,
        639,
        639,
        58,
        639,
        629
    ],
    "u1f6a3_1f3fb_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        639,
        58,
        639,
        6
    ],
    "u1f6a3_1f3fb_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        639,
        58,
        639,
        197
    ],
    "u1f6a3_1f3fc":[
        52,
        194,
        13,
        3,
        662,
        642,
        642,
        677,
        642,
        629
    ],
    "u1f6a3_1f3fc_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        642,
        677,
        642,
        6
    ],
    "u1f6a3_1f3fc_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        642,
        677,
        642,
        197
    ],
    "u1f6a3_1f3fd":[
        52,
        194,
        13,
        3,
        662,
        672,
        672,
        678,
        672,
        629
    ],
    "u1f6a3_1f3fd_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        672,
        678,
        672,
        6
    ],
    "u1f6a3_1f3fd_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        672,
        678,
        672,
        197
    ],
    "u1f6a3_1f3fe":[
        52,
        194,
        13,
        3,
        662,
        644,
        644,
        645,
        644,
        629
    ],
    "u1f6a3_1f3fe_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        644,
        645,
        644,
        6
    ],
    "u1f6a3_1f3fe_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        644,
        645,
        644,
        197
    ],
    "u1f6a3_1f3ff":[
        52,
        194,
        13,
        3,
        662,
        647,
        647,
        679,
        647,
        629
    ],
    "u1f6a3_1f3ff_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        647,
        679,
        647,
        6
    ],
    "u1f6a3_1f3ff_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        647,
        679,
        647,
        197
    ],
    "u1f6a3_200d_2640_fe0f":[
        52,
        194,
        13,
        3,
        662,
        649,
        4,
        649,
        6
    ],
    "u1f6a3_200d_2642_fe0f":[
        52,
        194,
        13,
        3,
        662,
        649,
        4,
        649,
        197
    ],
    "u1f6b4_1f3fb":[
        639,
        697,
        58,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        639,
        675,
        629,
        675
    ],
    "u1f6b4_1f3fb_200d_2640_fe0f":[
        639,
        639,
        58,
        697,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        639,
        1,
        2
    ],
    "u1f6b4_1f3fb_200d_2642_fe0f":[
        639,
        58,
        697,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        639,
        673,
        674
    ],
    "u1f6b4_1f3fc":[
        642,
        702,
        677,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        642,
        675,
        629,
        675
    ],
    "u1f6b4_1f3fc_200d_2640_fe0f":[
        642,
        642,
        677,
        702,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        642,
        1,
        2
    ],
    "u1f6b4_1f3fc_200d_2642_fe0f":[
        642,
        677,
        702,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        642,
        673,
        674
    ],
    "u1f6b4_1f3fd":[
        672,
        703,
        678,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        672,
        675,
        629,
        675
    ],
    "u1f6b4_1f3fd_200d_2640_fe0f":[
        643,
        643,
        678,
        703,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        643,
        1,
        2
    ],
    "u1f6b4_1f3fd_200d_2642_fe0f":[
        643,
        678,
        703,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        643,
        673,
        674
    ],
    "u1f6b4_1f3fe":[
        644,
        704,
        645,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        644,
        675,
        629,
        675
    ],
    "u1f6b4_1f3fe_200d_2640_fe0f":[
        644,
        644,
        645,
        704,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        644,
        1,
        2
    ],
    "u1f6b4_1f3fe_200d_2642_fe0f":[
        644,
        645,
        704,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        644,
        673,
        674
    ],
    "u1f6b4_1f3ff":[
        647,
        705,
        679,
        676,
        58,
        589,
        589,
        589,
        589,
        58,
        676,
        647,
        675,
        629,
        675
    ],
    "u1f6b4_1f3ff_200d_2640_fe0f":[
        647,
        647,
        679,
        705,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        647,
        1,
        2
    ],
    "u1f6b4_1f3ff_200d_2642_fe0f":[
        647,
        679,
        705,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        647,
        673,
        674
    ],
    "u1f6b4_200d_2640_fe0f":[
        649,
        649,
        4,
        706,
        9,
        58,
        589,
        589,
        589,
        589,
        58,
        9,
        649,
        1,
        2
    ],
    "u1f6b4_200d_2642_fe0f":[
        649,
        4,
        706,
        698,
        58,
        589,
        589,
        589,
        589,
        58,
        698,
        649,
        673,
        674
    ],
    "u1f6b5_1f3fb":[
        197,
        639,
        58,
        697,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        639,
        675,
        629,
        675
    ],
    "u1f6b5_1f3fb_200d_2640_fe0f":[
        197,
        639,
        639,
        58,
        697,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        639,
        1,
        2
    ],
    "u1f6b5_1f3fb_200d_2642_fe0f":[
        197,
        639,
        58,
        697,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        639,
        673,
        674
    ],
    "u1f6b5_1f3fc":[
        197,
        642,
        677,
        702,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        642,
        675,
        629,
        675
    ],
    "u1f6b5_1f3fc_200d_2640_fe0f":[
        197,
        642,
        642,
        677,
        702,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        642,
        1,
        2
    ],
    "u1f6b5_1f3fc_200d_2642_fe0f":[
        197,
        642,
        677,
        702,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        642,
        673,
        674
    ],
    "u1f6b5_1f3fd":[
        197,
        672,
        678,
        703,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        672,
        675,
        629,
        675
    ],
    "u1f6b5_1f3fd_200d_2640_fe0f":[
        197,
        643,
        643,
        678,
        703,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        643,
        1,
        2
    ],
    "u1f6b5_1f3fd_200d_2642_fe0f":[
        197,
        643,
        678,
        703,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        643,
        673,
        674
    ],
    "u1f6b5_1f3fe":[
        197,
        644,
        645,
        704,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        644,
        675,
        629,
        675
    ],
    "u1f6b5_1f3fe_200d_2640_fe0f":[
        197,
        644,
        644,
        645,
        704,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        644,
        1,
        2
    ],
    "u1f6b5_1f3fe_200d_2642_fe0f":[
        197,
        644,
        645,
        704,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        644,
        673,
        674
    ],
    "u1f6b5_1f3ff":[
        197,
        647,
        679,
        705,
        676,
        58,
        79,
        79,
        79,
        79,
        58,
        676,
        647,
        675,
        629,
        675
    ],
    "u1f6b5_1f3ff_200d_2640_fe0f":[
        197,
        647,
        647,
        679,
        705,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        647,
        1,
        2
    ],
    "u1f6b5_1f3ff_200d_2642_fe0f":[
        197,
        647,
        679,
        705,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        647,
        673,
        674
    ],
    "u1f6b5_200d_2640_fe0f":[
        197,
        649,
        649,
        4,
        706,
        9,
        58,
        79,
        79,
        79,
        79,
        58,
        9,
        649,
        1,
        2
    ],
    "u1f6b5_200d_2642_fe0f":[
        197,
        649,
        4,
        706,
        698,
        58,
        79,
        79,
        79,
        79,
        58,
        698,
        649,
        673,
        674
    ],
    "u1f6b6_1f3fb":[
        58,
        697,
        698,
        674,
        639,
        639,
        58,
        629,
        639
    ],
    "u1f6b6_1f3fb_200d_2640_fe0f":[
        58,
        697,
        639,
        639,
        58,
        58,
        698,
        674,
        784,
        639
    ],
    "u1f6b6_1f3fb_200d_2642_fe0f":[
        58,
        697,
        639,
        639,
        58,
        698,
        674,
        197,
        639
    ],
    "u1f6b6_1f3fc":[
        58,
        702,
        698,
        674,
        642,
        642,
        677,
        629,
        642
    ],
    "u1f6b6_1f3fc_200d_2640_fe0f":[
        58,
        702,
        642,
        642,
        677,
        677,
        698,
        674,
        784,
        642
    ],
    "u1f6b6_1f3fc_200d_2642_fe0f":[
        58,
        702,
        642,
        642,
        677,
        698,
        674,
        197,
        642
    ],
    "u1f6b6_1f3fd":[
        58,
        703,
        698,
        674,
        672,
        672,
        678,
        629,
        672
    ],
    "u1f6b6_1f3fd_200d_2640_fe0f":[
        58,
        703,
        672,
        672,
        678,
        678,
        698,
        674,
        784,
        672
    ],
    "u1f6b6_1f3fd_200d_2642_fe0f":[
        58,
        703,
        672,
        672,
        678,
        698,
        674,
        197,
        672
    ],
    "u1f6b6_1f3fe":[
        58,
        704,
        698,
        674,
        644,
        644,
        645,
        629,
        644
    ],
    "u1f6b6_1f3fe_200d_2640_fe0f":[
        58,
        704,
        644,
        644,
        645,
        645,
        698,
        674,
        784,
        644
    ],
    "u1f6b6_1f3fe_200d_2642_fe0f":[
        58,
        704,
        644,
        644,
        645,
        698,
        674,
        197,
        644
    ],
    "u1f6b6_1f3ff":[
        58,
        705,
        698,
        674,
        647,
        647,
        679,
        629,
        647
    ],
    "u1f6b6_1f3ff_200d_2640_fe0f":[
        58,
        705,
        647,
        647,
        679,
        679,
        698,
        674,
        784,
        647
    ],
    "u1f6b6_1f3ff_200d_2642_fe0f":[
        58,
        705,
        647,
        647,
        679,
        698,
        674,
        197,
        647
    ],
    "u1f6b6_200d_2640_fe0f":[
        58,
        706,
        649,
        649,
        4,
        4,
        698,
        674,
        784,
        649
    ],
    "u1f6b6_200d_2642_fe0f":[
        58,
        706,
        649,
        649,
        4,
        698,
        674,
        197,
        649
    ],
    "u1f6c0_1f3fb":[
        639,
        58,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c0_1f3fc":[
        642,
        677,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c0_1f3fd":[
        643,
        678,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c0_1f3fe":[
        644,
        645,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6c0_1f3ff":[
        647,
        679,
        196,
        222,
        196,
        222,
        196
    ],
    "u1f6cc_1f3fb":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        639,
        58
    ],
    "u1f6cc_1f3fc":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        642,
        677
    ],
    "u1f6cc_1f3fd":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        643,
        678
    ],
    "u1f6cc_1f3fe":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        644,
        645
    ],
    "u1f6cc_1f3ff":[
        194,
        590,
        3,
        671,
        189,
        10,
        12,
        647,
        679
    ],
    "u1f90c_1f3fb":[
        684,
        684,
        697,
        684,
        697,
        684,
        697,
        684,
        684,
        639
    ],
    "u1f90c_1f3fc":[
        686,
        686,
        702,
        686,
        702,
        686,
        702,
        686,
        686,
        642
    ],
    "u1f90c_1f3fd":[
        688,
        688,
        703,
        688,
        703,
        688,
        703,
        688,
        688,
        672
    ],
    "u1f90c_1f3fe":[
        690,
        690,
        704,
        690,
        704,
        690,
        704,
        690,
        690,
        644
    ],
    "u1f90c_1f3ff":[
        692,
        692,
        705,
        692,
        705,
        692,
        705,
        692,
        692,
        647
    ],
    "u1f90f_1f3fb":[
        639,
        684
    ],
    "u1f90f_1f3fc":[
        642,
        686
    ],
    "u1f90f_1f3fd":[
        672,
        688
    ],
    "u1f90f_1f3fe":[
        644,
        690
    ],
    "u1f90f_1f3ff":[
        647,
        692
    ],
    "u1f918_1f3fb":[
        684,
        639,
        639,
        639
    ],
    "u1f918_1f3fc":[
        686,
        642,
        642,
        642
    ],
    "u1f918_1f3fd":[
        688,
        672,
        672,
        672
    ],
    "u1f918_1f3fe":[
        690,
        644,
        644,
        644
    ],
    "u1f918_1f3ff":[
        692,
        647,
        647,
        647
    ],
    "u1f919_1f3fb":[
        639,
        684,
        639,
        684,
        639,
        684,
        639
    ],
    "u1f919_1f3fc":[
        642,
        686,
        642,
        686,
        642,
        686,
        642
    ],
    "u1f919_1f3fd":[
        643,
        688,
        643,
        688,
        643,
        688,
        643
    ],
    "u1f919_1f3fe":[
        644,
        690,
        644,
        690,
        644,
        690,
        644
    ],
    "u1f919_1f3ff":[
        647,
        692,
        647,
        692,
        647,
        692,
        647
    ],
    "u1f91a_1f3fb":[
        684,
        639
    ],
    "u1f91a_1f3fc":[
        686,
        642
    ],
    "u1f91a_1f3fd":[
        688,
        643
    ],
    "u1f91a_1f3fe":[
        690,
        644
    ],
    "u1f91a_1f3ff":[
        692,
        647
    ],
    "u1f91b_1f3fb":[
        639,
        684,
        684,
        684,
        639
    ],
    "u1f91b_1f3fc":[
        642,
        642,
        686,
        686,
        686
    ],
    "u1f91b_1f3fd":[
        643,
        643,
        688,
        688,
        688
    ],
    "u1f91b_1f3fe":[
        644,
        644,
        690,
        690,
        690
    ],
    "u1f91b_1f3ff":[
        647,
        647,
        692,
        692,
        692
    ],
    "u1f91c_1f3fb":[
        639,
        684,
        684,
        684,
        639
    ],
    "u1f91c_1f3fc":[
        642,
        642,
        686,
        686,
        686
    ],
    "u1f91c_1f3fd":[
        643,
        643,
        688,
        688,
        688
    ],
    "u1f91c_1f3fe":[
        644,
        644,
        690,
        690,
        690
    ],
    "u1f91c_1f3ff":[
        647,
        647,
        692,
        692,
        692
    ],
    "u1f91d_1f3fb":[
        684,
        639,
        684,
        697
    ],
    "u1f91d_1f3fc":[
        686,
        642,
        686,
        702
    ],
    "u1f91d_1f3fd":[
        688,
        672,
        688,
        703
    ],
    "u1f91d_1f3fe":[
        690,
        644,
        690,
        704
    ],
    "u1f91d_1f3ff":[
        692,
        647,
        692,
        705
    ],
    "u1f91e_1f3fb":[
        684,
        639,
        639
    ],
    "u1f91e_1f3fc":[
        686,
        642,
        642
    ],
    "u1f91e_1f3fd":[
        688,
        672,
        672
    ],
    "u1f91e_1f3fe":[
        690,
        644,
        644
    ],
    "u1f91e_1f3ff":[
        692,
        647,
        647
    ],
    "u1f91f_1f3fb":[
        639,
        684,
        639,
        684,
        639
    ],
    "u1f91f_1f3fc":[
        642,
        686,
        642,
        686,
        642
    ],
    "u1f91f_1f3fd":[
        643,
        688,
        643,
        688,
        643
    ],
    "u1f91f_1f3fe":[
        644,
        690,
        644,
        690,
        644
    ],
    "u1f91f_1f3ff":[
        647,
        692,
        647,
        692,
        647
    ],
    "u1f926_1f3fb":[
        629,
        58,
        639,
        639,
        58,
        194,
        675,
        675,
        8,
        684,
        863,
        639,
        684,
        194,
        684,
        8,
        629
    ],
    "u1f926_1f3fb_200d_2640_fe0f":[
        58,
        784,
        639,
        58,
        58,
        785,
        785,
        8,
        684,
        863,
        639,
        684,
        760,
        684,
        9,
        784
    ],
    "u1f926_1f3fb_200d_2642_fe0f":[
        674,
        698,
        639,
        194,
        639,
        58,
        698,
        684,
        8,
        863,
        194,
        684,
        864,
        639,
        684,
        674
    ],
    "u1f926_1f3fc":[
        629,
        677,
        642,
        642,
        677,
        194,
        675,
        675,
        8,
        686,
        863,
        642,
        686,
        194,
        686,
        8,
        629
    ],
    "u1f926_1f3fc_200d_2640_fe0f":[
        677,
        784,
        642,
        677,
        677,
        785,
        785,
        8,
        686,
        863,
        642,
        686,
        760,
        686,
        9,
        784
    ],
    "u1f926_1f3fc_200d_2642_fe0f":[
        674,
        698,
        642,
        194,
        642,
        677,
        698,
        686,
        8,
        863,
        194,
        686,
        864,
        642,
        686,
        674
    ],
    "u1f926_1f3fd":[
        629,
        678,
        672,
        672,
        678,
        194,
        675,
        675,
        8,
        688,
        863,
        672,
        688,
        194,
        688,
        8,
        629
    ],
    "u1f926_1f3fd_200d_2640_fe0f":[
        678,
        784,
        643,
        678,
        678,
        785,
        785,
        8,
        688,
        863,
        643,
        688,
        760,
        688,
        9,
        784
    ],
    "u1f926_1f3fd_200d_2642_fe0f":[
        674,
        698,
        643,
        194,
        643,
        678,
        698,
        688,
        8,
        863,
        194,
        688,
        864,
        643,
        688,
        674
    ],
    "u1f926_1f3fe":[
        629,
        645,
        644,
        644,
        645,
        646,
        675,
        675,
        690,
        863,
        644,
        690,
        646,
        690,
        8,
        629
    ],
    "u1f926_1f3fe_200d_2640_fe0f":[
        645,
        784,
        644,
        645,
        645,
        785,
        785,
        690,
        863,
        644,
        690,
        760,
        690,
        9,
        784
    ],
    "u1f926_1f3fe_200d_2642_fe0f":[
        674,
        698,
        644,
        194,
        644,
        645,
        698,
        690,
        645,
        865,
        646,
        690,
        866,
        644,
        690,
        674
    ],
    "u1f926_1f3ff":[
        629,
        679,
        647,
        647,
        679,
        648,
        675,
        675,
        52,
        692,
        52,
        647,
        692,
        648,
        692,
        52,
        629
    ],
    "u1f926_1f3ff_200d_2640_fe0f":[
        679,
        784,
        647,
        679,
        679,
        785,
        785,
        52,
        692,
        52,
        647,
        692,
        760,
        692,
        9,
        784
    ],
    "u1f926_1f3ff_200d_2642_fe0f":[
        674,
        698,
        647,
        194,
        647,
        679,
        698,
        692,
        692,
        648,
        52,
        647,
        692,
        674
    ],
    "u1f926_200d_2640_fe0f":[
        4,
        784,
        649,
        4,
        4,
        785,
        785,
        8,
        694,
        863,
        649,
        694,
        760,
        694,
        9,
        784
    ],
    "u1f926_200d_2642_fe0f":[
        674,
        698,
        649,
        194,
        649,
        4,
        698,
        694,
        8,
        863,
        194,
        694,
        864,
        649,
        694,
        674
    ],
    "u1f930_1f3fb":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        697,
        639,
        684,
        58,
        639,
        58,
        741,
        8,
        760
    ],
    "u1f930_1f3fc":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        702,
        642,
        686,
        677,
        642,
        677,
        741,
        8,
        760
    ],
    "u1f930_1f3fd":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        703,
        643,
        688,
        678,
        643,
        678,
        741,
        8,
        760
    ],
    "u1f930_1f3fe":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        704,
        644,
        690,
        645,
        644,
        645,
        646,
        645,
        760
    ],
    "u1f930_1f3ff":[
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        785,
        784,
        705,
        647,
        692,
        679,
        647,
        679,
        648,
        52,
        760
    ],
    "u1f931_1f3fb":[
        784,
        639,
        684,
        58,
        639,
        697,
        639,
        741,
        8,
        760,
        58,
        58,
        197,
        639
    ],
    "u1f931_1f3fc":[
        784,
        642,
        686,
        677,
        642,
        702,
        642,
        741,
        8,
        760,
        677,
        677,
        197,
        642
    ],
    "u1f931_1f3fd":[
        784,
        643,
        688,
        678,
        643,
        703,
        643,
        741,
        8,
        760,
        678,
        678,
        197,
        643
    ],
    "u1f931_1f3fe":[
        784,
        644,
        690,
        645,
        644,
        704,
        644,
        646,
        8,
        760,
        645,
        645,
        197,
        644
    ],
    "u1f931_1f3ff":[
        784,
        647,
        692,
        679,
        647,
        705,
        647,
        648,
        52,
        760,
        679,
        679,
        197,
        647
    ],
    "u1f932_1f3fb":[
        684,
        639,
        684,
        639,
        684
    ],
    "u1f932_1f3fc":[
        686,
        642,
        686,
        642,
        686
    ],
    "u1f932_1f3fd":[
        688,
        643,
        688,
        643,
        688
    ],
    "u1f932_1f3fe":[
        690,
        644,
        690,
        644,
        690
    ],
    "u1f932_1f3ff":[
        692,
        647,
        692,
        647,
        692
    ],
    "u1f933_1f3fb":[
        639,
        684,
        639,
        721,
        336,
        125,
        684,
        639,
        639,
        639,
        639,
        639,
        639,
        189
    ],
    "u1f933_1f3fc":[
        642,
        686,
        642,
        721,
        336,
        125,
        686,
        642,
        642,
        642,
        642,
        642,
        642,
        189
    ],
    "u1f933_1f3fd":[
        643,
        688,
        643,
        721,
        336,
        125,
        688,
        643,
        643,
        643,
        643,
        643,
        643,
        189
    ],
    "u1f933_1f3fe":[
        644,
        690,
        644,
        721,
        336,
        125,
        690,
        644,
        644,
        644,
        644,
        644,
        644,
        189
    ],
    "u1f933_1f3ff":[
        647,
        692,
        647,
        721,
        336,
        125,
        692,
        647,
        647,
        647,
        647,
        647,
        647,
        189
    ],
    "u1f934_1f3fb":[
        639,
        8,
        194,
        58,
        196,
        79
    ],
    "u1f934_1f3fc":[
        642,
        8,
        194,
        677,
        196,
        79
    ],
    "u1f934_1f3fd":[
        643,
        8,
        194,
        678,
        196,
        79
    ],
    "u1f934_1f3fe":[
        644,
        645,
        646,
        645,
        196,
        79
    ],
    "u1f934_1f3ff":[
        647,
        52,
        648,
        679,
        196,
        79
    ],
    "u1f935_1f3fb":[
        58,
        818,
        67,
        871,
        58,
        639,
        697,
        769,
        58,
        639,
        194,
        8
    ],
    "u1f935_1f3fb_200d_2640_fe0f":[
        58,
        639,
        697,
        818,
        67,
        871,
        58,
        769,
        58,
        639,
        760,
        194,
        8,
        58,
        639
    ],
    "u1f935_1f3fb_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        639,
        697,
        639,
        769,
        58,
        194,
        8,
        58
    ],
    "u1f935_1f3fc":[
        58,
        818,
        67,
        871,
        58,
        642,
        702,
        769,
        58,
        677,
        642,
        194,
        8
    ],
    "u1f935_1f3fc_200d_2640_fe0f":[
        677,
        58,
        642,
        702,
        818,
        67,
        871,
        58,
        769,
        58,
        642,
        760,
        194,
        8,
        677,
        642
    ],
    "u1f935_1f3fc_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        642,
        702,
        642,
        769,
        58,
        194,
        8,
        677
    ],
    "u1f935_1f3fd":[
        58,
        818,
        67,
        871,
        58,
        672,
        703,
        769,
        58,
        678,
        672,
        194,
        8
    ],
    "u1f935_1f3fd_200d_2640_fe0f":[
        678,
        58,
        672,
        703,
        818,
        67,
        871,
        58,
        769,
        58,
        672,
        760,
        194,
        8,
        678,
        672
    ],
    "u1f935_1f3fd_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        672,
        703,
        672,
        769,
        58,
        194,
        8,
        678
    ],
    "u1f935_1f3fe":[
        58,
        818,
        67,
        871,
        58,
        644,
        704,
        769,
        58,
        645,
        644,
        646,
        8
    ],
    "u1f935_1f3fe_200d_2640_fe0f":[
        645,
        58,
        644,
        704,
        818,
        67,
        871,
        58,
        769,
        58,
        644,
        760,
        646,
        8,
        645,
        644
    ],
    "u1f935_1f3fe_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        644,
        704,
        644,
        769,
        58,
        646,
        8,
        645
    ],
    "u1f935_1f3ff":[
        58,
        818,
        67,
        871,
        58,
        647,
        705,
        769,
        58,
        679,
        647,
        648,
        52
    ],
    "u1f935_1f3ff_200d_2640_fe0f":[
        679,
        58,
        647,
        705,
        818,
        67,
        871,
        58,
        769,
        58,
        647,
        760,
        648,
        52,
        679,
        647
    ],
    "u1f935_1f3ff_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        647,
        705,
        647,
        769,
        58,
        648,
        52,
        679
    ],
    "u1f935_200d_2640_fe0f":[
        4,
        58,
        649,
        706,
        818,
        67,
        871,
        58,
        769,
        58,
        649,
        760,
        194,
        8,
        4,
        649
    ],
    "u1f935_200d_2642_fe0f":[
        58,
        818,
        67,
        871,
        58,
        649,
        706,
        649,
        769,
        58,
        194,
        8,
        4
    ],
    "u1f936_1f3fb":[
        641,
        639,
        641,
        639,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f936_1f3fc":[
        641,
        642,
        641,
        642,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f936_1f3fd":[
        641,
        643,
        641,
        643,
        2,
        194,
        8,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f936_1f3fe":[
        641,
        644,
        641,
        644,
        2,
        646,
        645,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f936_1f3ff":[
        641,
        647,
        641,
        647,
        2,
        648,
        52,
        612,
        612,
        612,
        612,
        641,
        612
    ],
    "u1f937_1f3fb":[
        58,
        629,
        639,
        675,
        684,
        639,
        629,
        194,
        8
    ],
    "u1f937_1f3fb_200d_2640_fe0f":[
        58,
        784,
        639,
        785,
        684,
        639,
        784,
        194,
        760,
        8
    ],
    "u1f937_1f3fb_200d_2642_fe0f":[
        674,
        698,
        684,
        639,
        674,
        639,
        639,
        58,
        741,
        8
    ],
    "u1f937_1f3fc":[
        677,
        629,
        642,
        675,
        686,
        642,
        629,
        194,
        8
    ],
    "u1f937_1f3fc_200d_2640_fe0f":[
        677,
        784,
        642,
        785,
        686,
        642,
        784,
        194,
        760,
        8
    ],
    "u1f937_1f3fc_200d_2642_fe0f":[
        674,
        698,
        686,
        642,
        674,
        642,
        642,
        677,
        741,
        8
    ],
    "u1f937_1f3fd":[
        678,
        629,
        672,
        675,
        688,
        672,
        629,
        194,
        8
    ],
    "u1f937_1f3fd_200d_2640_fe0f":[
        678,
        784,
        643,
        785,
        688,
        643,
        784,
        194,
        760,
        8
    ],
    "u1f937_1f3fd_200d_2642_fe0f":[
        674,
        698,
        688,
        643,
        674,
        643,
        643,
        678,
        741,
        8
    ],
    "u1f937_1f3fe":[
        645,
        629,
        644,
        675,
        690,
        644,
        629,
        646,
        8
    ],
    "u1f937_1f3fe_200d_2640_fe0f":[
        645,
        784,
        644,
        785,
        690,
        644,
        784,
        646,
        760,
        645
    ],
    "u1f937_1f3fe_200d_2642_fe0f":[
        674,
        698,
        690,
        644,
        674,
        644,
        644,
        645,
        646,
        8
    ],
    "u1f937_1f3ff":[
        679,
        629,
        647,
        675,
        692,
        647,
        629,
        648,
        52
    ],
    "u1f937_1f3ff_200d_2640_fe0f":[
        679,
        784,
        647,
        785,
        692,
        647,
        784,
        648,
        760,
        52
    ],
    "u1f937_1f3ff_200d_2642_fe0f":[
        674,
        698,
        692,
        647,
        674,
        647,
        647,
        679,
        648,
        52
    ],
    "u1f937_200d_2640_fe0f":[
        4,
        784,
        649,
        785,
        634,
        649,
        784,
        741,
        760,
        8
    ],
    "u1f937_200d_2642_fe0f":[
        674,
        698,
        634,
        649,
        674,
        649,
        649,
        4,
        741,
        8
    ],
    "u1f938_1f3fb":[
        639,
        639,
        639,
        639,
        58,
        675,
        222,
        629,
        222,
        675,
        222,
        639,
        629
    ],
    "u1f938_1f3fb_200d_2640_fe0f":[
        639,
        58,
        639,
        639,
        639,
        58,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        639,
        2
    ],
    "u1f938_1f3fb_200d_2642_fe0f":[
        639,
        639,
        639,
        639,
        58,
        10,
        222,
        12,
        222,
        10,
        222,
        639,
        12
    ],
    "u1f938_1f3fc":[
        642,
        642,
        642,
        642,
        677,
        675,
        222,
        629,
        222,
        675,
        222,
        642,
        629
    ],
    "u1f938_1f3fc_200d_2640_fe0f":[
        642,
        677,
        642,
        642,
        642,
        677,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        642,
        2
    ],
    "u1f938_1f3fc_200d_2642_fe0f":[
        642,
        642,
        642,
        642,
        677,
        10,
        222,
        12,
        222,
        10,
        222,
        642,
        12
    ],
    "u1f938_1f3fd":[
        672,
        672,
        672,
        672,
        678,
        675,
        222,
        629,
        222,
        675,
        222,
        672,
        629
    ],
    "u1f938_1f3fd_200d_2640_fe0f":[
        672,
        678,
        672,
        672,
        672,
        678,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        672,
        2
    ],
    "u1f938_1f3fd_200d_2642_fe0f":[
        672,
        672,
        672,
        672,
        678,
        10,
        222,
        12,
        222,
        10,
        222,
        672,
        12
    ],
    "u1f938_1f3fe":[
        644,
        644,
        644,
        644,
        645,
        675,
        222,
        629,
        222,
        675,
        222,
        644,
        629
    ],
    "u1f938_1f3fe_200d_2640_fe0f":[
        644,
        645,
        644,
        644,
        644,
        645,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        644,
        2
    ],
    "u1f938_1f3fe_200d_2642_fe0f":[
        644,
        644,
        644,
        644,
        645,
        10,
        222,
        12,
        222,
        10,
        222,
        644,
        12
    ],
    "u1f938_1f3ff":[
        647,
        647,
        647,
        647,
        679,
        675,
        222,
        629,
        222,
        675,
        222,
        647,
        629
    ],
    "u1f938_1f3ff_200d_2640_fe0f":[
        647,
        679,
        647,
        647,
        647,
        679,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        647,
        2
    ],
    "u1f938_1f3ff_200d_2642_fe0f":[
        647,
        647,
        647,
        647,
        679,
        10,
        222,
        12,
        222,
        10,
        222,
        647,
        12
    ],
    "u1f938_200d_2640_fe0f":[
        649,
        4,
        649,
        649,
        649,
        4,
        1,
        222,
        2,
        222,
        1,
        222,
        9,
        649,
        2
    ],
    "u1f938_200d_2642_fe0f":[
        649,
        649,
        649,
        649,
        4,
        10,
        222,
        12,
        222,
        10,
        222,
        649,
        12
    ],
    "u1f939_1f3fb":[
        629,
        639,
        697,
        58,
        639,
        194,
        8,
        641,
        189,
        1,
        12,
        697,
        639,
        675
    ],
    "u1f939_1f3fb_200d_2640_fe0f":[
        58,
        6,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_1f3fb_200d_2642_fe0f":[
        197,
        639,
        697,
        639,
        194,
        58,
        8,
        641,
        189,
        1,
        12
    ],
    "u1f939_1f3fc":[
        629,
        642,
        702,
        677,
        642,
        194,
        8,
        641,
        189,
        1,
        12,
        702,
        642,
        675
    ],
    "u1f939_1f3fc_200d_2640_fe0f":[
        677,
        6,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_1f3fc_200d_2642_fe0f":[
        197,
        642,
        702,
        642,
        194,
        677,
        8,
        641,
        189,
        1,
        12
    ],
    "u1f939_1f3fd":[
        629,
        672,
        703,
        678,
        672,
        194,
        8,
        641,
        189,
        1,
        12,
        703,
        672,
        675
    ],
    "u1f939_1f3fd_200d_2640_fe0f":[
        6,
        678,
        6,
        643,
        703,
        643,
        760,
        678,
        8,
        194,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_1f3fd_200d_2642_fe0f":[
        197,
        643,
        703,
        643,
        194,
        678,
        8,
        641,
        189,
        1,
        12
    ],
    "u1f939_1f3fe":[
        629,
        644,
        704,
        645,
        644,
        646,
        8,
        641,
        189,
        1,
        12,
        704,
        644,
        675
    ],
    "u1f939_1f3fe_200d_2640_fe0f":[
        645,
        6,
        644,
        704,
        644,
        760,
        645,
        645,
        646,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_1f3fe_200d_2642_fe0f":[
        197,
        644,
        704,
        644,
        646,
        645,
        645,
        641,
        189,
        1,
        12,
        197,
        704,
        644,
        189
    ],
    "u1f939_1f3ff":[
        629,
        647,
        705,
        679,
        647,
        648,
        52,
        641,
        189,
        1,
        12,
        705,
        647,
        675
    ],
    "u1f939_1f3ff_200d_2640_fe0f":[
        679,
        6,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_1f3ff_200d_2642_fe0f":[
        197,
        647,
        705,
        647,
        648,
        679,
        52,
        641,
        189,
        1,
        12
    ],
    "u1f939_200d_2640_fe0f":[
        4,
        6,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        641,
        189,
        1,
        12,
        576
    ],
    "u1f939_200d_2642_fe0f":[
        197,
        649,
        706,
        649,
        194,
        4,
        8,
        641,
        189,
        1,
        12
    ],
    "u1f93c_200d_2640_fe0f":[
        5,
        706,
        706,
        706,
        5,
        649,
        6,
        649,
        6,
        4,
        4,
        9,
        2,
        649,
        706,
        649,
        9,
        649,
        649,
        649,
        4,
        4
    ],
    "u1f93c_200d_2642_fe0f":[
        579,
        706,
        706,
        706,
        579,
        649,
        197,
        649,
        197,
        4,
        698,
        674,
        674,
        649,
        706,
        649,
        698,
        649,
        649,
        649,
        4
    ],
    "u1f93d_1f3fb":[
        639,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93d_1f3fb_200d_2640_fe0f":[
        639,
        2,
        3,
        872,
        873
    ],
    "u1f93d_1f3fb_200d_2642_fe0f":[
        639,
        674,
        3,
        872,
        873
    ],
    "u1f93d_1f3fc":[
        642,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93d_1f3fc_200d_2640_fe0f":[
        642,
        2,
        3,
        872,
        873
    ],
    "u1f93d_1f3fc_200d_2642_fe0f":[
        642,
        674,
        3,
        872,
        873
    ],
    "u1f93d_1f3fd":[
        672,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93d_1f3fd_200d_2640_fe0f":[
        643,
        2,
        3,
        872,
        873
    ],
    "u1f93d_1f3fd_200d_2642_fe0f":[
        643,
        674,
        3,
        872,
        873
    ],
    "u1f93d_1f3fe":[
        644,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93d_1f3fe_200d_2640_fe0f":[
        644,
        2,
        3,
        872,
        873
    ],
    "u1f93d_1f3fe_200d_2642_fe0f":[
        644,
        674,
        3,
        872,
        873
    ],
    "u1f93d_1f3ff":[
        647,
        629,
        675,
        3,
        872,
        873
    ],
    "u1f93d_1f3ff_200d_2640_fe0f":[
        647,
        2,
        3,
        872,
        873
    ],
    "u1f93d_1f3ff_200d_2642_fe0f":[
        647,
        674,
        3,
        872,
        873
    ],
    "u1f93d_200d_2640_fe0f":[
        649,
        2,
        3,
        872,
        873
    ],
    "u1f93d_200d_2642_fe0f":[
        649,
        674,
        3,
        872,
        873
    ],
    "u1f93e_1f3fb":[
        675,
        676,
        222,
        639,
        675,
        675,
        639,
        639,
        58,
        629,
        10,
        639
    ],
    "u1f93e_1f3fb_200d_2640_fe0f":[
        2,
        9,
        222,
        639,
        639,
        58,
        9,
        9,
        2,
        10,
        639
    ],
    "u1f93e_1f3fb_200d_2642_fe0f":[
        662,
        661,
        222,
        639,
        639,
        58,
        662,
        662,
        674,
        10,
        639
    ],
    "u1f93e_1f3fc":[
        675,
        676,
        222,
        642,
        675,
        675,
        642,
        642,
        677,
        629,
        10,
        642
    ],
    "u1f93e_1f3fc_200d_2640_fe0f":[
        2,
        9,
        222,
        642,
        642,
        677,
        9,
        9,
        2,
        10,
        642
    ],
    "u1f93e_1f3fc_200d_2642_fe0f":[
        662,
        661,
        222,
        642,
        642,
        677,
        662,
        662,
        674,
        10,
        642
    ],
    "u1f93e_1f3fd":[
        675,
        676,
        222,
        672,
        675,
        675,
        672,
        672,
        678,
        629,
        10,
        672
    ],
    "u1f93e_1f3fd_200d_2640_fe0f":[
        2,
        9,
        222,
        672,
        672,
        678,
        9,
        9,
        2,
        10,
        672
    ],
    "u1f93e_1f3fd_200d_2642_fe0f":[
        662,
        661,
        222,
        672,
        672,
        678,
        662,
        662,
        674,
        10,
        672
    ],
    "u1f93e_1f3fe":[
        675,
        676,
        222,
        644,
        675,
        675,
        644,
        644,
        645,
        629,
        10,
        644
    ],
    "u1f93e_1f3fe_200d_2640_fe0f":[
        2,
        9,
        222,
        644,
        644,
        645,
        9,
        9,
        2,
        10,
        644
    ],
    "u1f93e_1f3fe_200d_2642_fe0f":[
        662,
        661,
        222,
        644,
        644,
        645,
        662,
        662,
        674,
        10,
        644
    ],
    "u1f93e_1f3ff":[
        675,
        676,
        222,
        647,
        675,
        675,
        647,
        647,
        679,
        629,
        10,
        647
    ],
    "u1f93e_1f3ff_200d_2640_fe0f":[
        2,
        9,
        222,
        647,
        647,
        679,
        9,
        9,
        2,
        10,
        647
    ],
    "u1f93e_1f3ff_200d_2642_fe0f":[
        662,
        661,
        222,
        647,
        647,
        679,
        662,
        662,
        674,
        10,
        647
    ],
    "u1f93e_200d_2640_fe0f":[
        2,
        9,
        222,
        649,
        649,
        4,
        9,
        9,
        2,
        10,
        649
    ],
    "u1f93e_200d_2642_fe0f":[
        662,
        661,
        222,
        649,
        649,
        4,
        662,
        662,
        674,
        10,
        649
    ],
    "u1f977_1f3fb":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        639,
        8
    ],
    "u1f977_1f3fc":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        642,
        8
    ],
    "u1f977_1f3fd":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        672,
        8
    ],
    "u1f977_1f3fe":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        644,
        8
    ],
    "u1f977_1f3ff":[
        907,
        67,
        52,
        58,
        52,
        58,
        58,
        58,
        58,
        52,
        58,
        67,
        58,
        67,
        647,
        52
    ],
    "u1f9b5_1f3fb":[
        639,
        684
    ],
    "u1f9b5_1f3fc":[
        642,
        686
    ],
    "u1f9b5_1f3fd":[
        643,
        688
    ],
    "u1f9b5_1f3fe":[
        644,
        690
    ],
    "u1f9b5_1f3ff":[
        647,
        692
    ],
    "u1f9b6_1f3fb":[
        639,
        684
    ],
    "u1f9b6_1f3fc":[
        642,
        686
    ],
    "u1f9b6_1f3fd":[
        643,
        688
    ],
    "u1f9b6_1f3fe":[
        644,
        690
    ],
    "u1f9b6_1f3ff":[
        647,
        692
    ],
    "u1f9b8_1f3fb":[
        9,
        9,
        3,
        639,
        697,
        58,
        639,
        194,
        10,
        125,
        194,
        2,
        2
    ],
    "u1f9b8_1f3fb_200d_2640_fe0f":[
        58,
        9,
        3,
        2,
        639,
        2,
        697,
        639,
        58,
        10,
        125,
        760,
        194
    ],
    "u1f9b8_1f3fb_200d_2642_fe0f":[
        9,
        3,
        2,
        639,
        2,
        697,
        639,
        58,
        10,
        125,
        194
    ],
    "u1f9b8_1f3fc":[
        9,
        9,
        3,
        642,
        702,
        677,
        642,
        194,
        10,
        125,
        194,
        2,
        2
    ],
    "u1f9b8_1f3fc_200d_2640_fe0f":[
        677,
        9,
        3,
        2,
        642,
        2,
        702,
        642,
        677,
        10,
        125,
        760,
        194
    ],
    "u1f9b8_1f3fc_200d_2642_fe0f":[
        9,
        3,
        2,
        642,
        2,
        702,
        642,
        677,
        10,
        125,
        194
    ],
    "u1f9b8_1f3fd":[
        9,
        9,
        3,
        672,
        703,
        678,
        672,
        194,
        10,
        125,
        194,
        2,
        2
    ],
    "u1f9b8_1f3fd_200d_2640_fe0f":[
        678,
        9,
        3,
        2,
        672,
        2,
        703,
        672,
        678,
        10,
        125,
        760,
        194
    ],
    "u1f9b8_1f3fd_200d_2642_fe0f":[
        9,
        3,
        2,
        672,
        2,
        703,
        672,
        678,
        10,
        125,
        194
    ],
    "u1f9b8_1f3fe":[
        9,
        9,
        3,
        644,
        704,
        645,
        644,
        646,
        10,
        125,
        646,
        2,
        2
    ],
    "u1f9b8_1f3fe_200d_2640_fe0f":[
        645,
        9,
        3,
        2,
        644,
        2,
        704,
        644,
        645,
        10,
        125,
        760,
        646
    ],
    "u1f9b8_1f3fe_200d_2642_fe0f":[
        9,
        3,
        2,
        644,
        2,
        704,
        644,
        645,
        10,
        125,
        646
    ],
    "u1f9b8_1f3ff":[
        9,
        9,
        3,
        647,
        705,
        679,
        647,
        648,
        10,
        125,
        648,
        2,
        2
    ],
    "u1f9b8_1f3ff_200d_2640_fe0f":[
        679,
        9,
        3,
        2,
        647,
        2,
        705,
        647,
        679,
        10,
        125,
        760,
        648
    ],
    "u1f9b8_1f3ff_200d_2642_fe0f":[
        9,
        3,
        2,
        647,
        2,
        705,
        647,
        679,
        10,
        125,
        648
    ],
    "u1f9b8_200d_2640_fe0f":[
        4,
        9,
        3,
        2,
        649,
        2,
        706,
        649,
        4,
        10,
        125,
        760,
        194
    ],
    "u1f9b8_200d_2642_fe0f":[
        9,
        3,
        2,
        649,
        2,
        706,
        649,
        4,
        10,
        125,
        194
    ],
    "u1f9b9_1f3fb":[
        58,
        196,
        639,
        697,
        639,
        58,
        639,
        1,
        196,
        52,
        194
    ],
    "u1f9b9_1f3fb_200d_2640_fe0f":[
        58,
        196,
        196,
        639,
        697,
        639,
        58,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fb_200d_2642_fe0f":[
        58,
        196,
        196,
        639,
        697,
        639,
        58,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fc":[
        58,
        196,
        642,
        702,
        642,
        677,
        642,
        1,
        196,
        52,
        194
    ],
    "u1f9b9_1f3fc_200d_2640_fe0f":[
        58,
        196,
        196,
        677,
        642,
        702,
        642,
        677,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fc_200d_2642_fe0f":[
        58,
        196,
        196,
        642,
        702,
        642,
        677,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fd":[
        58,
        196,
        672,
        703,
        672,
        678,
        672,
        1,
        196,
        52,
        194
    ],
    "u1f9b9_1f3fd_200d_2640_fe0f":[
        58,
        196,
        196,
        678,
        672,
        703,
        672,
        678,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fd_200d_2642_fe0f":[
        58,
        196,
        196,
        672,
        703,
        672,
        678,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_1f3fe":[
        58,
        196,
        644,
        704,
        644,
        645,
        644,
        1,
        196,
        52,
        646
    ],
    "u1f9b9_1f3fe_200d_2640_fe0f":[
        58,
        196,
        196,
        645,
        644,
        704,
        644,
        645,
        1,
        52,
        646,
        196
    ],
    "u1f9b9_1f3fe_200d_2642_fe0f":[
        58,
        196,
        196,
        644,
        704,
        644,
        645,
        1,
        52,
        646,
        196
    ],
    "u1f9b9_1f3ff":[
        58,
        196,
        647,
        705,
        647,
        679,
        647,
        1,
        196,
        52,
        648
    ],
    "u1f9b9_1f3ff_200d_2640_fe0f":[
        58,
        196,
        196,
        679,
        647,
        705,
        647,
        679,
        1,
        52,
        648,
        196
    ],
    "u1f9b9_1f3ff_200d_2642_fe0f":[
        58,
        196,
        196,
        647,
        705,
        647,
        679,
        1,
        52,
        648,
        196
    ],
    "u1f9b9_200d_2640_fe0f":[
        58,
        196,
        196,
        4,
        649,
        706,
        649,
        4,
        1,
        52,
        194,
        196
    ],
    "u1f9b9_200d_2642_fe0f":[
        58,
        196,
        196,
        649,
        706,
        649,
        4,
        1,
        52,
        194,
        196
    ],
    "u1f9bb_1f3fb":[
        67,
        196,
        639,
        684,
        67,
        196,
        222
    ],
    "u1f9bb_1f3fc":[
        67,
        196,
        642,
        686,
        67,
        196,
        222
    ],
    "u1f9bb_1f3fd":[
        67,
        196,
        672,
        688,
        67,
        196,
        222
    ],
    "u1f9bb_1f3fe":[
        67,
        196,
        644,
        690,
        67,
        196,
        222
    ],
    "u1f9bb_1f3ff":[
        67,
        196,
        647,
        692,
        67,
        196,
        222
    ],
    "u1f9cd_1f3fb":[
        639,
        674,
        629,
        58,
        639,
        697,
        639,
        8,
        194
    ],
    "u1f9cd_1f3fb_200d_2640_fe0f":[
        639,
        674,
        58,
        784,
        639,
        697,
        58,
        639,
        58,
        58,
        8,
        760,
        194
    ],
    "u1f9cd_1f3fb_200d_2642_fe0f":[
        639,
        674,
        197,
        639,
        697,
        58,
        639,
        58,
        8,
        194
    ],
    "u1f9cd_1f3fc":[
        642,
        674,
        629,
        677,
        642,
        702,
        642,
        8,
        194,
        58
    ],
    "u1f9cd_1f3fc_200d_2640_fe0f":[
        642,
        674,
        677,
        784,
        642,
        702,
        677,
        642,
        677,
        677,
        8,
        760,
        194,
        58
    ],
    "u1f9cd_1f3fc_200d_2642_fe0f":[
        642,
        674,
        197,
        642,
        702,
        677,
        642,
        677,
        8,
        194,
        58
    ],
    "u1f9cd_1f3fd":[
        672,
        674,
        629,
        678,
        672,
        703,
        672,
        8,
        194,
        58
    ],
    "u1f9cd_1f3fd_200d_2640_fe0f":[
        672,
        674,
        678,
        784,
        672,
        703,
        678,
        672,
        678,
        678,
        8,
        760,
        194,
        58
    ],
    "u1f9cd_1f3fd_200d_2642_fe0f":[
        672,
        674,
        197,
        672,
        703,
        678,
        672,
        678,
        8,
        194,
        58
    ],
    "u1f9cd_1f3fe":[
        644,
        674,
        629,
        645,
        644,
        704,
        644,
        8,
        646,
        58
    ],
    "u1f9cd_1f3fe_200d_2640_fe0f":[
        644,
        674,
        645,
        784,
        644,
        704,
        645,
        644,
        645,
        645,
        8,
        760,
        646,
        58
    ],
    "u1f9cd_1f3fe_200d_2642_fe0f":[
        644,
        674,
        197,
        644,
        704,
        645,
        644,
        645,
        8,
        646,
        58
    ],
    "u1f9cd_1f3ff":[
        647,
        674,
        629,
        679,
        647,
        705,
        647,
        52,
        648,
        58
    ],
    "u1f9cd_1f3ff_200d_2640_fe0f":[
        647,
        674,
        679,
        784,
        647,
        705,
        679,
        647,
        679,
        679,
        52,
        760,
        648,
        58
    ],
    "u1f9cd_1f3ff_200d_2642_fe0f":[
        647,
        674,
        197,
        647,
        705,
        679,
        647,
        679,
        52,
        648,
        58
    ],
    "u1f9cd_200d_2640_fe0f":[
        649,
        674,
        4,
        784,
        649,
        706,
        4,
        649,
        4,
        4,
        8,
        760,
        194,
        58
    ],
    "u1f9cd_200d_2642_fe0f":[
        649,
        674,
        197,
        649,
        706,
        4,
        649,
        4,
        8,
        194,
        58
    ],
    "u1f9ce_1f3fb":[
        639,
        639,
        58,
        629,
        698,
        674,
        639
    ],
    "u1f9ce_1f3fb_200d_2640_fe0f":[
        639,
        639,
        58,
        58,
        784,
        698,
        674,
        639
    ],
    "u1f9ce_1f3fb_200d_2642_fe0f":[
        639,
        639,
        58,
        197,
        698,
        674,
        639
    ],
    "u1f9ce_1f3fc":[
        642,
        642,
        677,
        629,
        58,
        698,
        674,
        642
    ],
    "u1f9ce_1f3fc_200d_2640_fe0f":[
        642,
        642,
        677,
        677,
        784,
        58,
        698,
        674,
        642
    ],
    "u1f9ce_1f3fc_200d_2642_fe0f":[
        642,
        642,
        677,
        197,
        58,
        698,
        674,
        642
    ],
    "u1f9ce_1f3fd":[
        672,
        672,
        678,
        629,
        58,
        698,
        674,
        672
    ],
    "u1f9ce_1f3fd_200d_2640_fe0f":[
        672,
        672,
        678,
        678,
        784,
        58,
        698,
        674,
        672
    ],
    "u1f9ce_1f3fd_200d_2642_fe0f":[
        672,
        672,
        678,
        197,
        58,
        698,
        674,
        672
    ],
    "u1f9ce_1f3fe":[
        644,
        644,
        645,
        629,
        58,
        698,
        674,
        644
    ],
    "u1f9ce_1f3fe_200d_2640_fe0f":[
        644,
        644,
        645,
        645,
        784,
        58,
        698,
        674,
        644
    ],
    "u1f9ce_1f3fe_200d_2642_fe0f":[
        644,
        644,
        645,
        197,
        58,
        698,
        674,
        644
    ],
    "u1f9ce_1f3ff":[
        647,
        647,
        679,
        629,
        58,
        698,
        674,
        647
    ],
    "u1f9ce_1f3ff_200d_2640_fe0f":[
        647,
        647,
        679,
        679,
        784,
        58,
        698,
        674,
        647
    ],
    "u1f9ce_1f3ff_200d_2642_fe0f":[
        647,
        647,
        679,
        197,
        58,
        698,
        674,
        647
    ],
    "u1f9ce_200d_2640_fe0f":[
        649,
        649,
        4,
        4,
        784,
        58,
        698,
        674,
        649
    ],
    "u1f9ce_200d_2642_fe0f":[
        649,
        649,
        4,
        197,
        58,
        698,
        674,
        649
    ],
    "u1f9cf_1f3fb":[
        629,
        58,
        639,
        697,
        639,
        194,
        8,
        675,
        629,
        639,
        593
    ],
    "u1f9cf_1f3fb_200d_2640_fe0f":[
        784,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        785,
        784,
        639,
        593
    ],
    "u1f9cf_1f3fb_200d_2642_fe0f":[
        674,
        639,
        697,
        639,
        194,
        58,
        8,
        698,
        674,
        639,
        593
    ],
    "u1f9cf_1f3fc":[
        629,
        677,
        642,
        702,
        642,
        194,
        8,
        675,
        629,
        642,
        593
    ],
    "u1f9cf_1f3fc_200d_2640_fe0f":[
        784,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        785,
        784,
        642,
        593
    ],
    "u1f9cf_1f3fc_200d_2642_fe0f":[
        674,
        642,
        702,
        642,
        194,
        677,
        8,
        698,
        674,
        642,
        593
    ],
    "u1f9cf_1f3fd":[
        629,
        678,
        672,
        703,
        672,
        194,
        8,
        675,
        629,
        672,
        593
    ],
    "u1f9cf_1f3fd_200d_2640_fe0f":[
        784,
        678,
        672,
        703,
        672,
        760,
        678,
        8,
        194,
        785,
        784,
        672,
        593
    ],
    "u1f9cf_1f3fd_200d_2642_fe0f":[
        674,
        672,
        703,
        672,
        194,
        678,
        8,
        698,
        674,
        672,
        593
    ],
    "u1f9cf_1f3fe":[
        629,
        645,
        644,
        704,
        644,
        646,
        8,
        675,
        629,
        644,
        593
    ],
    "u1f9cf_1f3fe_200d_2640_fe0f":[
        784,
        645,
        644,
        704,
        644,
        760,
        645,
        8,
        646,
        785,
        784,
        644,
        593
    ],
    "u1f9cf_1f3fe_200d_2642_fe0f":[
        674,
        644,
        704,
        644,
        646,
        645,
        8,
        698,
        674,
        644,
        593
    ],
    "u1f9cf_1f3ff":[
        629,
        679,
        647,
        705,
        647,
        648,
        52,
        675,
        629,
        647,
        593
    ],
    "u1f9cf_1f3ff_200d_2640_fe0f":[
        784,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        785,
        784,
        647,
        593
    ],
    "u1f9cf_1f3ff_200d_2642_fe0f":[
        674,
        647,
        705,
        647,
        648,
        679,
        52,
        698,
        674,
        647,
        593
    ],
    "u1f9cf_200d_2640_fe0f":[
        784,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        785,
        784,
        649,
        593
    ],
    "u1f9cf_200d_2642_fe0f":[
        674,
        649,
        706,
        649,
        194,
        4,
        8,
        698,
        674,
        649,
        593
    ],
    "u1f9d1_1f3fb":[
        58,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f33e":[
        197,
        579,
        639,
        697,
        58,
        639,
        194,
        8,
        12,
        12,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_1f3fb_200d_1f373":[
        79,
        10,
        639,
        697,
        58,
        639,
        194,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_1f3fb_200d_1f37c":[
        629,
        639,
        697,
        58,
        639,
        194,
        8,
        2,
        79,
        125,
        3,
        220,
        639,
        629,
        639,
        629
    ],
    "u1f9d1_1f3fb_200d_1f384":[
        2,
        641,
        639,
        194,
        8,
        612
    ],
    "u1f9d1_1f3fb_200d_1f393":[
        58,
        67,
        629,
        675,
        639,
        697,
        58,
        639,
        194,
        8,
        58,
        67,
        195
    ],
    "u1f9d1_1f3fb_200d_1f3a4":[
        639,
        58,
        963,
        963,
        964,
        965,
        965,
        639,
        697,
        966,
        639,
        424,
        194,
        8,
        767,
        768
    ],
    "u1f9d1_1f3fb_200d_1f3a8":[
        629,
        697,
        58,
        639,
        194,
        8,
        67,
        596,
        189,
        10,
        2,
        195,
        639
    ],
    "u1f9d1_1f3fb_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        639,
        697,
        58,
        639,
        194,
        8,
        478
    ],
    "u1f9d1_1f3fb_200d_1f3ed":[
        12,
        58,
        639,
        3,
        697,
        58,
        639,
        194,
        8,
        697,
        697,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_1f3fb_200d_1f4bb":[
        629,
        639,
        697,
        58,
        639,
        194,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_1f3fb_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        639,
        697,
        58,
        639,
        194,
        8,
        769
    ],
    "u1f9d1_1f3fb_200d_1f527":[
        222,
        639,
        697,
        58,
        639,
        194,
        8,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_1f3fb_200d_1f52c":[
        222,
        639,
        697,
        58,
        639,
        194,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_1f3fb_200d_1f680":[
        79,
        214,
        1,
        58,
        639,
        697,
        58,
        639,
        194,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_1f3fb_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        639,
        697,
        58,
        639,
        194,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_1f3fb_200d_1f91d_200d_1f9d1_1f3fb":[
        639,
        189,
        58,
        196,
        639,
        697,
        639,
        194,
        8,
        674,
        58,
        629,
        639,
        697,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f91d_200d_1f9d1_1f3fc":[
        639,
        189,
        58,
        196,
        639,
        697,
        639,
        194,
        8,
        642,
        674,
        58,
        629,
        677,
        642,
        702,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f91d_200d_1f9d1_1f3fd":[
        639,
        189,
        58,
        196,
        639,
        697,
        639,
        194,
        8,
        672,
        674,
        58,
        629,
        678,
        672,
        703,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f91d_200d_1f9d1_1f3fe":[
        639,
        189,
        58,
        196,
        639,
        697,
        639,
        194,
        8,
        644,
        674,
        58,
        629,
        645,
        644,
        704,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fb_200d_1f91d_200d_1f9d1_1f3ff":[
        639,
        189,
        58,
        196,
        639,
        697,
        639,
        194,
        8,
        647,
        674,
        58,
        629,
        679,
        647,
        705,
        647,
        648,
        52
    ],
    "u1f9d1_1f3fb_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        639,
        639,
        58,
        698,
        674,
        629,
        639,
        58
    ],
    "u1f9d1_1f3fb_200d_1f9b0":[
        588,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f9b1":[
        639,
        58,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f9b2":[
        639,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f9b3":[
        79,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fb_200d_1f9bc":[
        58,
        58,
        674,
        674,
        639,
        639,
        58,
        629,
        67,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        639,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_1f3fb_200d_1f9bd":[
        58,
        58,
        674,
        674,
        639,
        639,
        58,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        639
    ],
    "u1f9d1_1f3fb_200d_2695_fe0f":[
        12,
        639,
        697,
        58,
        639,
        194,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_1f3fb_200d_2696_fe0f":[
        58,
        79,
        639,
        697,
        721,
        639,
        194,
        8,
        196,
        478,
        58,
        67,
        194,
        639
    ],
    "u1f9d1_1f3fb_200d_2708_fe0f":[
        79,
        58,
        639,
        697,
        58,
        639,
        194,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fc":[
        639,
        8,
        194,
        58,
        642,
        8,
        194,
        677,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fd":[
        639,
        8,
        194,
        58,
        672,
        8,
        194,
        678,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fe":[
        639,
        8,
        194,
        58,
        644,
        8,
        646,
        645,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3ff":[
        639,
        8,
        194,
        58,
        647,
        52,
        648,
        679,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f9d1_1f3fc":[
        58,
        196,
        67,
        639,
        194,
        8,
        677,
        629,
        675,
        642,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f9d1_1f3fd":[
        58,
        196,
        67,
        639,
        194,
        8,
        678,
        629,
        675,
        672,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f9d1_1f3fe":[
        58,
        196,
        67,
        639,
        194,
        8,
        645,
        629,
        675,
        644,
        646,
        8,
        2
    ],
    "u1f9d1_1f3fb_200d_2764_fe0f_200d_1f9d1_1f3ff":[
        58,
        196,
        67,
        639,
        194,
        8,
        679,
        629,
        675,
        647,
        648,
        52,
        2
    ],
    "u1f9d1_1f3fc":[
        677,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f33e":[
        197,
        579,
        642,
        702,
        677,
        642,
        194,
        8,
        12,
        12,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_1f3fc_200d_1f373":[
        79,
        10,
        642,
        702,
        677,
        642,
        194,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_1f3fc_200d_1f37c":[
        629,
        642,
        702,
        677,
        642,
        194,
        8,
        2,
        79,
        125,
        3,
        220,
        642,
        629,
        642,
        629
    ],
    "u1f9d1_1f3fc_200d_1f384":[
        2,
        641,
        642,
        194,
        8,
        612
    ],
    "u1f9d1_1f3fc_200d_1f393":[
        58,
        67,
        629,
        675,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        67,
        195
    ],
    "u1f9d1_1f3fc_200d_1f3a4":[
        642,
        58,
        963,
        963,
        964,
        965,
        965,
        642,
        702,
        966,
        642,
        424,
        194,
        8,
        767,
        768
    ],
    "u1f9d1_1f3fc_200d_1f3a8":[
        629,
        702,
        677,
        642,
        194,
        8,
        67,
        596,
        189,
        10,
        2,
        195,
        642
    ],
    "u1f9d1_1f3fc_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        642,
        702,
        677,
        642,
        194,
        8,
        478
    ],
    "u1f9d1_1f3fc_200d_1f3ed":[
        12,
        58,
        642,
        3,
        702,
        677,
        642,
        194,
        8,
        702,
        702,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_1f3fc_200d_1f4bb":[
        629,
        642,
        702,
        677,
        642,
        194,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_1f3fc_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        642,
        702,
        677,
        642,
        194,
        8,
        769
    ],
    "u1f9d1_1f3fc_200d_1f527":[
        222,
        642,
        702,
        677,
        642,
        194,
        8,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_1f3fc_200d_1f52c":[
        222,
        642,
        702,
        677,
        642,
        194,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_1f3fc_200d_1f680":[
        79,
        214,
        1,
        58,
        642,
        702,
        677,
        642,
        194,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_1f3fc_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_1f3fc_200d_1f91d_200d_1f9d1_1f3fb":[
        642,
        189,
        58,
        196,
        677,
        642,
        702,
        642,
        194,
        8,
        639,
        674,
        58,
        629,
        639,
        697,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f91d_200d_1f9d1_1f3fc":[
        642,
        189,
        58,
        196,
        677,
        642,
        702,
        642,
        194,
        8,
        674,
        58,
        629,
        642,
        702,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f91d_200d_1f9d1_1f3fd":[
        642,
        189,
        58,
        196,
        677,
        642,
        702,
        642,
        194,
        8,
        672,
        674,
        58,
        629,
        678,
        672,
        703,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f91d_200d_1f9d1_1f3fe":[
        642,
        189,
        58,
        196,
        677,
        642,
        702,
        642,
        194,
        8,
        644,
        674,
        58,
        629,
        645,
        644,
        704,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fc_200d_1f91d_200d_1f9d1_1f3ff":[
        642,
        189,
        58,
        196,
        677,
        642,
        702,
        642,
        194,
        8,
        647,
        674,
        58,
        629,
        679,
        647,
        705,
        647,
        648,
        52
    ],
    "u1f9d1_1f3fc_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        642,
        642,
        677,
        698,
        674,
        629,
        642,
        58
    ],
    "u1f9d1_1f3fc_200d_1f9b0":[
        588,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f9b1":[
        642,
        677,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f9b2":[
        642,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f9b3":[
        79,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fc_200d_1f9bc":[
        58,
        58,
        674,
        674,
        642,
        642,
        677,
        629,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        642,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_1f3fc_200d_1f9bd":[
        58,
        58,
        674,
        674,
        642,
        642,
        677,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        642
    ],
    "u1f9d1_1f3fc_200d_2695_fe0f":[
        12,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_1f3fc_200d_2696_fe0f":[
        58,
        79,
        642,
        702,
        721,
        642,
        194,
        8,
        196,
        478,
        58,
        67,
        194,
        642
    ],
    "u1f9d1_1f3fc_200d_2708_fe0f":[
        79,
        58,
        642,
        702,
        677,
        642,
        194,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fb":[
        642,
        8,
        194,
        677,
        639,
        8,
        194,
        58,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fd":[
        642,
        8,
        194,
        677,
        672,
        8,
        194,
        678,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fe":[
        642,
        8,
        194,
        677,
        644,
        8,
        646,
        645,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3ff":[
        642,
        8,
        194,
        677,
        647,
        52,
        648,
        679,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f9d1_1f3fb":[
        677,
        196,
        67,
        642,
        194,
        8,
        58,
        629,
        675,
        639,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f9d1_1f3fd":[
        677,
        196,
        67,
        642,
        194,
        8,
        678,
        629,
        675,
        672,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f9d1_1f3fe":[
        677,
        196,
        67,
        642,
        194,
        8,
        645,
        629,
        675,
        644,
        646,
        8,
        2
    ],
    "u1f9d1_1f3fc_200d_2764_fe0f_200d_1f9d1_1f3ff":[
        677,
        196,
        67,
        642,
        194,
        8,
        679,
        629,
        675,
        647,
        648,
        52,
        2
    ],
    "u1f9d1_1f3fd":[
        678,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f33e":[
        197,
        579,
        672,
        703,
        678,
        672,
        194,
        8,
        12,
        12,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_1f3fd_200d_1f373":[
        79,
        10,
        672,
        703,
        678,
        672,
        194,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_1f3fd_200d_1f37c":[
        629,
        672,
        703,
        678,
        672,
        194,
        8,
        2,
        79,
        125,
        3,
        220,
        672,
        629,
        672,
        629
    ],
    "u1f9d1_1f3fd_200d_1f384":[
        2,
        641,
        672,
        194,
        8,
        612
    ],
    "u1f9d1_1f3fd_200d_1f393":[
        58,
        67,
        629,
        675,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        67,
        195
    ],
    "u1f9d1_1f3fd_200d_1f3a4":[
        672,
        58,
        963,
        963,
        964,
        965,
        965,
        672,
        703,
        966,
        672,
        424,
        194,
        8,
        767,
        768
    ],
    "u1f9d1_1f3fd_200d_1f3a8":[
        629,
        703,
        678,
        672,
        194,
        8,
        67,
        596,
        189,
        10,
        2,
        195,
        672
    ],
    "u1f9d1_1f3fd_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        672,
        703,
        678,
        672,
        194,
        8,
        478
    ],
    "u1f9d1_1f3fd_200d_1f3ed":[
        12,
        58,
        672,
        3,
        703,
        678,
        672,
        194,
        8,
        703,
        703,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_1f3fd_200d_1f4bb":[
        629,
        672,
        703,
        678,
        672,
        194,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_1f3fd_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        672,
        703,
        678,
        672,
        194,
        8,
        769
    ],
    "u1f9d1_1f3fd_200d_1f527":[
        222,
        672,
        703,
        678,
        672,
        194,
        8,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_1f3fd_200d_1f52c":[
        222,
        672,
        703,
        678,
        672,
        194,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_1f3fd_200d_1f680":[
        79,
        214,
        1,
        58,
        672,
        703,
        678,
        672,
        194,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_1f3fd_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_1f3fd_200d_1f91d_200d_1f9d1_1f3fb":[
        672,
        189,
        58,
        196,
        678,
        672,
        703,
        672,
        194,
        8,
        639,
        674,
        58,
        629,
        639,
        697,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f91d_200d_1f9d1_1f3fc":[
        672,
        189,
        58,
        196,
        678,
        672,
        703,
        672,
        194,
        8,
        642,
        674,
        58,
        629,
        677,
        642,
        702,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f91d_200d_1f9d1_1f3fd":[
        672,
        189,
        58,
        196,
        678,
        672,
        703,
        672,
        194,
        8,
        674,
        58,
        629,
        672,
        703,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f91d_200d_1f9d1_1f3fe":[
        672,
        189,
        58,
        196,
        678,
        672,
        703,
        672,
        194,
        8,
        644,
        674,
        58,
        629,
        645,
        644,
        704,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fd_200d_1f91d_200d_1f9d1_1f3ff":[
        672,
        189,
        58,
        196,
        678,
        672,
        703,
        672,
        194,
        8,
        647,
        674,
        58,
        629,
        679,
        647,
        705,
        647,
        648,
        52
    ],
    "u1f9d1_1f3fd_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        672,
        672,
        678,
        698,
        674,
        629,
        672,
        58
    ],
    "u1f9d1_1f3fd_200d_1f9b0":[
        588,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f9b1":[
        672,
        678,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f9b2":[
        672,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f9b3":[
        79,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fd_200d_1f9bc":[
        58,
        58,
        674,
        674,
        672,
        672,
        678,
        629,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        672,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_1f3fd_200d_1f9bd":[
        58,
        58,
        674,
        674,
        672,
        672,
        678,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        672
    ],
    "u1f9d1_1f3fd_200d_2695_fe0f":[
        12,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_1f3fd_200d_2696_fe0f":[
        58,
        79,
        672,
        703,
        721,
        672,
        194,
        8,
        196,
        478,
        58,
        67,
        194,
        672
    ],
    "u1f9d1_1f3fd_200d_2708_fe0f":[
        79,
        58,
        672,
        703,
        678,
        672,
        194,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fb":[
        672,
        8,
        194,
        678,
        639,
        8,
        194,
        58,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fc":[
        672,
        8,
        194,
        678,
        642,
        8,
        194,
        677,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fe":[
        672,
        8,
        194,
        678,
        644,
        8,
        646,
        645,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3ff":[
        672,
        8,
        194,
        678,
        647,
        52,
        648,
        679,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f9d1_1f3fb":[
        678,
        196,
        67,
        672,
        194,
        8,
        58,
        629,
        675,
        639,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f9d1_1f3fc":[
        678,
        196,
        67,
        672,
        194,
        8,
        677,
        629,
        675,
        642,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f9d1_1f3fe":[
        678,
        196,
        67,
        672,
        194,
        8,
        645,
        629,
        675,
        644,
        646,
        8,
        2
    ],
    "u1f9d1_1f3fd_200d_2764_fe0f_200d_1f9d1_1f3ff":[
        678,
        196,
        67,
        672,
        194,
        8,
        679,
        629,
        675,
        647,
        648,
        52,
        2
    ],
    "u1f9d1_1f3fe":[
        645,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f33e":[
        197,
        579,
        644,
        704,
        645,
        644,
        646,
        8,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_1f3fe_200d_1f373":[
        79,
        10,
        644,
        704,
        645,
        644,
        646,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_1f3fe_200d_1f37c":[
        629,
        644,
        704,
        645,
        644,
        646,
        8,
        2,
        79,
        125,
        3,
        220,
        644,
        629,
        644,
        629
    ],
    "u1f9d1_1f3fe_200d_1f384":[
        2,
        641,
        644,
        646,
        8,
        612
    ],
    "u1f9d1_1f3fe_200d_1f393":[
        58,
        67,
        629,
        675,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        67,
        195
    ],
    "u1f9d1_1f3fe_200d_1f3a4":[
        644,
        58,
        963,
        963,
        964,
        965,
        965,
        644,
        704,
        966,
        644,
        424,
        646,
        8,
        767,
        768
    ],
    "u1f9d1_1f3fe_200d_1f3a8":[
        629,
        704,
        645,
        644,
        646,
        8,
        67,
        596,
        189,
        10,
        2,
        195,
        644
    ],
    "u1f9d1_1f3fe_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        644,
        704,
        645,
        644,
        646,
        8,
        478
    ],
    "u1f9d1_1f3fe_200d_1f3ed":[
        12,
        58,
        644,
        3,
        704,
        645,
        644,
        646,
        8,
        704,
        704,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_1f3fe_200d_1f4bb":[
        629,
        644,
        704,
        645,
        644,
        646,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_1f3fe_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        644,
        704,
        645,
        644,
        646,
        8,
        769
    ],
    "u1f9d1_1f3fe_200d_1f527":[
        222,
        644,
        704,
        645,
        644,
        646,
        8,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_1f3fe_200d_1f52c":[
        222,
        644,
        704,
        645,
        644,
        646,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_1f3fe_200d_1f680":[
        79,
        214,
        1,
        58,
        644,
        704,
        645,
        644,
        646,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_1f3fe_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_1f3fe_200d_1f91d_200d_1f9d1_1f3fb":[
        644,
        189,
        58,
        196,
        645,
        644,
        704,
        644,
        646,
        8,
        639,
        674,
        58,
        629,
        639,
        697,
        639,
        194,
        8
    ],
    "u1f9d1_1f3fe_200d_1f91d_200d_1f9d1_1f3fc":[
        644,
        189,
        58,
        196,
        645,
        644,
        704,
        644,
        646,
        8,
        642,
        674,
        58,
        629,
        677,
        642,
        702,
        642,
        194,
        8
    ],
    "u1f9d1_1f3fe_200d_1f91d_200d_1f9d1_1f3fd":[
        644,
        189,
        58,
        196,
        645,
        644,
        704,
        644,
        646,
        8,
        672,
        674,
        58,
        629,
        678,
        672,
        703,
        672,
        194,
        8
    ],
    "u1f9d1_1f3fe_200d_1f91d_200d_1f9d1_1f3fe":[
        644,
        189,
        58,
        196,
        645,
        644,
        704,
        644,
        646,
        8,
        674,
        58,
        629,
        644,
        704,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f91d_200d_1f9d1_1f3ff":[
        644,
        189,
        58,
        196,
        645,
        644,
        704,
        644,
        646,
        8,
        647,
        674,
        58,
        629,
        679,
        647,
        705,
        647,
        648,
        52
    ],
    "u1f9d1_1f3fe_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        644,
        644,
        645,
        698,
        674,
        629,
        644,
        58
    ],
    "u1f9d1_1f3fe_200d_1f9b0":[
        588,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f9b1":[
        644,
        645,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f9b2":[
        644,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f9b3":[
        79,
        644,
        646,
        8
    ],
    "u1f9d1_1f3fe_200d_1f9bc":[
        58,
        58,
        674,
        674,
        644,
        644,
        645,
        629,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        644,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_1f3fe_200d_1f9bd":[
        58,
        58,
        674,
        674,
        644,
        644,
        645,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        644
    ],
    "u1f9d1_1f3fe_200d_2695_fe0f":[
        12,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_1f3fe_200d_2696_fe0f":[
        58,
        79,
        644,
        704,
        721,
        644,
        646,
        8,
        196,
        478,
        58,
        67,
        194,
        8,
        194,
        644
    ],
    "u1f9d1_1f3fe_200d_2708_fe0f":[
        79,
        58,
        644,
        704,
        645,
        644,
        646,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fb":[
        644,
        8,
        646,
        645,
        639,
        8,
        194,
        58,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fc":[
        644,
        8,
        646,
        645,
        642,
        8,
        194,
        677,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fd":[
        644,
        8,
        646,
        645,
        672,
        8,
        194,
        678,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3ff":[
        644,
        8,
        646,
        645,
        647,
        52,
        648,
        679,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f9d1_1f3fb":[
        645,
        196,
        67,
        644,
        646,
        8,
        58,
        629,
        675,
        639,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f9d1_1f3fc":[
        645,
        196,
        67,
        644,
        646,
        8,
        677,
        629,
        675,
        642,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f9d1_1f3fd":[
        645,
        196,
        67,
        644,
        646,
        8,
        678,
        629,
        675,
        672,
        194,
        8,
        2
    ],
    "u1f9d1_1f3fe_200d_2764_fe0f_200d_1f9d1_1f3ff":[
        645,
        196,
        67,
        644,
        646,
        8,
        679,
        629,
        675,
        647,
        648,
        52,
        2
    ],
    "u1f9d1_1f3ff":[
        679,
        647,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f33e":[
        197,
        579,
        647,
        705,
        679,
        647,
        648,
        52,
        12,
        12,
        194,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_1f3ff_200d_1f373":[
        79,
        10,
        647,
        705,
        679,
        647,
        648,
        52,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_1f3ff_200d_1f37c":[
        629,
        647,
        705,
        679,
        647,
        648,
        52,
        2,
        79,
        125,
        3,
        220,
        647,
        629,
        647,
        629
    ],
    "u1f9d1_1f3ff_200d_1f384":[
        2,
        641,
        647,
        648,
        52,
        612
    ],
    "u1f9d1_1f3ff_200d_1f393":[
        58,
        67,
        629,
        675,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        67,
        195
    ],
    "u1f9d1_1f3ff_200d_1f3a4":[
        647,
        58,
        963,
        963,
        964,
        965,
        965,
        647,
        705,
        966,
        647,
        424,
        648,
        52,
        767,
        768
    ],
    "u1f9d1_1f3ff_200d_1f3a8":[
        629,
        705,
        679,
        647,
        648,
        52,
        67,
        596,
        189,
        10,
        2,
        195,
        647
    ],
    "u1f9d1_1f3ff_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        647,
        705,
        679,
        647,
        648,
        52,
        478
    ],
    "u1f9d1_1f3ff_200d_1f3ed":[
        12,
        58,
        647,
        3,
        705,
        679,
        647,
        648,
        52,
        705,
        705,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_1f3ff_200d_1f4bb":[
        629,
        647,
        705,
        679,
        647,
        648,
        52,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_1f3ff_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        647,
        705,
        679,
        647,
        648,
        52,
        769
    ],
    "u1f9d1_1f3ff_200d_1f527":[
        222,
        647,
        705,
        679,
        647,
        648,
        52,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_1f3ff_200d_1f52c":[
        222,
        647,
        705,
        679,
        647,
        648,
        52,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_1f3ff_200d_1f680":[
        79,
        214,
        1,
        58,
        647,
        705,
        679,
        647,
        648,
        52,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_1f3ff_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_1f3ff_200d_1f91d_200d_1f9d1_1f3fb":[
        647,
        189,
        58,
        196,
        679,
        647,
        705,
        647,
        648,
        52,
        639,
        674,
        58,
        629,
        639,
        697,
        639,
        194,
        8
    ],
    "u1f9d1_1f3ff_200d_1f91d_200d_1f9d1_1f3fc":[
        647,
        189,
        58,
        196,
        679,
        647,
        705,
        647,
        648,
        52,
        642,
        674,
        58,
        629,
        677,
        642,
        702,
        642,
        194,
        8
    ],
    "u1f9d1_1f3ff_200d_1f91d_200d_1f9d1_1f3fd":[
        647,
        189,
        58,
        196,
        679,
        647,
        705,
        647,
        648,
        52,
        672,
        674,
        58,
        629,
        678,
        672,
        703,
        672,
        194,
        8
    ],
    "u1f9d1_1f3ff_200d_1f91d_200d_1f9d1_1f3fe":[
        647,
        189,
        58,
        196,
        679,
        647,
        705,
        647,
        648,
        52,
        644,
        674,
        58,
        629,
        645,
        644,
        704,
        644,
        646,
        8
    ],
    "u1f9d1_1f3ff_200d_1f91d_200d_1f9d1_1f3ff":[
        647,
        189,
        58,
        196,
        679,
        647,
        705,
        647,
        648,
        52,
        674,
        58,
        629,
        647,
        705,
        647,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        647,
        647,
        679,
        698,
        674,
        629,
        647,
        58
    ],
    "u1f9d1_1f3ff_200d_1f9b0":[
        588,
        647,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f9b1":[
        647,
        679,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f9b2":[
        647,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f9b3":[
        79,
        647,
        648,
        52
    ],
    "u1f9d1_1f3ff_200d_1f9bc":[
        58,
        58,
        674,
        674,
        647,
        647,
        679,
        629,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        647,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_1f3ff_200d_1f9bd":[
        58,
        58,
        674,
        674,
        647,
        647,
        679,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        647
    ],
    "u1f9d1_1f3ff_200d_2695_fe0f":[
        12,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_1f3ff_200d_2696_fe0f":[
        58,
        79,
        647,
        705,
        721,
        647,
        648,
        52,
        196,
        478,
        58,
        67,
        194,
        8,
        194,
        647
    ],
    "u1f9d1_1f3ff_200d_2708_fe0f":[
        79,
        58,
        647,
        705,
        679,
        647,
        648,
        52,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fb":[
        647,
        52,
        648,
        679,
        639,
        8,
        194,
        58,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fc":[
        647,
        52,
        648,
        679,
        642,
        8,
        194,
        677,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fd":[
        647,
        52,
        648,
        679,
        672,
        8,
        194,
        678,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f48b_200d_1f9d1_1f3fe":[
        647,
        52,
        648,
        679,
        644,
        8,
        646,
        645,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f9d1_1f3fb":[
        679,
        196,
        67,
        647,
        648,
        52,
        58,
        629,
        675,
        639,
        194,
        8,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f9d1_1f3fc":[
        679,
        196,
        67,
        647,
        648,
        52,
        677,
        629,
        675,
        642,
        194,
        8,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f9d1_1f3fd":[
        679,
        196,
        67,
        647,
        648,
        52,
        678,
        629,
        675,
        672,
        194,
        8,
        2
    ],
    "u1f9d1_1f3ff_200d_2764_fe0f_200d_1f9d1_1f3fe":[
        679,
        196,
        67,
        647,
        648,
        52,
        645,
        629,
        675,
        644,
        646,
        8,
        2
    ],
    "u1f9d1_200d_1f33e":[
        197,
        579,
        649,
        706,
        4,
        649,
        194,
        8,
        12,
        12,
        194,
        58,
        67,
        67
    ],
    "u1f9d1_200d_1f373":[
        79,
        10,
        649,
        706,
        4,
        649,
        194,
        8,
        222,
        196,
        79,
        196,
        194,
        194,
        222
    ],
    "u1f9d1_200d_1f37c":[
        629,
        649,
        706,
        4,
        649,
        194,
        8,
        2,
        79,
        125,
        3,
        220,
        649,
        629,
        649,
        629
    ],
    "u1f9d1_200d_1f384":[
        2,
        641,
        649,
        194,
        8,
        612
    ],
    "u1f9d1_200d_1f393":[
        58,
        67,
        629,
        675,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        67,
        195
    ],
    "u1f9d1_200d_1f3a4":[
        649,
        58,
        963,
        963,
        964,
        965,
        965,
        649,
        706,
        966,
        649,
        424,
        194,
        8,
        767,
        768
    ],
    "u1f9d1_200d_1f3a8":[
        629,
        706,
        4,
        649,
        194,
        8,
        67,
        596,
        189,
        10,
        2,
        195,
        649
    ],
    "u1f9d1_200d_1f3eb":[
        596,
        189,
        11,
        222,
        196,
        649,
        706,
        4,
        649,
        194,
        8,
        478
    ],
    "u1f9d1_200d_1f3ed":[
        12,
        58,
        649,
        3,
        706,
        4,
        649,
        194,
        8,
        706,
        706,
        79,
        79,
        58,
        67,
        196,
        13,
        649,
        12,
        10
    ],
    "u1f9d1_200d_1f4bb":[
        629,
        649,
        706,
        4,
        649,
        194,
        8,
        79,
        79,
        196,
        79
    ],
    "u1f9d1_200d_1f4bc":[
        715,
        478,
        665,
        197,
        424,
        586,
        667,
        715,
        649,
        706,
        4,
        649,
        194,
        8,
        769
    ],
    "u1f9d1_200d_1f527":[
        222,
        649,
        706,
        4,
        649,
        194,
        8,
        10,
        10,
        478,
        12,
        10,
        3,
        67
    ],
    "u1f9d1_200d_1f52c":[
        222,
        649,
        706,
        4,
        649,
        194,
        8,
        67,
        478,
        770,
        478,
        6,
        771,
        79,
        6
    ],
    "u1f9d1_200d_1f680":[
        79,
        214,
        1,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        3,
        195,
        79,
        58,
        4,
        649,
        6,
        593
    ],
    "u1f9d1_200d_1f692":[
        772,
        773,
        222,
        9,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        760,
        214,
        772
    ],
    "u1f9d1_200d_1f91d_200d_1f9d1":[
        649,
        189,
        58,
        196,
        4,
        649,
        706,
        649,
        194,
        8,
        674,
        58,
        629,
        649,
        706,
        649,
        194,
        8
    ],
    "u1f9d1_200d_1f9af":[
        222,
        2,
        58,
        58,
        196,
        58,
        58,
        649,
        649,
        4,
        698,
        674,
        629,
        649,
        58
    ],
    "u1f9d1_200d_1f9b0":[
        588,
        649,
        194,
        8
    ],
    "u1f9d1_200d_1f9b1":[
        649,
        4,
        194,
        8
    ],
    "u1f9d1_200d_1f9b2":[
        649,
        194,
        8
    ],
    "u1f9d1_200d_1f9b3":[
        79,
        649,
        194,
        8
    ],
    "u1f9d1_200d_1f9bc":[
        58,
        58,
        674,
        674,
        649,
        649,
        4,
        629,
        67,
        58,
        58,
        58,
        2,
        58,
        2,
        58,
        2,
        2,
        67,
        2,
        2,
        58,
        649,
        67,
        222,
        58,
        67
    ],
    "u1f9d1_200d_1f9bd":[
        58,
        58,
        674,
        674,
        649,
        649,
        4,
        629,
        2,
        2,
        58,
        58,
        2,
        58,
        2,
        67,
        67,
        67,
        58,
        67,
        58,
        2,
        67,
        58,
        649
    ],
    "u1f9d1_200d_2695_fe0f":[
        12,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        222,
        478,
        58,
        222
    ],
    "u1f9d1_200d_2696_fe0f":[
        58,
        79,
        649,
        706,
        721,
        649,
        194,
        8,
        196,
        478,
        58,
        67,
        194,
        649
    ],
    "u1f9d1_200d_2708_fe0f":[
        79,
        58,
        649,
        706,
        4,
        649,
        194,
        8,
        58,
        67,
        4,
        668,
        196,
        58,
        478,
        774
    ],
    "u1f9d2_1f3fb":[
        58,
        639,
        194,
        8
    ],
    "u1f9d2_1f3fc":[
        677,
        642,
        194,
        8
    ],
    "u1f9d2_1f3fd":[
        678,
        672,
        194,
        8
    ],
    "u1f9d2_1f3fe":[
        645,
        644,
        646,
        8
    ],
    "u1f9d2_1f3ff":[
        679,
        647,
        648,
        52
    ],
    "u1f9d3_1f3fb":[
        641,
        639,
        194,
        8,
        641
    ],
    "u1f9d3_1f3fc":[
        641,
        642,
        194,
        8,
        641
    ],
    "u1f9d3_1f3fd":[
        641,
        672,
        194,
        8,
        641
    ],
    "u1f9d3_1f3fe":[
        641,
        644,
        646,
        8,
        641
    ],
    "u1f9d3_1f3ff":[
        641,
        647,
        648,
        52,
        641
    ],
    "u1f9d4_1f3fb":[
        58,
        639,
        58,
        639,
        194,
        8
    ],
    "u1f9d4_1f3fb_200d_2640_fe0f":[
        58,
        639,
        194,
        58,
        8,
        679,
        58,
        639
    ],
    "u1f9d4_1f3fb_200d_2642_fe0f":[
        639,
        639,
        8,
        194,
        58,
        639
    ],
    "u1f9d4_1f3fc":[
        677,
        642,
        677,
        642,
        194,
        8
    ],
    "u1f9d4_1f3fc_200d_2640_fe0f":[
        677,
        642,
        194,
        677,
        8,
        706,
        677,
        642
    ],
    "u1f9d4_1f3fc_200d_2642_fe0f":[
        642,
        642,
        8,
        194,
        677,
        642
    ],
    "u1f9d4_1f3fd":[
        678,
        672,
        678,
        672,
        194,
        8
    ],
    "u1f9d4_1f3fd_200d_2640_fe0f":[
        678,
        672,
        194,
        678,
        8,
        967,
        678,
        672
    ],
    "u1f9d4_1f3fd_200d_2642_fe0f":[
        672,
        672,
        8,
        194,
        678,
        672
    ],
    "u1f9d4_1f3fe":[
        645,
        644,
        645,
        644,
        646,
        8
    ],
    "u1f9d4_1f3fe_200d_2640_fe0f":[
        645,
        644,
        646,
        645,
        8,
        968,
        645,
        644
    ],
    "u1f9d4_1f3fe_200d_2642_fe0f":[
        644,
        644,
        8,
        646,
        645,
        644
    ],
    "u1f9d4_1f3ff":[
        679,
        647,
        679,
        647,
        648,
        52
    ],
    "u1f9d4_1f3ff_200d_2640_fe0f":[
        679,
        647,
        648,
        679,
        52,
        969,
        679,
        647
    ],
    "u1f9d4_1f3ff_200d_2642_fe0f":[
        647,
        647,
        52,
        648,
        679,
        647
    ],
    "u1f9d4_200d_2640_fe0f":[
        4,
        649,
        194,
        4,
        8,
        694,
        4,
        649
    ],
    "u1f9d4_200d_2642_fe0f":[
        649,
        649,
        8,
        194,
        4,
        649
    ],
    "u1f9d5_1f3fb":[
        6,
        576,
        6,
        639,
        760,
        8,
        194,
        5
    ],
    "u1f9d5_1f3fc":[
        6,
        576,
        6,
        642,
        760,
        8,
        194,
        5
    ],
    "u1f9d5_1f3fd":[
        6,
        576,
        6,
        643,
        760,
        8,
        194,
        5
    ],
    "u1f9d5_1f3fe":[
        6,
        576,
        6,
        644,
        760,
        8,
        646,
        5
    ],
    "u1f9d5_1f3ff":[
        6,
        576,
        6,
        647,
        760,
        52,
        648,
        5
    ],
    "u1f9d6_1f3fb":[
        79,
        79,
        79,
        79,
        639,
        58,
        639,
        697,
        639,
        194,
        8,
        697,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d6_1f3fb_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        639,
        58,
        639,
        697,
        639,
        760,
        58,
        8,
        194,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_1f3fb_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        639,
        639,
        697,
        639,
        194,
        58,
        8,
        197,
        220,
        971
    ],
    "u1f9d6_1f3fc":[
        79,
        79,
        79,
        79,
        642,
        677,
        642,
        702,
        642,
        194,
        8,
        702,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d6_1f3fc_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        642,
        677,
        642,
        702,
        642,
        760,
        677,
        8,
        194,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_1f3fc_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        642,
        642,
        702,
        642,
        194,
        677,
        8,
        197,
        220,
        971
    ],
    "u1f9d6_1f3fd":[
        79,
        79,
        79,
        79,
        672,
        678,
        672,
        703,
        672,
        194,
        8,
        703,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d6_1f3fd_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        672,
        678,
        672,
        703,
        672,
        760,
        678,
        8,
        194,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_1f3fd_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        672,
        672,
        703,
        672,
        194,
        678,
        8,
        197,
        220,
        971
    ],
    "u1f9d6_1f3fe":[
        79,
        79,
        79,
        79,
        644,
        645,
        644,
        704,
        644,
        646,
        8,
        704,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d6_1f3fe_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        644,
        645,
        644,
        704,
        644,
        760,
        645,
        8,
        646,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_1f3fe_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        644,
        644,
        704,
        644,
        646,
        645,
        8,
        197,
        220,
        971
    ],
    "u1f9d6_1f3ff":[
        79,
        79,
        79,
        79,
        647,
        679,
        647,
        705,
        647,
        648,
        52,
        705,
        675,
        676,
        629,
        675,
        629,
        972,
        971
    ],
    "u1f9d6_1f3ff_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        647,
        679,
        647,
        705,
        647,
        760,
        679,
        52,
        648,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_1f3ff_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        647,
        647,
        705,
        647,
        648,
        679,
        52,
        197,
        220,
        971
    ],
    "u1f9d6_200d_2640_fe0f":[
        79,
        79,
        79,
        79,
        649,
        4,
        649,
        706,
        649,
        760,
        4,
        8,
        194,
        6,
        970,
        576,
        576,
        6,
        5,
        971
    ],
    "u1f9d6_200d_2642_fe0f":[
        79,
        79,
        79,
        79,
        197,
        649,
        649,
        706,
        649,
        194,
        4,
        8,
        197,
        220,
        971
    ],
    "u1f9d7_1f3fb":[
        67,
        196,
        639,
        639,
        58,
        697,
        697,
        697,
        676,
        639,
        675,
        639,
        629,
        676,
        58,
        67
    ],
    "u1f9d7_1f3fb_200d_2640_fe0f":[
        67,
        196,
        58,
        697,
        697,
        697,
        9,
        639,
        1,
        639,
        639,
        2,
        58,
        58,
        67
    ],
    "u1f9d7_1f3fb_200d_2642_fe0f":[
        67,
        196,
        58,
        697,
        697,
        697,
        698,
        639,
        673,
        639,
        639,
        674,
        58,
        67,
        698,
        639
    ],
    "u1f9d7_1f3fc":[
        67,
        196,
        642,
        642,
        677,
        58,
        702,
        702,
        702,
        676,
        642,
        675,
        642,
        629,
        676,
        58,
        67
    ],
    "u1f9d7_1f3fc_200d_2640_fe0f":[
        67,
        196,
        58,
        702,
        702,
        702,
        9,
        642,
        1,
        642,
        642,
        2,
        58,
        677,
        677,
        67
    ],
    "u1f9d7_1f3fc_200d_2642_fe0f":[
        67,
        196,
        58,
        702,
        702,
        702,
        698,
        642,
        673,
        642,
        642,
        674,
        58,
        677,
        67,
        698,
        642
    ],
    "u1f9d7_1f3fd":[
        67,
        196,
        672,
        672,
        678,
        58,
        703,
        703,
        703,
        676,
        672,
        675,
        672,
        629,
        676,
        58,
        67
    ],
    "u1f9d7_1f3fd_200d_2640_fe0f":[
        67,
        196,
        58,
        703,
        703,
        703,
        9,
        643,
        1,
        643,
        643,
        2,
        58,
        678,
        678,
        67
    ],
    "u1f9d7_1f3fd_200d_2642_fe0f":[
        67,
        196,
        58,
        703,
        703,
        703,
        698,
        643,
        673,
        643,
        643,
        674,
        58,
        678,
        67,
        698,
        643
    ],
    "u1f9d7_1f3fe":[
        67,
        196,
        644,
        644,
        645,
        58,
        704,
        704,
        704,
        676,
        644,
        675,
        644,
        629,
        676,
        58,
        67
    ],
    "u1f9d7_1f3fe_200d_2640_fe0f":[
        67,
        196,
        58,
        704,
        704,
        704,
        9,
        644,
        1,
        644,
        644,
        2,
        58,
        645,
        645,
        67
    ],
    "u1f9d7_1f3fe_200d_2642_fe0f":[
        67,
        196,
        58,
        704,
        704,
        704,
        698,
        644,
        673,
        644,
        644,
        674,
        58,
        645,
        67,
        698,
        644
    ],
    "u1f9d7_1f3ff":[
        67,
        196,
        647,
        647,
        679,
        58,
        705,
        705,
        705,
        676,
        647,
        675,
        647,
        629,
        676,
        58,
        67
    ],
    "u1f9d7_1f3ff_200d_2640_fe0f":[
        67,
        196,
        58,
        705,
        705,
        705,
        9,
        647,
        1,
        647,
        647,
        2,
        58,
        679,
        679,
        67
    ],
    "u1f9d7_1f3ff_200d_2642_fe0f":[
        67,
        196,
        58,
        705,
        705,
        705,
        698,
        647,
        673,
        647,
        647,
        674,
        58,
        679,
        67,
        698,
        647
    ],
    "u1f9d7_200d_2640_fe0f":[
        67,
        196,
        58,
        706,
        706,
        706,
        9,
        649,
        1,
        649,
        649,
        2,
        58,
        4,
        4,
        67
    ],
    "u1f9d7_200d_2642_fe0f":[
        67,
        196,
        58,
        706,
        706,
        706,
        698,
        649,
        673,
        649,
        649,
        674,
        58,
        4,
        67,
        698,
        649
    ],
    "u1f9d8_1f3fb":[
        674,
        639,
        639,
        697,
        697,
        639,
        629,
        58,
        639,
        697,
        639,
        194
    ],
    "u1f9d8_1f3fb_200d_2640_fe0f":[
        674,
        639,
        639,
        697,
        697,
        639,
        58,
        784,
        639,
        697,
        639,
        760,
        194
    ],
    "u1f9d8_1f3fb_200d_2642_fe0f":[
        674,
        639,
        639,
        697,
        697,
        639,
        197,
        639,
        697,
        639,
        194,
        58
    ],
    "u1f9d8_1f3fc":[
        674,
        642,
        642,
        702,
        702,
        642,
        629,
        677,
        642,
        702,
        642,
        194
    ],
    "u1f9d8_1f3fc_200d_2640_fe0f":[
        674,
        642,
        642,
        702,
        702,
        642,
        677,
        784,
        642,
        702,
        642,
        760,
        194
    ],
    "u1f9d8_1f3fc_200d_2642_fe0f":[
        674,
        642,
        642,
        702,
        702,
        642,
        197,
        642,
        702,
        642,
        194,
        677
    ],
    "u1f9d8_1f3fd":[
        674,
        672,
        672,
        703,
        703,
        672,
        629,
        678,
        672,
        703,
        672,
        194
    ],
    "u1f9d8_1f3fd_200d_2640_fe0f":[
        674,
        672,
        672,
        703,
        703,
        672,
        678,
        784,
        672,
        703,
        672,
        760,
        194
    ],
    "u1f9d8_1f3fd_200d_2642_fe0f":[
        674,
        672,
        672,
        703,
        703,
        672,
        197,
        672,
        703,
        672,
        194,
        678
    ],
    "u1f9d8_1f3fe":[
        674,
        644,
        644,
        704,
        704,
        644,
        629,
        645,
        644,
        704,
        644,
        646
    ],
    "u1f9d8_1f3fe_200d_2640_fe0f":[
        674,
        644,
        644,
        704,
        704,
        644,
        645,
        784,
        644,
        704,
        644,
        760,
        646
    ],
    "u1f9d8_1f3fe_200d_2642_fe0f":[
        674,
        644,
        644,
        704,
        704,
        644,
        197,
        644,
        704,
        644,
        646,
        645
    ],
    "u1f9d8_1f3ff":[
        674,
        647,
        647,
        705,
        705,
        647,
        629,
        679,
        647,
        705,
        647,
        648
    ],
    "u1f9d8_1f3ff_200d_2640_fe0f":[
        674,
        647,
        647,
        705,
        705,
        647,
        679,
        784,
        647,
        705,
        647,
        760,
        648
    ],
    "u1f9d8_1f3ff_200d_2642_fe0f":[
        674,
        647,
        647,
        705,
        705,
        647,
        197,
        647,
        705,
        647,
        648,
        679
    ],
    "u1f9d8_200d_2640_fe0f":[
        674,
        649,
        649,
        706,
        706,
        649,
        4,
        784,
        649,
        706,
        649,
        760,
        194
    ],
    "u1f9d8_200d_2642_fe0f":[
        674,
        649,
        649,
        706,
        706,
        649,
        197,
        649,
        706,
        649,
        194,
        4
    ],
    "u1f9d9_1f3fb":[
        676,
        629,
        639,
        697,
        641,
        639,
        194,
        8,
        629,
        596,
        3,
        973,
        639
    ],
    "u1f9d9_1f3fb_200d_2640_fe0f":[
        58,
        641,
        6,
        639,
        697,
        639,
        760,
        194,
        8,
        641,
        6,
        596,
        3,
        973,
        639
    ],
    "u1f9d9_1f3fb_200d_2642_fe0f":[
        674,
        698,
        58,
        639,
        8,
        194,
        0,
        674,
        596,
        3,
        973,
        639
    ],
    "u1f9d9_1f3fc":[
        676,
        629,
        642,
        702,
        641,
        642,
        194,
        8,
        629,
        596,
        3,
        973,
        642
    ],
    "u1f9d9_1f3fc_200d_2640_fe0f":[
        58,
        641,
        6,
        642,
        702,
        642,
        760,
        194,
        8,
        641,
        6,
        596,
        3,
        973,
        642
    ],
    "u1f9d9_1f3fc_200d_2642_fe0f":[
        674,
        698,
        58,
        642,
        8,
        194,
        0,
        674,
        596,
        3,
        973,
        642
    ],
    "u1f9d9_1f3fd":[
        676,
        629,
        672,
        703,
        641,
        672,
        194,
        8,
        629,
        596,
        3,
        973,
        672
    ],
    "u1f9d9_1f3fd_200d_2640_fe0f":[
        58,
        641,
        6,
        643,
        703,
        643,
        760,
        194,
        8,
        641,
        6,
        596,
        3,
        973,
        643
    ],
    "u1f9d9_1f3fd_200d_2642_fe0f":[
        674,
        698,
        58,
        643,
        8,
        194,
        0,
        674,
        596,
        3,
        973,
        643
    ],
    "u1f9d9_1f3fe":[
        676,
        629,
        644,
        704,
        641,
        644,
        646,
        8,
        629,
        596,
        3,
        973,
        644
    ],
    "u1f9d9_1f3fe_200d_2640_fe0f":[
        58,
        641,
        6,
        644,
        704,
        644,
        760,
        646,
        8,
        641,
        6,
        596,
        3,
        973,
        644
    ],
    "u1f9d9_1f3fe_200d_2642_fe0f":[
        674,
        698,
        58,
        644,
        8,
        646,
        0,
        674,
        596,
        3,
        973,
        644
    ],
    "u1f9d9_1f3ff":[
        676,
        629,
        647,
        705,
        641,
        647,
        648,
        52,
        629,
        596,
        3,
        973,
        647
    ],
    "u1f9d9_1f3ff_200d_2640_fe0f":[
        58,
        641,
        6,
        647,
        705,
        647,
        760,
        648,
        52,
        641,
        6,
        596,
        3,
        973,
        647
    ],
    "u1f9d9_1f3ff_200d_2642_fe0f":[
        674,
        698,
        58,
        647,
        52,
        648,
        0,
        674,
        596,
        3,
        973,
        647
    ],
    "u1f9d9_200d_2640_fe0f":[
        58,
        641,
        6,
        649,
        706,
        649,
        760,
        194,
        8,
        641,
        6,
        596,
        3,
        973,
        649
    ],
    "u1f9d9_200d_2642_fe0f":[
        674,
        698,
        58,
        649,
        8,
        194,
        0,
        674,
        596,
        3,
        973,
        649
    ],
    "u1f9da_1f3fb":[
        974,
        974,
        3,
        639,
        639,
        697,
        629,
        58,
        639,
        194,
        8
    ],
    "u1f9da_1f3fb_200d_2640_fe0f":[
        974,
        974,
        3,
        639,
        58,
        639,
        697,
        639,
        760,
        58,
        639,
        8,
        194,
        197
    ],
    "u1f9da_1f3fb_200d_2642_fe0f":[
        974,
        974,
        3,
        639,
        58,
        639,
        697,
        639,
        58,
        194,
        189,
        8
    ],
    "u1f9da_1f3fc":[
        974,
        974,
        3,
        642,
        642,
        702,
        629,
        677,
        642,
        194,
        8
    ],
    "u1f9da_1f3fc_200d_2640_fe0f":[
        974,
        974,
        3,
        642,
        677,
        642,
        702,
        642,
        760,
        677,
        642,
        8,
        194,
        197
    ],
    "u1f9da_1f3fc_200d_2642_fe0f":[
        974,
        974,
        3,
        642,
        677,
        642,
        702,
        642,
        677,
        194,
        189,
        8
    ],
    "u1f9da_1f3fd":[
        974,
        974,
        3,
        672,
        672,
        703,
        629,
        678,
        672,
        194,
        8
    ],
    "u1f9da_1f3fd_200d_2640_fe0f":[
        974,
        974,
        3,
        643,
        678,
        643,
        703,
        643,
        760,
        678,
        643,
        8,
        194,
        197
    ],
    "u1f9da_1f3fd_200d_2642_fe0f":[
        974,
        974,
        3,
        643,
        678,
        643,
        703,
        643,
        678,
        194,
        189,
        8
    ],
    "u1f9da_1f3fe":[
        974,
        974,
        3,
        644,
        644,
        704,
        629,
        645,
        644,
        646,
        8
    ],
    "u1f9da_1f3fe_200d_2640_fe0f":[
        974,
        974,
        3,
        644,
        645,
        644,
        704,
        644,
        760,
        645,
        644,
        8,
        646,
        197
    ],
    "u1f9da_1f3fe_200d_2642_fe0f":[
        974,
        974,
        3,
        644,
        645,
        644,
        704,
        644,
        645,
        646,
        189,
        8
    ],
    "u1f9da_1f3ff":[
        974,
        974,
        3,
        647,
        647,
        705,
        629,
        679,
        647,
        648,
        52
    ],
    "u1f9da_1f3ff_200d_2640_fe0f":[
        974,
        974,
        3,
        647,
        679,
        647,
        705,
        647,
        760,
        679,
        647,
        52,
        648,
        197
    ],
    "u1f9da_1f3ff_200d_2642_fe0f":[
        974,
        974,
        3,
        647,
        679,
        647,
        705,
        647,
        679,
        648,
        189,
        52
    ],
    "u1f9da_200d_2640_fe0f":[
        974,
        974,
        3,
        649,
        4,
        649,
        706,
        649,
        760,
        4,
        649,
        8,
        194,
        197
    ],
    "u1f9da_200d_2642_fe0f":[
        974,
        974,
        3,
        649,
        4,
        649,
        706,
        649,
        4,
        194,
        189,
        8
    ],
    "u1f9db_1f3fb":[
        58,
        1,
        194,
        639,
        697,
        58,
        639,
        194,
        2,
        639,
        194,
        194,
        478
    ],
    "u1f9db_1f3fb_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        639,
        639,
        697,
        639,
        58,
        2,
        194,
        639,
        194,
        478
    ],
    "u1f9db_1f3fb_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        639,
        697,
        639,
        194,
        478,
        2,
        639,
        58
    ],
    "u1f9db_1f3fc":[
        58,
        1,
        194,
        642,
        702,
        58,
        642,
        194,
        2,
        642,
        194,
        194,
        478
    ],
    "u1f9db_1f3fc_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        642,
        642,
        702,
        642,
        58,
        2,
        194,
        642,
        194,
        478
    ],
    "u1f9db_1f3fc_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        642,
        702,
        642,
        194,
        478,
        2,
        642,
        58
    ],
    "u1f9db_1f3fd":[
        58,
        1,
        194,
        672,
        703,
        58,
        672,
        194,
        2,
        672,
        194,
        194,
        478
    ],
    "u1f9db_1f3fd_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        643,
        643,
        703,
        643,
        58,
        2,
        194,
        643,
        194,
        478
    ],
    "u1f9db_1f3fd_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        643,
        703,
        643,
        194,
        478,
        2,
        643,
        58
    ],
    "u1f9db_1f3fe":[
        58,
        1,
        194,
        644,
        704,
        58,
        644,
        646,
        2,
        644,
        646,
        646,
        478
    ],
    "u1f9db_1f3fe_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        644,
        644,
        704,
        644,
        58,
        2,
        646,
        644,
        646,
        478
    ],
    "u1f9db_1f3fe_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        644,
        704,
        644,
        646,
        478,
        2,
        644,
        58
    ],
    "u1f9db_1f3ff":[
        58,
        1,
        194,
        647,
        705,
        58,
        647,
        648,
        2,
        647,
        648,
        648,
        478
    ],
    "u1f9db_1f3ff_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        647,
        647,
        705,
        647,
        58,
        2,
        648,
        647,
        648,
        478
    ],
    "u1f9db_1f3ff_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        647,
        705,
        647,
        648,
        478,
        2,
        647,
        58
    ],
    "u1f9db_200d_2640_fe0f":[
        58,
        1,
        194,
        58,
        58,
        649,
        649,
        706,
        649,
        58,
        2,
        194,
        649,
        194,
        478
    ],
    "u1f9db_200d_2642_fe0f":[
        58,
        1,
        79,
        196,
        649,
        706,
        649,
        741,
        478,
        2,
        194,
        649,
        58
    ],
    "u1f9dc_1f3fb":[
        639,
        639,
        965,
        977,
        978,
        977,
        639,
        697,
        576,
        639,
        194,
        8
    ],
    "u1f9dc_1f3fb_200d_2640_fe0f":[
        975,
        639,
        976,
        977,
        978,
        977,
        639,
        697,
        639,
        760,
        8,
        194,
        975
    ],
    "u1f9dc_1f3fb_200d_2642_fe0f":[
        639,
        684,
        979,
        744,
        979,
        639,
        697,
        639,
        194,
        8,
        744
    ],
    "u1f9dc_1f3fc":[
        642,
        642,
        965,
        977,
        978,
        977,
        642,
        702,
        576,
        642,
        194,
        8
    ],
    "u1f9dc_1f3fc_200d_2640_fe0f":[
        975,
        642,
        976,
        977,
        978,
        977,
        642,
        702,
        642,
        760,
        8,
        194,
        975
    ],
    "u1f9dc_1f3fc_200d_2642_fe0f":[
        642,
        686,
        979,
        744,
        979,
        642,
        702,
        642,
        194,
        8,
        744
    ],
    "u1f9dc_1f3fd":[
        672,
        672,
        965,
        977,
        978,
        977,
        672,
        703,
        576,
        672,
        194,
        8
    ],
    "u1f9dc_1f3fd_200d_2640_fe0f":[
        975,
        672,
        976,
        977,
        978,
        977,
        672,
        703,
        672,
        760,
        8,
        194,
        975
    ],
    "u1f9dc_1f3fd_200d_2642_fe0f":[
        672,
        688,
        979,
        744,
        979,
        672,
        703,
        672,
        194,
        8,
        744
    ],
    "u1f9dc_1f3fe":[
        644,
        644,
        965,
        977,
        978,
        977,
        644,
        704,
        576,
        644,
        646,
        8
    ],
    "u1f9dc_1f3fe_200d_2640_fe0f":[
        975,
        644,
        976,
        977,
        978,
        977,
        644,
        704,
        644,
        760,
        8,
        646,
        975
    ],
    "u1f9dc_1f3fe_200d_2642_fe0f":[
        644,
        690,
        979,
        744,
        979,
        644,
        704,
        644,
        646,
        8,
        744
    ],
    "u1f9dc_1f3ff":[
        647,
        647,
        965,
        977,
        978,
        977,
        647,
        705,
        576,
        647,
        648,
        52
    ],
    "u1f9dc_1f3ff_200d_2640_fe0f":[
        975,
        647,
        976,
        977,
        978,
        977,
        647,
        705,
        647,
        760,
        52,
        648,
        975
    ],
    "u1f9dc_1f3ff_200d_2642_fe0f":[
        647,
        692,
        979,
        744,
        979,
        647,
        705,
        647,
        648,
        52,
        744
    ],
    "u1f9dc_200d_2640_fe0f":[
        975,
        649,
        976,
        977,
        978,
        977,
        649,
        706,
        649,
        760,
        8,
        194,
        975
    ],
    "u1f9dc_200d_2642_fe0f":[
        649,
        694,
        979,
        744,
        979,
        649,
        706,
        649,
        194,
        8,
        744
    ],
    "u1f9dd_1f3fb":[
        8,
        629,
        639,
        697,
        8,
        639,
        194,
        8,
        639,
        8
    ],
    "u1f9dd_1f3fb_200d_2640_fe0f":[
        8,
        214,
        8,
        189,
        639,
        697,
        639,
        8,
        194,
        760,
        639,
        8,
        8,
        214
    ],
    "u1f9dd_1f3fb_200d_2642_fe0f":[
        8,
        8,
        12,
        639,
        697,
        639,
        194,
        8,
        639,
        8,
        8
    ],
    "u1f9dd_1f3fc":[
        8,
        629,
        642,
        702,
        8,
        642,
        194,
        8,
        642,
        8
    ],
    "u1f9dd_1f3fc_200d_2640_fe0f":[
        8,
        214,
        8,
        189,
        642,
        702,
        642,
        8,
        194,
        760,
        642,
        8,
        8,
        214
    ],
    "u1f9dd_1f3fc_200d_2642_fe0f":[
        8,
        8,
        12,
        642,
        702,
        642,
        194,
        8,
        642,
        8,
        8
    ],
    "u1f9dd_1f3fd":[
        8,
        629,
        672,
        703,
        8,
        672,
        194,
        8,
        672,
        8
    ],
    "u1f9dd_1f3fd_200d_2640_fe0f":[
        8,
        214,
        8,
        189,
        643,
        703,
        643,
        8,
        194,
        760,
        643,
        8,
        8,
        214
    ],
    "u1f9dd_1f3fd_200d_2642_fe0f":[
        8,
        8,
        12,
        643,
        703,
        643,
        194,
        8,
        643,
        8,
        8
    ],
    "u1f9dd_1f3fe":[
        8,
        629,
        644,
        704,
        8,
        644,
        646,
        8,
        644,
        8
    ],
    "u1f9dd_1f3fe_200d_2640_fe0f":[
        645,
        214,
        645,
        189,
        644,
        704,
        644,
        8,
        646,
        760,
        644,
        645,
        645,
        214
    ],
    "u1f9dd_1f3fe_200d_2642_fe0f":[
        645,
        645,
        12,
        644,
        704,
        644,
        646,
        8,
        644,
        645,
        645
    ],
    "u1f9dd_1f3ff":[
        8,
        629,
        647,
        705,
        8,
        647,
        648,
        52,
        647,
        8
    ],
    "u1f9dd_1f3ff_200d_2640_fe0f":[
        679,
        214,
        679,
        189,
        647,
        705,
        647,
        52,
        648,
        760,
        647,
        679,
        679,
        214
    ],
    "u1f9dd_1f3ff_200d_2642_fe0f":[
        679,
        679,
        12,
        647,
        705,
        647,
        648,
        52,
        647,
        679,
        679
    ],
    "u1f9dd_200d_2640_fe0f":[
        8,
        214,
        8,
        189,
        649,
        706,
        649,
        8,
        194,
        760,
        649,
        8,
        8,
        214
    ],
    "u1f9dd_200d_2642_fe0f":[
        8,
        8,
        12,
        649,
        706,
        649,
        194,
        8,
        649,
        8,
        8
    ],
    "u1f9de_200d_2640_fe0f":[
        58,
        58,
        195,
        125,
        125,
        3,
        125,
        760,
        8,
        3,
        58,
        125,
        125,
        9,
        195
    ],
    "u1f9de_200d_2642_fe0f":[
        125,
        125,
        125,
        8,
        10,
        3,
        576,
        591,
        591,
        546,
        4,
        52
    ],
    "u1f9df_200d_2640_fe0f":[
        12,
        8,
        222,
        58,
        222,
        196,
        222,
        67,
        58,
        196,
        8,
        196,
        222
    ],
    "u1f9df_200d_2642_fe0f":[
        596,
        222,
        196,
        222,
        196,
        67,
        58,
        8,
        194,
        10,
        546,
        196,
        669,
        222
    ],
    "u1fac3_1f3fb":[
        197,
        1040,
        697,
        639,
        684,
        58,
        194,
        8
    ],
    "u1fac3_1f3fc":[
        197,
        1040,
        702,
        642,
        686,
        677,
        194,
        8
    ],
    "u1fac3_1f3fd":[
        197,
        1040,
        703,
        672,
        688,
        678,
        194,
        8
    ],
    "u1fac3_1f3fe":[
        197,
        1040,
        704,
        644,
        690,
        645,
        646,
        8
    ],
    "u1fac3_1f3ff":[
        197,
        1040,
        705,
        647,
        692,
        679,
        648,
        52
    ],
    "u1fac4_1f3fb":[
        629,
        675,
        697,
        639,
        684,
        58,
        194,
        8
    ],
    "u1fac4_1f3fc":[
        629,
        675,
        702,
        642,
        686,
        677,
        194,
        8
    ],
    "u1fac4_1f3fd":[
        629,
        675,
        703,
        672,
        688,
        678,
        194,
        8
    ],
    "u1fac4_1f3fe":[
        629,
        675,
        704,
        644,
        690,
        645,
        646,
        8
    ],
    "u1fac4_1f3ff":[
        629,
        675,
        705,
        647,
        692,
        679,
        648,
        52
    ],
    "u1fac5_1f3fb":[
        58,
        639,
        194,
        8,
        1041,
        1042
    ],
    "u1fac5_1f3fc":[
        677,
        642,
        194,
        8,
        1041,
        1042
    ],
    "u1fac5_1f3fd":[
        678,
        672,
        194,
        8,
        1041,
        1042
    ],
    "u1fac5_1f3fe":[
        645,
        644,
        646,
        8,
        1041,
        1042
    ],
    "u1fac5_1f3ff":[
        679,
        647,
        648,
        52,
        1041,
        1042
    ],
    "u1faf0_1f3fb":[
        639,
        684
    ],
    "u1faf0_1f3fc":[
        642,
        686
    ],
    "u1faf0_1f3fd":[
        672,
        688
    ],
    "u1faf0_1f3fe":[
        644,
        690
    ],
    "u1faf0_1f3ff":[
        647,
        692
    ],
    "u1faf1_1f3fb":[
        684,
        639
    ],
    "u1faf1_1f3fb_200d_1faf2_1f3fc":[
        684,
        639,
        686,
        642
    ],
    "u1faf1_1f3fb_200d_1faf2_1f3fd":[
        684,
        639,
        688,
        672
    ],
    "u1faf1_1f3fb_200d_1faf2_1f3fe":[
        684,
        639,
        690,
        644
    ],
    "u1faf1_1f3fb_200d_1faf2_1f3ff":[
        684,
        639,
        692,
        647
    ],
    "u1faf1_1f3fc":[
        686,
        642
    ],
    "u1faf1_1f3fc_200d_1faf2_1f3fb":[
        686,
        642,
        684,
        639
    ],
    "u1faf1_1f3fc_200d_1faf2_1f3fd":[
        686,
        642,
        688,
        672
    ],
    "u1faf1_1f3fc_200d_1faf2_1f3fe":[
        686,
        642,
        690,
        644
    ],
    "u1faf1_1f3fc_200d_1faf2_1f3ff":[
        686,
        642,
        692,
        647
    ],
    "u1faf1_1f3fd":[
        688,
        672
    ],
    "u1faf1_1f3fd_200d_1faf2_1f3fb":[
        688,
        672,
        684,
        639
    ],
    "u1faf1_1f3fd_200d_1faf2_1f3fc":[
        688,
        672,
        686,
        642
    ],
    "u1faf1_1f3fd_200d_1faf2_1f3fe":[
        688,
        672,
        690,
        644
    ],
    "u1faf1_1f3fd_200d_1faf2_1f3ff":[
        688,
        672,
        692,
        647
    ],
    "u1faf1_1f3fe":[
        690,
        644
    ],
    "u1faf1_1f3fe_200d_1faf2_1f3fb":[
        690,
        644,
        684,
        639
    ],
    "u1faf1_1f3fe_200d_1faf2_1f3fc":[
        690,
        644,
        686,
        642
    ],
    "u1faf1_1f3fe_200d_1faf2_1f3fd":[
        690,
        644,
        688,
        672
    ],
    "u1faf1_1f3fe_200d_1faf2_1f3ff":[
        690,
        644,
        692,
        647
    ],
    "u1faf1_1f3ff":[
        692,
        647
    ],
    "u1faf1_1f3ff_200d_1faf2_1f3fb":[
        692,
        647,
        684,
        639
    ],
    "u1faf1_1f3ff_200d_1faf2_1f3fc":[
        692,
        647,
        686,
        642
    ],
    "u1faf1_1f3ff_200d_1faf2_1f3fd":[
        692,
        647,
        688,
        672
    ],
    "u1faf1_1f3ff_200d_1faf2_1f3fe":[
        692,
        647,
        690,
        644
    ],
    "u1faf2_1f3fb":[
        684,
        639,
        684
    ],
    "u1faf2_1f3fc":[
        686,
        642,
        686
    ],
    "u1faf2_1f3fd":[
        688,
        672,
        688
    ],
    "u1faf2_1f3fe":[
        690,
        644,
        690
    ],
    "u1faf2_1f3ff":[
        692,
        647,
        692
    ],
    "u1faf3_1f3fb":[
        684,
        639,
        684,
        639,
        684,
        639,
        639,
        684
    ],
    "u1faf3_1f3fc":[
        686,
        642,
        686,
        642,
        686,
        642,
        642,
        686
    ],
    "u1faf3_1f3fd":[
        688,
        672,
        688,
        672,
        688,
        672,
        672,
        688
    ],
    "u1faf3_1f3fe":[
        690,
        644,
        690,
        644,
        690,
        644,
        644,
        690
    ],
    "u1faf3_1f3ff":[
        692,
        647,
        692,
        647,
        692,
        647,
        647,
        692
    ],
    "u1faf4_1f3fb":[
        639,
        684,
        639,
        684,
        639,
        684,
        639,
        684
    ],
    "u1faf4_1f3fc":[
        642,
        686,
        642,
        686,
        642,
        686,
        642,
        686
    ],
    "u1faf4_1f3fd":[
        672,
        688,
        672,
        688,
        672,
        688,
        672,
        688
    ],
    "u1faf4_1f3fe":[
        644,
        690,
        644,
        690,
        644,
        690,
        644,
        690
    ],
    "u1faf4_1f3ff":[
        647,
        692,
        647,
        692,
        647,
        692,
        647,
        692
    ],
    "u1faf5_1f3fb":[
        639,
        684
    ],
    "u1faf5_1f3fc":[
        642,
        686
    ],
    "u1faf5_1f3fd":[
        672,
        688
    ],
    "u1faf5_1f3fe":[
        644,
        690
    ],
    "u1faf5_1f3ff":[
        647,
        692
    ],
    "u1faf6_1f3fb":[
        639,
        697
    ],
    "u1faf6_1f3fc":[
        642,
        702
    ],
    "u1faf6_1f3fd":[
        672,
        703
    ],
    "u1faf6_1f3fe":[
        644,
        704
    ],
    "u1faf6_1f3ff":[
        647,
        705
    ],
    "u23_fe0f_20e3":[
        12,
        11
    ],
    "u261d_1f3fb":[
        684,
        639,
        639
    ],
    "u261d_1f3fc":[
        686,
        642,
        642
    ],
    "u261d_1f3fd":[
        688,
        672,
        672
    ],
    "u261d_1f3fe":[
        690,
        644,
        644
    ],
    "u261d_1f3ff":[
        692,
        647,
        647
    ],
    "u26f7_1f3fb":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        639,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f7_1f3fc":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        642,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f7_1f3fd":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        672,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f7_1f3fe":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        644,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f7_1f3ff":[
        79,
        1062,
        790,
        662,
        790,
        662,
        3,
        1062,
        662,
        660,
        419,
        662,
        647,
        669,
        660,
        671,
        660,
        671,
        1062,
        426,
        3
    ],
    "u26f9_1f3fb":[
        639,
        675,
        222,
        629,
        222,
        676,
        675,
        639,
        58,
        629,
        639,
        4,
        194
    ],
    "u26f9_1f3fb_200d_2640_fe0f":[
        639,
        1,
        222,
        2,
        222,
        9,
        1,
        639,
        58,
        2,
        639,
        4,
        194
    ],
    "u26f9_1f3fb_200d_2642_fe0f":[
        639,
        662,
        222,
        661,
        222,
        673,
        639,
        58,
        674,
        639,
        4,
        194
    ],
    "u26f9_1f3fc":[
        642,
        675,
        222,
        629,
        222,
        676,
        675,
        642,
        677,
        629,
        642,
        4,
        194
    ],
    "u26f9_1f3fc_200d_2640_fe0f":[
        642,
        1,
        222,
        2,
        222,
        9,
        1,
        642,
        677,
        2,
        642,
        4,
        194
    ],
    "u26f9_1f3fc_200d_2642_fe0f":[
        642,
        662,
        222,
        661,
        222,
        673,
        642,
        677,
        674,
        642,
        4,
        194
    ],
    "u26f9_1f3fd":[
        672,
        675,
        222,
        629,
        222,
        676,
        675,
        672,
        678,
        629,
        672,
        4,
        194
    ],
    "u26f9_1f3fd_200d_2640_fe0f":[
        672,
        1,
        222,
        2,
        222,
        9,
        1,
        672,
        678,
        2,
        672,
        4,
        194
    ],
    "u26f9_1f3fd_200d_2642_fe0f":[
        672,
        662,
        222,
        661,
        222,
        673,
        672,
        678,
        674,
        672,
        4,
        194
    ],
    "u26f9_1f3fe":[
        644,
        675,
        222,
        629,
        222,
        676,
        675,
        644,
        645,
        629,
        644,
        4,
        194
    ],
    "u26f9_1f3fe_200d_2640_fe0f":[
        644,
        1,
        222,
        2,
        222,
        9,
        1,
        644,
        645,
        2,
        644,
        4,
        194
    ],
    "u26f9_1f3fe_200d_2642_fe0f":[
        644,
        662,
        222,
        661,
        222,
        673,
        644,
        645,
        674,
        644,
        4,
        194
    ],
    "u26f9_1f3ff":[
        647,
        675,
        222,
        629,
        222,
        676,
        675,
        647,
        679,
        629,
        647,
        4,
        194
    ],
    "u26f9_1f3ff_200d_2640_fe0f":[
        647,
        1,
        222,
        2,
        222,
        9,
        1,
        647,
        679,
        2,
        647,
        4,
        194
    ],
    "u26f9_1f3ff_200d_2642_fe0f":[
        647,
        662,
        222,
        661,
        222,
        673,
        647,
        679,
        674,
        647,
        4,
        194
    ],
    "u26f9_fe0f_200d_2640_fe0f":[
        649,
        1,
        222,
        2,
        222,
        9,
        1,
        649,
        4,
        2,
        649,
        194
    ],
    "u26f9_fe0f_200d_2642_fe0f":[
        649,
        662,
        222,
        661,
        222,
        673,
        649,
        4,
        674,
        649,
        194
    ],
    "u270a_1f3fb":[
        684,
        639,
        639
    ],
    "u270a_1f3fc":[
        686,
        642,
        642
    ],
    "u270a_1f3fd":[
        688,
        672,
        672
    ],
    "u270a_1f3fe":[
        690,
        644,
        644
    ],
    "u270a_1f3ff":[
        692,
        647,
        647
    ],
    "u270b_1f3fb":[
        684,
        639,
        684
    ],
    "u270b_1f3fc":[
        686,
        642,
        686
    ],
    "u270b_1f3fd":[
        688,
        643,
        688
    ],
    "u270b_1f3fe":[
        690,
        644,
        690
    ],
    "u270b_1f3ff":[
        692,
        647,
        692
    ],
    "u270c_1f3fb":[
        684,
        639,
        639
    ],
    "u270c_1f3fc":[
        686,
        642,
        642
    ],
    "u270c_1f3fd":[
        688,
        672,
        672
    ],
    "u270c_1f3fe":[
        690,
        644,
        644
    ],
    "u270c_1f3ff":[
        692,
        647,
        647
    ],
    "u270d_1f3fb":[
        684,
        697,
        684,
        697,
        684,
        697,
        12,
        12,
        684,
        639,
        684,
        12
    ],
    "u270d_1f3fc":[
        686,
        702,
        686,
        702,
        686,
        702,
        12,
        12,
        686,
        642,
        686,
        12
    ],
    "u270d_1f3fd":[
        688,
        703,
        688,
        703,
        688,
        703,
        12,
        12,
        688,
        643,
        688,
        12
    ],
    "u270d_1f3fe":[
        690,
        704,
        690,
        704,
        690,
        704,
        12,
        12,
        690,
        644,
        690,
        12
    ],
    "u270d_1f3ff":[
        692,
        705,
        692,
        705,
        692,
        705,
        12,
        12,
        692,
        647,
        692,
        12
    ],
    "u2764_fe0f_200d_1f525":[
        13,
        195,
        2,
        195
    ],
    "u2764_fe0f_200d_1fa79":[
        2,
        0,
        641,
        714
    ],
    "u2a_fe0f_20e3":[
        12,
        11,
        11,
        11
    ],
    "u30_fe0f_20e3":[
        12,
        11
    ],
    "u31_fe0f_20e3":[
        12,
        11
    ],
    "u32_fe0f_20e3":[
        12,
        11
    ],
    "u33_fe0f_20e3":[
        12,
        11
    ],
    "u34_fe0f_20e3":[
        12,
        11
    ],
    "u35_fe0f_20e3":[
        12,
        11
    ],
    "u36_fe0f_20e3":[
        12,
        11
    ],
    "u37_fe0f_20e3":[
        12,
        11
    ],
    "u38_fe0f_20e3":[
        12,
        11
    ],
    "u39_fe0f_20e3":[
        12,
        11
    ]
}