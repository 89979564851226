[
    "e6e7e8",
    "be1931",
    "dd2e44",
    "55acee",
    "ffac33",
    "553788",
    "9266cc",
    "edbb9f",
    "662113",
    "a0041e",
    "226699",
    "ffffff",
    "3b88c3",
    "f4900c",
    "00247d",
    "288548",
    "6488bd",
    "297f47",
    "689f9e",
    "481918",
    "3ab1d6",
    "74948f",
    "cfdcd9",
    "3c4544",
    "d1bea7",
    "937a61",
    "af987e",
    "ba8f69",
    "bb6c3f",
    "020202",
    "905f41",
    "8fd2e2",
    "eeeeee",
    "cf1b2b",
    "d01c3a",
    "0020a8",
    "fedf00",
    "c7b37f",
    "af9167",
    "068241",
    "141414",
    "ec2028",
    "007a36",
    "d32011",
    "ff5b57",
    "fcd116",
    "0072c6",
    "ce1126",
    "cccc32",
    "ff9900",
    "99ccff",
    "e41e20",
    "000000",
    "d90012",
    "f2a800",
    "0033a0",
    "ce1b26",
    "f9d616",
    "292f33",
    "265fb5",
    "75aadb",
    "fcbf49",
    "843511",
    "c16540",
    "ed8662",
    "bd1721",
    "d89500",
    "66757f",
    "ffc220",
    "9c3900",
    "ed2939",
    "4189dd",
    "d21034",
    "ffce00",
    "0053a5",
    "e00034",
    "0098c3",
    "00ae65",
    "2d3189",
    "e1e8ed",
    "fbd116",
    "00267f",
    "ffc726",
    "006a4d",
    "f42a41",
    "fdda24",
    "ef3340",
    "009e49",
    "ef2b2d",
    "d62612",
    "00966e",
    "1eb53a",
    "e8112d",
    "008751",
    "ce1a24",
    "f6e4be",
    "bcad96",
    "666666",
    "1353b4",
    "eace23",
    "5e520d",
    "2e8f22",
    "ffe000",
    "64b4d1",
    "cccccc",
    "e4cb5e",
    "9f5a2b",
    "f7e017",
    "cf1b26",
    "007934",
    "d52b1e",
    "a05a2d",
    "e8a30f",
    "e7e7e7",
    "00a6de",
    "b37d0a",
    "32b266",
    "eeeded",
    "012a87",
    "f9d90f",
    "dc171d",
    "009b3a",
    "fedf01",
    "002776",
    "cbe9d4",
    "88c9f9",
    "00abc9",
    "fae042",
    "ff4e11",
    "ffd521",
    "002868",
    "edecec",
    "4aa657",
    "c8313e",
    "003e87",
    "730900",
    "007f00",
    "b34b00",
    "289400",
    "adadad",
    "6699ff",
    "d3d3d3",
    "9ed7ff",
    "006ac8",
    "5ac800",
    "ffd801",
    "ffd83c",
    "782121",
    "892614",
    "fbd44d",
    "9b5f00",
    "008000",
    "802000",
    "007fff",
    "f7d618",
    "ce1021",
    "003082",
    "289728",
    "009543",
    "fbde4a",
    "dc241f",
    "d32d27",
    "f77f00",
    "009e60",
    "1f429b",
    "d42d27",
    "cc212d",
    "288541",
    "fee833",
    "de2910",
    "ffde02",
    "22408c",
    "ce2028",
    "002495",
    "002b7f",
    "ce1225",
    "f4cb58",
    "dca841",
    "89c5e4",
    "088930",
    "002a8f",
    "cf142b",
    "003893",
    "cf2027",
    "f7d116",
    "f9e814",
    "0021ad",
    "1b8a42",
    "ffc639",
    "5c913b",
    "d7141a",
    "11457e",
    "ffcd05",
    "ed1f24",
    "c1694f",
    "ffcc4d",
    "99aab5",
    "77b255",
    "6ab2e7",
    "12ad2b",
    "c60c30",
    "006b3f",
    "006000",
    "00693f",
    "7d76b6",
    "ffd420",
    "002d62",
    "996b38",
    "ffce46",
    "549334",
    "006233",
    "d20f34",
    "c60a1d",
    "ffc400",
    "ea596e",
    "f4a2b2",
    "ed1c23",
    "034ea2",
    "ffdd00",
    "6dbe46",
    "a6d388",
    "8ed2e7",
    "ccd6dd",
    "4891d9",
    "bf9300",
    "007a3d",
    "c4111b",
    "3e9a00",
    "4289de",
    "eb0438",
    "ffc827",
    "fcdd0a",
    "da1219",
    "0f47af",
    "003399",
    "ffcc00",
    "003580",
    "68bfe5",
    "ffd101",
    "964b36",
    "00a651",
    "fff202",
    "ba6747",
    "d7a896",
    "e9cfc6",
    "0072c4",
    "015121",
    "dd8b59",
    "fecf3d",
    "ff0100",
    "75b2dd",
    "0065bd",
    "3a75c4",
    "007a5e",
    "078930",
    "fcdd09",
    "da121a",
    "f9dd16",
    "da1c0b",
    "fff100",
    "d00c33",
    "cc162b",
    "3c762c",
    "0d218a",
    "009460",
    "002488",
    "e32118",
    "0173ce",
    "ffd701",
    "a36629",
    "009a3b",
    "0d5eb0",
    "cdad57",
    "923f00",
    "1500ff",
    "656263",
    "adb0b2",
    "6a4c2d",
    "00713d",
    "1e5aa6",
    "016c00",
    "8b9497",
    "f66b01",
    "f8f001",
    "c11600",
    "ffbc00",
    "4997d0",
    "437e24",
    "b1b6ba",
    "6c301e",
    "fab81b",
    "f9f0aa",
    "e2cf81",
    "00297b",
    "017bdd",
    "66afeb",
    "9c8431",
    "029270",
    "7b4800",
    "0156a3",
    "2657a7",
    "171796",
    "0193dd",
    "d21c33",
    "00209f",
    "006a16",
    "f1b517",
    "cd2a3e",
    "436f4d",
    "0868a9",
    "c09b5a",
    "8c8d8f",
    "ebd11b",
    "be1827",
    "4c86c7",
    "a5a7a9",
    "dc1f26",
    "169b62",
    "ff883e",
    "0038b8",
    "f8dc15",
    "138808",
    "ff9933",
    "f7f7f7",
    "000080",
    "6666b3",
    "007a3c",
    "da0001",
    "239f40",
    "e96667",
    "7bc58c",
    "003897",
    "d72828",
    "ce2b37",
    "009246",
    "d52048",
    "231f20",
    "11429a",
    "ffd202",
    "ed1b2f",
    "006600",
    "bb1600",
    "032ea1",
    "e01e24",
    "db7f86",
    "f15a29",
    "ffc61e",
    "3d8e33",
    "024fa2",
    "ed1c27",
    "003478",
    "f9d050",
    "db1c1c",
    "0b50a0",
    "059334",
    "073163",
    "00493d",
    "00afca",
    "66ccff",
    "b28914",
    "ffd83d",
    "ffb700",
    "8d2129",
    "005641",
    "ff5b00",
    "bf0a30",
    "53646d",
    "006a44",
    "fdb913",
    "c1272d",
    "00a1de",
    "9e3039",
    "e70013",
    "239e46",
    "a0724a",
    "fc3d32",
    "007e3a",
    "d20000",
    "ffe600",
    "14b53a",
    "ea2839",
    "34b232",
    "fecb01",
    "c4272f",
    "005197",
    "f9cf01",
    "00785e",
    "ffb636",
    "b58fe5",
    "6b26b5",
    "8c8a8c",
    "207900",
    "103800",
    "de200f",
    "ffe301",
    "21428e",
    "ce2540",
    "0f6433",
    "ffc514",
    "9f1d22",
    "77b253",
    "faaa34",
    "f6ddcd",
    "955c0f",
    "b2c0c9",
    "1a206d",
    "ffd500",
    "00a551",
    "006847",
    "010066",
    "fce100",
    "007168",
    "ed4135",
    "0035ad",
    "fae600",
    "e05206",
    "0db02b",
    "009a49",
    "a9bf4c",
    "bbddf5",
    "e2f09f",
    "ae1f28",
    "20478b",
    "22408b",
    "dd2f45",
    "ffe150",
    "004a77",
    "db161b",
    "005293",
    "d91023",
    "083e9d",
    "ff9d11",
    "630810",
    "0038a8",
    "004600",
    "dc143c",
    "0092c8",
    "ca0000",
    "009a67",
    "d4d5d6",
    "4d8fcc",
    "ea0b19",
    "0f55ec",
    "007229",
    "829acd",
    "4aadd6",
    "ffde00",
    "007127",
    "8d1b3d",
    "3366ff",
    "ff0000",
    "ffff00",
    "0c4076",
    "c6363c",
    "edb92e",
    "20603d",
    "fad201",
    "006c35",
    "0051ba",
    "215b33",
    "d62828",
    "fcd856",
    "003f87",
    "006aa7",
    "fecc00",
    "8fc5ff",
    "366cc9",
    "00d860",
    "5d3100",
    "cf6200",
    "ac5f1a",
    "005da4",
    "ee2024",
    "0a4ea2",
    "5eb6e4",
    "a0cfeb",
    "8bb273",
    "658d5c",
    "f5f8fa",
    "00853f",
    "fdef42",
    "e31b23",
    "377e3f",
    "b40a2d",
    "ecc81d",
    "1f601a",
    "06209f",
    "ffcc01",
    "017e93",
    "e60000",
    "032a87",
    "dc181e",
    "fada10",
    "bd725f",
    "63b0e2",
    "018830",
    "b6b6b6",
    "3e5eb9",
    "ffd900",
    "b10d0d",
    "3d5da7",
    "a70000",
    "c0e6e9",
    "ae7521",
    "c7e9eb",
    "9fc2ff",
    "00a3d6",
    "8da8c3",
    "cc1530",
    "d9a323",
    "179af9",
    "d8ab6d",
    "fbfae7",
    "fec400",
    "00a728",
    "f0082d",
    "ff9f3e",
    "ff927f",
    "fe9de0",
    "ba5b1f",
    "002664",
    "fecb00",
    "002395",
    "006a4e",
    "a7122d",
    "292648",
    "cc0000",
    "fed100",
    "29ae66",
    "ca3745",
    "faae29",
    "c10000",
    "e30917",
    "5b97b1",
    "fe0000",
    "000095",
    "00a3dd",
    "fbd035",
    "005bbb",
    "d90001",
    "fcdc02",
    "d90000",
    "9ca69c",
    "b22334",
    "3c3b6e",
    "4b92db",
    "ffd983",
    "0099b5",
    "fddf00",
    "a1a1a1",
    "cb9c2b",
    "b4b4b5",
    "ec1c24",
    "006129",
    "ffc6b5",
    "9c5100",
    "f8c600",
    "379543",
    "0081c7",
    "f4c63d",
    "a70033",
    "172668",
    "da251d",
    "fdce12",
    "244aa5",
    "d0a650",
    "ededed",
    "d42e11",
    "de3830",
    "ffb611",
    "007a4d",
    "1a8a00",
    "ef7d00",
    "ffd200",
    "319209",
    "fe5011",
    "744eaa",
    "3f484c",
    "ffe8b6",
    "3e721d",
    "485359",
    "8767ac",
    "eb2027",
    "f19020",
    "ffcb4c",
    "5c903f",
    "226798",
    "4b545d",
    "e95f28",
    "aab8c2",
    "aa8dd8",
    "cbb7ea",
    "5b6876",
    "5dadec",
    "8899a6",
    "788895",
    "d99e82",
    "f7bba6",
    "bdbdbd",
    "808285",
    "a6a6a6",
    "d8d8d8",
    "a95233",
    "da2f47",
    "f4abba",
    "732700",
    "f7b82d",
    "a06253",
    "ff886c",
    "e29386",
    "e6e9ea",
    "939598",
    "f1f2f2",
    "aa695b",
    "cc927a",
    "b90053",
    "8e0042",
    "ffd671",
    "fcb1e3",
    "ff839b",
    "d372b8",
    "f9d2dc",
    "f98097",
    "8a4b38",
    "da9f83",
    "b2c2cc",
    "b26304",
    "b27962",
    "cc6f00",
    "fa743e",
    "e4ebef",
    "264612",
    "99aab5",
    "fdd888",
    "fcab40",
    "fff8e8",
    "faaa35",
    "3f7123",
    "642116",
    "f7dece",
    "b7755e",
    "d1d3d4",
    "f3d2a2",
    "d5ab88",
    "af7e57",
    "60352a",
    "915a34",
    "7c533e",
    "3d2e24",
    "ffdc5d",
    "bb1a34",
    "e8eaeb",
    "f26522",
    "d14a15",
    "ffb699",
    "27aae1",
    "005792",
    "26aae1",
    "31373d",
    "394146",
    "67757f",
    "3b94d9",
    "1c6399",
    "c6e5b3",
    "14171a",
    "50a5e6",
    "000000",
    "3a87c2",
    "fdcb58",
    "9aaab4",
    "f18f26",
    "bdddf4",
    "d4ab88",
    "357baa",
    "4289c1",
    "dd551f",
    "c63900",
    "ffe51e",
    "963b22",
    "0b0200",
    "623120",
    "f2d1a1",
    "ae7e57",
    "7c543e",
    "e0aa94",
    "d89882",
    "d2a077",
    "c68f6a",
    "b78b60",
    "aa8052",
    "90603e",
    "845636",
    "583529",
    "543226",
    "ef9645",
    "657786",
    "3a6693",
    "eec2ad",
    "2a6797",
    "8e9195",
    "b7c0c1",
    "a0a7ad",
    "e2c196",
    "cc9b7a",
    "9b6a49",
    "664131",
    "f9ca55",
    "414042",
    "58595b",
    "f1f1f1",
    "a8b6c0",
    "78b159",
    "bd2032",
    "cdd7df",
    "bcbec0",
    "ccd6dc",
    "e9eff3",
    "7450a0",
    "dac8b1",
    "f1dcc1",
    "ffd4de",
    "6d6e71",
    "a7a9ac",
    "f2f9ff",
    "880082",
    "3558a0",
    "138f3e",
    "fad220",
    "ff7300",
    "ff000e",
    "5bcefa",
    "f5a9b8",
    "ce1124",
    "272b2b",
    "e6aaaa",
    "c3c914",
    "7f676d",
    "ffcc4e",
    "e75a70",
    "fee7b8",
    "ffdd99",
    "bf6952",
    "d79e84",
    "f4c7b5",
    "4292e0",
    "1f2326",
    "77bcf7",
    "380f09",
    "ad743d",
    "8b5e3c",
    "dfa994",
    "d19f77",
    "b68a60",
    "8f613e",
    "ffdb5e",
    "ee9547",
    "981ceb",
    "4ca0d3",
    "c36446",
    "803f29",
    "df1f32",
    "205e7a",
    "8cc6eb",
    "1d6398",
    "4191ce",
    "ecc0ac",
    "662213",
    "424143",
    "59595c",
    "f4f7f9",
    "ffffff",
    "a8bccc",
    "ce9e5d",
    "fff35f",
    "323a3f",
    "e0bf94",
    "cb9a7a",
    "9a6a4a",
    "915b34",
    "674232",
    "3e2e24",
    "f8c954",
    "3b93d9",
    "1b6399",
    "9268ca",
    "7450a8",
    "a88dc1",
    "7355a2",
    "522e8d",
    "de2032",
    "aa8ed6",
    "193d59",
    "1e4b6e",
    "662214",
    "f2760f",
    "fff75f",
    "8ccaf7",
    "553986",
    "e39c2f",
    "add3e0",
    "c1e1ea",
    "d2ecf3",
    "f2abba",
    "e5a157",
    "edb980",
    "a7d28b",
    "cbb8e9",
    "aebbc1",
    "9a4e1c",
    "e0e7ec",
    "e39f3d",
    "9d0522",
    "854836",
    "47ded4",
    "ffff87",
    "7d8b93",
    "c16a4f",
    "98a9b4",
    "546170",
    "8a4633",
    "cdd6dd",
    "157569",
    "3fc9b9",
    "664500",
    "ff7892",
    "e75b70",
    "de3146",
    "66471b",
    "e8596e",
    "dee7ed",
    "f3f7f9",
    "c9d5dd",
    "65471b",
    "fdd54c",
    "f5bf44",
    "9b3c07",
    "ec9dad",
    "ac5640",
    "5d9040",
    "ff8044",
    "c7521e",
    "ffa06c",
    "662113",
    "662113",
    "c1694f",
    "c1694f",
    "aeb3b2",
    "fa8948",
    "faa572",
    "fac19c",
    "191919",
    "313131",
    "787878",
    "e1e1e1",
    "ff4018",
    "e2121c",
    "a6d488",
    "1e5200",
    "f492a9",
    "452e04",
    "b55005",
    "825d0e",
    "64aadd",
    "4f140b",
    "894935",
    "49251b",
    "77472b",
    "77af57",
    "5d8f3f",
    "f36c24",
    "ce5c1f",
    "dfe7ec",
    "ffd03f",
    "ffad1f",
    "4e9322",
    "3c96ce",
    "c6e4b5",
    "e27e00",
    "ffb900",
    "f4bd61",
    "e9ab61",
    "e4973e",
    "8c989f",
    "be2032",
    "9e5200",
    "627077",
    "ff8a3b",
    "7c4119",
    "e2ea55",
    "beca28",
    "db7803",
    "7c3225",
    "d67503",
    "edb97f",
    "929497",
    "e18524",
    "e5922e",
    "d17a28",
    "ffd882",
    "603913",
    "a97c50",
    "ad5810",
    "679e44",
    "ea564b",
    "f97253",
    "994500",
    "e2a62d",
    "42484c",
    "66a141",
    "ff960d",
    "c1cdd5",
    "60379a",
    "758795",
    "53626c",
    "ee7c0e",
    "c43512",
    "546066",
    "754c29",
    "3e701e",
    "5c913a",
    "77b155",
    "292e32",
    "a78e81",
    "846659",
    "cab8ac",
    "89021f",
    "da9e82",
    "ffe9b7",
    "662113",
    "e08110",
    "7a5c44",
    "e2d2c7",
    "e26000",
    "d9b981",
    "c45400",
    "c09154",
    "93493b",
    "377700",
    "489c00",
    "f2c994",
    "994308",
    "eb7421",
    "fcfb65",
    "e3a919",
    "d39954",
    "edd6a6",
    "e2a759",
    "c15829",
    "933813",
    "a0ad55",
    "c4ce89",
    "d3790d",
    "d3790d",
    "a5503c",
    "cfe1ea",
    "9bc2d4",
    "d3a471",
    "ecd2a7",
    "898379",
    "4a4153",
    "69615d",
    "744629",
    "ffeba5",
    "ff7862",
    "de2f33",
    "ff5540",
    "ff605e",
    "7f331c",
    "4d2b22",
    "212629",
    "a47de2",
    "ffffff",
    "fa8c56",
    "b0f0ff",
    "abdfff",
    "2cb4cd",
    "dd415d",
    "316116",
    "66a644",
    "184f87",
    "539ae0",
    "2b7bb9",
    "fa3c32",
    "dc282c",
    "b3282c",
    "ffe933",
    "68e090",
    "17bf63",
    "934035",
    "ffccd6",
    "1213bd",
    "2976aa",
    "1d567c",
    "eabaa7",
    "f7ded5",
    "e954d2",
    "e85e00",
    "a4523a",
    "7f031e",
    "464f25",
    "383a18",
    "717735",
    "a3a364",
    "677032",
    "b52e04",
    "ffffff",
    "dd637f",
    "bf345d",
    "eb8caa",
    "3d130c",
    "511212",
    "f98e00",
    "289193",
    "3db8c1",
    "ade3ff",
    "f0ffff",
    "a8c1ff",
    "f1c6ff",
    "ccb3ff",
    "b0f7ff",
    "49a8ff",
    "dfcebf",
    "fffff5",
    "7d7585",
    "d42544",
    "ff475c",
    "a8e0f9",
    "e1e8ed",
    "ef8ea7",
    "bf345d",
    "d65073",
    "a04e3c",
    "ef8294",
    "fbbea9",
    "bd9366",
    "a57f5a",
    "936d46",
    "dead74",
    "f7dae2",
    "d84864",
    "5b9239",
    "a0aab1",
    "e9edf0",
    "5864b7",
    "334372",
    "253356",
    "909b50",
    "c7cc62",
    "964f45",
    "c4c9c8",
    "d9dddc",
    "a6373b",
    "ca694d",
    "ae3d2f",
    "f6cfb2",
    "fff5db",
    "78273b",
    "a8e0f9",
    "d67909",
    "b3bec4",
    "c6d5db",
    "edf1f4",
    "8899a7"
]