{
    "people":[
        "u1f600",
        "u1f603",
        "u1f604",
        "u1f601",
        "u1f606",
        "u1f605",
        "u1f923",
        "u1f602",
        "u1f642",
        "u1f643",
        "u1fae0",
        "u1f609",
        "u1f60a",
        "u1f607",
        "u1f970",
        "u1f60d",
        "u1f929",
        "u1f618",
        "u1f617",
        "u263a",
        "u1f61a",
        "u1f619",
        "u1f972",
        "u1f60b",
        "u1f61b",
        "u1f61c",
        "u1f92a",
        "u1f61d",
        "u1f911",
        "u1f917",
        "u1f92d",
        "u1fae2",
        "u1fae3",
        "u1f92b",
        "u1f914",
        "u1fae1",
        "u1f910",
        "u1f928",
        "u1f610",
        "u1f611",
        "u1f636",
        "u1fae5",
        "u1f636_200d_1f32b_fe0f",
        "u1f60f",
        "u1f612",
        "u1f644",
        "u1f62c",
        "u1f62e_200d_1f4a8",
        "u1f925",
        "u1f60c",
        "u1f614",
        "u1f62a",
        "u1f924",
        "u1f634",
        "u1f637",
        "u1f912",
        "u1f915",
        "u1f922",
        "u1f92e",
        "u1f927",
        "u1f975",
        "u1f976",
        "u1f974",
        "u1f635",
        "u1f635_200d_1f4ab",
        "u1f92f",
        "u1f920",
        "u1f973",
        "u1f978",
        "u1f60e",
        "u1f913",
        "u1f9d0",
        "u1f615",
        "u1fae4",
        "u1f61f",
        "u1f641",
        "u2639",
        "u1f62e",
        "u1f62f",
        "u1f632",
        "u1f633",
        "u1f97a",
        "u1f979",
        "u1f626",
        "u1f627",
        "u1f628",
        "u1f630",
        "u1f625",
        "u1f622",
        "u1f62d",
        "u1f631",
        "u1f616",
        "u1f623",
        "u1f61e",
        "u1f613",
        "u1f629",
        "u1f62b",
        "u1f971",
        "u1f624",
        "u1f621",
        "u1f620",
        "u1f92c",
        "u1f608",
        "u1f47f",
        "u1f480",
        "u2620",
        "u1f4a9",
        "u1f921",
        "u1f479",
        "u1f47a",
        "u1f47b",
        "u1f47d",
        "u1f47e",
        "u1f916",
        "u1f44b",
        "u1f91a",
        "u1f590",
        "u270b",
        "u1f596",
        "u1faf1",
        "u1faf2",
        "u1faf3",
        "u1faf4",
        "u1f44c",
        "u1f90c",
        "u1f90f",
        "u270c",
        "u1f91e",
        "u1faf0",
        "u1f91f",
        "u1f918",
        "u1f919",
        "u1f448",
        "u1f449",
        "u1f446",
        "u1f595",
        "u1f447",
        "u261d",
        "u1faf5",
        "u1f44d",
        "u1f44e",
        "u270a",
        "u1f44a",
        "u1f91b",
        "u1f91c",
        "u1f44f",
        "u1f64c",
        "u1faf6",
        "u1f450",
        "u1f932",
        "u1f91d",
        "u1f64f",
        "u270d",
        "u1f485",
        "u1f933",
        "u1f4aa",
        "u1f9be",
        "u1f9bf",
        "u1f9b5",
        "u1f9b6",
        "u1f442",
        "u1f9bb",
        "u1f443",
        "u1f9e0",
        "u1fac0",
        "u1fac1",
        "u1f9b7",
        "u1f9b4",
        "u1f440",
        "u1f441",
        "u1f445",
        "u1f444",
        "u1fae6",
        "u1f476",
        "u1f9d2",
        "u1f466",
        "u1f467",
        "u1f9d1",
        "u1f471",
        "u1f468",
        "u1f9d4",
        "u1f9d4_200d_2642_fe0f",
        "u1f9d4_200d_2640_fe0f",
        "u1f468_200d_1f9b0",
        "u1f468_200d_1f9b1",
        "u1f468_200d_1f9b3",
        "u1f468_200d_1f9b2",
        "u1f469",
        "u1f469_200d_1f9b0",
        "u1f9d1_200d_1f9b0",
        "u1f469_200d_1f9b1",
        "u1f9d1_200d_1f9b1",
        "u1f469_200d_1f9b3",
        "u1f9d1_200d_1f9b3",
        "u1f469_200d_1f9b2",
        "u1f9d1_200d_1f9b2",
        "u1f471_200d_2640_fe0f",
        "u1f471_200d_2642_fe0f",
        "u1f9d3",
        "u1f474",
        "u1f475",
        "u1f64d",
        "u1f64d_200d_2642_fe0f",
        "u1f64d_200d_2640_fe0f",
        "u1f64e",
        "u1f64e_200d_2642_fe0f",
        "u1f64e_200d_2640_fe0f",
        "u1f645",
        "u1f645_200d_2642_fe0f",
        "u1f645_200d_2640_fe0f",
        "u1f646",
        "u1f646_200d_2642_fe0f",
        "u1f646_200d_2640_fe0f",
        "u1f481",
        "u1f481_200d_2642_fe0f",
        "u1f481_200d_2640_fe0f",
        "u1f64b",
        "u1f64b_200d_2642_fe0f",
        "u1f64b_200d_2640_fe0f",
        "u1f9cf",
        "u1f9cf_200d_2642_fe0f",
        "u1f9cf_200d_2640_fe0f",
        "u1f647",
        "u1f647_200d_2642_fe0f",
        "u1f647_200d_2640_fe0f",
        "u1f926",
        "u1f926_200d_2642_fe0f",
        "u1f926_200d_2640_fe0f",
        "u1f937",
        "u1f937_200d_2642_fe0f",
        "u1f937_200d_2640_fe0f",
        "u1f9d1_200d_2695_fe0f",
        "u1f468_200d_2695_fe0f",
        "u1f469_200d_2695_fe0f",
        "u1f9d1_200d_1f393",
        "u1f468_200d_1f393",
        "u1f469_200d_1f393",
        "u1f9d1_200d_1f3eb",
        "u1f468_200d_1f3eb",
        "u1f469_200d_1f3eb",
        "u1f9d1_200d_2696_fe0f",
        "u1f468_200d_2696_fe0f",
        "u1f469_200d_2696_fe0f",
        "u1f9d1_200d_1f33e",
        "u1f468_200d_1f33e",
        "u1f469_200d_1f33e",
        "u1f9d1_200d_1f373",
        "u1f468_200d_1f373",
        "u1f469_200d_1f373",
        "u1f9d1_200d_1f527",
        "u1f468_200d_1f527",
        "u1f469_200d_1f527",
        "u1f9d1_200d_1f3ed",
        "u1f468_200d_1f3ed",
        "u1f469_200d_1f3ed",
        "u1f9d1_200d_1f4bc",
        "u1f468_200d_1f4bc",
        "u1f469_200d_1f4bc",
        "u1f9d1_200d_1f52c",
        "u1f468_200d_1f52c",
        "u1f469_200d_1f52c",
        "u1f9d1_200d_1f4bb",
        "u1f468_200d_1f4bb",
        "u1f469_200d_1f4bb",
        "u1f9d1_200d_1f3a4",
        "u1f468_200d_1f3a4",
        "u1f469_200d_1f3a4",
        "u1f9d1_200d_1f3a8",
        "u1f468_200d_1f3a8",
        "u1f469_200d_1f3a8",
        "u1f9d1_200d_2708_fe0f",
        "u1f468_200d_2708_fe0f",
        "u1f469_200d_2708_fe0f",
        "u1f9d1_200d_1f680",
        "u1f468_200d_1f680",
        "u1f469_200d_1f680",
        "u1f9d1_200d_1f692",
        "u1f468_200d_1f692",
        "u1f469_200d_1f692",
        "u1f46e",
        "u1f46e_200d_2642_fe0f",
        "u1f46e_200d_2640_fe0f",
        "u1f575",
        "u1f575_fe0f_200d_2642_fe0f",
        "u1f575_fe0f_200d_2640_fe0f",
        "u1f482",
        "u1f482_200d_2642_fe0f",
        "u1f482_200d_2640_fe0f",
        "u1f977",
        "u1f477",
        "u1f477_200d_2642_fe0f",
        "u1f477_200d_2640_fe0f",
        "u1fac5",
        "u1f934",
        "u1f478",
        "u1f473",
        "u1f473_200d_2642_fe0f",
        "u1f473_200d_2640_fe0f",
        "u1f472",
        "u1f9d5",
        "u1f935",
        "u1f935_200d_2642_fe0f",
        "u1f935_200d_2640_fe0f",
        "u1f470",
        "u1f470_200d_2642_fe0f",
        "u1f470_200d_2640_fe0f",
        "u1f930",
        "u1fac3",
        "u1fac4",
        "u1f931",
        "u1f469_200d_1f37c",
        "u1f468_200d_1f37c",
        "u1f9d1_200d_1f37c",
        "u1f47c",
        "u1f385",
        "u1f936",
        "u1f9d1_200d_1f384",
        "u1f9b8",
        "u1f9b8_200d_2642_fe0f",
        "u1f9b8_200d_2640_fe0f",
        "u1f9b9",
        "u1f9b9_200d_2642_fe0f",
        "u1f9b9_200d_2640_fe0f",
        "u1f9d9",
        "u1f9d9_200d_2642_fe0f",
        "u1f9d9_200d_2640_fe0f",
        "u1f9da",
        "u1f9da_200d_2642_fe0f",
        "u1f9da_200d_2640_fe0f",
        "u1f9db",
        "u1f9db_200d_2642_fe0f",
        "u1f9db_200d_2640_fe0f",
        "u1f9dc",
        "u1f9dc_200d_2642_fe0f",
        "u1f9dc_200d_2640_fe0f",
        "u1f9dd",
        "u1f9dd_200d_2642_fe0f",
        "u1f9dd_200d_2640_fe0f",
        "u1f9de",
        "u1f9de_200d_2642_fe0f",
        "u1f9de_200d_2640_fe0f",
        "u1f9df",
        "u1f9df_200d_2642_fe0f",
        "u1f9df_200d_2640_fe0f",
        "u1f9cc",
        "u1f486",
        "u1f486_200d_2642_fe0f",
        "u1f486_200d_2640_fe0f",
        "u1f487",
        "u1f487_200d_2642_fe0f",
        "u1f487_200d_2640_fe0f",
        "u1f6b6",
        "u1f6b6_200d_2642_fe0f",
        "u1f6b6_200d_2640_fe0f",
        "u1f9cd",
        "u1f9cd_200d_2642_fe0f",
        "u1f9cd_200d_2640_fe0f",
        "u1f9ce",
        "u1f9ce_200d_2642_fe0f",
        "u1f9ce_200d_2640_fe0f",
        "u1f9d1_200d_1f9af",
        "u1f468_200d_1f9af",
        "u1f469_200d_1f9af",
        "u1f9d1_200d_1f9bc",
        "u1f468_200d_1f9bc",
        "u1f469_200d_1f9bc",
        "u1f9d1_200d_1f9bd",
        "u1f468_200d_1f9bd",
        "u1f469_200d_1f9bd",
        "u1f3c3",
        "u1f3c3_200d_2642_fe0f",
        "u1f3c3_200d_2640_fe0f",
        "u1f483",
        "u1f57a",
        "u1f574",
        "u1f46f",
        "u1f46f_200d_2642_fe0f",
        "u1f46f_200d_2640_fe0f",
        "u1f9d6",
        "u1f9d6_200d_2642_fe0f",
        "u1f9d6_200d_2640_fe0f",
        "u1f9d7",
        "u1f9d7_200d_2642_fe0f",
        "u1f9d7_200d_2640_fe0f",
        "u1f93a",
        "u1f3c7",
        "u26f7",
        "u1f3c2",
        "u1f3cc",
        "u1f3cc_fe0f_200d_2642_fe0f",
        "u1f3cc_fe0f_200d_2640_fe0f",
        "u1f3c4",
        "u1f3c4_200d_2642_fe0f",
        "u1f3c4_200d_2640_fe0f",
        "u1f6a3",
        "u1f6a3_200d_2642_fe0f",
        "u1f6a3_200d_2640_fe0f",
        "u1f3ca",
        "u1f3ca_200d_2642_fe0f",
        "u1f3ca_200d_2640_fe0f",
        "u26f9",
        "u26f9_fe0f_200d_2642_fe0f",
        "u26f9_fe0f_200d_2640_fe0f",
        "u1f3cb",
        "u1f3cb_fe0f_200d_2642_fe0f",
        "u1f3cb_fe0f_200d_2640_fe0f",
        "u1f6b4",
        "u1f6b4_200d_2642_fe0f",
        "u1f6b4_200d_2640_fe0f",
        "u1f6b5",
        "u1f6b5_200d_2642_fe0f",
        "u1f6b5_200d_2640_fe0f",
        "u1f938",
        "u1f938_200d_2642_fe0f",
        "u1f938_200d_2640_fe0f",
        "u1f93c",
        "u1f93c_200d_2642_fe0f",
        "u1f93c_200d_2640_fe0f",
        "u1f93d",
        "u1f93d_200d_2642_fe0f",
        "u1f93d_200d_2640_fe0f",
        "u1f93e",
        "u1f93e_200d_2642_fe0f",
        "u1f93e_200d_2640_fe0f",
        "u1f939",
        "u1f939_200d_2642_fe0f",
        "u1f939_200d_2640_fe0f",
        "u1f9d8",
        "u1f9d8_200d_2642_fe0f",
        "u1f9d8_200d_2640_fe0f",
        "u1f6c0",
        "u1f6cc",
        "u1f9d1_200d_1f91d_200d_1f9d1",
        "u1f46d",
        "u1f46b",
        "u1f46c",
        "u1f48f",
        "u1f469_200d_2764_fe0f_200d_1f48b_200d_1f468",
        "u1f468_200d_2764_fe0f_200d_1f48b_200d_1f468",
        "u1f469_200d_2764_fe0f_200d_1f48b_200d_1f469",
        "u1f491",
        "u1f469_200d_2764_fe0f_200d_1f468",
        "u1f468_200d_2764_fe0f_200d_1f468",
        "u1f469_200d_2764_fe0f_200d_1f469",
        "u1f46a",
        "u1f468_200d_1f469_200d_1f466",
        "u1f468_200d_1f469_200d_1f467",
        "u1f468_200d_1f469_200d_1f467_200d_1f466",
        "u1f468_200d_1f469_200d_1f466_200d_1f466",
        "u1f468_200d_1f469_200d_1f467_200d_1f467",
        "u1f468_200d_1f468_200d_1f466",
        "u1f468_200d_1f468_200d_1f467",
        "u1f468_200d_1f468_200d_1f467_200d_1f466",
        "u1f468_200d_1f468_200d_1f466_200d_1f466",
        "u1f468_200d_1f468_200d_1f467_200d_1f467",
        "u1f469_200d_1f469_200d_1f466",
        "u1f469_200d_1f469_200d_1f467",
        "u1f469_200d_1f469_200d_1f467_200d_1f466",
        "u1f469_200d_1f469_200d_1f466_200d_1f466",
        "u1f469_200d_1f469_200d_1f467_200d_1f467",
        "u1f468_200d_1f466",
        "u1f468_200d_1f466_200d_1f466",
        "u1f468_200d_1f467",
        "u1f468_200d_1f467_200d_1f466",
        "u1f468_200d_1f467_200d_1f467",
        "u1f469_200d_1f466",
        "u1f469_200d_1f466_200d_1f466",
        "u1f469_200d_1f467",
        "u1f469_200d_1f467_200d_1f466",
        "u1f469_200d_1f467_200d_1f467",
        "u1f5e3",
        "u1f464",
        "u1f465",
        "u1fac2",
        "u1f463",
        "u1f63a",
        "u1f638",
        "u1f639",
        "u1f63b",
        "u1f63c",
        "u1f63d",
        "u1f640",
        "u1f63f",
        "u1f63e",
        "u1f648",
        "u1f649",
        "u1f64a",
        "u1f48b",
        "u1f48c",
        "u1f498",
        "u1f49d",
        "u1f496",
        "u1f497",
        "u1f493",
        "u1f49e",
        "u1f495",
        "u1f49f",
        "u2763",
        "u1f494",
        "u2764_fe0f_200d_1f525",
        "u2764_fe0f_200d_1fa79",
        "u2764",
        "u1f9e1",
        "u1f49b",
        "u1f49a",
        "u1f499",
        "u1f49c",
        "u1f90e",
        "u1f5a4",
        "u1f90d",
        "u1f4af",
        "u1f4a2",
        "u1f4a5",
        "u1f4ab",
        "u1f4a6",
        "u1f4a8",
        "u1f573",
        "u1f4a3",
        "u1f4ac",
        "u1f441_fe0f_200d_1f5e8_fe0f",
        "u1f5e8",
        "u1f5ef",
        "u1f4ad",
        "u1f4a4"
    ],
    "nature":[
        "u1f435",
        "u1f412",
        "u1f98d",
        "u1f9a7",
        "u1f436",
        "u1f415",
        "u1f9ae",
        "u1f415_200d_1f9ba",
        "u1f429",
        "u1f43a",
        "u1f98a",
        "u1f99d",
        "u1f431",
        "u1f408",
        "u1f408_200d_2b1b",
        "u1f981",
        "u1f42f",
        "u1f405",
        "u1f406",
        "u1f434",
        "u1f40e",
        "u1f984",
        "u1f993",
        "u1f98c",
        "u1f9ac",
        "u1f42e",
        "u1f402",
        "u1f403",
        "u1f404",
        "u1f437",
        "u1f416",
        "u1f417",
        "u1f43d",
        "u1f40f",
        "u1f411",
        "u1f410",
        "u1f42a",
        "u1f42b",
        "u1f999",
        "u1f992",
        "u1f418",
        "u1f9a3",
        "u1f98f",
        "u1f99b",
        "u1f42d",
        "u1f401",
        "u1f400",
        "u1f439",
        "u1f430",
        "u1f407",
        "u1f43f",
        "u1f9ab",
        "u1f994",
        "u1f987",
        "u1f43b",
        "u1f43b_200d_2744_fe0f",
        "u1f428",
        "u1f43c",
        "u1f9a5",
        "u1f9a6",
        "u1f9a8",
        "u1f998",
        "u1f9a1",
        "u1f43e",
        "u1f983",
        "u1f414",
        "u1f413",
        "u1f423",
        "u1f424",
        "u1f425",
        "u1f426",
        "u1f427",
        "u1f54a",
        "u1f985",
        "u1f986",
        "u1f9a2",
        "u1f989",
        "u1f9a4",
        "u1fab6",
        "u1f9a9",
        "u1f99a",
        "u1f99c",
        "u1f438",
        "u1f40a",
        "u1f422",
        "u1f98e",
        "u1f40d",
        "u1f432",
        "u1f409",
        "u1f995",
        "u1f996",
        "u1f433",
        "u1f40b",
        "u1f42c",
        "u1f9ad",
        "u1f41f",
        "u1f420",
        "u1f421",
        "u1f988",
        "u1f419",
        "u1f41a",
        "u1fab8",
        "u1f40c",
        "u1f98b",
        "u1f41b",
        "u1f41c",
        "u1f41d",
        "u1fab2",
        "u1f41e",
        "u1f997",
        "u1fab3",
        "u1f577",
        "u1f578",
        "u1f982",
        "u1f99f",
        "u1fab0",
        "u1fab1",
        "u1f9a0",
        "u1f490",
        "u1f338",
        "u1f4ae",
        "u1fab7",
        "u1f3f5",
        "u1f339",
        "u1f940",
        "u1f33a",
        "u1f33b",
        "u1f33c",
        "u1f337",
        "u1f331",
        "u1fab4",
        "u1f332",
        "u1f333",
        "u1f334",
        "u1f335",
        "u1f33e",
        "u1f33f",
        "u2618",
        "u1f340",
        "u1f341",
        "u1f342",
        "u1f343",
        "u1fab9",
        "u1faba"
    ],
    "foods":[
        "u1f347",
        "u1f348",
        "u1f349",
        "u1f34a",
        "u1f34b",
        "u1f34c",
        "u1f34d",
        "u1f96d",
        "u1f34e",
        "u1f34f",
        "u1f350",
        "u1f351",
        "u1f352",
        "u1f353",
        "u1fad0",
        "u1f95d",
        "u1f345",
        "u1fad2",
        "u1f965",
        "u1f951",
        "u1f346",
        "u1f954",
        "u1f955",
        "u1f33d",
        "u1f336",
        "u1fad1",
        "u1f952",
        "u1f96c",
        "u1f966",
        "u1f9c4",
        "u1f9c5",
        "u1f344",
        "u1f95c",
        "u1fad8",
        "u1f330",
        "u1f35e",
        "u1f950",
        "u1f956",
        "u1fad3",
        "u1f968",
        "u1f96f",
        "u1f95e",
        "u1f9c7",
        "u1f9c0",
        "u1f356",
        "u1f357",
        "u1f969",
        "u1f953",
        "u1f354",
        "u1f35f",
        "u1f355",
        "u1f32d",
        "u1f96a",
        "u1f32e",
        "u1f32f",
        "u1fad4",
        "u1f959",
        "u1f9c6",
        "u1f95a",
        "u1f373",
        "u1f958",
        "u1f372",
        "u1fad5",
        "u1f963",
        "u1f957",
        "u1f37f",
        "u1f9c8",
        "u1f9c2",
        "u1f96b",
        "u1f371",
        "u1f358",
        "u1f359",
        "u1f35a",
        "u1f35b",
        "u1f35c",
        "u1f35d",
        "u1f360",
        "u1f362",
        "u1f363",
        "u1f364",
        "u1f365",
        "u1f96e",
        "u1f361",
        "u1f95f",
        "u1f960",
        "u1f961",
        "u1f980",
        "u1f99e",
        "u1f990",
        "u1f991",
        "u1f9aa",
        "u1f366",
        "u1f367",
        "u1f368",
        "u1f369",
        "u1f36a",
        "u1f382",
        "u1f370",
        "u1f9c1",
        "u1f967",
        "u1f36b",
        "u1f36c",
        "u1f36d",
        "u1f36e",
        "u1f36f",
        "u1f37c",
        "u1f95b",
        "u2615",
        "u1fad6",
        "u1f375",
        "u1f376",
        "u1f37e",
        "u1f377",
        "u1f378",
        "u1f379",
        "u1f37a",
        "u1f37b",
        "u1f942",
        "u1f943",
        "u1fad7",
        "u1f964",
        "u1f9cb",
        "u1f9c3",
        "u1f9c9",
        "u1f9ca",
        "u1f962",
        "u1f37d",
        "u1f374",
        "u1f944",
        "u1f52a",
        "u1fad9",
        "u1f3fa"
    ],
    "activity":[
        "u1f383",
        "u1f384",
        "u1f386",
        "u1f387",
        "u1f9e8",
        "u2728",
        "u1f388",
        "u1f389",
        "u1f38a",
        "u1f38b",
        "u1f38d",
        "u1f38e",
        "u1f38f",
        "u1f390",
        "u1f391",
        "u1f9e7",
        "u1f380",
        "u1f381",
        "u1f397",
        "u1f39f",
        "u1f3ab",
        "u1f396",
        "u1f3c6",
        "u1f3c5",
        "u1f947",
        "u1f948",
        "u1f949",
        "u26bd",
        "u26be",
        "u1f94e",
        "u1f3c0",
        "u1f3d0",
        "u1f3c8",
        "u1f3c9",
        "u1f3be",
        "u1f94f",
        "u1f3b3",
        "u1f3cf",
        "u1f3d1",
        "u1f3d2",
        "u1f94d",
        "u1f3d3",
        "u1f3f8",
        "u1f94a",
        "u1f94b",
        "u1f945",
        "u26f3",
        "u26f8",
        "u1f3a3",
        "u1f93f",
        "u1f3bd",
        "u1f3bf",
        "u1f6f7",
        "u1f94c",
        "u1f3af",
        "u1fa80",
        "u1fa81",
        "u1f3b1",
        "u1f52e",
        "u1fa84",
        "u1f9ff",
        "u1faac",
        "u1f3ae",
        "u1f579",
        "u1f3b0",
        "u1f3b2",
        "u1f9e9",
        "u1f9f8",
        "u1fa85",
        "u1faa9",
        "u1fa86",
        "u2660",
        "u2665",
        "u2666",
        "u2663",
        "u265f",
        "u1f0cf",
        "u1f004",
        "u1f3b4",
        "u1f3ad",
        "u1f5bc",
        "u1f3a8",
        "u1f9f5",
        "u1faa1",
        "u1f9f6",
        "u1faa2"
    ],
    "places":[
        "u1f30d",
        "u1f30e",
        "u1f30f",
        "u1f310",
        "u1f5fa",
        "u1f5fe",
        "u1f9ed",
        "u1f3d4",
        "u26f0",
        "u1f30b",
        "u1f5fb",
        "u1f3d5",
        "u1f3d6",
        "u1f3dc",
        "u1f3dd",
        "u1f3de",
        "u1f3df",
        "u1f3db",
        "u1f3d7",
        "u1f9f1",
        "u1faa8",
        "u1fab5",
        "u1f6d6",
        "u1f3d8",
        "u1f3da",
        "u1f3e0",
        "u1f3e1",
        "u1f3e2",
        "u1f3e3",
        "u1f3e4",
        "u1f3e5",
        "u1f3e6",
        "u1f3e8",
        "u1f3e9",
        "u1f3ea",
        "u1f3eb",
        "u1f3ec",
        "u1f3ed",
        "u1f3ef",
        "u1f3f0",
        "u1f492",
        "u1f5fc",
        "u1f5fd",
        "u26ea",
        "u1f54c",
        "u1f6d5",
        "u1f54d",
        "u26e9",
        "u1f54b",
        "u26f2",
        "u26fa",
        "u1f301",
        "u1f303",
        "u1f3d9",
        "u1f304",
        "u1f305",
        "u1f306",
        "u1f307",
        "u1f309",
        "u2668",
        "u1f3a0",
        "u1f6dd",
        "u1f3a1",
        "u1f3a2",
        "u1f488",
        "u1f3aa",
        "u1f682",
        "u1f683",
        "u1f684",
        "u1f685",
        "u1f686",
        "u1f687",
        "u1f688",
        "u1f689",
        "u1f68a",
        "u1f69d",
        "u1f69e",
        "u1f68b",
        "u1f68c",
        "u1f68d",
        "u1f68e",
        "u1f690",
        "u1f691",
        "u1f692",
        "u1f693",
        "u1f694",
        "u1f695",
        "u1f696",
        "u1f697",
        "u1f698",
        "u1f699",
        "u1f6fb",
        "u1f69a",
        "u1f69b",
        "u1f69c",
        "u1f3ce",
        "u1f3cd",
        "u1f6f5",
        "u1f9bd",
        "u1f9bc",
        "u1f6fa",
        "u1f6b2",
        "u1f6f4",
        "u1f6f9",
        "u1f6fc",
        "u1f68f",
        "u1f6e3",
        "u1f6e4",
        "u1f6e2",
        "u26fd",
        "u1f6de",
        "u1f6a8",
        "u1f6a5",
        "u1f6a6",
        "u1f6d1",
        "u1f6a7",
        "u2693",
        "u1f6df",
        "u26f5",
        "u1f6f6",
        "u1f6a4",
        "u1f6f3",
        "u26f4",
        "u1f6e5",
        "u1f6a2",
        "u2708",
        "u1f6e9",
        "u1f6eb",
        "u1f6ec",
        "u1fa82",
        "u1f4ba",
        "u1f681",
        "u1f69f",
        "u1f6a0",
        "u1f6a1",
        "u1f6f0",
        "u1f680",
        "u1f6f8",
        "u1f6ce",
        "u1f9f3",
        "u231b",
        "u23f3",
        "u231a",
        "u23f0",
        "u23f1",
        "u23f2",
        "u1f570",
        "u1f55b",
        "u1f567",
        "u1f550",
        "u1f55c",
        "u1f551",
        "u1f55d",
        "u1f552",
        "u1f55e",
        "u1f553",
        "u1f55f",
        "u1f554",
        "u1f560",
        "u1f555",
        "u1f561",
        "u1f556",
        "u1f562",
        "u1f557",
        "u1f563",
        "u1f558",
        "u1f564",
        "u1f559",
        "u1f565",
        "u1f55a",
        "u1f566",
        "u1f311",
        "u1f312",
        "u1f313",
        "u1f314",
        "u1f315",
        "u1f316",
        "u1f317",
        "u1f318",
        "u1f319",
        "u1f31a",
        "u1f31b",
        "u1f31c",
        "u1f321",
        "u2600",
        "u1f31d",
        "u1f31e",
        "u1fa90",
        "u2b50",
        "u1f31f",
        "u1f320",
        "u1f30c",
        "u2601",
        "u26c5",
        "u26c8",
        "u1f324",
        "u1f325",
        "u1f326",
        "u1f327",
        "u1f328",
        "u1f329",
        "u1f32a",
        "u1f32b",
        "u1f32c",
        "u1f300",
        "u1f308",
        "u1f302",
        "u2602",
        "u2614",
        "u26f1",
        "u26a1",
        "u2744",
        "u2603",
        "u26c4",
        "u2604",
        "u1f525",
        "u1f4a7",
        "u1f30a"
    ],
    "objects":[
        "u1f453",
        "u1f576",
        "u1f97d",
        "u1f97c",
        "u1f9ba",
        "u1f454",
        "u1f455",
        "u1f456",
        "u1f9e3",
        "u1f9e4",
        "u1f9e5",
        "u1f9e6",
        "u1f457",
        "u1f458",
        "u1f97b",
        "u1fa71",
        "u1fa72",
        "u1fa73",
        "u1f459",
        "u1f45a",
        "u1f45b",
        "u1f45c",
        "u1f45d",
        "u1f6cd",
        "u1f392",
        "u1fa74",
        "u1f45e",
        "u1f45f",
        "u1f97e",
        "u1f97f",
        "u1f460",
        "u1f461",
        "u1fa70",
        "u1f462",
        "u1f451",
        "u1f452",
        "u1f3a9",
        "u1f393",
        "u1f9e2",
        "u1fa96",
        "u26d1",
        "u1f4ff",
        "u1f484",
        "u1f48d",
        "u1f48e",
        "u1f507",
        "u1f508",
        "u1f509",
        "u1f50a",
        "u1f4e2",
        "u1f4e3",
        "u1f4ef",
        "u1f514",
        "u1f515",
        "u1f3bc",
        "u1f3b5",
        "u1f3b6",
        "u1f399",
        "u1f39a",
        "u1f39b",
        "u1f3a4",
        "u1f3a7",
        "u1f4fb",
        "u1f3b7",
        "u1fa97",
        "u1f3b8",
        "u1f3b9",
        "u1f3ba",
        "u1f3bb",
        "u1fa95",
        "u1f941",
        "u1fa98",
        "u1f4f1",
        "u1f4f2",
        "u260e",
        "u1f4de",
        "u1f4df",
        "u1f4e0",
        "u1f50b",
        "u1faab",
        "u1f50c",
        "u1f4bb",
        "u1f5a5",
        "u1f5a8",
        "u2328",
        "u1f5b1",
        "u1f5b2",
        "u1f4bd",
        "u1f4be",
        "u1f4bf",
        "u1f4c0",
        "u1f9ee",
        "u1f3a5",
        "u1f39e",
        "u1f4fd",
        "u1f3ac",
        "u1f4fa",
        "u1f4f7",
        "u1f4f8",
        "u1f4f9",
        "u1f4fc",
        "u1f50d",
        "u1f50e",
        "u1f56f",
        "u1f4a1",
        "u1f526",
        "u1f3ee",
        "u1fa94",
        "u1f4d4",
        "u1f4d5",
        "u1f4d6",
        "u1f4d7",
        "u1f4d8",
        "u1f4d9",
        "u1f4da",
        "u1f4d3",
        "u1f4d2",
        "u1f4c3",
        "u1f4dc",
        "u1f4c4",
        "u1f4f0",
        "u1f5de",
        "u1f4d1",
        "u1f516",
        "u1f3f7",
        "u1f4b0",
        "u1fa99",
        "u1f4b4",
        "u1f4b5",
        "u1f4b6",
        "u1f4b7",
        "u1f4b8",
        "u1f4b3",
        "u1f9fe",
        "u1f4b9",
        "u2709",
        "u1f4e7",
        "u1f4e8",
        "u1f4e9",
        "u1f4e4",
        "u1f4e5",
        "u1f4e6",
        "u1f4eb",
        "u1f4ea",
        "u1f4ec",
        "u1f4ed",
        "u1f4ee",
        "u1f5f3",
        "u270f",
        "u2712",
        "u1f58b",
        "u1f58a",
        "u1f58c",
        "u1f58d",
        "u1f4dd",
        "u1f4bc",
        "u1f4c1",
        "u1f4c2",
        "u1f5c2",
        "u1f4c5",
        "u1f4c6",
        "u1f5d2",
        "u1f5d3",
        "u1f4c7",
        "u1f4c8",
        "u1f4c9",
        "u1f4ca",
        "u1f4cb",
        "u1f4cc",
        "u1f4cd",
        "u1f4ce",
        "u1f587",
        "u1f4cf",
        "u1f4d0",
        "u2702",
        "u1f5c3",
        "u1f5c4",
        "u1f5d1",
        "u1f512",
        "u1f513",
        "u1f50f",
        "u1f510",
        "u1f511",
        "u1f5dd",
        "u1f528",
        "u1fa93",
        "u26cf",
        "u2692",
        "u1f6e0",
        "u1f5e1",
        "u2694",
        "u1f52b",
        "u1fa83",
        "u1f3f9",
        "u1f6e1",
        "u1fa9a",
        "u1f527",
        "u1fa9b",
        "u1f529",
        "u2699",
        "u1f5dc",
        "u2696",
        "u1f9af",
        "u1f517",
        "u26d3",
        "u1fa9d",
        "u1f9f0",
        "u1f9f2",
        "u1fa9c",
        "u2697",
        "u1f9ea",
        "u1f9eb",
        "u1f9ec",
        "u1f52c",
        "u1f52d",
        "u1f4e1",
        "u1f489",
        "u1fa78",
        "u1f48a",
        "u1fa79",
        "u1fa7c",
        "u1fa7a",
        "u1fa7b",
        "u1f6aa",
        "u1f6d7",
        "u1fa9e",
        "u1fa9f",
        "u1f6cf",
        "u1f6cb",
        "u1fa91",
        "u1f6bd",
        "u1faa0",
        "u1f6bf",
        "u1f6c1",
        "u1faa4",
        "u1fa92",
        "u1f9f4",
        "u1f9f7",
        "u1f9f9",
        "u1f9fa",
        "u1f9fb",
        "u1faa3",
        "u1f9fc",
        "u1fae7",
        "u1faa5",
        "u1f9fd",
        "u1f9ef",
        "u1f6d2",
        "u1f6ac",
        "u26b0",
        "u1faa6",
        "u26b1",
        "u1f5ff",
        "u1faa7",
        "u1faaa"
    ],
    "symbols":[
        "u1f3e7",
        "u1f6ae",
        "u1f6b0",
        "u267f",
        "u1f6b9",
        "u1f6ba",
        "u1f6bb",
        "u1f6bc",
        "u1f6be",
        "u1f6c2",
        "u1f6c3",
        "u1f6c4",
        "u1f6c5",
        "u26a0",
        "u1f6b8",
        "u26d4",
        "u1f6ab",
        "u1f6b3",
        "u1f6ad",
        "u1f6af",
        "u1f6b1",
        "u1f6b7",
        "u1f4f5",
        "u1f51e",
        "u2622",
        "u2623",
        "u2b06",
        "u2197",
        "u27a1",
        "u2198",
        "u2b07",
        "u2199",
        "u2b05",
        "u2196",
        "u2195",
        "u2194",
        "u21a9",
        "u21aa",
        "u2934",
        "u2935",
        "u1f503",
        "u1f504",
        "u1f519",
        "u1f51a",
        "u1f51b",
        "u1f51c",
        "u1f51d",
        "u1f6d0",
        "u269b",
        "u1f549",
        "u2721",
        "u2638",
        "u262f",
        "u271d",
        "u2626",
        "u262a",
        "u262e",
        "u1f54e",
        "u1f52f",
        "u2648",
        "u2649",
        "u264a",
        "u264b",
        "u264c",
        "u264d",
        "u264e",
        "u264f",
        "u2650",
        "u2651",
        "u2652",
        "u2653",
        "u26ce",
        "u1f500",
        "u1f501",
        "u1f502",
        "u25b6",
        "u23e9",
        "u23ed",
        "u23ef",
        "u25c0",
        "u23ea",
        "u23ee",
        "u1f53c",
        "u23eb",
        "u1f53d",
        "u23ec",
        "u23f8",
        "u23f9",
        "u23fa",
        "u23cf",
        "u1f3a6",
        "u1f505",
        "u1f506",
        "u1f4f6",
        "u1f4f3",
        "u1f4f4",
        "u2640",
        "u2642",
        "u26a7",
        "u2716",
        "u2795",
        "u2796",
        "u2797",
        "u1f7f0",
        "u267e",
        "u203c",
        "u2049",
        "u2753",
        "u2754",
        "u2755",
        "u2757",
        "u3030",
        "u1f4b1",
        "u1f4b2",
        "u2695",
        "u267b",
        "u269c",
        "u1f531",
        "u1f4db",
        "u1f530",
        "u2b55",
        "u2705",
        "u2611",
        "u2714",
        "u274c",
        "u274e",
        "u27b0",
        "u27bf",
        "u303d",
        "u2733",
        "u2734",
        "u2747",
        "u00a9",
        "u00ae",
        "u2122",
        "u0023_fe0f_20e3",
        "u002a_fe0f_20e3",
        "u0030_fe0f_20e3",
        "u0031_fe0f_20e3",
        "u0032_fe0f_20e3",
        "u0033_fe0f_20e3",
        "u0034_fe0f_20e3",
        "u0035_fe0f_20e3",
        "u0036_fe0f_20e3",
        "u0037_fe0f_20e3",
        "u0038_fe0f_20e3",
        "u0039_fe0f_20e3",
        "u1f51f",
        "u1f520",
        "u1f521",
        "u1f522",
        "u1f523",
        "u1f524",
        "u1f170",
        "u1f18e",
        "u1f171",
        "u1f191",
        "u1f192",
        "u1f193",
        "u2139",
        "u1f194",
        "u24c2",
        "u1f195",
        "u1f196",
        "u1f17e",
        "u1f197",
        "u1f17f",
        "u1f198",
        "u1f199",
        "u1f19a",
        "u1f201",
        "u1f202",
        "u1f237",
        "u1f236",
        "u1f22f",
        "u1f250",
        "u1f239",
        "u1f21a",
        "u1f232",
        "u1f251",
        "u1f238",
        "u1f234",
        "u1f233",
        "u3297",
        "u3299",
        "u1f23a",
        "u1f235",
        "u1f534",
        "u1f7e0",
        "u1f7e1",
        "u1f7e2",
        "u1f535",
        "u1f7e3",
        "u1f7e4",
        "u26ab",
        "u26aa",
        "u1f7e5",
        "u1f7e7",
        "u1f7e8",
        "u1f7e9",
        "u1f7e6",
        "u1f7ea",
        "u1f7eb",
        "u2b1b",
        "u2b1c",
        "u25fc",
        "u25fb",
        "u25fe",
        "u25fd",
        "u25aa",
        "u25ab",
        "u1f536",
        "u1f537",
        "u1f538",
        "u1f539",
        "u1f53a",
        "u1f53b",
        "u1f4a0",
        "u1f518",
        "u1f533",
        "u1f532"
    ],
    "flags":[
        "u1f3c1",
        "u1f1e8_1f1f3",
        "u1f38c",
        "u1f1e9_1f1ea",
        "u1f1ea_1f1f8",
        "u1f1e6_1f1e8",
        "u1f1e6_1f1e9",
        "u1f1e6_1f1ea",
        "u1f1e6_1f1eb",
        "u1f1e6_1f1ec",
        "u1f1e6_1f1ee",
        "u1f1e6_1f1f1",
        "u1f1e6_1f1f2",
        "u1f1e6_1f1f4",
        "u1f1e6_1f1f6",
        "u1f1e6_1f1f7",
        "u1f1e6_1f1f8",
        "u1f1e6_1f1f9",
        "u1f1e6_1f1fa",
        "u1f1e6_1f1fc",
        "u1f1e6_1f1fd",
        "u1f1e6_1f1ff",
        "u1f1e7_1f1e6",
        "u1f1e7_1f1e7",
        "u1f1e7_1f1e9",
        "u1f1e7_1f1ea",
        "u1f1e7_1f1eb",
        "u1f1e7_1f1ec",
        "u1f1e7_1f1ed",
        "u1f1e7_1f1ee",
        "u1f1e7_1f1ef",
        "u1f1e7_1f1f1",
        "u1f1e7_1f1f2",
        "u1f1e7_1f1f3",
        "u1f1e7_1f1f4",
        "u1f1e7_1f1f6",
        "u1f1e7_1f1f7",
        "u1f1e7_1f1f8",
        "u1f1e7_1f1f9",
        "u1f1e7_1f1fb",
        "u1f1e7_1f1fc",
        "u1f1e7_1f1fe",
        "u1f1e7_1f1ff",
        "u1f1e8_1f1e6",
        "u1f1e8_1f1e8",
        "u1f1e8_1f1e9",
        "u1f1e8_1f1eb",
        "u1f1e8_1f1ec",
        "u1f1e8_1f1ed",
        "u1f1e8_1f1ee",
        "u1f1e8_1f1f0",
        "u1f1e8_1f1f1",
        "u1f1e8_1f1f2",
        "u1f1e8_1f1f4",
        "u1f1e8_1f1f5",
        "u1f1e8_1f1f7",
        "u1f1e8_1f1fa",
        "u1f1e8_1f1fb",
        "u1f1e8_1f1fc",
        "u1f1e8_1f1fd",
        "u1f1e8_1f1fe",
        "u1f1e8_1f1ff",
        "u1f1e9_1f1ec",
        "u1f1e9_1f1ef",
        "u1f1e9_1f1f0",
        "u1f1e9_1f1f2",
        "u1f1e9_1f1f4",
        "u1f1e9_1f1ff",
        "u1f1ea_1f1e6",
        "u1f1ea_1f1e8",
        "u1f1ea_1f1ea",
        "u1f1ea_1f1ec",
        "u1f1ea_1f1ed",
        "u1f3f4_e0067_e0062_e0065_e006e_e0067_e007f",
        "u1f1ea_1f1f7",
        "u1f1ea_1f1f9",
        "u1f1ea_1f1fa",
        "u1f1eb_1f1ee",
        "u1f1eb_1f1ef",
        "u1f1eb_1f1f0",
        "u1f1eb_1f1f2",
        "u1f1eb_1f1f4",
        "u1f1ec_1f1e6",
        "u1f1ec_1f1e9",
        "u1f1ec_1f1ea",
        "u1f1ec_1f1eb",
        "u1f1ec_1f1ec",
        "u1f1ec_1f1ed",
        "u1f1ec_1f1ee",
        "u1f1ec_1f1f1",
        "u1f1ec_1f1f2",
        "u1f1ec_1f1f3",
        "u1f1ec_1f1f5",
        "u1f1ec_1f1f6",
        "u1f1ec_1f1f7",
        "u1f1ec_1f1f8",
        "u1f1ec_1f1f9",
        "u1f1ec_1f1fa",
        "u1f1ec_1f1fc",
        "u1f1ec_1f1fe",
        "u1f1ed_1f1f0",
        "u1f1ed_1f1f2",
        "u1f1ed_1f1f3",
        "u1f1ed_1f1f7",
        "u1f1ed_1f1f9",
        "u1f1ed_1f1fa",
        "u1f1ee_1f1e8",
        "u1f1ee_1f1e9",
        "u1f1ee_1f1ea",
        "u1f1ee_1f1f1",
        "u1f1ee_1f1f2",
        "u1f1ee_1f1f3",
        "u1f1ee_1f1f4",
        "u1f1ee_1f1f6",
        "u1f1ee_1f1f7",
        "u1f1ee_1f1f8",
        "u1f1ef_1f1ea",
        "u1f1ef_1f1f2",
        "u1f1ef_1f1f4",
        "u1f1f0_1f1ea",
        "u1f1f0_1f1ec",
        "u1f1f0_1f1ed",
        "u1f1f0_1f1ee",
        "u1f1f0_1f1f2",
        "u1f1f0_1f1f3",
        "u1f1f0_1f1f5",
        "u1f1f0_1f1fc",
        "u1f1f0_1f1fe",
        "u1f1f0_1f1ff",
        "u1f1f1_1f1e6",
        "u1f1f1_1f1e7",
        "u1f1f1_1f1e8",
        "u1f1f1_1f1ee",
        "u1f1f1_1f1f0",
        "u1f1f1_1f1f7",
        "u1f1f1_1f1f8",
        "u1f1f1_1f1f9",
        "u1f1f1_1f1fa",
        "u1f1f1_1f1fb",
        "u1f1f1_1f1fe",
        "u1f1f2_1f1e6",
        "u1f1f2_1f1e8",
        "u1f1f2_1f1e9",
        "u1f1f2_1f1ea",
        "u1f1f2_1f1eb",
        "u1f1f2_1f1ec",
        "u1f1f2_1f1ed",
        "u1f1f2_1f1f0",
        "u1f1f2_1f1f1",
        "u1f1f2_1f1f2",
        "u1f1f2_1f1f3",
        "u1f1f2_1f1f4",
        "u1f1f2_1f1f5",
        "u1f1f2_1f1f6",
        "u1f1f2_1f1f7",
        "u1f1f2_1f1f8",
        "u1f1f2_1f1f9",
        "u1f1f2_1f1fa",
        "u1f1f2_1f1fb",
        "u1f1f2_1f1fc",
        "u1f1f2_1f1fd",
        "u1f1f2_1f1fe",
        "u1f1f2_1f1ff",
        "u1f1f3_1f1e6",
        "u1f1f3_1f1e8",
        "u1f1f3_1f1ea",
        "u1f1f3_1f1eb",
        "u1f1f3_1f1ec",
        "u1f1f3_1f1ee",
        "u1f1f3_1f1f1",
        "u1f1f3_1f1f4",
        "u1f1f3_1f1f5",
        "u1f1f3_1f1f7",
        "u1f1f3_1f1fa",
        "u1f1f3_1f1ff",
        "u1f1f4_1f1f2",
        "u1f1f5_1f1e6",
        "u1f1f5_1f1ea",
        "u1f1f5_1f1eb",
        "u1f1f5_1f1ec",
        "u1f1f5_1f1ed",
        "u1f1f5_1f1f0",
        "u1f1f5_1f1f1",
        "u1f1f5_1f1f2",
        "u1f1f5_1f1f3",
        "u1f1f5_1f1f7",
        "u1f1f5_1f1f8",
        "u1f1f5_1f1f9",
        "u1f1f5_1f1fc",
        "u1f1f5_1f1fe",
        "u1f1f6_1f1e6",
        "u1f1f7_1f1ea",
        "u1f1f7_1f1f4",
        "u1f1f7_1f1f8",
        "u1f1f7_1f1fc",
        "u1f1f8_1f1e6",
        "u1f1f8_1f1e7",
        "u1f1f8_1f1e8",
        "u1f3f4_e0067_e0062_e0073_e0063_e0074_e007f",
        "u1f1f8_1f1e9",
        "u1f1f8_1f1ea",
        "u1f1f8_1f1ec",
        "u1f1f8_1f1ed",
        "u1f1f8_1f1ee",
        "u1f1f8_1f1ef",
        "u1f1f8_1f1f0",
        "u1f1f8_1f1f1",
        "u1f1f8_1f1f2",
        "u1f1f8_1f1f3",
        "u1f1f8_1f1f4",
        "u1f1f8_1f1f7",
        "u1f1f8_1f1f8",
        "u1f1f8_1f1f9",
        "u1f1f8_1f1fb",
        "u1f1f8_1f1fd",
        "u1f1f8_1f1fe",
        "u1f1f8_1f1ff",
        "u1f1f9_1f1e6",
        "u1f1f9_1f1e8",
        "u1f1f9_1f1e9",
        "u1f1f9_1f1eb",
        "u1f1f9_1f1ec",
        "u1f1f9_1f1ed",
        "u1f1f9_1f1ef",
        "u1f1f9_1f1f0",
        "u1f1f9_1f1f1",
        "u1f1f9_1f1f2",
        "u1f1f9_1f1f3",
        "u1f1f9_1f1f4",
        "u1f1f9_1f1f7",
        "u1f1f9_1f1f9",
        "u1f1f9_1f1fb",
        "u1f1f9_1f1fc",
        "u1f1f9_1f1ff",
        "u1f1fa_1f1e6",
        "u1f1fa_1f1ec",
        "u1f1fa_1f1f2",
        "u1f1fa_1f1f3",
        "u1f1fa_1f1fe",
        "u1f1fa_1f1ff",
        "u1f1fb_1f1e6",
        "u1f1fb_1f1e8",
        "u1f1fb_1f1ea",
        "u1f1fb_1f1ec",
        "u1f1fb_1f1ee",
        "u1f1fb_1f1f3",
        "u1f1fb_1f1fa",
        "u1f3f4_e0067_e0062_e0077_e006c_e0073_e007f",
        "u1f1fc_1f1eb",
        "u1f1fc_1f1f8",
        "u1f1fd_1f1f0",
        "u1f1fe_1f1ea",
        "u1f1fe_1f1f9",
        "u1f1ff_1f1e6",
        "u1f1ff_1f1f2",
        "u1f1ff_1f1fc",
        "u1f1eb_1f1f7",
        "u1f1ec_1f1e7",
        "u1f1ee_1f1f9",
        "u1f1ef_1f1f5",
        "u1f1f0_1f1f7",
        "u1f3f4_200d_2620_fe0f",
        "u1f3f3_fe0f_200d_1f308",
        "u1f1f7_1f1fa",
        "u1f3f3_fe0f_200d_26a7_fe0f",
        "u1f6a9",
        "u1f1fa_1f1f8",
        "u1f3f4",
        "u1f3f3"
    ]
}